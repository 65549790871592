import * as React from 'react';
import {useState} from 'react';
import {
  BackgroundColor,
  Button,
  ButtonSkin,
  classNames,
  Grid, JustifyContent,
  MaxWidth,
  Width
} from '@snoam/pinata';
import {Modal} from "@snoam/mono-modal";
import {Address, IAddress} from '@snoam/mono-address/lib/Address';
import ErrorBoxInvalidAddress from './ErrorBoxInvalidAddress';


interface IAddressModal {
  id: string;
  onClose: () => void;
  addressUppdated: (address: IAddress) => void;
  address: IAddress;
}

const AddressModal = ({id, onClose, address, addressUppdated}: IAddressModal) => {
  const [stateAddress, setAddress] = useState(address as IAddress);
  const [showValidationError, setShowValidationError] = useState(false);

  function isValidAddress(): boolean {
    return !!(stateAddress.streetNumber && stateAddress.streetNumber.deliveryPointId) ||
      !!(stateAddress.houseHold && stateAddress.houseHold.deliveryPointId);
  }

  function updateAddress() {
    if (isValidAddress()) {
      setShowValidationError(false);
      addressUppdated(stateAddress);
    } else {
      setShowValidationError(true);
    }
  }

  function onAddressUpdated(address: IAddress) {
    setAddress(address);
    setShowValidationError(false);
  }

  return (
    <Modal
      key={id}
      onClose={onClose}
      backdrop={true}
      id={id}
      className={BackgroundColor.BG_NEUTRAL_1}
    >
      <input tabIndex={-1} style={{opacity: 0, position: "absolute"}} id={"disable-chrome-autocomplete"} />
      <Address
        key={`address_picker`}
        {...stateAddress}
        addressUpdated={onAddressUpdated}
      />

      {showValidationError && <ErrorBoxInvalidAddress/>}

      <Grid className={classNames(JustifyContent.JUSTIFY_CENTER, Width.W_FULL)}>
        <Button
          text="Lagre adresse"
          ariaLabel="Lagre adresse"
          onClick={() => updateAddress()}
          className={MaxWidth.MAX_W_MD}
        />
        <Button
          text="Avbyt"
          ariaLabel="Avbyt"
          onClick={onClose}
          className={MaxWidth.MAX_W_MD}
          skin={ButtonSkin.SECONDARY}
        />
      </Grid>
    </Modal>
  );
};

export default AddressModal;
