import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  classNames,
  Display,
  FlexDirection,
  FlexWrap,
  FontWeight,
  Grid,
  GridComposition,
  GridGutter,
  GridItem,
  GridSpan,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Margin,
  MaxWidth,
  MinHeight,
  Padding,
  TextColor,
  Width,
} from '@snoam/pinata';
import * as React from 'react';
import {useContext} from 'react';
import Table from '../Table/Table';
import {isSubscriptionActive, ISubscription} from '../../models/EmployeeModel';
import Circle from './Circle';
import TerminateSubscriptionButton from './TerminateSubscriptionButton';
import UndoTerminateSubscriptionButton from './UndoTerminateSubscriptionButton';
import InvoiceButton from './InvoiceButton';
import References from './References';
import TerminateSubscriptionModal from "./TerminateSubscriptionModal";
import {ModalContext} from "@snoam/mono-modal";
import {AdminType, PaymentLevel, SubscriptionAgreementStatus} from "../../__generated__/globalTypes";
import {GetAgreement_me_agreementsWithDetails,} from "../../__generated__/GetAgreement";
import {useRole} from "../Boundary/RoleBoundary";
import AccessBoundary from "../Boundary/AccessBoundary";
import {SuperAdminOrAdminBoundary} from "../../routes/compositions/boundaries/RoleBoundary";

const styleClass = {
  root: (status: SubscriptionAgreementStatus) => classNames(
    FlexWrap.FLEX_WRAP,
    {
      [TextColor.TEXT_NEUTRAL_5]: !isSubscriptionActive(status)
    },
  ),
  subscriptionName: (status: SubscriptionAgreementStatus) => classNames(
    FontWeight.FONT_BOLD,
    {
      [TextColor.TEXT_BLACK]: isSubscriptionActive(status)
    }
  ),
  description: (status: SubscriptionAgreementStatus) => classNames(
    {
      [TextColor.TEXT_NEUTRAL_6]: isSubscriptionActive(status)
    },
    Margin.ML_1,
  ),
  subscriptionColumn: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    FlexWrap.FLEX_WRAP,
    Width.W_FULL,
    'subscriptionColumn',
  ),
  changeSubscriptionColumn: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    FlexWrap.FLEX_WRAP,
    MinHeight.MIN_H_24,
    Padding.PR_4,
    Width.W_FULL,
    Width.SM_W_1_2,
    'changeSubscriptionColumn',
  ),
  toggleText: (status: SubscriptionAgreementStatus) => classNames(
    {
      [TextColor.TEXT_PRIMARY_3]: isSubscriptionActive(status),
      [TextColor.TEXT_NEUTRAL_3]: !isSubscriptionActive(status),
    },
    Margin.MR_1
  ),
  headerColumn: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    FlexWrap.FLEX_WRAP,
    Width.W_FULL,
    'headerColumn',
  ),
  krWithCircle: (status: SubscriptionAgreementStatus) => classNames(
    Margin.MR_1,
    {
      [TextColor.TEXT_PRIMARY_3]: isSubscriptionActive(status),
      [BorderColor.BORDER_PRIMARY_3]: isSubscriptionActive(status),
      [BorderColor.BORDER_NEUTRAL_3]: !isSubscriptionActive(status)
    }
  ),
  verticalAlign: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    FlexWrap.FLEX_WRAP,
  ),
  subscription: classNames(
    Display.FLEX,
    Width.W_FULL,
    FlexWrap.FLEX_WRAP,
    Padding.PT_4,
    Padding.PB_4,
    MaxWidth.MAX_W_2XL,
    Padding.SM_PX_4,
    AlignItems.ITEMS_CENTER

  ),
  taxBox: classNames(
    Padding.XS_PT_2,
    Padding.SM_PT_6,
    Padding.LG_PT_1,
    Display.FLEX,
    FlexDirection.FLEX_COL,
  ),
  taxBoxBold: classNames(
    FontWeight.FONT_BOLD,
    Padding.PR_1,
    Padding.PL_2,
  ),
  taxUntilNowBox: classNames(
    Padding.PR_1,
    Padding.PL_1,
  ),
};

export interface Props {
  agreement: Pick<GetAgreement_me_agreementsWithDetails,
    | "accountName"
    | "accountNumber"
    | "agreementNumber"
    | "departments"
  >;
  subscriptions: ISubscription[];
}

function renderSubscription(subscription: ISubscription, expanded: boolean) {
  const { id, name, description, startDate, endDate, reference1, reference2, status } = subscription;

  return (
    <Grid
      key={id}
      title={id}
      composition={GridComposition.DEFAULT}
      className={styleClass.root(status)}
    >
      <GridItem>
        <div className={styleClass.verticalAlign}>
          <div className={styleClass.subscriptionName(status)}>{name}</div>
          <div className={styleClass.description(status)}>{description}</div>
        </div>
      </GridItem>

      <Grid
        isNested={true}
        composition={GridComposition.DEFAULT}
        className={classNames(FlexWrap.FLEX_WRAP, Width.W_FULL, Margin.MT_2)}
        gutter={GridGutter.NONE}
      >

        <GridItem
          className={classNames(Padding.XS_PT_1)}
          span={[GridSpan.XSMALL_12, GridSpan.SMALL_12, GridSpan.MEDIUM_12, GridSpan.LARGE_5]}
        >
          <div className={classNames(styleClass.verticalAlign)}>
            {startDate && <div className={Margin.MY_1}>Startdato: {startDate.format('DD.MM.YYYY')}</div>}
            <div className={Margin.MX_2}>{endDate && <Circle />}</div>
            <div>{endDate && <>Sluttdato: {endDate.format('DD.MM.YYYY')}</>}</div>
          </div>
        </GridItem>


        { // We do not want to remove until we know the markets does not want this feature
          /*<GridItem
          className={styleClass.taxBox}
          span={[GridSpan.XSMALL_12, GridSpan.SMALL_12, GridSpan.MEDIUM_12, GridSpan.LARGE_4]}
        >
          {
            taxes.map((tax, i) => {
              return (
                <GridItem key={i} className={classNames(Padding.XS_PT_1, Padding.SM_PT_0)}>
                  <div className={classNames(Display.SM_FLEX, Display.XS_HIDDEN)}>
                    {tax.year === moment().format('YYYY') ? <><KrWithCircle className={styleClass.krWithCircle(status)} /></> : <div className={Margin.MX_3}/>}
                    {tax.year}:
                    <span className={styleClass.taxBoxBold}>{tax.year === moment().format('YYYY') ? tax.taxForEntireYear : tax.taxForPeriod} kr</span>
                    <span className={styleClass.taxUntilNowBox}>{tax.year === moment().format('YYYY') ? `(til nå ${tax.taxForPeriod} kr) *` : ``}</span>*!/
                  </div>
                </GridItem>
              )
            })
          }
        </GridItem>*/}


        <GridItem
          className={Padding.XS_PT_2}
          span={[GridSpan.XSMALL_12, GridSpan.SMALL_12, GridSpan.MEDIUM_12, GridSpan.LARGE_3]}
        >
          <div className={styleClass.verticalAlign}>
            {!expanded && reference1 && <div>{reference1.value}</div>}
            {!expanded && reference1 &&
              <div className={classNames(Margin.MX_2)}>{reference1 && reference2 && reference2.value && <Circle />}</div>}
            {!expanded && reference2 && <div>{reference2.value}</div>}
          </div>
        </GridItem>

      </Grid>
    </Grid>
  );
}

function closeSymbolRenderer(subscription: ISubscription) {
  return (
    <div className={classNames(Width.W_16, Display.FLEX, AlignItems.ITEMS_CENTER)}>
      <div className={styleClass.toggleText(subscription.status)}>Lukk</div>
      <Icon name={IconName.CLOSE} size={IconSize.MEDIUM} color={IconColor.NEUTRAL_4} />
    </div>
  )
}

function expandSymbolRenderer(subscription: ISubscription) {

  if (!isSubscriptionActive(subscription.status)) {
    return (
      <div className={classNames(Width.W_16, Display.FLEX, AlignItems.ITEMS_CENTER)}>
        <div className={styleClass.toggleText(subscription.status)}>Avsluttet</div>
      </div>
    )
  }

  return (
    <AccessBoundary role={[AdminType.SUPERADMIN, AdminType.ADMIN]}>
      <div className={classNames(Width.W_16, Display.FLEX, AlignItems.ITEMS_CENTER)}>
        <div className={styleClass.toggleText(subscription.status)}>Endre?</div>
        <Icon name={IconName._EDIT} size={IconSize.MEDIUM} color={IconColor.NEUTRAL_4} />
      </div>
    </AccessBoundary>
  )
}

const SubscriptionsTable = ({ agreement, subscriptions }: Props) => {

  const { accountName, accountNumber, agreementNumber, departments } = agreement;
  const { closeModal, openModal, openModalId } = useContext(ModalContext);
  const {role: userRole} = useRole();
  const toggleModal = (terminateSubscriptionModalId: string) => () => {
    openModalId ? closeModal() : openModal(terminateSubscriptionModalId)
  };

  function renderChangeSubscription(agreementNumber: number, subscription: ISubscription) {
    const { endDate, stopOrders } = subscription;
    const modalId = `terminate-subscription-modal-${subscription.assetNumber}`;
    const department = departments.find(o => o.id === subscription.location);

    return (
      <div className={styleClass.subscription}>

        <References
          className={styleClass.changeSubscriptionColumn}
          agreementNumber={agreementNumber}
          subscription={subscription}
          allSubscriptions={subscriptions}
          disabled={userRole !== AdminType.SUPERADMIN}
        />

        <div className={classNames(styleClass.changeSubscriptionColumn, Padding.SM_PR_4)}>
          {subscription.agreementPaymentlevel === PaymentLevel.Department && <>
            <div
              className={classNames(FontWeight.FONT_BOLD, Padding.PT_10)}>Fakturabetaler: {(department || { name: accountName }).name}</div>
            <div className={classNames(Padding.PY_1)}>Ta kontakt med kundeservice for endringer i fakturabetaler</div>
          </>}
          {subscription.agreementPaymentlevel === PaymentLevel.Company && <>
            <div className={classNames(FontWeight.FONT_BOLD, Padding.PT_10)}>Fakturabetaler: Konsern</div>
            <div className={classNames(Padding.PY_1)}>Ta kontakt med kundeservice for endringer i fakturabetaler</div>
          </>}
          {subscription.agreementPaymentlevel === PaymentLevel.Employee && (
            <InvoiceButton classNames={Padding.PT_10}
                           accountNumber={accountNumber}
                           agreementNumber={+subscription.agreementNumber}
            />
            )}
        </div>

        <div className={classNames(styleClass.changeSubscriptionColumn)}>
          {!endDate &&
            <SuperAdminOrAdminBoundary noAccess={null}>
              <TerminateSubscriptionButton classNames={Padding.PT_10} onClick={toggleModal(modalId)} />
              <TerminateSubscriptionModal
                id={modalId}
                onClose={closeModal}
                subscription={subscription}
                agreementNumber={agreementNumber}
              />
            </SuperAdminOrAdminBoundary>
          }
          {endDate && stopOrders.length && (
            <SuperAdminOrAdminBoundary noAccess={null}>
              <UndoTerminateSubscriptionButton
                agreementNumber={agreementNumber}
                assetNumber={subscription.assetNumber}
                classNames={Padding.PT_10}
                stopOrders={stopOrders}
              />
            </SuperAdminOrAdminBoundary>
            )
          }
        </div>
      </div>
    );
  }

  return (
    <Table
      rows={subscriptions}
      rowRenderer={renderSubscription}
      canExpand={(subscription: ISubscription) => [SubscriptionAgreementStatus.Aktiv, SubscriptionAgreementStatus.Avventende].indexOf(subscription.status) > -1}
      expandSymbolRenderer={expandSymbolRenderer}
      closeSymbolRenderer={closeSymbolRenderer}
      expandedRenderer={(subscription: ISubscription) => renderChangeSubscription(agreementNumber, subscription)}
      backgroundColorHeaderExpanded={BackgroundColor.BG_WHITE}
      backgroundColorBodyExpanded={BackgroundColor.BG_NEUTRAL_2}
    />
  );
};

export default SubscriptionsTable;
