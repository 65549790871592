import { GetAgreement_me_agreementsWithDetails, } from "../../../../__generated__/GetAgreement";
import React, { FormEventHandler, useEffect, useState } from "react";
import { Arrow, Button, Icon, IconName, Margin } from "@snoam/pinata";
import {
  UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsAzureAdGraphApi as MutationReturnValue,
  UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsAzureAdGraphApiVariables as MutationVariables
} from "../../../../__generated__/UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsAzureAdGraphApi";
import { MutationFunction, useMutation } from "@apollo/client";
import {
  UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_AZURE_AD_GRAPH_API as MUTATION
} from "../../../../mutations";
import { ApolloError } from "@apollo/client";
import { styleClass } from "../VerificationSectionOpenidConnect";
import { MessageBox } from "@snoam/mono-messagebox";
import { EmploymentPeriodicVerificationMethod } from "../../../../__generated__/globalTypes";

interface EmploymentPeriodicVerificationSettingsAzureAdGraphApiProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}


interface SaveStatus {
  error?: string[];
  pending: boolean;
  success: boolean;
}

const EmploymentPeriodicVerificationSettingsAzureAdGraphApi: React.FC<EmploymentPeriodicVerificationSettingsAzureAdGraphApiProps> = ({ agreement }) => {

  const { id: agreementId, agreementNumber, settings: { employeePeriodicVerificationSettings } } = agreement;

  const [saveStatus, setSaveStatus] = useState<SaveStatus>({ error: undefined, pending: false, success: false });
  const [showSaveButton, setShowSaveButton] = useState<boolean>(employeePeriodicVerificationSettings !== null);
  const updateAgreementSettings: MutationFunction<MutationReturnValue, MutationVariables> = useMutation<MutationReturnValue, MutationVariables>(MUTATION)[0];

  const hasChanges = (): boolean => employeePeriodicVerificationSettings === null || employeePeriodicVerificationSettings.__typename !== "EmploymentPeriodicVerificationSettingsAzureAdGraphApi";

  useEffect(() => {
    setShowSaveButton(hasChanges());
  }, [employeePeriodicVerificationSettings, saveStatus.error, saveStatus.pending, saveStatus.success]);

  const saveButtonLabel = saveStatus.pending ? "Aktiverer Azure AD Graph API for periodisk verifisering..." : "Aktiver Azure AD Graph API for periodisk verifisering";

  const save = () => {
    setSaveStatus({ error: undefined, pending: true, success: false });
    const variables: MutationVariables = {
      agreementNumber: agreementNumber,
      agreementId: agreementId,
      settings: {
        type: EmploymentPeriodicVerificationMethod.AZURE_AD,
      },
    };

    updateAgreementSettings({ variables })
      .then(() => {
        setSaveStatus({ error: undefined, pending: false, success: true });
      })
      .catch((error: ApolloError) => {
        const states: { [k:string]: string[] } = (error.graphQLErrors || [])
          .map((e) => (e as any).state as { [k: string]: string[] })
          .filter(Boolean)
          .reduce((result, s) => Object.assign(result, s), {});

        if (Object.keys(states).length) {
          setSaveStatus({ error: [JSON.stringify(states)], pending: false, success: false });
        } else {
          setSaveStatus({
            error: [error.message],
            pending: false,
            success: false,
          });
        }
      });
  };

  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (hasChanges()) {
      save();
    }
  };

  return (
    <>
      <div className={ styleClass.settings.openidContainer }>
        <form className={ styleClass.settings.openidInputContainer } onSubmit={ submit }>
          { showSaveButton && <Button ariaLabel={ saveButtonLabel } text={ saveButtonLabel }/> }
          { (!showSaveButton && saveStatus.success) && (
            <div className={styleClass.settings.message}>
              <MessageBox
                message={`Endringene er registrert.`}
                backgroundColor={`#BFE6D7`}
                arrow={Arrow.TOP_CENTER}
                icon={
                  <Icon
                    name={IconName.CHECK}
                    style={{color: 'rgb(1, 154, 2)'}}
                    className={`${Margin.MR_3}`}
                  />
                }
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EmploymentPeriodicVerificationSettingsAzureAdGraphApi;
