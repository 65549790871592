import {Link} from "react-router-dom";
import * as React from "react";
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Display,
  FlexDirection,
  Height,
  Icon, IconColor, IconName, IconSize,
  JustifyContent, Padding,
  TextDecoration,
  Width
} from "@snoam/pinata";
import PathBoundary from "../PathBoundary/PathBoundary";
import { Publication, publications } from '../../utils';
import { useClientContext } from '../../context/ClientContextProvider';


const styleClass = {
  logo: {
    root: (exclude: boolean, mobile: boolean) => classNames(
      {
        [BackgroundColor.BG_PRIMARY_1]: !exclude,
        [Padding.PY_1]: !exclude,
        [Padding.PY_6]: exclude,
        [Padding.PX_2]: exclude,

        // Mobile
        [Width.W_16]: mobile,
        [Height.H_16]: mobile,
        [Display.FLEX]: mobile,
        [JustifyContent.JUSTIFY_CENTER]: mobile,
        [AlignItems.ITEMS_CENTER]: mobile
      },
      TextDecoration.NO_UNDERLINE,
      Display.FLEX,
      FlexDirection.FLEX_ROW,
      JustifyContent.JUSTIFY_END
    ),
  }
};


export interface ILogoProps {
  mobile: boolean;
}

export interface IPublicationLogoProps {
  publicationName: string;
  className: string;
  tabIndex?: number;
}

export const Logo: React.FC<ILogoProps> = ({mobile}) => {

  const logoImg = (
    <div className={styleClass.logo.root(true, false)}>
    <img
      src={`${process.env.PUBLIC_URL}/svg/publications/schibsted.svg`}
      className={classNames(Width.W_48)}
      alt={'Schibsted Logo'}
    />
    </div>
  );

  return (
    <PathBoundary
      excludeIfUrl={'/bestill'}
      elementToRender={logoImg}
    >
      <Link to={'/'} className={styleClass.logo.root(false, mobile)} title={'Til Startsiden'} >
        <Icon
          name={IconName.SCHIBSTED_LOGO_SIMPLE}
          size={mobile ? IconSize.LARGE : IconSize.XXLARGE}
          color={IconColor.PRIMARY_LIGHT}
          screenReaderText={'Schibsted Logo'}
        />
      </Link>
    </PathBoundary>
  )
};

export const PublicationLogo = (props: IPublicationLogoProps = {className: '', publicationName: 'SCH', tabIndex: 0}) => {
  const { isProduction } = useClientContext();
  const publication = publications(isProduction).find((publication: Publication) => publication.productGroup === props.publicationName);
  if(publication) {
    return (
      <div>
        <img
          src={`${process.env.PUBLIC_URL}${publication.logo}`}
          className={props.className}
          alt={publication ? `${publication.productGroup} Logo` : 'Schibsted Logo'}
        />
      </div>
    );
  }
  return (
    <Icon
      name={IconName.SCHIBSTED_LOGO_SIMPLE}
      size={IconSize.XXLARGE}
      color={IconColor.PRIMARY_DARK}
      screenReaderText={'Schibsted Logo'}
    />
  )
};

export default Logo;
