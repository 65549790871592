import { GetAgreements_me_agreements_edges_settings_reference1 } from '../__generated__/GetAgreements';
import { GetAgreement_me_agreementsWithDetails_settings_reference2 } from '../__generated__/GetAgreement';
import {PaymentLevel, ReferenceType} from '../__generated__/globalTypes';

export interface INumberOfProductsType {
  name: string;
  value: string;
}

export const numberOfProducts = (): INumberOfProductsType[] => {
  const productsAllowed: INumberOfProductsType[] = [];
  for (let i = 0; i <= 10; i++) {
    if(i === 0) {
      productsAllowed.push({
        name: 'Ubegrenset antall',
        value: `${i}`,
      });
    } else {
      productsAllowed.push({
        name: `Max ${i} produkt${i > 1 ? 'er' : ''}`,
        value: `${i}`,
      });
    }
  }
  return productsAllowed;
};

export interface IReferenceType {
  name: string;
  type: ReferenceType;
  allowedValues: string[] | null;
}

export type AgreementReference = Pick<GetAgreements_me_agreements_edges_settings_reference1 | GetAgreement_me_agreementsWithDetails_settings_reference2, 'name' | 'allowedValues' | 'type'>;

export const referenceType: {value: string, type: ReferenceType, name: string}[] = [
  {
    name: 'Lokasjon',
    value: 'lokasjon',
    type: ReferenceType.LIST,
  },
  {
    name: 'Ansattnummer',
    value: 'ansattnummer',
    type: ReferenceType.FREETEXT,

  },
  {
    name: 'Avdeling',
    value: 'avdeling',
    type: ReferenceType.LIST,
  },
  {
    name: 'Underavdeling',
    value: 'underavdeling',
    type: ReferenceType.LIST,

  },
  {
    name: 'Ressursnummer',
    value: 'ressursnummer',
    type: ReferenceType.FREETEXT,

  },
  {
    name: 'Avdelingsnummer',
    value: 'avdelingsnummer',
    type: ReferenceType.LIST,

  },
  {
    name: 'Prosjektnummer',
    value: 'prosjektnummer',
    type: ReferenceType.FREETEXT,
  },
  {
    name: 'AD-nummer',
    value: 'ad-nummer',
    type: ReferenceType.FREETEXT,
  },
];

export interface IBillingType {
  id: PaymentLevel,
  label: string;
}

export const BillingTypes: IBillingType[] = [
  {id: PaymentLevel.Company, label: 'Avtalen faktureres samlet'},
  {id: PaymentLevel.Department, label: 'Avtalen faktureres per avdeling'},
  {id: PaymentLevel.Employee, label: 'Avtalen faktureres per ansatt'},
];
