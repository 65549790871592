import * as React from "react";
import { ReactSVG } from "react-svg";
import { Errback, EvalScripts } from '@tanem/svg-injector';

export interface ISvgImageProps {
  href: string;
  className?: string;
  evalScripts?: EvalScripts;
  fallback?: React.ReactType;
  loading?: React.ReactType;
  onInjected?: Errback;
  renumerateIRIElements?: boolean;
  svgStyle?: React.CSSProperties;
  wrapper?: 'div' | 'span';
  title?: string;
}

export default class SvgImage extends React.Component<ISvgImageProps> {
  render() {
    const {href, className, svgStyle, ...rest} = this.props;
    return (
      <ReactSVG {...rest} src={href} beforeInjection={svg => {
        if (svgStyle) {
          Object.entries(svgStyle).forEach(([key, value]) => {
            svg.style[key] = value;
          });
        }
        if (className) {
          className.split(/ +/).forEach(singleClass => {
            svg.classList.add(singleClass);
          });
        }
      }}/>
    )
  }

}
