import * as React from "react";
import {
  classNames,
  Input,
  Display,
  Padding,
  FontSize,
  TextColor,
  FlexDirection,
  AlignItems,
} from "@snoam/pinata";

const styleClass = {
  input: classNames(
    Padding.PB_0,
    Padding.PR_3
  ),
  inputContainer: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    Padding.PB_4
  ),
  inputContainerActive: classNames(
    FontSize.TEXT_SM,
    TextColor.TEXT_NEUTRAL_5
  ),
};

interface ActiveReferenceProps {
  reference: string;
}

const ActiveReference = ({ reference }: ActiveReferenceProps) => {
  return (
    <div className={styleClass.inputContainer}>
      <Input
        value={reference}
        className={styleClass.input}
        disabled
      />
      <span className={styleClass.inputContainerActive}>Aktiv</span>
    </div>
  )
};

export default ActiveReference;
