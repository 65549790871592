import React, { ReactNode } from "react";
import {
  BorderColor,
  BorderWidth,
  Checkbox,
  classNames,
  Display,
  FlexDirection,
  Margin,
  Width,
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import { Eq, NotBlank } from "../../components/Filter/types";
import { periodicEmployeeVerificationMethods } from "../AgreementSettings/VerificationSection/EmploymentPeriodicVerificationSettings";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4,
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

export const EmployeePeriodicVerificationMethodFilter: React.FunctionComponent = () => {

  return (
    <Filter
      heading={'Periodisk verifisering av ansattforhold'}
      id={'employeePeriodicVerificationMethod'}
      wrapper={customWrapper}
    >
      {
        periodicEmployeeVerificationMethods.map((employmentPeriodicVerificationMethod, i) => (
          <Eq
            id={`employeePeriodicVerificationMethod_${employmentPeriodicVerificationMethod}_${i}`}
            key={`employeePeriodicVerificationMethod_${employmentPeriodicVerificationMethod}_${i}`}
            name={'settings.employeePeriodicVerificationSettings.__typename'}
            value={employmentPeriodicVerificationMethod.value || null}
          >
            {
              ({active, toggleFilter}) => {
                return (
                  <div className={styleClass.inner}>
                    <Checkbox
                      checked={!!active}
                      onClick={toggleFilter}
                      text={employmentPeriodicVerificationMethod.name}
                    />
                  </div>
                )
              }
            }
          </Eq>
        ))
      }
      <NotBlank
        id={`employeePeriodicVerificationMethod_Feide_${periodicEmployeeVerificationMethods.length}`}
        key={`employeePeriodicVerificationMethod_Feide_${periodicEmployeeVerificationMethods.length}`}
        name={'settings.feideApiCheckUserExistenceEndpoint'}
      >
        {
          ({active, toggleFilter}) => {
            return (
              <div className={styleClass.inner}>
                <Checkbox
                  checked={!!active}
                  onClick={toggleFilter}
                  text={'Feide Check User Existence API'}
                />
              </div>
            )
          }
        }
      </NotBlank>
    </Filter>
  )
};

export default EmployeePeriodicVerificationMethodFilter;
