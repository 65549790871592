import {
  Button,
  ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  FontWeight,
  Icon,
  IconColor,
  IconName,
  IconSize,
  JustifyContent,
  LineHeight,
  ListStyleType,
  Margin,
  Padding,
  Width,
} from '@snoam/pinata';
import * as React from 'react';
import AgreementsCard from './AgreementsCard';
import {GetAgreements_me_agreements_edges_products} from '../../__generated__/GetAgreements';
import {hasOneOrMoreItems, sortMerchants} from '../../utils';
import {getGroupOfProductsSortedByBundleCode, groupBy} from '../GroupProducts/GroupProducts';
import {AdminType} from "../../__generated__/globalTypes";
import {MaybeAdminType} from "../Boundary/RoleBoundary";

const styleClass = {
  agreement: {
    expandedBody: {
      addProductsButton: classNames(
        Padding.PL_3,
        Width.MD_W_AUTO,
        Margin.MX_AUTO,
        Width.W_64,
        Width.LG_W_64,
        Margin.MT_4,
        Margin.MB_0
      )
    },
  },
};

interface IAgreementCardProductsProps {
  products: GetAgreements_me_agreements_edges_products[];
  agreementNumber: number;
  userRole: MaybeAdminType;
}

const renderProducts = (products: GetAgreements_me_agreements_edges_products[]) => {

  const groupedProducts = groupBy('productGroup', products);
  const groupedProductSortedByMerchant = sortMerchants(Object.keys(groupedProducts)).map(k => groupedProducts[k]) as GetAgreements_me_agreements_edges_products[][];
  return groupedProductSortedByMerchant
    .map((product: GetAgreements_me_agreements_edges_products[], i) => {
      const productGroupsSortedByBundleCode = getGroupOfProductsSortedByBundleCode(product) as GetAgreements_me_agreements_edges_products[];
      const productName = product[0].productName;
      return (
        <li key={`${productName}_${i}`}>
          <dl>
            <dt className={classNames(FontWeight.FONT_BOLD, Padding.PT_2)}>{productName}</dt>
            {
              productGroupsSortedByBundleCode
                .map((product: GetAgreements_me_agreements_edges_products,i: number) => (
                  <dd key={`${product.productGroup}_${i}`} className={Padding.PL_4}>
                    {product.productVariation}
                  </dd>
                ))
            }
          </dl>
        </li>
      )
    })
};

const renderAddProductsButton = (agreementNumber: number) => {
  return (
    <div className={classNames(Display.FLEX, JustifyContent.JUSTIFY_CENTER, FlexDirection.FLEX_COL)}>
      <Button
        text={"Legg til produkter"}
        ariaLabel={"Legg til produkter"}
        href={`avtaler/${agreementNumber}/produkter`}
        skin={ButtonSkin.SECONDARY}
        className={styleClass.agreement.expandedBody.addProductsButton}
        style={{transition: 'background-color 500ms ease'}}
        iconLeft={
          <Icon
            name={IconName.ADD}
            size={IconSize.MEDIUM}
            color={IconColor.NEUTRAL_6}
          />
        }
      />
    </div>
  )
};

const AgreementCardProducts = ({products, agreementNumber, userRole}: IAgreementCardProductsProps) => {

  const activeProducts = products.filter(p => p.active);


  return (

    <AgreementsCard
      title={`${hasOneOrMoreItems(products) ? `${activeProducts.length} Produkter` : 'Ingen produkter'}`}
      pathName={`${agreementNumber}/produkter`}
      icon={<Icon
        size={IconSize.MEDIUM}
        name={IconName.EDIT}
        color={IconColor.NEUTRAL_4}
      />}
    >
      {hasOneOrMoreItems(activeProducts) ? (
        <ul className={classNames(ListStyleType.LIST_NONE, Margin.MT_2, LineHeight.LEADING_HEADLINE3, FontSize.TEXT_SM)}>
          {renderProducts(activeProducts)}
        </ul>
      ) : userRole === AdminType.SUPERADMIN && renderAddProductsButton(agreementNumber)}
    </AgreementsCard>
  )
};

export default AgreementCardProducts;
