import {
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference1,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference2
} from '../../__generated__/GetAgreement';
import * as React from 'react';
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  KeyboardEventHandler,
  ReactNode,
  SetStateAction,
  useState
} from 'react';
import {
  Arrow,
  Button,
  ButtonSkin,
  classNames,
  Display,
  Dropdown,
  FontSize,
  FontWeight,
  Margin,
  MaxWidth,
  Padding,
  TextColor,
  Width
} from '@snoam/pinata';
import {ReferenceType} from '../../__generated__/globalTypes';
import {ISubscription} from '../../models/EmployeeModel';
import {MutationFunction, useMutation} from '@apollo/client';
import {checkNoErrors, UPDATE_SUBSCRIPTION_ITEM_REFERENCES} from '../../mutations';
import {UpdateReferences, UpdateReferencesVariables} from '../../__generated__/UpdateReferences';
import {MessageBox, MessageBoxType} from '@snoam/mono-messagebox';
import InputWithValidator from '../InputWithValidator/InputWithValidator';
import ReferencesUpdateModal, {ReferenceUpdateValue} from "./ReferencesUpdateModal";

const debug = require('debug')('minbedrift:client:References');

const styleClass = {
  saveButton: classNames(
    Margin.MR_2,
    Padding.PX_2,
  ),
  cancelButton: classNames(
    Padding.PX_2,
  ),
  dropdownLabel: classNames(
    FontWeight.FONT_HAIRLINE,
    TextColor.TEXT_NEUTRAL_5,
    FontSize.TEXT_SM,
    Margin.MB_2
  ),
  dropdownError: classNames(
    TextColor.TEXT_NEGATIVE,
    FontSize.TEXT_SM,
  )
};

interface ReferenceProps {
  assetNumber?: string;
  className?: string;
  onChange?: ChangeEventHandler<HTMLInputElement> & ((item: string) => void);
  value?: string;
  reference?: Pick<GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference1 | GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference2, 'allowedValues' | 'name' | 'type' | 'value'>;
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>;
  skipHeader?: boolean;
  disabled?: boolean
}

export const Reference: React.FunctionComponent<ReferenceProps> = ({className, onChange, reference, skipHeader, onKeyPress, disabled = false}) => {

  if (!reference || !reference.type && !reference.value) {
    return null;
  }
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={className}
      onKeyPress={e => onKeyPress && onKeyPress(e)}
    >
      {!skipHeader && <label className={styleClass.dropdownLabel}>{reference.name || 'Referanse'}</label>}
      {reference.type === ReferenceType.LIST ? (
        <Dropdown
          onChange={onChange || (() => {
          })}
          className={MaxWidth.MAX_W_SM}
          data={(reference.allowedValues || []).map(e => ({
            name: e,
            value: e,
          }))}
          hideLabel={true}
          disabled={disabled}
          defaultTriggerText={`Velg ${reference.name}`}
          value={reference.value as string}
        />
      ) : (
        <InputWithValidator
          validator={(value) => (value || '').length > 0}
          required={true}
          errorMsg={`Vennligst oppgi ${reference.name || 'referanse'}`}
          placeholder={reference.name || 'Referanse'}
          ariaLabel={`Legg til ${reference.name && reference.name || 'referanse'}`}
          value={reference.value || ''}
          className={MaxWidth.MAX_W_SM}
          onChange={onChange}
          disabled={disabled}
        />
      )}
    </div>
  );
};

interface ReferencesProps {
  agreementNumber: number;
  className: string;
  subscription: Pick<ISubscription, 'assetNumber' | 'deliveryAddressId' | 'id' | 'reference1' | 'reference2' | 'status'>;
  allSubscriptions: Pick<ISubscription, 'assetNumber' | 'deliveryAddressId' | 'id' | 'reference1' | 'reference2' | 'status'>[];
  disabled: boolean;
}

type performMutationFunction = (props: {
  setMessage: Dispatch<SetStateAction<ReactNode>>,
  updateReferences: MutationFunction<UpdateReferences, UpdateReferencesVariables>,
  variables: UpdateReferencesVariables,
  setIsUpdating: Dispatch<SetStateAction<boolean>>,
  setChangingReferences: Dispatch<SetStateAction<boolean>>,
}) => void;
const performMutation: performMutationFunction = async ({variables, setMessage, updateReferences, setIsUpdating, setChangingReferences}) => {
  setMessage(undefined);
  const asyncAction = () => updateReferences({variables}).then(checkNoErrors);
  try {
    await asyncAction();
  } catch (error) {
    setMessage(<div className={Width.W_FULL}>
      <MessageBox message={`Noe gikk galt, prøv igjen (${error.toString()}).`} type={MessageBoxType.INFO}
                  arrow={Arrow.TOP_CENTER}/>
    </div>);
  }
  setIsUpdating(false);
  setChangingReferences(false);
};

const References: React.FunctionComponent<ReferencesProps> = ({agreementNumber, className, subscription, allSubscriptions, disabled}) => {
  const [message, setMessage] = useState<ReactNode>(undefined);
  const updateReferences: MutationFunction<UpdateReferences, UpdateReferencesVariables> = useMutation<UpdateReferences, UpdateReferencesVariables>(UPDATE_SUBSCRIPTION_ITEM_REFERENCES)[0];

  const [reference1value, setReference1Value] = useState(subscription.reference1 && subscription.reference1.value || '');
  const [reference2value, setReference2Value] = useState(subscription.reference2 && subscription.reference2.value || '');
  const [isChangingReferences, setChangingReferences] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  function update(value: ReferenceUpdateValue) {
    setModalOpen(false);
    setIsUpdating(true);
    let subscriptionsToUpdate;
    if (value === ReferenceUpdateValue.All) {
      subscriptionsToUpdate = allSubscriptions;
    } else {
      subscriptionsToUpdate = [subscription];
    }
    debug('Set references for subscriptions: %o, newValueReference1: %s newValueReference2: %s', subscriptionsToUpdate, reference1value, reference2value);

    const variables: UpdateReferencesVariables = {
      agreementNumber,
      referenceSettings: subscriptionsToUpdate.map(s => {
        return {
          assetNumber: s.assetNumber,
          deliveryAddressId: s.deliveryAddressId,
          id: s.id,
          reference1: {
            allowedValues: s.reference1.allowedValues,
            id: s.reference1.id,
            name: s.reference1.name,
            type: s.reference1.type,
            value: reference1value,
          },
          reference2: {
            allowedValues: s.reference2.allowedValues,
            id: s.reference2.id,
            name: s.reference2.name,
            type: s.reference2.type,
            value: reference2value,
          },
          status: s.status,
        }
      }),
    };
    performMutation({
      variables,
      setMessage,
      updateReferences,
      setIsUpdating,
      setChangingReferences
    });
  }

  function cancelUpdate() {
    setReference1Value(subscription.reference1 && subscription.reference1.value || '');
    setReference2Value(subscription.reference2 && subscription.reference2.value || '');
    setChangingReferences(false);
    setMessage(undefined);
  }

  function openModelOrSaveOnEnterKeyPressed(event: React.KeyboardEvent<HTMLDivElement>) {
    if (isChangingReferences && event.key === 'Enter') {
      event.stopPropagation();
      openModalOrSaveReferences();
    }
  }

  function cancelUpdateEnterKeyPressed(event: React.KeyboardEvent<HTMLDivElement>) {
    if (isChangingReferences && event.key === 'Enter') {
      event.stopPropagation();
      cancelUpdate();
    }
  }

  function openModalOrSaveReferences() {
    showModal ? setModalOpen(true) : update(ReferenceUpdateValue.OnlyThis)
  }

  const showModal = allSubscriptions.length > 1;

  return (
    <>
      <Reference className={className}
                 assetNumber={subscription.assetNumber}
                 value={reference1value}
                 onChange={(e: string | ChangeEvent<HTMLInputElement>) => {
                   const value: string = typeof e === 'object' ? e.target.value : e;
                   setReference1Value(value);
                   setChangingReferences(true);
                   setMessage(undefined);
                 }}
                 reference={subscription.reference1}
                 onKeyPress={openModelOrSaveOnEnterKeyPressed}
                 disabled={disabled}
      />
      <Reference className={className}
                 assetNumber={subscription.assetNumber}
                 value={reference2value}
                 onChange={(e: string | ChangeEvent<HTMLInputElement>) => {
                   const value: string = typeof e === 'object' ? e.target.value : e;
                   setReference2Value(value);
                   setChangingReferences(true);
                   setMessage(undefined);
                 }}
                 reference={subscription.reference2}
                 onKeyPress={openModelOrSaveOnEnterKeyPressed}
                 disabled={disabled}
      />

      {message}
      {isChangingReferences && !disabled &&
      <>
        <div className={classNames(Width.W_FULL)}>
          <div className={Display.FLEX}>
            <Button
              text={"Lagre"}
              ariaLabel={"Lagre"}
              skin={ButtonSkin.PRIMARY}
              className={styleClass.saveButton}
              onClick={() => openModalOrSaveReferences()}
              loading={isUpdating}
              loadingText="Lagrer ..."
              onKeyPress={openModelOrSaveOnEnterKeyPressed}
            />
            <Button
              text={"Avbryt"}
              ariaLabel={"Avbryt"}
              skin={ButtonSkin.SECONDARY}
              className={styleClass.cancelButton}
              onClick={() => cancelUpdate()}
              onKeyPress={cancelUpdateEnterKeyPressed}
            />
          </div>
          <ReferencesUpdateModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onSave={value => update(value)}
          />
        </div>
      </>}
    </>
  );
};

export default References;
