import * as React from "react";
import {EnvelopeProvider} from "../../context/EnvelopeContext/EnvelopeContext";
import {FilterEnvelopeWidth} from "../../Const";

export const EnvelopeProviderWrapper: React.FunctionComponent = ({ children }) => (
  <EnvelopeProvider
    width={FilterEnvelopeWidth}
  >
    {children}
  </EnvelopeProvider>
);
