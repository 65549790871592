/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountNameConditionOperator {
  greaterThan = "greaterThan",
  lessThan = "lessThan",
}

export enum AdminType {
  ACCOUNTANT = "ACCOUNTANT",
  ADMIN = "ADMIN",
  SUPERADMIN = "SUPERADMIN",
  SUPEROBSERVER = "SUPEROBSERVER",
}

export enum AgreementStatus {
  Aktiv = "Aktiv",
  Avsluttet = "Avsluttet",
  Inaktiv = "Inaktiv",
  Kansellert = "Kansellert",
}

export enum CompanyCode {
  AP = "AP",
  ASK = "ASK",
  BTI = "BTI",
  BYN = "BYN",
  DNB = "DNB",
  DP = "DP",
  FAK = "FAK",
  FVN = "FVN",
  Forlag = "Forlag",
  LIN = "LIN",
  MPL = "MPL",
  SA = "SA",
  SCH = "SCH",
  STR = "STR",
  SYS = "SYS",
  VG = "VG",
  VNY = "VNY",
}

/**
 * Method used for verifying employment.
 */
export enum EmploymentPeriodicVerificationMethod {
  AZURE_AD = "AZURE_AD",
  OKTA_API = "OKTA_API",
  OKTA_SYSTEM_LOG = "OKTA_SYSTEM_LOG",
  PHENIX_ID = "PHENIX_ID",
}

export enum EmploymentVerificationMethod {
  EMAIL = "EMAIL",
  OPENID_CONNECT = "OPENID_CONNECT",
}

export enum InvoiceAccountType {
  Bedrift = "Bedrift",
  FellesFaktura = "FellesFaktura",
  Gratist = "Gratist",
  Intern = "Intern",
  Konsern = "Konsern",
  Kontaktperson = "Kontaktperson",
  Normal = "Normal",
}

export enum PaymentLevel {
  Company = "Company",
  Department = "Department",
  Employee = "Employee",
}

export enum ReferenceType {
  FREETEXT = "FREETEXT",
  LIST = "LIST",
}

export enum SubscriptionAgreementStatus {
  Aktiv = "Aktiv",
  Avventende = "Avventende",
  Inaktiv = "Inaktiv",
}

export interface AccountNameCondition {
  operator?: AccountNameConditionOperator | null;
  character: string;
}

export interface AgreementFilter {
  invoiceAccountType?: (InvoiceAccountType | null)[] | null;
  paymentLevel?: (PaymentLevel | null)[] | null;
  settings?: AgreementSettingsFilter | null;
}

export interface AgreementSettingsFilter {
  enableOrdering?: (boolean | null)[] | null;
}

export interface NewAdminSettings {
  adminType: AdminType;
  agreementNumber?: number | null;
  spid: number;
}

export interface NewAgreementProduct {
  id: string;
  productGroup: string;
  productName: string;
  productCombination: string;
  productVariation: string;
  productVariationDescription?: string | null;
  productVariationImageUrl?: string | null;
  productVariationActive: boolean;
  companyCode: CompanyCode;
  discountPercent: number;
  active: boolean;
  public: boolean;
  siebelProductId: string;
  monthlyPrice: number;
}

export interface NewAgreementSettings {
  activationPotential?: number | null;
  showActivationDegreeForCompanyAdmin?: boolean | null;
  emailDomains: (any | null)[];
  employeeVerificationMethod: EmploymentVerificationMethod;
  enableOrdering: boolean;
  id: string;
  feideApiCheckUserExistenceEndpoint?: string | null;
  openidConnectUserIdField?: string | null;
  openidConnectClientId?: string | null;
  openidConnectClientSecret?: string | null;
  openidConnectDiscoveryUrl?: string | null;
  openidConnectSystemName?: string | null;
  openidConnectScope?: string | null;
  reference1?: ReferenceInput | null;
  reference2?: ReferenceInput | null;
}

export interface NewEmploymentPeriodicVerificationSettingsAzureAdGraphApiSettings {
  type: EmploymentPeriodicVerificationMethod;
}

export interface NewEmploymentPeriodicVerificationSettingsOktaApiSettings {
  oktaApiToken: string;
  type: EmploymentPeriodicVerificationMethod;
}

export interface NewEmploymentPeriodicVerificationSettingsOktaSystemLogSettings {
  oktaAppId: string;
  oktaApiToken: string;
  oktaOrgUrl: string;
  type?: EmploymentPeriodicVerificationMethod | null;
}

export interface NewEmploymentPeriodicVerificationSettingsPhenixIdApiSettings {
  phenixIdApiUrl: string;
  type?: EmploymentPeriodicVerificationMethod | null;
}

export interface NewProductSettings {
  id: string;
  productGroup: string;
  productName: string;
  productCombination: string;
  productVariation: string;
  productVariationDescription?: string | null;
  productVariationImageUrl?: string | null;
  productVariationActive: boolean;
  companyCode: CompanyCode;
  discountPercent: number;
  active: boolean;
  public: boolean;
  siebelProductId: string;
  monthlyPrice: number;
}

export interface NewReferences {
  id: string;
  assetNumber: string;
  deliveryAddressId: string;
  reference1: ReferenceInput;
  reference2: ReferenceInput;
  status: SubscriptionAgreementStatus;
}

export interface ReferenceInput {
  id: string;
  name?: string | null;
  type?: ReferenceType | null;
  allowedValues?: string[] | null;
  value?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
