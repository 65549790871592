import { gql } from '@apollo/client';
import { fragments as subscriptionsFragments } from './pages/Subscriptions/fragments';


export const GET_ADMIN_SEARCH_BY_EMAIL = gql`
  query FindAdmin($email: String!) {
  findByEmail(email:$email) {
    id
    email
    userId
    name {
      formatted
    }
  }
}
`;
export const GET_ADMIN_SEARCH_BY_ID = gql`
  query FindAdminById($schibstedAccountId: String!) {
  findBySchibstedAccountId(schibstedAccountId: $schibstedAccountId) {
    id
    email
    userId
    name {
      formatted
    }
  }
}
`;

export const GET_POLICY = gql`
  query GetPolicy {
    policy {
      content
      title
    }
  }
`;

export const GET_SUBSCRIBER_PRODUCTS = gql`
  query GetSubscriberProducts($agreementNumber: Int!) {
    me {
      id
      subscriptions(agreementNumber: $agreementNumber) {
        id
        activeProducts {
          id
          bundle
          productId
          productName
          companyCode
          productCombination
          productGroup
          agreementId
          status
        }
        activeCount
      }
    }
}
`;

export const GET_ROLE = gql`
  query GetRole($agreementNumber: Int) {
    me {
      id
      email
      role(agreementNumber: $agreementNumber)
    }
  }
`;

export const GET_AGREEMENT_DEPARTMENTS = gql`
  query GetAgreementDepartments($agreementNumber: [Int!]!) {
    me {
      id
      agreementsWithDetails(agreementNumbers: $agreementNumber) {
        id,
        agreementPaymentlevel: paymentLevel
        departments {
          id
          accountNumber
          agreementNumber
          name
        }
      }
    }
  }
`;

export const GET_AGREEMENTS = gql`
  query GetAgreements($filter: AgreementFilter) {
    me {
      id
      role
      numAgreements
      agreements(filter: $filter) {
        totalCount
        edges {
          accountName
          accountNumber
          created
          activationDegree
          invoiceAccountType
          manualInvoiceFlag
          agreementStatus
          agreementDiscountPct
          agreementNumber
          id
          paymentLevel
          agreementSubscriberCount: subscriberCount
          maxSubscriptionsPerEmployee

          settings {
            showActivationDegreeForCompanyAdmin
            activationPotential
            emailDomains
            employeeVerificationMethod
            employeePeriodicVerificationSettings {
              __typename
              ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
                type
              }
              ... on EmploymentPeriodicVerificationSettingsOktaApi {
                oktaApiToken
              }
              ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
                oktaApiToken
                oktaAppId
                oktaOrgUrl
              }
              ... on EmploymentPeriodicVerificationSettingsPhenixId {
                phenixIdApiUrl
              }
            }
            enableOrdering
            feideApiCheckUserExistenceEndpoint
            id
            openidConnectUserIdField
            openidConnectClientId
            openidConnectClientSecretMasked
            openidConnectDiscoveryUrl
            openidConnectSystemName
            openidConnectScope
            openidConnectSystemName
            reference1 {
              id
              name
              type
              allowedValues
            }
            reference2 {
              id
              name
              type
              allowedValues
            }
          }

          products {
            id
            active
            productName
            companyCode
            productGroup
            productVariation
            siebelProductId
            productVariationImageUrl
            productVariationDescription
            productCombination
            productVariationActive
            monthlyPrice
            discountPercent
            public
          }

          admins {
            id
            spid
            displayName
            email
            name {
              formatted
            }
          }
        }
      }
    }
  }
`;

export const QUERY_INVOICES = gql`
  query QueryInvoices($agreementNumber: Int!, $accountNumber: Int!) {
    me {
      id
      invoices(agreementNumber: $agreementNumber, accountNumber: $accountNumber) {
        id
        invoiceNumber
        invoiceDate
        invoiceType
        invoiceAmount
        invoicePaid
        invoicePaidDate
        paymentMethod
        invoiceDueDate
        invoiceItems {
          id
          itemProduct
          itemProductCombo
          invoicedFrom
          invoicedTill
          itemAssetNumber
          itemAmount
        }
      }
    }
  }
`;

export const GET_INVOICE = gql`
  query GetInvoice($agreementNumber: Int!, $accountNumber: Int!, $invoiceNumber: String!) {
    me {
      id
      invoice(agreementNumber: $agreementNumber, accountNumber: $accountNumber, invoiceNumber: $invoiceNumber) {
        contentType
        dataBase64Encoded
        id
      }
    }
  }
`;

export const GET_AGREEMENT_NUM_SUBSCRIPTIONS = gql`
  query GetAgreementNumSubscriptions($department: String, $agreementNumber: [Int!]!) {
    me {
      id
      agreementsWithDetails(agreementNumbers: $agreementNumber, department: $department) {
        id
        agreementSubscriberCount: subscriberCount
      }
    }
  }
`;

export const GET_AGREEMENT = gql`
  query GetAgreement($department: String, $agreementNumber: [Int!]!, $shouldFetchSubscriptions: Boolean = false, $accountNameConditions: [AccountNameCondition!]) {
    me {
      id
      agreementsWithDetails(agreementNumbers: $agreementNumber, department: $department, accountNameConditions: $accountNameConditions) {
        accountName
        accountNumber
        accountInvoiceType: invoiceAccountType
        activationDegree
        agreementStatus
        agreementNumber
        id
        agreementDiscountPct
        agreementPaymentlevel: paymentLevel
        agreementSubscriberCount: subscriberCount
        maxSubscriptionsPerEmployee
        allowNewSubscriptions
        departments {
          id
          accountNumber
          agreementNumber
          name
        }
        ...SubscriptionsPageSubscriptions @include(if: $shouldFetchSubscriptions)

        settings {
          showActivationDegreeForCompanyAdmin
          activationPotential
          emailDomains
          employeeVerificationMethod
          employeePeriodicVerificationSettings {
            __typename
            ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
              type
            }
            ... on EmploymentPeriodicVerificationSettingsOktaApi {
              oktaApiToken
            }
            ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
              oktaApiToken
              oktaAppId
              oktaOrgUrl
            }
            ... on EmploymentPeriodicVerificationSettingsPhenixId {
              phenixIdApiUrl
            }
          }
          enableOrdering
          feideApiCheckUserExistenceEndpoint
          id
          openidConnectUserIdField
          openidConnectClientId
          openidConnectClientSecretMasked
          openidConnectDiscoveryUrl
          openidConnectSystemName
          openidConnectScope
          openidConnectSystemName
          reference1 {
            id
            name
            type
            allowedValues
          }
          reference2 {
            id
            name
            type
            allowedValues
          }
        }

        products {
          id
          active
          productName
          companyCode
          productGroup
          productVariation
          siebelProductId
          productVariationImageUrl
          productVariationDescription
          productCombination
          productVariationActive
          monthlyPrice
          discountPercent
          public
        }

        admins {
          id
          spid
          displayName
          email
          adminType
          name {
            formatted
          }
        }

        contactPerson {
          id
          firstName
          lastName
        }
      }
    }
  }
  ${subscriptionsFragments.subscriptions}
`;

export const GET_AGREEMENT_ORDER_INITIAL = gql`
  query GetAgreementOrderInitial($agreementNumber: Int!) {
    agreement(agreementNumber: $agreementNumber) {
      accountName
      agreementNumber
      id
      maxSubscriptionsPerEmployee
      departments {
        id
        accountNumber
        agreementNumber
        name
      }
      products {
        id
        active
        productName
        companyCode
        productGroup
        productVariation
        siebelProductId
        productVariationImageUrl
        productVariationDescription
        productCombination
        productVariationActive
        monthlyPrice
        discountPercent
        public
      }
      settings {
        activationPotential
        emailDomains
        employeeVerificationMethod
        employeePeriodicVerificationSettings {
          __typename
          ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
            type
          }
          ... on EmploymentPeriodicVerificationSettingsOktaApi {
            oktaApiToken
          }
          ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
            oktaApiToken
            oktaAppId
            oktaOrgUrl
          }
          ... on EmploymentPeriodicVerificationSettingsPhenixId {
            phenixIdApiUrl
          }
        }
        enableOrdering
        feideApiCheckUserExistenceEndpoint
        id
        openidConnectAuthorizationEndpoint
        openidConnectClientId
        openidConnectScope
        openidConnectSystemName
        reference1 {
          id
          name
          type
          allowedValues
        }
        reference2 {
          id
          name
          type
          allowedValues
        }
      }
    }
  }
`;

export const GET_AGREEMENT_ORDER_INITIAL_LOGGED_OUT = gql`
query GetAgreementOrderInitialLoggedOut($agreementNumber: Int!) {
  agreement(agreementNumber: $agreementNumber) {
    id
    accountName
    products {
      id
      companyCode
    }

    settings {
      id
      enableOrdering
    }
  }
}
`;

export const GET_AGREEMENT_PRODUCTS = gql`
  query GetAgreementProducts($agreementNumber: [Int!]!) {
    me {
      id
      agreementsWithDetails(agreementNumbers: $agreementNumber) {
        id
        accountName
        agreementNumber
        maxSubscriptionsPerEmployee
        allowNewSubscriptions
        paymentLevel
        products {
          id
          active
          productName
          companyCode
          productGroup
          productVariation
          siebelProductId
          productVariationImageUrl
          productVariationDescription
          productCombination
          productVariationActive
          monthlyPrice
          discountPercent
          siebelProductId
          public
        }
        departments {
          id
          accountNumber
          agreementNumber
          name
        }
        settings {
          id
          enableOrdering,
          emailDomains
          reference1 {
            id
            name
            type
            allowedValues
          }
          reference2 {
            id
            name
            type
            allowedValues
          }
        }
      }
    }
  }
`;

export const GET_SUPER_ADMINS = gql`
  query GetSuperAdmins {
    superAdmins {
      id
      spid
      email
      adminType
      name {
        formatted
      }
    }
  }
`;

export const GET_ALL_PRODUCTS = gql`
  query GetAllProducts($showOtherProducts: Boolean){
    availableProducts(showOtherProducts: $showOtherProducts) {
      id
      active
      productName
      companyCode
      productGroup
      productVariation
      siebelProductId
      productVariationImageUrl
      productVariationDescription
      productCombination
      productVariationActive
      monthlyPrice
      discountPercent
      public
    }
}
`;

export const GET_ALL_MESSAGES = gql`
  query GetAllMessages {
    adminMessages {
      id
      message
      productGroups
      createdAt
    }
    availableProducts {
      id
      active
      productName
      productGroup
    }
  }
`;

export const GET_MESSAGES_FOR_PRODUCT_GROUPS = gql`
    query GetAllMessagesForProductGroups($filter: [String!]) {
      adminMessages(filter: $filter) {
        id
        message
        productGroups
        createdAt
      }
    }
`;

export const GET_AGREEMENT_PRODUCTS_AND_SIEBEL_PRODUCTS = gql`
  query GetAgreementProductsAndSiebelProducts($agreementNumber: [Int!]!, $showOtherProducts: Boolean) {
    me {
      id
      agreementsWithDetails(agreementNumbers: $agreementNumber) {
        id
        accountName
        agreementDiscountPct
        maxSubscriptionsPerEmployee
        products {
          id
          active
          productName
          companyCode
          productGroup
          productVariation
          siebelProductId
          productVariationImageUrl
          productVariationDescription
          productCombination
          productVariationActive
          monthlyPrice
          discountPercent
          public
        }
        settings {
          id
          enableOrdering
          emailDomains
        }
      }
    }
    availableProducts(agreementNumber: $agreementNumber, showOtherProducts: $showOtherProducts) {
      id
      productName
      companyCode
      productGroup
      productVariation
      siebelProductId
      productVariationImageUrl
      productVariationDescription
      productCombination
      productVariationActive
      monthlyPrice
      discountPercent
      public
    }
  }
`;
