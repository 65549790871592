import * as React from 'react';
import {
  classNames,
  Cursor,
  Display,
  Icon,
  IconColor,
  IconName,
  Margin,
  Padding,
  TextColor,
  FontSize,
  Width,
  AlignItems,
  TextDecoration
} from '@snoam/pinata';
import References from './References';
import {
  GetAgreementOrderInitial_agreement_departments,
  GetAgreementOrderInitial_agreement_products,
  GetAgreementOrderInitial_agreement_settings
} from "../../__generated__/GetAgreementOrderInitial";
import { OrderSessionModel, Reference } from "../../pages/Order/models/OrderSessionModel";
import { Dispatch } from 'react';
import { SetStateAction } from 'react';

const styleClass = {
  whiteBox: classNames(
    Width.W_FULL,
    Display.FLEX,
    Cursor.CURSOR_POINTER,
    Padding.PY_4,
    AlignItems.ITEMS_CENTER
  ),
  additionalReference: classNames(
    Margin.ML_2,
    TextColor.TEXT_NEUTRAL_6,
    FontSize.XS_TEXT_SM
  )
};

interface IReferencesPerProduct {
  formSubmitted: boolean;
  products: GetAgreementOrderInitial_agreement_products[];
  settings: GetAgreementOrderInitial_agreement_settings;
  departments: GetAgreementOrderInitial_agreement_departments[];
  setReferences: Dispatch<SetStateAction<Partial<Reference>[]>>;
  referencesPrProduct: boolean;
  setReferencesPrProduct: Dispatch<SetStateAction<boolean>>;
  orderModel: OrderSessionModel;
  setSelectedDepartments: Dispatch<SetStateAction<string[]>>;
}

const ReferencesPerProductBox: React.FunctionComponent<IReferencesPerProduct> = ({formSubmitted, products, settings, departments, setReferences, referencesPrProduct, setReferencesPrProduct, orderModel, setSelectedDepartments}) => {

  const renderReferences = () => {
    if (!referencesPrProduct) {
      const [product] = products;
      return (
        <References
          orderModel={orderModel}
          setReferences={setReferences}
          setSelectedDepartments={setSelectedDepartments}
          heading="Referanser"
          product={product}
          referencesPrProduct={false}
          formSubmitted={formSubmitted}
          settings={settings}
          departments={departments}
        />
      )
    }

    return products.map((product, i) => {
      const marginBottom = i === products.length - 1 ? '' : Margin.MB_2;
      return (
        <References
          orderModel={orderModel}
          setReferences={setReferences}
          setSelectedDepartments={setSelectedDepartments}
          key={i}
          index={i}
          product={product}
          referencesPrProduct={true}
          heading={`Referanse for ${product.productName}`}
          formSubmitted={formSubmitted}
          departments={departments}
          settings={settings}
          className={classNames(marginBottom)}
        />
      )
    })
  };

  const toggleReferencesPerProduct = () => {
    setReferencesPrProduct(!referencesPrProduct);
    setReferences([]);
    orderModel.references = [];
  };

  return (
    <>
      {renderReferences()}
      {products.length > 1 &&
      <div
        tabIndex={0}
        role="button"
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleReferencesPerProduct();
          }
        }}
        className={classNames(styleClass.whiteBox, "multiple-references")}
        onClick={toggleReferencesPerProduct}
      >
        <Icon name={referencesPrProduct ? IconName.MINUS : IconName.INFO_OUTLINE} color={IconColor.NEUTRAL_4} className={classNames(Margin.ML_2)}/>
        <div className={styleClass.additionalReference}>
          {referencesPrProduct ? 'Samme referanser for alle produkter' : <>Vil du legge til egne referanser pr. produkt? <span className={TextDecoration.UNDERLINE}>Klikk her</span></> }
        </div>
      </div>}
    </>
  )
};

export default ReferencesPerProductBox;
