import * as React from "react";
import {useState} from "react";
import {
  AlignItems,
  classNames,
  Display,
  Icon,
  IconColor,
  IconName,
  Padding,
  TextDecoration
} from "@snoam/pinata";

import SelectProductModal from "../../components/SelectProductModal/SelectProductModal";
import ProductBox from "../../components/ProductBox/ProductBox";
import {getGroupOfProductsSortedByBundleCode, SyntheticAgreementProduct} from "../../components/GroupProducts/GroupProducts";
import {GetAgreementProducts_me_agreementsWithDetails_products} from "../../__generated__/GetAgreementProducts";
import {MutationFunction, useMutation} from "@apollo/client";
import {checkNoErrors, UPDATE_AGREEMENT_PRODUCTS} from "../../mutations";
import {updateAgreementProduct, updateAgreementProductVariables} from "../../__generated__/updateAgreementProduct";
import {GET_AGREEMENT_PRODUCTS_AND_SIEBEL_PRODUCTS} from "../../queries";
import {useContext} from "react";
import {ModalContext} from "@snoam/mono-modal";
import {trackClickEngagment, getDiscountPercentage} from '../../utils';
import {GetAgreementProductsAndSiebelProducts_availableProducts} from '../../__generated__/GetAgreementProductsAndSiebelProducts';

interface IAvailableProductBox {
  groupProducts: GetAgreementProductsAndSiebelProducts_availableProducts[];
  agreementNumber: number;
  agreementDiscountPercent: number;
  modalKey: number;
}

const AvailableProductBox = ({groupProducts, agreementNumber, modalKey, agreementDiscountPercent}: IAvailableProductBox) => {

  const modalId = `available-product-modal-${modalKey}`;
  const { closeModal, openModal, openModalId } = useContext(ModalContext);
  const toggleModal = (changeAccessModalId: string) => openModalId ? closeModal() : openModal(changeAccessModalId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const sortedGroupProducts = getGroupOfProductsSortedByBundleCode(groupProducts) as SyntheticAgreementProduct[];
  const [product] = sortedGroupProducts;
  const updateAgreement: MutationFunction<updateAgreementProduct, updateAgreementProductVariables> = useMutation(UPDATE_AGREEMENT_PRODUCTS)[0];
  const discountPercent = getDiscountPercentage(agreementDiscountPercent, groupProducts);

  const addProducts = (selectedProducts: GetAgreementProducts_me_agreementsWithDetails_products[]) => {
    const refetchQueries = [{query: GET_AGREEMENT_PRODUCTS_AND_SIEBEL_PRODUCTS, variables: {agreementNumber: [agreementNumber]}}];
    if(selectedProducts.length > 0) {
      selectedProducts.map(async (product) => {

        setIsLoading(!isLoading);
        delete product.__typename;
        if(product.hasOwnProperty("active")) {
          trackClickEngagment(
            `Produkter - Legg til ${product.productVariation}`);
        }
        if(!product.hasOwnProperty("active")) {
          product.active = false;
        }
        await updateAgreement(
          {
            refetchQueries,
            variables: {
              active: product.active ? 'Y' : 'N',
              agreementNumber: agreementNumber,
              product: product
            }
          }).then(checkNoErrors).then(() => {
            setIsLoading(false);
            closeModal();
          });
      });
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleModal(modalId);
    }
  };

  if(!product) {
    return null;
  }

  return (
    <>
      <ProductBox
        img={product.productVariationImageUrl ? product.productVariationImageUrl : ''}
        heading={product.productName}
        infoText={product.productVariationDescription}
        price={product.monthlyPrice}
        percent={discountPercent}
        onClick={() => toggleModal(modalId)}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        productCanBeClicked={true}
        footer={
          <div className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER, Padding.PY_2)}>
            <Icon name={IconName.PLUS} color={IconColor.NEUTRAL_4}/>
            <span className={classNames(Padding.PL_4, TextDecoration.NO_UNDERLINE)}>Legg til produkt</span>
          </div>
        }
      />
      <SelectProductModal
        onSave={addProducts}
        products={sortedGroupProducts}
        id={modalId}
        publication={product.productName}
        isActiveProducts={false}
        onClose={() => toggleModal(modalId)}
        preselectedProducts={[]}
        isLoading={isLoading}
        buttonText={"Legg til"}
        isLoadingText={"Legger til i våre produkter"}
        agreementDiscountPercent={agreementDiscountPercent}
      />
    </>
  )
};

export default AvailableProductBox;
