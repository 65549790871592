import {IconName, Search} from '@snoam/pinata';
import * as React from 'react';
import {ChangeEvent, useContext} from 'react';
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";

interface ISearchButtonProps {
  onSearch?: (evt: ChangeEvent<any>) => void
  placeholder?: string;
  disabled?: boolean;
}

const SearchButton = ({placeholder, disabled, onSearch = (evt: ChangeEvent<any>) => void 0}: ISearchButtonProps) => {
  const {mobile} = useContext(ResponsiveContext);
  placeholder = mobile ? 'Søk' : placeholder;
  return (
    <Search
      iconName={IconName.SEARCH}
      onChange={onSearch}
      disabled={disabled}
      aria-label={placeholder}
      aria-disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default SearchButton;
