import React, {CSSProperties, ReactNode} from "react";
import {classNames, Container, ContainerWidth, Height, Overflow} from "@snoam/pinata";


const styleClass = {
  root: (className?: string) => classNames(
    className,
    Height.H_FULL,
    Overflow.OVERFLOW_X_HIDDEN,
    Overflow.OVERFLOW_Y_HIDDEN,
  ),
};

export interface ILoginPageProps {
  className?: string;
  children?: ReactNode;
  width?: ContainerWidth;
}

const LoginPage: React.FunctionComponent<ILoginPageProps> = React.memo(({className, children, width = ContainerWidth.WIDER}) => {
  const containerStyle: CSSProperties = {
    height: `calc(100vh)`
  };

  return (
    <div className={styleClass.root(className)}>
      <Container
        width={width}
        style={containerStyle}
      >
        {children}
      </Container>
    </div>
  )
});


export default LoginPage;
