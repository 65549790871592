import React, {useContext} from "react";
import Column from "../Column/Column";
import {isMinBedriftRouteWithIcon, routes} from "../../routes/routes";
import Menu from "../Menu/Menu";
import MenuItem from "../MenuItem/MenuItem";
import {BackgroundColor, classNames, Display, FlexDirection} from "@snoam/pinata";
import {DesktopSideBarWidth} from "../../Const";
import {useQuery} from '@apollo/client';
import {GetRole, GetRoleVariables} from '../../__generated__/GetRole';
import {GET_ROLE} from '../../queries';
import {AdminType} from '../../__generated__/globalTypes';
import {MinBedriftSuperAdminRouteWithIcon} from "../../routes/utils";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {useParams} from "react-router-dom";

const styleClass = {
  root: classNames(
    FlexDirection.FLEX_COL,
    Display.INLINE_FLEX,
    BackgroundColor.BG_WHITE
  )
};

export interface ISidebarProps {
  headerHeight: string;
  hideSidebar: boolean;
}

const Sidebar = ({hideSidebar = false, headerHeight}: ISidebarProps) => {

  const {mobile, tablet, portrait} = useContext(ResponsiveContext);
  const { agreementNumber } = useParams<{ agreementNumber?: string }>();

  const variables = {
    agreementNumber: agreementNumber ? +agreementNumber : undefined,
  };
  const {data, error, loading} = useQuery<GetRole, GetRoleVariables>(GET_ROLE, {variables});

  if (hideSidebar || mobile || tablet && portrait) {
    return null;
  }

  const role: AdminType | null = (loading || error) ? null : data ? data.me.role : null;
  const superAdminRoutes = (role === AdminType.SUPERADMIN && !agreementNumber) ? routes.super.routes : [];
  const superObserverRoutes = (role === AdminType.SUPEROBSERVER && !agreementNumber) ? routes.superObserver.routes : [];
  const adminRoutes = routes.admin.routes;

  return (
    <Column.Content
      className={styleClass.root}
      style={{
        height: `calc(100vh - ${headerHeight})`,
        width: DesktopSideBarWidth
      }}
    >
      <Menu>
        {
          adminRoutes
            .concat(superAdminRoutes as MinBedriftSuperAdminRouteWithIcon[])
            .concat(superObserverRoutes)
            .filter(isMinBedriftRouteWithIcon)
            .map(({element, path, ...entry}) => (
                <MenuItem
                  path={path}
                  key={entry.label}
                  {...entry}
                />
              )
            )
        }
      </Menu>
    </Column.Content>
  )
};

export default Sidebar;
