import React from "react";
import {BaseFilter, BaseFilterProps} from "./BaseFilter";
import {FilterType} from "../utils";

type ExprFilterProps = Omit<BaseFilterProps, 'type'> & { value: RegExp };

export const Expr: React.FunctionComponent<ExprFilterProps> = (props) => (
  <BaseFilter
    {...props}
    type={FilterType.EXPR}/>
);
