import {
  classNames,
  Display,
  FlexWrap,
  FontSize,
  Icon,
  IconColor,
  IconName,
  IconSize,
  LineHeight,
  ListStyleType,
  Margin,
  Whitespace,
} from '@snoam/pinata';
import * as React from 'react';
import AgreementsCard from './AgreementsCard';
import { GetAgreements_me_agreements_edges, } from '../../__generated__/GetAgreements';
import { EmploymentVerificationMethod } from "../../__generated__/globalTypes";

const styleClass = {
  agreement: {
    expandedBody: {
      card: {
        svg: {
          settings: classNames(
            Display.BLOCK
          )
        },
        list: classNames(
          ListStyleType.LIST_NONE,
          Margin.MT_2,
          LineHeight.LEADING_HEADLINE3,
          FontSize.TEXT_SM
        ),
        listItem: classNames(
          Display.FLEX,
          FlexWrap.FLEX_WRAP
        ),
        domain: classNames(
          Whitespace.WHITESPACE_NO_WRAP,
          Margin.MR_1
        )
      }
    },
  },
};

interface IAgreementBodyProps {
  agreement: Pick<GetAgreements_me_agreements_edges, 'agreementNumber' | 'maxSubscriptionsPerEmployee' | 'settings'>;
}

const maxSubscriptionsPerEmployee = (agreement: Pick<GetAgreements_me_agreements_edges, 'maxSubscriptionsPerEmployee' | 'settings'>): string => {
  return `${agreement.maxSubscriptionsPerEmployee
    ? `Max ${agreement.maxSubscriptionsPerEmployee}`
    : 'Ubegrenset antall'} abonnement pr.ansatt`;
};

const EmailDomains: React.FunctionComponent<Pick<GetAgreements_me_agreements_edges, 'settings'>> = (agreement) => {

  const domains = agreement.settings.emailDomains.length ? (
    <>
      {agreement.settings.emailDomains.map((domain, i, a) => {
        return (
          <span key={i} className={Margin.MR_1}>
            {domain}
            {i < a.length - 1 ? ',' : ''}
          </span>
        );
      })}
    </>
  ) : <>(blank)</>;

  return (
    <>
      <span className={styleClass.agreement.expandedBody.card.domain}>E-post domener:</span>
      {domains}
    </>
  );
};

const OpenidConnect: React.FunctionComponent<Pick<GetAgreements_me_agreements_edges, 'settings'>> = (agreement) => {

  const settings: Array<[string, string]> = [
    ['Navn på bedriftens påloggingssystem', agreement.settings.openidConnectSystemName || '(blank)'],
    ['OpenID Connect discovery URL', agreement.settings.openidConnectDiscoveryUrl || '(blank)'],
    ['OpenID Connect client id', agreement.settings.openidConnectClientId || '(blank)'],
    ['OpenID Connect client secret', agreement.settings.openidConnectClientSecretMasked || '(blank)'],
  ];

  return (
    <>
      <span className={styleClass.agreement.expandedBody.card.domain}>OpenID Connect-innstillinger:</span>
      <ul className={Margin.ML_2}>
        {settings.map(([label, value], i) => (
          <li key={`OIDC-setting-${i}`}>{label}: {value}</li>
        ))}
      </ul>
    </>
  );
};

const renderSettings = (agreement: Pick<GetAgreements_me_agreements_edges, 'maxSubscriptionsPerEmployee' | 'settings'>) => {
  const verificationInfo = agreement.settings.employeeVerificationMethod === EmploymentVerificationMethod.EMAIL ? (
    <li className={styleClass.agreement.expandedBody.card.listItem}>
      <EmailDomains {...agreement}/>
    </li>
  ) : (<li className={styleClass.agreement.expandedBody.card.listItem}>
    <OpenidConnect {...agreement}/>
  </li>);
  return (
    <ul className={styleClass.agreement.expandedBody.card.list}>
      <li>{maxSubscriptionsPerEmployee(agreement)}</li>
      {verificationInfo}
    </ul>
  );
};

const AgreementCardSettings = ({ agreement }: IAgreementBodyProps) => (

  <AgreementsCard
    title={'Instillinger'}
    pathName={`${agreement.agreementNumber}/avtale?tab=settings`}
    icon={
      <Icon
        key={`${IconName.SETTINGS}`}
        name={IconName.SETTINGS}
        color={IconColor.NEUTRAL_4}
        size={IconSize.MEDIUM}
        className={styleClass.agreement.expandedBody.card.svg.settings}
      />
    }
  >
    {renderSettings(agreement)}
  </AgreementsCard>
);

export default AgreementCardSettings;
