import * as React from 'react';
import {
  BackgroundColor,
  classNames,
  Display,
  FlexDirection,
  FontWeight,
  Heading,
  HeadingLevel,
  Margin,
  TextColor
} from "@snoam/pinata";
import {Modal} from "@snoam/mono-modal";
import TermsText from "./TermsText";

interface ITermsAndConditionModal {
  id: string;
}

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextColor.TEXT_NEUTRAL_6,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    Margin.MB_4,
  ),
};

const TermsAndConditionModal: React.FunctionComponent<ITermsAndConditionModal> = ({id}) => {

  return (
    <Modal
      id={id}
      backdrop={true}
      className={BackgroundColor.BG_NEUTRAL_1}
    >
      <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
        Abonnementsvilkår 11.19
      </Heading>
      <div className={styleClass.root}>
        <TermsText/>
      </div>
    </Modal>
  )
};

export default TermsAndConditionModal;
