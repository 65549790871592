import * as React from "react";
import {classNames, ListStyleType} from "@snoam/pinata";

const styleClass = {
  root: classNames(
    ListStyleType.LIST_NONE
  )
};


export default class Menu extends React.Component {

  render(): React.ReactNode {
    const {children} = this.props;
    return (
      <nav>
        <ul className={styleClass.root}>
          {children}
        </ul>
      </nav>
    );
  }
}
