import React from "react";
import {
  AlignItems,
  classNames,
  Display,
  FlexDirection,
  FlexGrow,
  FlexShrink, FontSize, FontWeight, Heading, HeadingLevel,
  JustifyContent, Margin, TextColor, Toggle, ToggleSize, ToggleSkin,
  Width
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Eq} from "../../components/Filter/types/Eq";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    FlexGrow.FLEX_GROW_0,
    FlexShrink.FLEX_SHRINK_0,
    AlignItems.ITEMS_START,
    JustifyContent.JUSTIFY_BETWEEN
  ),
  descr: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Width.MD_W_FULL
    ),
    heading: classNames(
      FontSize.TEXT_BASE,
      Margin.MB_1,
      FontWeight.FONT_NORMAL
    ),
    text: classNames(
      TextColor.TEXT_NEUTRAL_4,
      FontSize.TEXT_SM
    )
  }
};
const inputId = `toggle_only_manuel_invoice`;
export const OnlyManuelInvoiceAgreementsFilter = () => (
  <Filter id={'OnlyManuelIInvoiceAgreementsFilter'}>
    <Eq
      id={'OnlyManuelIInvoiceAgreementsFilter_0'}
      key={'OnlyManuelIInvoiceAgreementsFilter_0'}
      name={'manualInvoiceFlag'}
      value={true}
    >
      {
        ({active, toggleFilter}) => (
          <label className={styleClass.root} htmlFor={inputId}>
            <div className={styleClass.descr.root}>
              <Heading level={HeadingLevel.FIVE} className={styleClass.descr.heading}>
                Vis kun avtaler med manuell fakturering
              </Heading>
            </div>

            <div>
              <Toggle
                id={inputId}
                size={ToggleSize.LARGE}
                skin={ToggleSkin.PRIMARY}
                checked={!!active}
                onClick={toggleFilter}
              />
            </div>
          </label>
        )
      }
    </Eq>
  </Filter>
);

