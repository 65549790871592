import * as React from "react";
import {
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  Margin,
  Padding,
  FontSize,
  Width, FlexDirection, AlignItems, FlexShrink, TextTransform,
} from "@snoam/pinata";
import {GetAgreement_me_agreementsWithDetails} from '../../__generated__/GetAgreement';

const styleClass = {
  agreement: {
    padding: classNames(
      Padding.MD_P_8,
    ),
    wrapper: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      FlexDirection.MD_FLEX_ROW,
      Margin.MB_8,
      Margin.MD_MB_4,
    ),
    firstColumn: (mobile: boolean | undefined) => classNames(
      {
        [BorderWidth.BORDER_B_1]: mobile,
        [BorderWidth.BORDER_R_1]: !mobile,
      },
      Padding.P_6,
      Padding.PB_4,
      Padding.MD_PB_0,
      Padding.MD_P_0,
      Margin.MB_4,
      Margin.MD_MB_0,
      BorderStyle.BORDER_DASHED,
      BorderColor.BORDER_NEUTRAL_4,
      Margin.MD_MR_8,
      Width.MD_W_1_3,
    ),
    helpText: classNames(
      Display.FLEX,
      AlignItems.ITEMS_START,
      AlignItems.MD_ITEMS_CENTER,
      Padding.PX_6,
      Padding.PB_10,
      Padding.MD_PX_0,
      Padding.MD_PB_6,
    ),
    helpTextIcon: classNames(
      FlexShrink.FLEX_SHRINK_0,
    ),
    secondColumn: classNames(
      Width.W_2_3,
      Padding.PX_6,
      Padding.MD_PX_0,
    ),
    subtitle: classNames(
      FontWeight.FONT_NORMAL,
      TextTransform.UPPERCASE,
      Margin.MB_3,
      FontSize.TEXT_SM,
    ),
    customerName: classNames(
      FontSize.TEXT_XL,
    ),
    contactInfo: classNames(
      Display.BLOCK,
      FontSize.TEXT_LG,
    ),
  },
};

const AgreementPanel: React.FunctionComponent<{ mobile: boolean | undefined, agreement: GetAgreement_me_agreementsWithDetails }> = ({mobile, agreement}) => (
  <div className={styleClass.agreement.padding}>

    <div className={styleClass.agreement.wrapper}>
      <div className={styleClass.agreement.firstColumn(mobile)}>
        <Heading level={HeadingLevel.FIVE} className={styleClass.agreement.subtitle}>
          Bedriftsnavn
        </Heading>
        <span className={styleClass.agreement.customerName}>
          {agreement.accountName}
        </span>
      </div>
      <div className={styleClass.agreement.secondColumn}>
        <Heading level={HeadingLevel.FIVE} className={styleClass.agreement.subtitle}>
          Kontaktperson Bedrift
        </Heading>
        {agreement.contactPerson ?
          <>
            <span className={styleClass.agreement.contactInfo}>{agreement.contactPerson.firstName}</span>
            <span className={styleClass.agreement.contactInfo}>{agreement.contactPerson.lastName}</span>
          </>
          :
          <span>Ingen kontaktinformasjon tilgjengelig.</span>
        }
      </div>
    </div>

    <p className={styleClass.agreement.helpText}>
      <Icon
        name={IconName.ANNOUNCEMENT}
        color={IconColor.NEUTRAL_4}
        className={styleClass.agreement.helpTextIcon}
      />
      <span className={Margin.ML_2}>Kontakt kundesenteret for å endre informasjonen.</span>
    </p>
  </div>
);

export default AgreementPanel;
