import React from "react";
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Display, FlexDirection, FontSize, FontWeight, Heading, HeadingLevel,
  Margin, MinHeight,
  Padding, TextColor, TextDecoration
} from "@snoam/pinata";
import {GetAgreements_me_agreements_edges} from "../../__generated__/GetAgreements";
import {capitalize} from "../../utils";
import {Link} from "react-router-dom";
import moment from "moment";

const styleClass = {
  root: classNames(
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_3,
    BackgroundColor.BG_WHITE,
    Padding.P_4,
    MinHeight.MIN_H_MD,
    Display.FLEX,
    FlexDirection.FLEX_COL,
  ),
  score: classNames(
    FontSize.TEXT_HERO,
    TextColor.TEXT_PRIMARY_1,
    FontWeight.FONT_LIGHT,
    'average-activation-degree--text'
  ),
  label: classNames(
    FontSize.TEXT_SM,
    TextColor.TEXT_NEUTRAL_5
  ),
  table: {
    root: classNames(
      Padding.PT_4,
    ),
    element: classNames(
      Padding.PY_1
    ),
    date: classNames(
      TextColor.TEXT_NEUTRAL_5,
      Margin.MR_4,
    ),
    link: classNames(
      TextColor.HOVER_TEXT_PRIMARY_1,
      TextDecoration.HOVER_UNDERLINE,
    )
  }
}

const NewestAgreements: React.FunctionComponent<{agreements: GetAgreements_me_agreements_edges[]}> = (props) => {
  const {agreements} = props;
  return(
    <div className={styleClass.root}>
      <div>
        <span className={styleClass.label}>TOPP 10</span>
        <Heading level={HeadingLevel.TWO}>Nyeste avtaler</Heading>
        <div className={styleClass.table.root}>
          {agreements.map(a => {
            const name = a.accountName.length > 40 ? a.accountName.substr(0, 40) + "..." : a.accountName;
            return(
              <div className={styleClass.table.element} key={a.accountNumber+a.agreementNumber}>
                <span className={styleClass.table.date}>{moment(a.created, 'MM/DD/YYYY').format("DD-MM-YYYY")}</span>
                <Link className={styleClass.table.link} to={`/admin/avtaler/${a.agreementNumber}/abonnenter`}>
                  {capitalize(name)}
                </Link>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default NewestAgreements;
