import * as React from 'react';
import {
  BackgroundColor,
  Button,
  classNames,
  Display,
  FlexDirection,
  FontWeight,
  Heading,
  HeadingLevel,
  Input,
  Margin,
  Padding,
  TextColor,
  Toggle,
  ToggleSize,
  ToggleSkin,
  Width
} from "@snoam/pinata";
import { ChangeEventHandler, MouseEventHandler, useState } from "react";
import {GetAllProducts_availableProducts} from "../../__generated__/GetAllProducts";
import {Modal} from "@snoam/mono-modal";

interface IEditProductModalProps {
  id: string;
  onClose: () => void;
  onSave: (data: GetAllProducts_availableProducts) => void;
  products: GetAllProducts_availableProducts[]
}

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextColor.TEXT_NEUTRAL_6,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    Margin.MB_4,
  ),
  textArea: classNames(
    Width.W_FULL,
    Padding.P_4
  )
};

interface IEditProductState {
  productName: string;
  productVariationImageUrl: string;
  productVariationDescription: string;
  productVariationActive: boolean;
}

const EditProductModal = ({id, onClose, onSave, products}: IEditProductModalProps) => {
  const singleProduct = products[0];
  const [productData, setProductData] = useState<IEditProductState>({
    productName: singleProduct.productName,
    productVariationActive: singleProduct.productVariationActive,
    productVariationDescription: singleProduct.productVariationDescription ? singleProduct.productVariationDescription : '',
    productVariationImageUrl: singleProduct.productVariationImageUrl ? singleProduct.productVariationImageUrl : ''
  });

  const onChangeProductName: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setProductData({
      ...productData,
      productName: value
    });
  };

  const onChangeImageUrl: ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.value;
    setProductData({
      ...productData,
      productVariationImageUrl: value
    });
  };

  const onChangeDescription: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    const value = e.target.value;
    setProductData({
      ...productData,
      productVariationDescription: value
    });
  };

  const onActivate: MouseEventHandler<HTMLElement> = () => {
    setProductData({
      ...productData,
      productVariationActive: !productData.productVariationActive
    });
  };

  const onSaveData: MouseEventHandler<HTMLButtonElement> = () => {
    products.forEach(p => {
      const saveProductData: GetAllProducts_availableProducts = Object.assign({}, p, productData);
      delete saveProductData.__typename;
      onSave(saveProductData);
    });

    setTimeout(() => {
      onClose();
    }, 1000);
  };

  return (
    <Modal
      id={id}
      backdrop={true}
      onClose={onClose}
      className={BackgroundColor.BG_NEUTRAL_1}
    >
      <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
        Rediger innholder for {singleProduct.productName}
      </Heading>
      <div className={styleClass.root}>
        <Input
          placeholder={"Produktnavn"}
          label={"Produktnavn"}
          onChange={onChangeProductName}
          value={productData.productName}
        />
        <Input
          placeholder={"Bilde url"}
          label={"Bildeurl"}
          onChange={onChangeImageUrl}
          value={productData.productVariationImageUrl}
        />
        <textarea
          placeholder={'Beskrivelse'}
          className={styleClass.textArea}
          onChange={onChangeDescription}
          value={productData.productVariationDescription}
          rows={5}
        />
        <label htmlFor={'toggle_activate_product'}>
          <div className={Margin.MY_2}>Aktivt</div>
          <Toggle
            id={'toggle_activate_product'}
            size={ToggleSize.LARGE}
            skin={ToggleSkin.PRIMARY}
            onClick={onActivate}
            checked={productData.productVariationActive}
          />
        </label>
        <div className={Margin.MX_AUTO}>
          <Button
            text={"Lagre"}
            ariaLabel={"Lagre"}
            onClick={onSaveData}
          />
        </div>
      </div>
    </Modal>
  );
};

export default EditProductModal;
