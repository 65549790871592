import Page from "../../components/Page/Page";
import LoginPage from "../../pages/LoginPage/LoginPage";
import FilterProvider from "../../context/FilterContext/FilterContext";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import * as React from "react";
import {BackgroundColor, ContainerWidth} from '@snoam/pinata';
import {ModalProvider} from "@snoam/mono-modal"
import {DepartmentContextProvider} from "../../context/DepartmentContext/DepartmentContext";
import {Tracker} from "./Tracker";
import {MessageContextProvider} from "../../context/MessageContext/MessageContext";
import {EnvelopeProviderWrapper} from "./EnvelopeProviderWrapper";
import {PageWithBackgroundColorAndNoFooter} from "./PageWithBackgroundColorAndNoFooter";
import {OrderPageWithBackgroundColorAndNoFooter} from "./OrderPageWithBackgroundColorAndNoFooter";
import {PageWithNoFooter} from "./PageWithNoFooter";

export {AdminNavigation} from "./AdminNavigation";
export {GenericNavigation} from "./GenericNavigation";
export {LoginNavigation} from "./LoginNavigation";
export {OrderNavigation} from "./OrderNavigation";

export const SubscriptionComposition: React.ComponentType<any>[] = [
  Page,
  ModalProvider,
  EnvelopeProviderWrapper,
  FilterProvider,
  MessageContextProvider,
  DepartmentContextProvider,
  Tracker,
  ErrorBoundary,
];


export const FilterComposition: React.ComponentType[] = [
  Page,
  ModalProvider,
  EnvelopeProviderWrapper,
  FilterProvider,
  ErrorBoundary,
];

export const PageComposition: React.ComponentType[] = [
  Page,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];

export const OrderLoginPageComposition: React.ComponentType[] = [
  PageWithNoFooter,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];

export const OrderPageWithBackgroundComposition: React.ComponentType[] = [
  PageWithBackgroundColorAndNoFooter,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];

export const OrderPageFlowWithBackgroundComposition: React.ComponentType[] = [
  OrderPageWithBackgroundColorAndNoFooter,
  ModalProvider,
  Tracker,
  ErrorBoundary,
];


const LoginPageWithFooter: React.FunctionComponent = ({children}) => (
  <LoginPage width={ContainerWidth.DEFAULT} className={BackgroundColor.BG_TERTIARY_3}>
    {children}
  </LoginPage>
);

export const LoginPageComposition: React.ComponentType[] = [
  LoginPageWithFooter,
  Tracker,
  ErrorBoundary
];
