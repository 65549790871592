import React, {ReactNode} from "react";
import {
  BackgroundColor,
  BorderColor,
  BorderRadius,
  BorderWidth,
  classNames,
  Display,
  Height,
  BoxShadow,
  TextColor,
  Width,
  FlexDirection,
  FlexShrink,
  AlignItems,
  JustifyContent
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Length} from "../../components/Filter/types/Length";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    FlexShrink.FLEX_SHRINK_0,
    AlignItems.ITEMS_BASELINE,
    JustifyContent.JUSTIFY_CENTER
  ),
  btn: (active: boolean) => classNames(
    Height.H_12,
    Width.W_12,
    BorderRadius.ROUNDED_FULL,
    BorderWidth.BORDER_1,
    BorderColor.BORDER_NEUTRAL_4,
    BoxShadow.SHADOW_BORDER_NEUTRAL_4,
    {
      [BackgroundColor.BG_PRIMARY_3]: active,
      [TextColor.TEXT_WHITE]: active,
    }
  )
};

const numBtns = [1, 1, 1, 1, 1];

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

export const NumSubscriptionsFilter = () => {
  const buttons: any = numBtns.map((_, n) => (
      <Length
        id={`subscriptions_filter_${n}`}
        key={`subscriptions_filter_${n}`}
        name={'activeSubscriptions'}
        value={n}
        variant={"eq"}
      >
        {
          ({active, toggleFilter}) => {
            return (
              <button
                className={styleClass.btn(!!active)}
                key={`num_sub_btn_${n}`}
                onClick={toggleFilter}
                aria-label={`${n}`}
              >
                {n}
              </button>
            )
          }
        }
      </Length>
    )
  );

  return (
    <Filter
      heading={'Antall aktive abonnement'}
      id={'numSubscriptions'}
      uniqueBy={'customerNumber'}
      wrapper={customWrapper}
    >
      {buttons}
      <Length
        id={`subscriptions_filter_${numBtns.length}`}
        key={`subscriptions_filter_${numBtns.length}`}
        name={'activeSubscriptions'}
        value={numBtns.length - 1}
        variant={"gt"}
      >
        {
          ({active, toggleFilter}) => {
            return (
              <button
                className={styleClass.btn(!!active)}
                key={`num_sub_btn_${numBtns.length}`}
                onClick={toggleFilter}
                aria-label={`${numBtns.length}`}
              >
                {numBtns.length}+
              </button>
            )
          }
        }
      </Length>
    </Filter>
  )
};

export default NumSubscriptionsFilter;
