import * as React from 'react';

const TermsText = () => {
  return (
    <div className="wrap">
      <div className="box hero-unit">
        <div className="box-body editor-styles">
          <p style={{fontWeight: 400}}>
            Vilkår for abonnement og bruk av tjenester i Schibsted Media AS
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Sist oppdatert: 06.2024
          </p>
          <h3><b>1 INNLEDNING</b></h3>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Schibsted Media AS består blant annet av mediehusene Aftenposten, Bergens Tidende, Stavanger Aftenblad,
            VG, E24 og Dine Penger (heretter kalt “Mediehusene”).
            Når du kjøper abonnement hos ett eller flere av Mediehusene, inngår du en abonnementsavtale med Schibsted
            Media AS (heretter kalt “Schibsted”).
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Disse abonnementsvilkårene regulerer dine rettigheter og plikter ved kjøp av digitalt og/eller
            papirabonnement og annen bruk av tjenester fra Mediehusene.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Schibsted bruker Schibsted-konto som påloggingsløsning og elektronisk betalingstjeneste. Når du blir
            abonnent
            eller registrerer en brukerkonto hos Schibsted, inngår du en brukeravtale som beskriver og regulerer
            Schibsteds bruk av opplysninger som samles inn. Alle kunder må opprette en Schibsted-konto for å kjøpe
            produkter digitalt, for å benytte digitale produkter, eksempelvis eAvisen, eller for å benytte nettjenester
            for å gjøre endringer på abonnementet. Brukernavn og passord på din Schibsted-konto er personlig og skal
            ikke
            distribueres til andre. Du plikter å holde all registrert informasjon knyttet til din Schibsted-konto
            oppdatert og korrekt.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Disse abonnementsvilkårene suppleres av vilkårene for bruk av påloggings- og betalingstjenesten
            Schibsted-konto og Schibsteds Personvernerklæring. Vi oppfordrer deg til å lese disse i sin helhet, blant
            annet for å forstå hvordan dine personopplysninger behandles av Schibsted og Schibsted-konto.
          </p>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Fra tid til annen vil vi uten nærmere varsel oppdatere disse vilkårene. Ved større endringer vil du bli bedt
            om å lese gjennom og akseptere vilkårene på nytt.
          </p>


          <h3><b>2 BESTILLING OG BETALING</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>Dersom du allerede har opprettet en Schibsted-konto (se
            punktet over) bruker du samme påloggingsinformasjon ved kjøp av abonnement eller andre tjenester fra
            Schibsted.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Aldersgrensen for å bestille abonnement på papiravis eller digitalt abonnement fra Schibsted er 18 år.
            Abonnement på en av Schibsteds digitale tjenester er til personlig og privat bruk. Noen av de digitale
            abonnementstjenestene kan deles med en eller flere andre personer avhengig av ditt abonnement. Dette
            administreres på Min side gjennom tjenesten<i>Del ditt abonnement.</i> Uten
            skriftlig avtale med
            Schibsted Media AS eller de enkelte Mediehus skal tjenestene og publikasjonene kun brukes ikke-kommersielt.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Du kan inngå avtale om abonnement for ulike abonnementsperioder. Gjeldende alternativer og priser finner du
            på det aktuelle Mediehusets nettsider. Nye priser og produktendringer kunngjøres i avisen og på det aktuelle
            Mediehusets nettsider senest 14 dager før iverksettelse. Nye priser gjøres først gjeldende fra neste
            abonnementsperiode.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Tilgang til digitale tjenester aktiveres normalt ved bestilling. Ved manglende betaling vil tilgangen til
            produktet eller tjenesten stenges. Du vil bli belastet for det du har mottatt av aviser eller tjenester til
            ordinær pris for perioden abonnementet har vært aktivt.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Alle abonnement forskuddsbetales for avtalt abonnementsperiode. Du vil bli fakturert eller trukket fra ditt
            betalingskort av det enkelte mediehus. Fakturautsteder for det enkelte mediehus er Arvato Finans. Dersom du
            har valgt kortbetaling vil du i rimelig tid før første abonnementsperiode utløper, motta elektronisk varsel
            om fornyelse av abonnementet og belastning av ditt registrerte betalingskort for ny abonnementsperiode.
            Abonnenter med abonnementsperiode på 3 måneder eller mer vil motta varsel før hver fornyelse.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Dersom du ikke ønsker å fortsette ditt abonnement med en ny abonnementsperiode kan du si opp abonnementet
            frem til 48 timer før ny abonnementsperiode starter. Se nærmere om dette i punkt 3 nedenfor.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Schibsteds gjeldende abonnementsperioder er definert som følger: 1 måned = 30 dager, 1 kvartal = 91 dager, 1
            halvår = 182 dager, 1 år = 365 dager.
          </p>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Schibsteds belaster fakturagebyr ved utsendelse av faktura. Ved bruk av AvtaleGiro, elektronisk faktura
            (eFaktura), PDF-faktura eller betalingskort belastes ikke fakturagebyr.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Dersom du midlertidig flytter levering av papiravis til utlandet, kan det påløpe ekstra kostnader. Eventuell
            kostnad avregnes på neste faktura og kan føre til endret pris eller abonnementsperiode. Ved omadressering
            til utlandet garanterer ikke Schibsted for forsendelsen av avisen, og eventuell manglende levering vil ikke
            erstattes av Schibsted.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Du kan velge å pause abonnement ditt en periode. Abonnementet vil ikke løpe i den perioden du har valgt
            pause, men fortsette å løpe ut det antallet dager du har betalt for når abonnementet reaktiveres.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Du kan midlertidig stoppe avisleveringen din slik du selv ønsker, men det gis ikke forlengelse i
            abonnementet i perioden avisen ikke leveres. Abonnementet belastes som normalt. I perioden når papiravisen
            ikke leveres, vil du fortsatt ha tilgang digitalt. Det er ikke mulig å midlertidig stoppe et digitalt
            abonnement.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Det gis ikke momsrefusjon for kjøp og bruk av digitale tjenester fra utlandet og Svalbard.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Forholdet til iTunes
            Ved kjøp av en av Schibsteds digitale tjenester gjennom Apples iTunes App Store gjelder Apples brukervilkår
            for registrering og kjøp. Du må administrere disse abonnementene og tjenestene i din iTunes-konto og ikke på
            Schibsteds nettsider.
          </p>

          <h3><b>3 VARIGHET OG OPPSIGELSE</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Ditt abonnement fornyes automatisk. Du fornyes til den abonnementsperioden som ble oppgitt i
            kjøpsøyeblikket.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Abonnement kan når som helst sies opp med virkning fra neste abonnementsperiode, men senest innen 48 timer
            før ny abonnementsperiode starter. Refusjon kan bli gitt, dersom du henvender deg til vårt kundesenter.
            En oppsigelse må rettes til vårt kundesenter på e-post eller telefon, eller gjennomføres på Min side, under “Endre abonnement”.
            Manglende betaling anses ikke som oppsigelse.
          </p>

          <h3><b>4 LEVERING</b></h3>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Avtalt leveringssted for papiravisen må merkes godt slik at distributøren finner fram. Det er normalt ikke
            avislevering på offentlige helligdager. For leveringsfrister, vennligst se
            aktuelt nettsted.
          </p>

          <h3><b>5 ANGRERETT</b></h3>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Ved kjøp av abonnement hos et Mediehus har du angrerett i henhold til<a
            href="https://lovdata.no/dokument/NL/lov/2014-06-20-27?q=angrerett" style={{color: "blue"}}> angrerettlovens
            bestemmelser</a>.
          </p>

          <p style={{fontWeight: 400}}>Du mottar angrerettsskjema i forbindelse med bestillingen, og kan gå fra avtalen
            ved returnere utfylt angrerettsskjema til vårt kundesenter innen 14 dager etter at angrerettskjemaet er
            mottatt.</p>

          <h3><b>6 REKLAMASJONER</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>Dersom du ønsker å reklamere på varen du har kjøpt, ber vi
            deg ta kontakt med oss via Min side, se linker i punkt 3.</p>

          <h3><b>7 RETTIGHETER</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>Alle rettigheter til tjenester og innhold fra Mediehusene i
            Schibsted tilhører det aktuelle Mediehus. Det er ikke tillatt å kopiere, distribuere eller på annen måte
            mangfoldiggjøre slikt innhold uten etter avtale med det aktuelle Mediehuset.</p>

          <h3><b>8 ANSVAR VED NEDETID ELLER TEKNISKE PROBLEMER</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>Schibsteds digitale tjenester kan fra tid til annen være
            helt eller delvis utilgjengelig på grunn av vedlikehold eller av andre grunner. Schibsted vil i den grad det
            er praktisk bestrebe seg på å gi brukerne informasjon om begrensninger i tilgjengeligheten. Vedvarende
            problemer med tilgang til de digitale tjenestene, må varsles vårt kundesenter.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Om levering av papiravis uteblir som følge av streik, lockout eller andre leveringsproblemer utenfor vår
            kontroll (force majeure), vil vårt erstatningsansvar begrenses oppad til det du har betalt for tilsvarende
            tidsrom.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Ved uteblitt eller vesentlig forsinket avis, vil Schibsted Media AS godskrive deg det som er betalt for avisen
            i tilsvarende tidsrom forutsatt at du snarest melder fra om uteblivelser til Schibsted Media AS på Min side.
            Alternativt krediteres løssalgspris ved kjøp av erstatningsavis hos løssalgforhandler.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Schibsted og Mediehusene er ikke under noen omstendighet ansvarlig for eventuelle konsekvenser eller tap som
            følge av at produktene og tjenestene ikke kan benyttes som forutsatt.
          </p>
          <p style={{fontWeight: 400, marginBottom: '10px'}}>
            Produktene og tjenestene fra Mediehusene tilbys uten garantier for deres funksjon, kvalitet, anvendbarhet
            for bestemt formål, sikkerhet eller riktighet. Schibsted og Mediehusene tar forbehold om at det kan
            forekomme feil og unøyaktigheter i innhold. Schibsted, Mediehusene og deres samarbeidspartnere kan ikke
            under noen omstendighet holdes ansvarlig for dette. Dette gjelder dog ikke dersom Schibsted, Mediehusene og
            deres samarbeidspartnere har påført en abonnent skade med hensikt eller gjennom grov uaktsomhet.
            Fraskrivelsene i dette punktet gjelder i den grad annet ikke følger av lov.
          </p>

          <h3><b>9 BEHANDLING AV PERSONOPPLYSNINGER</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>Schibsted Media AS er behandlingsansvarlig for de
            personopplysninger som er relatert til ditt kundeforhold i Schibsted Media AS. For mer informasjon om
            personvern oppfordrer vi deg til å lese vår <a
              href="https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/"
              style={{color: "blue"}}>personvernerklæring</a>.
          </p>

          <h3><b>10 SAMTYKKE TIL MARKEDSFØRING OG AKSEPT AV ANNONSEVISNING</b></h3>
          <p>
            Som aktiv og betalende kunde hos Schibsted vil vi kunne ringe deg, samt sende deg tilbud og annen relevant
            informasjon basert på opplysninger vi har om deg som kunde. Vi anser deg å være aktiv kunde 365 dager etter
            siste aktive abonnement. Markedsføringen vil være i form av nyhetsbrev, e-post, sms eller lignende, og kan
            inneholde tilbud og informasjon på tilsvarende produkter og tjenester.
            Hvis du ikke har et aktivt kundeforhold til Schibsted vil du bare motta elektronisk markedsføring dersom du
            har gitt særskilt samtykke til det.
            Dersom du ikke ønsker å motta slik markedsføring kan du følge avmeldingslenke i markedsføringshenvendelsene
            du mottar.
          </p>

          <h3><b>12 BRUDD PÅ ABONNEMENTSVILKÅRENE</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>Ved brudd på denne disse abonnementsvilkårene forbeholder
            Schibsted og Mediehusene seg retten til å avslutte eller fryse ditt abonnement.</p>

          <h3><b>11 KONTAKTINFORMASJON</b></h3>

          <p style={{fontWeight: 400, marginBottom: '10px'}}>Har du spørsmål om våre brukervilkår, ta gjerne kontakt med
            oss. Du finner oppdatert kontaktinformasjon på våre hjelpesider.
          </p>
        </div>
      </div>
    </div>
)
}

export default TermsText;
