import * as React from 'react';
import {
  BackgroundColor,
  classNames,
  Grid,
  GridGutter,
  Margin,
  MaxWidth,
  Padding,
  FontWeight,
} from '@snoam/pinata';
import InputWithValidator, {emailValidator} from '../../components/InputWithValidator/InputWithValidator';
import {
  GetAgreementOrderInitial_agreement,
  GetAgreementOrderInitial_agreement_settings
} from "../../__generated__/GetAgreementOrderInitial";

const styleClass = {
  greyBox: classNames(
    Padding.P_4, BackgroundColor.BG_NEUTRAL_1
  ),
};

interface IEmailVerificationBox {
  email: string;
  onChange: (email: string) => void;
  formSubmitted: boolean;
  agreement: GetAgreementOrderInitial_agreement;
  settings: GetAgreementOrderInitial_agreement_settings;
  className?: string;
}

const EmailVerificationBox: React.FunctionComponent<IEmailVerificationBox> = (props) => {
  const {emailDomains} = props.settings;

  return (
      <Grid className={classNames(styleClass.greyBox, props.className)} gutter={GridGutter.NONE}>
        <div className={classNames(Padding.PT_6, MaxWidth.MAX_W_SM)}>For å verifisere at du jobber
          i {props.agreement.accountName} trenger vi din jobb-epost. Det er kun eposter med domenet <span className={FontWeight.FONT_BOLD}>{emailDomains.join(", ").replace(/,(?=[^,]*$)/, ' eller')}</span> som
          godkjennes.
        </div>
        <InputWithValidator
          value={props.email}
          validator={emailValidator(emailDomains)}
          required={true}
          className={classNames(MaxWidth.MAX_W_SM, Margin.MT_4)}
          placeholder="Din jobb-epostadresse"
          ariaLabel={"Legg inn din jobb-epostadresse"}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.target.value)}
          errorMsg={`Vennligst oppgi en gyldig jobb-epost`}
          formSubmitted={props.formSubmitted}
        />
      </Grid>
  );
};

export default EmailVerificationBox;
