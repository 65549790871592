import {
  BackgroundColor, BorderRadius,
  classNames,
  Height,
  Width
} from '@snoam/pinata';
import * as React from 'react';

const styleClass = {
  circle: classNames(
    BorderRadius.ROUNDED_FULL,
    BackgroundColor.BG_NEUTRAL_5,
    Width.W_1,
    Height.H_1
  ),
};

const Circle = () => {
  return (
    <div className={classNames(styleClass.circle)}/>
  );
};

export default Circle;
