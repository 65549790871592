import * as React from 'react';

import {MutationFunction, useMutation} from '@apollo/client';

import {UpdateAgreementSettings, UpdateAgreementSettingsVariables} from '../../__generated__/UpdateAgreementSettings';
import ToggleWithPotentialError from '../ToggleWithPotentialError/ToggleWithPotentialError';
import {IToggleProps} from '@snoam/pinata';
import {GetAgreement_me_agreementsWithDetails} from '../../__generated__/GetAgreement';
import {checkNoErrorsUpdateAgreementSettings, UPDATE_AGREEMENT_SETTINGS} from '../../mutations';
import {removeTypename} from '../../utils';
import {useFilterFactory} from "../../context/FilterContext/FilterContext";
import {GET_AGREEMENTS} from "../../queries";

interface ToggleAgreementActivatedProps extends Omit<IToggleProps, 'asyncAction'> {
  agreement: Pick<GetAgreement_me_agreementsWithDetails, 'agreementNumber' | 'id' | 'settings' | 'maxSubscriptionsPerEmployee'>;
  ariaLabel?: string;
}

const ToggleAgreementActivated = ({agreement, defaultChecked, ariaLabel, size, skin, disabled = false}: ToggleAgreementActivatedProps) => {
  const {getParams} = useFilterFactory();
  const updateAgreementSettings: MutationFunction<UpdateAgreementSettings, UpdateAgreementSettingsVariables> = useMutation<UpdateAgreementSettings, UpdateAgreementSettingsVariables>(UPDATE_AGREEMENT_SETTINGS)[0];
  const {
    openidConnectClientId,
    openidConnectClientSecretMasked,
    openidConnectDiscoveryUrl,
    openidConnectSystemName,
    employeePeriodicVerificationSettings,
    ...oldSettings
  } = removeTypename(agreement.settings);
  const variables: UpdateAgreementSettingsVariables = {
    agreementNumber: agreement.agreementNumber,
    agreementId: agreement.id,
    settings: {
      ...oldSettings,
      enableOrdering: !agreement.settings.enableOrdering,
    },
  };

  const asyncAction = () => updateAgreementSettings({
    variables,
    refetchQueries: [
      {
        query: GET_AGREEMENTS,
        variables: {
          filter: getParams()
        }
      }
    ]
  }).then(checkNoErrorsUpdateAgreementSettings);

  return (
    <ToggleWithPotentialError
      key={`toggle-${agreement.id}-${agreement.settings.enableOrdering}`}
      asyncAction={asyncAction}
      defaultChecked={defaultChecked || agreement.settings.enableOrdering}
      size={size}
      skin={skin}
      aria-label={ariaLabel}
      disabled={disabled}
    />
  );
};

export default ToggleAgreementActivated;
