import {
  classNames,
  Display,
  Icon,
  IconName,
  Padding,
  Fill,
  Width, FlexShrink
} from "@snoam/pinata";
import * as React from "react";

export const SettingsIcon = (style?: { style?: React.CSSProperties }) => {
  const iconStyle = style ? style.style : {};
  return (
    <Icon
      name={IconName.SETTINGS}
      className={classNames(Padding.PR_2, Fill.FILL_NEUTRAL_4)}
      style={iconStyle}
    />
  );
};

export const ShareIcon = (style?: { style?: React.CSSProperties }) => {
  const iconStyle = style ? style.style : {};
  return (
    <Icon
      name={IconName.SHARE}
      className={classNames(Display.FLEX, FlexShrink.FLEX_SHRINK_0, Width.W_FULL)}
      style={iconStyle}
    />
  );
};

export const TouchAppIcon = (style?: { style?: React.CSSProperties }) => {
  const iconStyle = style ? style.style : {};
  return (
    <Icon
      name={IconName.TOUCH_APP}
      className={classNames(
        Fill.FILL_WHITE,
        Padding.PX_2,
        Display.FLEX,
        FlexShrink.FLEX_SHRINK_0,
        Width.W_12
      )}
      style={iconStyle}
    />
  );
};
