import * as React from 'react';
import {
  GetAgreementOrderInitial,
  GetAgreementOrderInitialVariables
} from "../../../__generated__/GetAgreementOrderInitial";
import { useQuery } from "@apollo/client";
import { GET_AGREEMENT_ORDER_INITIAL } from "../../../queries";
import { LoadingFragment } from "../../LoadingPage";
import { EmptyAgreements } from "../../Agreements/EmptyAgreements";
import {
  AlignItems,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  Margin,
  Padding,
  TextAlign,
  Width,
} from "@snoam/pinata";
import SvgImage from "../../../components/SvgImage/SvgImage";
import { MySpidContext } from "../../../utils";
import PlaceOrder from "./PlaceOrder";
import {useParams, useSearchParams} from "react-router-dom";
import {MinBedriftRouteComponentProps} from "../../../routes/utils";

export const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextAlign.TEXT_CENTER,
    AlignItems.ITEMS_CENTER,
    Margin.MD_MT_8,
    Margin.LG_MT_16,
    Padding.PX_4,
    Padding.PB_10,
    Margin.MB_8,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.MD_TEXT_2XL,
    FontSize.TEXT_LG,
    Margin.LG_MT_8,
    Margin.MB_6,
  ),
  text: classNames(
    FontSize.MD_TEXT_LG,
    FontSize.TEXT_SM,
    FontWeight.FONT_LIGHT,
  ),
};

export interface Props extends MinBedriftRouteComponentProps {
  spidContext: MySpidContext;
}

const Index: React.FunctionComponent<Props> = (props) => {

  const params = useParams<{ agreementNumber: string }>();
  const [searchParams] = useSearchParams();
  // @ts-ignore
  const agreementNumber = +params.agreementNumber;
  const { code = '', error: queryError = '', error_description = '' } = Object.fromEntries([...searchParams]) as unknown as {
    code: string,
    error: string,
    error_description: string,
  };
  const variables: GetAgreementOrderInitialVariables = { agreementNumber };
  const { data, error, loading } = useQuery<GetAgreementOrderInitial, GetAgreementOrderInitialVariables>(GET_AGREEMENT_ORDER_INITIAL, {
    variables,
    fetchPolicy: "cache-first"
  });

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error || !data || !data.agreement) {
    return <EmptyAgreements error={ error }/>;
  }

  const agreement = data.agreement;

  const showError = queryError || !code;

  if (showError) {
    const errorDescriptionMappings: Array<[RegExp, string]> = [
      [/^User is not assigned to the client application.$/, `Det ser ikke ut som du har tilgang til å bestille abonnement. Ta kontakt med administrator eller IT-support i din bedrift slik at du får den rette tilgangen.`],
      [/^(\w+: )?The signed in user .* is not assigned to a role for the application/, `Det ser ikke ut som du har tilgang til å bestille abonnement. Ta kontakt med administrator eller IT-support i din bedrift slik at du får den rette tilgangen.`],
    ];

    return (
      <div className={ styleClass.root }>
        <SvgImage href={ `${ process.env.PUBLIC_URL }/svg/error.svg` } title={ error_description || error }
                  className={ Width.W_FULL }/>
        <Heading level={ HeadingLevel.TWO } className={ styleClass.heading }>
          Noe gikk galt.
        </Heading>

        <Heading level={ HeadingLevel.TWO } className={ styleClass.heading }>
          { (errorDescriptionMappings.find(([r]) => r.test(error_description)) || [])[1] || error_description || 'Prøv gjerne igjen senere.' }
        </Heading>
      </div>
    );
  }

  return (
    <PlaceOrder {...props} className={styleClass.root} agreement={agreement} code={code}/>
  );
};

export default Index;
