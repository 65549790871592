import React from 'react';
import {
  AlignItems,
  Arrow,
  Card,
  classNames,
  Display,
  Icon,
  IconColor,
  IconName,
  Margin,
  TextColor,
  TransparentSkin,
  Width,
} from '@snoam/pinata';

const ErrorBoxInvalidAddress = () =>  {
  return (
    <Card transparent={TransparentSkin.NEGATIVE_20} arrow={Arrow.TOP_CENTER} >
      <div className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER)}>
          <Icon name={IconName.INFO} color={IconColor.NEGATIVE} className={classNames(Margin.MR_6, Width.W_12)} />
          <div className={TextColor.TEXT_NEUTRAL_7}>
            Vi finner dessverre ikke denne adressen. For at vår leverandør skal kunne finne frem, trenger vi en godkjent postadresse.
          </div>
      </div>
    </Card>
  )
};

export default ErrorBoxInvalidAddress;
