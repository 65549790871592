import * as React from 'react';
import {ReactElement, useState} from 'react';

import {IWithSpidContext, withSpidContext} from '@snoam/mono-spid';
import {
  classNames,
  Display,
  Icon,
  IconName,
  Margin,
  MaxWidth,
  Padding,
  Fill,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  AlignItems,
  ButtonSkin
} from '@snoam/pinata';

import InvoiceButton from '../../pages/Invoice/InvoiceButton';
import SvgImage from '../SvgImage/SvgImage';
import {QueryInvoices, QueryInvoicesVariables} from '../../__generated__/QueryInvoices';
import {useQuery} from '@apollo/client';
import {QUERY_INVOICES} from '../../queries';

const styleClass = {
  button: classNames(
    Width.W_FULL,
    MaxWidth.MAX_W_SM,
  ),
};

export interface IInvoiceButtonProps extends IWithSpidContext {
  agreementNumber: number;
  accountNumber: number;
  classNames?: string;
}

const ShowInvoice = (props: IInvoiceButtonProps) => {
  const {accountNumber, agreementNumber, spidContext} = props;
  const variables: QueryInvoicesVariables = {
    accountNumber,
    agreementNumber,
  };
  const {data, error, loading} = useQuery<QueryInvoices, QueryInvoicesVariables>(QUERY_INVOICES, {
    variables,
    fetchPolicy: 'network-only'
  });
  const [downloadError, setDownloadError] = useState<ReactElement | string | null>(null);

  if (loading) {
    return (
      <div className={classNames(props.classNames, Display.FLEX, AlignItems.ITEMS_CENTER)}>
        <Icon name={IconName.LOADING_WHEEL} className={"spin"}/>
        <span className={Padding.PL_2}>Laster fakturaer...</span>
      </div>
    );
  }
  if (error) {
    throw new Error(error.message);
  }

  if (!data) {
    throw new Error(`Kunne ikke hente fakturaer.`);
  }

  if (!data.me.invoices.length) {
    return (<></>);
  }

  const invoices = data.me.invoices;
  const lastInvoice = invoices.sort((a, b) => a.invoiceDate < b.invoiceDate ? 1 : -1)[0];

  const icon = (
    <SvgImage
      className={classNames(Margin.ML_3, Fill.FILL_BLACK)}
      svgStyle={{width: '26px', height: '26px'}}
      href={`${process.env.PUBLIC_URL}/svg/pdf.svg`}/>
  );

  return (
    <div className={classNames(styleClass.button, props.classNames)}>
      <InvoiceButton
        accountNumber={accountNumber}
        agreementNumber={agreementNumber}
        icon={icon}
        invoiceNumber={lastInvoice.invoiceNumber}
        setError={setDownloadError}
        skin={ButtonSkin.SECONDARY}
        spidContext={spidContext}
        text={"Faktura"}
      />
      {downloadError &&
      <div className={classNames(TextColor.TEXT_NEGATIVE, TextAlign.TEXT_CENTER, FontSize.TEXT_SM)}>
        {downloadError}
      </div>
      }
    </div>
  );
};

export default withSpidContext<IInvoiceButtonProps>(ShowInvoice);
