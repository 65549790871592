import * as React from 'react';
import { ReactNode, useState } from 'react';
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';
import {
  Arrow,
  classNames,
  Display,
  Icon,
  IconColor,
  IconName,
  ILinkProps,
  Link,
  Margin,
} from '@snoam/pinata';
import { MutationFunction, PureQueryOptions, useMutation } from '@apollo/client';
import { DeleteAdmin, DeleteAdminVariables } from '../../__generated__/DeleteAdmin';
import { checkNoErrors, DELETE_ADMIN } from '../../mutations';
import { GetSuperAdmins_superAdmins } from '../../__generated__/GetSuperAdmins';

interface DeleteAdminButtonProps extends Omit<ILinkProps, 'children' | 'id' | 'onClick'>, Pick<GetSuperAdmins_superAdmins, 'id'> {
  mobile: boolean;
  refetchQueries?: PureQueryOptions[];
}

const DeleteAdminLink: React.FunctionComponent<DeleteAdminButtonProps> = ({ id, mobile, refetchQueries, ...rest }) => {
  const loadingMessage = (
    <>
      <Icon name={IconName.LOADING_WHEEL} color={IconColor.NEUTRAL_4} className={'spin'}/>
      {!mobile && <p className={Margin.ML_2}>Sletter administrator...</p>}
    </>
  );
  const errorMessage = (message: string) => (
    <MessageBox message={message} type={MessageBoxType.INFO} arrow={Arrow.TOP_CENTER} />
  );

  const defaultMessage = (isLoading: boolean) => (
    <span className={classNames(Display.FLEX)}>
      <Icon name={isLoading ? IconName.LOADING_WHEEL : IconName.DELETE} color={IconColor.NEUTRAL_4}/>
      {!mobile && <p className={Margin.ML_2}>Slett administrator</p>}
    </span>
  );
  const [message, setMessage] = useState<ReactNode>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const deleteAdmin: MutationFunction<DeleteAdmin, DeleteAdminVariables> = useMutation<DeleteAdmin, DeleteAdminVariables>(DELETE_ADMIN)[0];

  const asyncAction = () => deleteAdmin({ refetchQueries, variables: { id } }).then(checkNoErrors);
  const onClick = async () => {
    setMessage(null);
    // eslint-disable-next-line no-restricted-globals
    if (confirm("Er du sikker på at du vil slette administratoren?")) {
      setMessage(loadingMessage);
      setLoading(true);
      try {
        await asyncAction();
        setLoading(false);
        setMessage(null);
      } catch (error) {
        setMessage(errorMessage(`Noe gikk galt, prøv igjen (${error.toString()}).`));
      }
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick();
    }
  };

  return (
    <>
      <Link
        {...rest}
        onClick={onClick}
        tabIndex={0}
        role="button"
        onKeyDown={handleKeyDown}
      >
        {message || defaultMessage(isLoading)}
      </Link>
    </>
  );
};

export default DeleteAdminLink;
