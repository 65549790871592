import * as React from "react";
import {IWithResponsiveProps, withResponsive} from "@snoam/pinata";

export const ResponsiveContext = React.createContext<{
  mobile: boolean;
  desktop: boolean;
  tablet: boolean;
  portrait: boolean;
  landscape: boolean;
}>({
  mobile: false,
  tablet: false,
  desktop: false,
  portrait: false,
  landscape: false
});

export const ResponsiveContextProvider = withResponsive<IWithResponsiveProps>(({children, mobile, tablet, desktop, portrait, landscape}) => {
  return (
    <ResponsiveContext.Provider value={{
      mobile: Boolean(mobile),
      tablet: Boolean(tablet),
      desktop: Boolean(desktop),
      portrait: Boolean(portrait),
      landscape: Boolean(landscape)
    }}>
      {children}
    </ResponsiveContext.Provider>
  )
});
