import {gql} from "@apollo/client";

export const fragments = {
  subscriptions: gql`
    fragment SubscriptionsPageSubscriptions on AgreementDetails {
      subscriptions {
        id
        agreementNumber
        created
        subscriberId
        subscriberName
        paymentFrequenzy
        paymentTypeCode
        rootAgreementId
        agreementNumber
        agreementStatus
        parentAgreementId
        subscriptionItems {
          id
          customerName
          customerNumber
          customerSpid
          deliveryAddressId
          numberofEditions
          deliveryStreetAddress
          deliveryCity
          deliveryCountry
          deliveryZipcode
          price
          agreementItemEndDate
          agreementItemStartDate
          taxes {
            year
            taxForPeriod
            taxForEntireYear
          }
          product {
            id
            productGroup
            productName
            productCombination
            productVariation
            productVariationDescription
            productVariationImageUrl
            productVariationActive
            companyCode
            discountPercent
            active
            siebelProductId
            monthlyPrice
          }
          status
          agreementId
          assetStatus
          assetNumber
          reference1 {
            id
            allowedValues
            name
            type
            value
          }
          reference2 {
            id
            allowedValues
            name
            type
            value
          }
          stopOrders {
            id
            date
          }
        }
      }
    }
  `,
};
