import * as React from 'react';
import {Helmet} from 'react-helmet';
import ClientContextProvider, {ClientContextConsumer,} from './context/ClientContextProvider';
import {
  BackgroundPosition,
  BackgroundRepeat,
  classNames,
  TextColor,
  Width
} from "@snoam/pinata";
import {isProduction, useSpid, SpidContextProvider} from "@snoam/mono-spid";
import {BrowserRouter, Routes} from "react-router-dom";
import {ClientShortHandName} from '@snoam/mono-scc';
import {ApolloProvider} from '@apollo/client';
import {createApolloClient} from "./utils";
import {createRedirect, createRoute, MinBedriftRoute, MinBedriftRouteParent, MinBedriftRoutes} from "./routes/utils";
import {routes} from "./routes/routes";
import {BackgroundLoader} from "@snoam/mono-background-loader";
import '../node_modules/@snoam/pinata/dist/assets/css/sch-bundle.css';
import {PulseTrackerProvider} from './routes/compositions/Tracker';
import {ResponsiveContextProvider} from "./context/ResponsiveContext/ResponsiveContext";
import {Sprite} from "./__generated__/sprite.symbol";
import MaintenanceContextProvider from "./context/MaintenanceContextProvider";

const debug = require('debug')('minbedrift:client:spidContext');

export interface IAppProps {
  domain: string;
  href: string;
}

const styleClass = {
  root: classNames(
    "App",
    TextColor.TEXT_NEUTRAL_6
  ),
  backgroundImage: classNames(
    Width.W_FULL,
    BackgroundRepeat.BG_NO_REPEAT,
    BackgroundPosition.BG_LEFT_TOP,
  ),
};

const renderRoutes = (routes: MinBedriftRoutes): JSX.Element[] => {
  let routesToRender: JSX.Element[] = [];
  for (let parentName in routes) {
    const routeParent: MinBedriftRouteParent = routes[parentName];
    const {routes: parentRoutes, wrappers: parentWrappers} = routeParent;
    routesToRender = (parentRoutes as MinBedriftRoute[]).reduce((acc, route: MinBedriftRoute) => {
      if (Array.isArray(route.redirectFrom)) {
        acc.push(...createRedirect(route))
      }
      acc.push(createRoute(route, parentWrappers));
      return acc;
    }, routesToRender);
  }
  return routesToRender;
};
const ContainerContent = () => {
  const spidContext = useSpid();
  return (
    <ApolloProvider client={createApolloClient(spidContext)}>
      <Routes>
        {renderRoutes(routes)}
      </Routes>
    </ApolloProvider>
  )
};
const isLocal = window.location.href.match(/.lh|localhost/) !== null;

const App: React.FunctionComponent<IAppProps> = (props: IAppProps) => {

  const {domain, href, ...otherProps} = props;


  return (
    <div className={styleClass.root}>
      <MaintenanceContextProvider>
        <BackgroundLoader className={styleClass.backgroundImage} transition={false}>
          <ClientContextProvider
            clientShortHandName={ClientShortHandName.SCH}
            domain={props.domain}
            href={props.href}
            isProduction={isProduction(window.location.href)}
          >
            <ClientContextConsumer>{() => (
              <React.Fragment>

                <Helmet>
                  <title>Min Bedrift</title>
                </Helmet>

                <SpidContextProvider
                  enableRefreshToken={false}
                  domain={domain}
                  href={href}
                  debug={debug}
                  teaser={'minbedrift-admin'}
                  sessionDomain={isProduction(window.location.href) ? "https://id.schibsted.no" : "https://id-pre.schibsted.no"}
                  {...otherProps}
                  siteSpecificLogout={!isLocal}
                  clientShortHandName={ClientShortHandName.SCH}
                >
                  <PulseTrackerProvider>
                    <BrowserRouter basename={'/'}>
                      <ResponsiveContextProvider>
                        <ContainerContent/>
                      </ResponsiveContextProvider>
                    </BrowserRouter>
                  </PulseTrackerProvider>
                </SpidContextProvider>

              </React.Fragment>
            )}
            </ClientContextConsumer>
          </ClientContextProvider>
        </BackgroundLoader>
      </MaintenanceContextProvider>
      <Sprite/>
    </div>
  );
};

export default App;
