import React, {useContext, useEffect, useState} from "react";
import {createActionState, FilterFactoryContext, FilterValue} from "../Filter";
import {useFilterFactory} from "../../../context/FilterContext/FilterContext";
import {FilterType} from "../utils";


export type BaseFilterProps = Omit<InternalFilterProps, 'createFilterAction' | 'setFilters'>

export interface InternalFilterProps {
  type: FilterType;
  defaultEnabled?: boolean;
  id: string;
  name: string[] | string;
  value?: any;
  createFilterAction?: any;
  setFilters?: any;
  children: ({active, state, toggleFilter, filterValue}: any) => JSX.Element;
}

export const BaseFilter: React.FunctionComponent<InternalFilterProps> = React.memo((props) => {
  const {addResetCallback} = useFilterFactory();
  const {createFilterAction} = useContext(FilterFactoryContext);
  const {id, name, value, type, defaultEnabled, children} = props;
  const [state, setState] = useState(createActionState({id, name, value, type, enabled: !!defaultEnabled}));

  useEffect(() => {
    addResetCallback(() => {
      setState(createActionState({id, name, value, type, enabled: !!defaultEnabled}));
    });
  }, []);

  useEffect(() => {
    createFilterAction(state);
  }, [state]);

  return children({
    active: state.enabled,
    state,
    toggleFilter: () => {
      const payload = {
        ...state,
        enabled: !state.enabled
      };
      setState(payload);
    },
    filterValue: (value: FilterValue) => {
      setState(createActionState({id, name, value, type, enabled: true}))
    },
    filter: (enabled: boolean) => {
      const payload = {
        ...state,
        enabled
      };
      setState(payload);
    }
  });
});
