import React, { useContext } from "react";
import {
  Heading,
  HeadingLevel,
  Margin,
  classNames,
  FontWeight,
  FontFamily,
  TextColor,
  Display,
  FlexDirection,
  AlignItems,
  Padding,
  TextDecoration
} from "@snoam/pinata";
import { footerConfig } from "../../configuration/footer";
import { ResponsiveContext } from "../../context/ResponsiveContext/ResponsiveContext";

const styleClass = {
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontFamily.FONT_BODY,
    TextColor.TEXT_WHITE,
    Margin.MT_2,
    Margin.LG_MT_16,
    Margin.MB_4
  ),
  policyParagraph: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_START,
    Margin.MT_AUTO,
    Padding.PT_10,
    TextDecoration.UNDERLINE
  )
};

interface IFooterSuggestionProps {
  dataPolicyUrl: string;
}

const FooterSuggestion: React.FC<IFooterSuggestionProps> = ({
  dataPolicyUrl
}) => {
  const { mobile, landscape } = useContext(ResponsiveContext);
  return (
    <>
      <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
        Forbedringsforslag?
      </Heading>
      <p>
        Vi ønsker dine innspill for å få en tjeneste som {!mobile && <br/>} oppfyller din
        bedrifts behov.
      </p>
      <p className={Margin.MT_4}>
        <span>Send tips til: </span>
        <a
          href={`mailto: ${footerConfig.improveEmail}`}
          className={TextDecoration.UNDERLINE}
        >
          {footerConfig.improveEmail}
        </a>
      </p>
      {landscape && (
        <p className={styleClass.policyParagraph}>
          <a href={dataPolicyUrl}>Vilkår og personvernserklæring</a>
        </p>
      )}
    </>
  );
};

export default FooterSuggestion;
