import * as React from "react";
import { RefObject } from "react";
import {
  classNames,
  FontWeight,
  Grid,
  GridComposition,
  GridItem,
  Heading,
  HeadingLevel,
  Margin,
  TextAlign,
  JustifyContent,
  Padding,
  MaxWidth
} from "@snoam/pinata";
import AvailableProductBox from "./AvailableProductsBox";
import { GetAllProducts_availableProducts } from "../../__generated__/GetAllProducts";
import { getAvailableProducts, } from "../../components/GroupProducts/GroupProducts";
import { GetAgreementProducts_me_agreementsWithDetails_products } from '../../__generated__/GetAgreementProducts';
import { GetAgreementProductsAndSiebelProducts_availableProducts } from '../../__generated__/GetAgreementProductsAndSiebelProducts';

interface IAvailableProducts {
  availableProductsDivRef: RefObject<HTMLDivElement>;
  agreementNumber: number;
  agreementDiscountPercent: number;
  activeProducts: Array<Array<GetAllProducts_availableProducts | GetAgreementProducts_me_agreementsWithDetails_products>>;
  products: Array<Array<GetAllProducts_availableProducts | GetAgreementProductsAndSiebelProducts_availableProducts>>;
}

const styleClass = {
  productBox: classNames(
    Padding.PX_4,
    Padding.PY_4,
  ),
  container: classNames(
    Padding.PB_10,
    MaxWidth.MAX_W_6XL,
    Margin.M_AUTO,
  ),
};

const AvailableProducts = ({ availableProductsDivRef, agreementNumber, activeProducts, products, agreementDiscountPercent }: IAvailableProducts) => {

  const availableProducts = getAvailableProducts(activeProducts, products);

  return (
    <div className={Margin.MB_8} ref={availableProductsDivRef}>
      <Heading level={HeadingLevel.TWO}
        className={classNames(FontWeight.FONT_LIGHT, Margin.MY_8, TextAlign.TEXT_CENTER)}>
        Tilby dine ansatte flere produkter fra Schibsted.
      </Heading>
      <div className={styleClass.container}>
        <Grid composition={GridComposition.ALPHA} className={classNames(JustifyContent.JUSTIFY_CENTER)}>
          {
            availableProducts.map((groupOfProducts, i) => {
              return (
                groupOfProducts.length > 0 &&
                <GridItem key={`${i}`}
                  className={styleClass.productBox}
                >

                  <AvailableProductBox
                    groupProducts={groupOfProducts}
                    agreementNumber={agreementNumber}
                    modalKey={i * 100}
                    agreementDiscountPercent={agreementDiscountPercent}
                  />
                </GridItem>
              )
            })}
        </Grid>
      </div>
    </div>
  )
};

export default AvailableProducts;
