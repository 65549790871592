import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  classNames,
  Cursor,
  Display,
  Icon,
  IconColor,
  IInputProps,
  Input,
  Margin,
  Position,
  FontSize,
  Whitespace,
  Width,
  FlexDirection,
  IconName
} from '@snoam/pinata';

//TODO: Move to Monorepo

interface IInputWithValidatorProps extends IInputProps {
  validator: (value?: string) => boolean;
  required: boolean;
  formSubmitted?: boolean;
  clearInput?: () => void;
  ariaLabel?: string;
  forceError?: boolean;
  disabled?: boolean;
}

export function validate(email?: string, customRegexp?: RegExp): boolean {
  if (!email) {
    return false;
  }

  // eslint-disable-next-line no-useless-escape
  const regexp = customRegexp ? customRegexp : /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
}

export function emailValidator(acceptableDomains?: string[], regex?: RegExp): ((email?: string) => boolean) {
  function validator(email?: string): boolean {
    const emailValid = validate(email, regex);
    if (!emailValid) {
      return false;
    }

    let acceptableDomainsLowercase: string[] = [];
    let emailWithLowercaseDomain = '';

    if(acceptableDomains) {
      acceptableDomains.forEach((domain) => {
        acceptableDomainsLowercase.push(domain.toLowerCase());
      });
    }

    if (email) {
      if (email.includes('@')) {
        const emailSplitted = email.split('@');
        const lowercaseDomain = emailSplitted[1].toLowerCase();
        emailWithLowercaseDomain = `${emailSplitted[0]}@${lowercaseDomain}`;
      }
    }

    return acceptableDomains ? !!acceptableDomainsLowercase.find(domain => (emailWithLowercaseDomain || '').includes(domain)) : true;
  }

  return validator;
}

const styleClass = {
  container: classNames(
    Margin.MT_6,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Width.MD_W_1_3,
  ),
  button: classNames(
    Margin.MY_0,
    Margin.MB_2,
    Whitespace.WHITESPACE_NO_WRAP,
    Width.W_FULL,
  ),
  inputContainer: classNames(
    Position.RELATIVE,
    Cursor.CURSOR_POINTER,
    Width.W_FULL,
    Margin.MR_2
  ),
  input: classNames(
    FontSize.TEXT_BASE,
  ),
  inputIcon: classNames(
    Position.ABSOLUTE,
    Position.RIGHT_0,
    Position.BOTTOM_0,
  ),
};

const iconStyle = {
  bottom: '2.3rem',
  right: '1rem'
};
const noop = () => void (0);

const InputWithValidator: React.FunctionComponent<IInputWithValidatorProps> = (props) => {
  const {
    validator,
    value,
    required,
    formSubmitted,
    validMsg,
    errorMsg,
    label,
    placeholder,
    onChange,
    onBlur,
    className,
    ariaLabel,
    clearInput = noop,
    forceError,
    type = "text",
    disabled = false,
  } = props;

  const [errorMessage, setErrorMessage] = useState('');
  const [validMessage, setValidMessage] = useState<string | boolean>();

  function validate(value?: string): boolean {
    try {
      const result = validator(value);
      if (!forceError && (result || (!required && value === ''))) {
        setErrorMessage('');
        setValidMessage(validMsg || true);
      } else {
        setErrorMessage(errorMsg || ' ');
        setValidMessage(false);
      }
      return result;
    } catch (e) {
      setErrorMessage(errorMsg && (errorMsg + e) || ('Validering feilet: ' + e));
      setValidMessage(false);
      return false;
    }

  }

  useEffect(() => {
    if (typeof forceError === 'boolean') {
      setErrorMessage(forceError && errorMsg || '');
    }
  }, [forceError]);
  useEffect(() => {
    if (formSubmitted) {
      validate(value as string);
    }
  }, [formSubmitted]);

  async function proxyBlur(e: React.FocusEvent<HTMLInputElement>) {
    try {
      await validate(e.target.value);
      onBlur && onBlur(e);
    } catch (ignored) {
      // ignore...
    }
  }

  return (
    <div className={styleClass.inputContainer}>
      <Input
        value={value}
        className={classNames(styleClass.input, className)}
        label={label}
        placeholder={placeholder}
        errorMsg={errorMessage}
        validMsg={validMessage}
        onChange={onChange}
        onBlur={proxyBlur}
        aria-label={ariaLabel}
        type={type}
        disabled={disabled}
      />
      {!disabled &&
        <Icon
          name={IconName._EDIT}
          className={styleClass.inputIcon}
          style={iconStyle}
          color={IconColor.NEUTRAL_4}
          onClick={value ? clearInput : noop}
        />
      }

    </div>
  );
};

export default InputWithValidator;
