import * as React from 'react';
import {useLocation} from "react-router-dom";

interface IPathBoundary {
  excludeIfUrl: string;
  elementToRender?: JSX.Element;
  children?: any;
}

const PathBoundary: React.FunctionComponent<IPathBoundary> = (props) => {
  const location = useLocation();
  if (location.pathname.includes(props.excludeIfUrl)) {
    return props.elementToRender || null;
  }
  return props.children;
};

export default PathBoundary;
