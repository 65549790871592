import * as React from "react";
import {NotLoggedIn} from "../pages/NotLoggedIn";
import {
  MinBedriftRoute,
  MinBedriftRouteComponentProps,
  MinBedriftRoutes,
  MinBedriftRouteWithIcon,
  MinBedriftSuperAdminRouteWithIcon
} from './utils';
import {
  SuperAdminOnlyBoundary,
  SuperAdminOrAdminOrAccountantBoundary,
  SuperAdminOrSuperObserverBoundary
} from "./compositions/boundaries/RoleBoundary";
import {
  AdminNavigation,
  GenericNavigation,
  LoginNavigation,
  OrderNavigation,
  FilterComposition,
  LoginPageComposition,
  OrderPageWithBackgroundComposition,
  PageComposition,
  SubscriptionComposition,
  OrderPageFlowWithBackgroundComposition
} from "./compositions";
import {OrderAuthBoundary} from "./compositions/boundaries/AuthBoundary";
import FilterProvider from "../context/FilterContext/FilterContext";
/**
 * Error routes
 */
import ErrorPage from '../pages/ErrorPage/ErrorPage';
/**
 * Super Admin routes
 */
import Agreements from '../pages/Agreements/Agreements';
import EditProducts from '../pages/Superadmin/EditProducts';
import Administrators from '../pages/Administration/Administration';
/**
 * Admin routes
 */
import Subscriptions from '../pages/Subscriptions/Subscriptions';
import Invite from '../pages/Invite/Invite';
import Products from '../pages/Products/ProductsPage';
import Invoices from '../pages/Invoice/Invoice';
import Agreement from '../pages/AgreementSettings/AgreementSettings';
/**
 * Orderflow
 */
import OrderInitial from '../pages/Order/Initialstep/OrderInitial';
import SelectProductsPage from '../pages/Order/SelectProducts/SelectProductsPage';
import UserInformationPage from '../pages/Order/UserInformationPage';
import InvitationCodePage from '../pages/Order/InvitationCodePage';
import ReceiptPage from '../pages/Order/RecieptPage';
import PolicyPage from '../pages/DataPolicyAndSubscriptionTerms/DataPolicyAndSubscriptionTerms';
import {IconName} from "@snoam/pinata";
import ActiveAgreementBoundary from "./compositions/boundaries/ActiveAgreementBoundary";
import OpenidConnectToExternalLoginPage from "../pages/Order/OpenidConnectToExternalLoginPage";
import OpenidConnectBackFromExternalLoginPage from "../pages/Order/OpenidConnectBackFromExternalLoginPage";
import Dashboard from "../pages/Dashboard/Dashboard";

export const errorPageRoute: MinBedriftRouteWithIcon = {
  path: '*',
  label: 'PageNotFound',
  icon: IconName.PRODUCTS,
  trackingLabel: 'Feil -',
  component: (props) => (
    <ErrorPage
      heading={'Noe gikk galt'}
      message={'Vi fant ikke siden du ønsket å se.'}
      {...props}
    />
  ),
  wrappers: [
    ...PageComposition
  ],
};

export const superAdminAgreementsRoute: MinBedriftSuperAdminRouteWithIcon = {
  appliesToAllRoles: true,
  path: '/admin/avtaler',
  label: 'Avtaler',
  icon: IconName.BEDRIFT_AVTALE,
  component: (props) => (
    <Agreements {...props}/>
  ),
  wrappers: [
    SuperAdminOrAdminOrAccountantBoundary,
    ...FilterComposition
  ]
};

export const superAdminDashboardRoute: MinBedriftSuperAdminRouteWithIcon = {
  appliesToAllRoles: true,
  path: '/admin/dashboard',
  redirectFrom: ['/admin'],
  label: 'Dashboard',
  icon: IconName.BEDRIFT_ICON_INVITE,
  component: (props) => (
    <Dashboard {...props} />
  ),
  wrappers: [
    SuperAdminOrAdminOrAccountantBoundary,
    ...FilterComposition
  ]
}

export const superAdminEditProductsRoute: MinBedriftSuperAdminRouteWithIcon = {
  appliesToAllRoles: true,
  path: '/admin/produkter',
  label: 'Rediger produkter',
  icon: IconName.BEDRIFT_EDIT_PRODUCTS,
  component: (props) => (
    <EditProducts {...props}/>
  ),
  wrappers: [
    SuperAdminOrSuperObserverBoundary,
    ...PageComposition
  ]
};

export const superAdminAdminPageRoute: MinBedriftSuperAdminRouteWithIcon = {
  appliesToAllRoles: false,
  path: '/admin/schibsted-administratorer',
  label: 'Schibsted admin',
  icon: IconName.BEDRIFT_ADMINS,
  component: (props) => (
    <Administrators {...props}/>
  ),
  wrappers: [
    SuperAdminOnlyBoundary,
    ...FilterComposition
  ]
};

export const subscribersPageRoute: MinBedriftRouteWithIcon = {
  path: '/admin/avtaler/:agreementNumber/abonnenter',
  label: 'Abonnenter',
  redirectFrom: [
    '/admin/avtaler/:agreementNumber',
    '/admin/avtaler/:agreementNumber/abonnement',
  ],
  icon: IconName.BEDRIFT_ICON_GROUP,
  component: (props) => (
    <Subscriptions {...props}/>
  ),
  wrappers: [
    SuperAdminOrAdminOrAccountantBoundary,
    ...SubscriptionComposition,
  ]
};

export const inviteEmployeesPageRoute: MinBedriftRouteWithIcon = {
  path: '/admin/avtaler/:agreementNumber/inviter',
  label: 'Inviter ansatte',
  icon: IconName.BEDRIFT_ICON_INVITE,
  component: (props) => (
    <Invite {...props}/>
  ),
  wrappers: [
    SuperAdminOrAdminOrAccountantBoundary,
    ...PageComposition,
    FilterProvider
  ],
};

export const productsPageRoute: MinBedriftRouteWithIcon = {
  path: '/admin/avtaler/:agreementNumber/produkter',
  label: 'Produkter',
  icon: IconName.BEDRIFT_EDIT_DIGITAL,
  component: (props) => (
    <Products {...props}/>
  ),
  wrappers: [
    SuperAdminOrAdminOrAccountantBoundary,
    ...PageComposition
  ],
};

export const invoicePageRoute: MinBedriftRouteWithIcon = {
  path: '/admin/avtaler/:agreementNumber/faktura',
  label: 'Faktura',
  icon: IconName.BEDRIFT_ICON_INVOICE,
  component: (props) => (
    <Invoices {...props}/>
  ),
  wrappers: [
    SuperAdminOrAdminOrAccountantBoundary,
    ...PageComposition
  ],
};

export const agreementPageRoute: MinBedriftRouteWithIcon = {
  path: '/admin/avtaler/:agreementNumber/avtale',
  label: 'Vår avtale',
  icon: IconName.BEDRIFT_SETTINGS_OUTLINE,
  component: (props) => (
    <Agreement {...props}/>
  ),
  wrappers: [
    SuperAdminOrAdminOrAccountantBoundary,
    ...PageComposition
  ],
};

export const orderInitialPageRoute: MinBedriftRoute = {
  path: '/bestill/:agreementNumber?',
  label: 'Bestill',
  component: (props) => (
    <OrderInitial {...props}/>
  ),
  wrappers: [
    ActiveAgreementBoundary,
    ...OrderPageFlowWithBackgroundComposition
  ],
};

export const orderProductSelectPageRoute: MinBedriftRoute = {
  path: '/bestill/:agreementNumber/flyt/produkter',
  label: 'Velg Produkter',
  trackingLabel: 'Bestill - ',
  redirectFrom: [
    '/bestill/:agreementNumber/flyt'
  ],
  component: (props) => (
    <SelectProductsPage {...props}/>
  ),
  wrappers: [
    ActiveAgreementBoundary,
    OrderAuthBoundary,
    ...OrderPageFlowWithBackgroundComposition
  ],
};

export const orderUserInformationPageRoute: MinBedriftRoute = {
  path: '/bestill/:agreementNumber/flyt/brukerinformasjon',
  label: 'Brukerinformasjon',
  trackingLabel: 'Bestill - ',
  component: (props) => (
    <UserInformationPage {...props}/>
  ),
  wrappers: [
    ActiveAgreementBoundary,
    OrderAuthBoundary,
    ...OrderPageWithBackgroundComposition
  ],
};

export const orderAcceptCodePageRoute: MinBedriftRoute = {
  path: '/bestill/:agreementNumber/flyt/invitasjonskode',
  label: 'Invitasjonskode',
  trackingLabel: 'Bestill - ',
  component: (props) => (
    <InvitationCodePage {...props} />
  ),
  wrappers: [
    ...OrderPageWithBackgroundComposition
  ],
};

export const orderGoToExternalLoginPageRoute: MinBedriftRoute = {
  path: '/bestill/:agreementNumber/flyt/til-ekstern-login',
  label: 'Logg inn hos din arbeidsgiver',
  trackingLabel: 'Bestill - ',
  component: (props) => (
    <OpenidConnectToExternalLoginPage {...props} />
  ),
  wrappers: [
    ...OrderPageWithBackgroundComposition
  ],
};

export const orderBackFromExternalLoginPageRoute: MinBedriftRoute = {
  path: '/bestill/:agreementNumber/flyt/fra-ekstern-login',
  label: 'Fullfører bestilling',
  trackingLabel: 'Bestill - ',
  component: (props) => (
    <OpenidConnectBackFromExternalLoginPage {...props} />
  ),
  wrappers: [
    OrderAuthBoundary,
    ...OrderPageWithBackgroundComposition
  ],
};

export const orderReceiptPage: MinBedriftRoute = {
  path: '/bestill/:agreementNumber/flyt/kvittering',
  label: 'Kvittering',
  trackingLabel: 'Bestill - ',
  component: (props) => (
    <ReceiptPage {...props} />
  ),
  wrappers: [
    ...OrderPageWithBackgroundComposition
  ],
};

export const dataPolicyAndSubscriptionTermsPage: MinBedriftRoute = {
  label: 'Vilkår og personvernserklæring',
  path: '/personvernserklæring',
  component: (props) => (
    <PolicyPage {...props} />
  ),
  wrappers: [
    ...PageComposition,
  ],
};
export const agreementDataPolicyAndSubscriptionTermsPage: MinBedriftRoute = {
  ...dataPolicyAndSubscriptionTermsPage,
  path: '/admin/avtaler/:agreementNumber/personvernserklæring',
};

export function isMinBedriftRouteWithIcon(r: MinBedriftRoute): r is MinBedriftRouteWithIcon {
  return !!(r as any).icon;
}

export const routes: MinBedriftRoutes = {
  super: {
    wrappers: [
      AdminNavigation
    ],
    routes: [
      superAdminDashboardRoute,
      superAdminAgreementsRoute,
      superAdminAdminPageRoute,
      superAdminEditProductsRoute
    ]
  },
  superObserver: {
    wrappers: [
      AdminNavigation
    ],
    routes: [
      superAdminDashboardRoute,
      superAdminAgreementsRoute,
      superAdminEditProductsRoute
    ]
  },
  admin: {
    wrappers: [
      AdminNavigation
    ],
    routes: [
      subscribersPageRoute,
      inviteEmployeesPageRoute,
      productsPageRoute,
      invoicePageRoute,
      agreementPageRoute,
      agreementDataPolicyAndSubscriptionTermsPage,
    ]
  },
  order: {
    wrappers: [
      OrderNavigation
    ],
    routes: [
      orderInitialPageRoute,
      orderProductSelectPageRoute,
      orderUserInformationPageRoute,
      orderAcceptCodePageRoute,
      orderGoToExternalLoginPageRoute,
      orderBackFromExternalLoginPageRoute,
      orderReceiptPage,
    ]
  },
  login: {
    wrappers: [
      LoginNavigation
    ],
    routes: [
      {
        path: '/login',
        redirectFrom: [
          '/'
        ],
        label: 'Login',
        icon: IconName.PRODUCTS,
        component: (props: MinBedriftRouteComponentProps) => (
          <NotLoggedIn {...props}/>
        ),
        wrappers: [
          ...LoginPageComposition
        ],
      } as any,
    ]
  },
  generic: {
    wrappers: [
      GenericNavigation
    ],
    routes: [
      dataPolicyAndSubscriptionTermsPage,
      errorPageRoute,
    ]
  },
};
