import * as React from 'react';

import { useQuery } from '@apollo/client';
import moment from 'moment';

import { QueryInvoices, QueryInvoices_me_invoices, QueryInvoicesVariables } from '../../__generated__/QueryInvoices';
import { QUERY_INVOICES } from '../../queries';
import {
  BackgroundColor,
  Card,
  classNames,
  FontWeight,
  Grid,
  Height,
  Margin,
  Padding,
  Fill,
  TextAlign, TextColor,
  FontSize,
  Width, ButtonSkin,
} from '@snoam/pinata';
import { LoadingFragment } from '../LoadingPage';
import NoInvoices from './NoInvoices';
import { IWithSpidContext, withSpidContext } from '@snoam/mono-spid';
import InvoiceButton from './InvoiceButton';
import { useState } from 'react';
import { ReactElement } from 'react';
import SvgImage from '../../components/SvgImage/SvgImage';

interface InvoiceBoxProps extends IWithSpidContext {
  className?: string;
  agreementNumber: number;
  accountNumber: number;
}

const InvoiceIcon = () => (
  <SvgImage className={classNames(Fill.FILL_WHITE, Height.H_6, Width.W_6, Margin.ML_3)}
            href={`${process.env.PUBLIC_URL}/svg/invoice.svg`}/>
);

const PdfIcon: React.FunctionComponent<{}> = () => (
  <SvgImage className={classNames(Fill.FILL_BLACK, Height.H_6, Width.W_6, Margin.ML_3)}
            href={`${process.env.PUBLIC_URL}/svg/pdf.svg`}/>
);

const InvoiceBox: React.FunctionComponent<InvoiceBoxProps> = (props) => {
  const {agreementNumber, accountNumber, className, spidContext} = props;
  const [downloadError, setDownloadError] = useState<ReactElement | string | null>(null);
  const {data, error, loading} = useQuery<QueryInvoices, QueryInvoicesVariables>(QUERY_INVOICES, {
    variables:{ agreementNumber, accountNumber },
  });

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error) {
    throw error;
  }

  const invoices = data!.me.invoices;

  if (!invoices.length) {
    return <NoInvoices/>;
  }

  const lastInvoice = [...invoices].sort((a, b) => a.invoiceDate < b.invoiceDate ? 1 : -1)[0];
  const properties: Array<[keyof QueryInvoices_me_invoices, string, ((v: boolean | number | string) => string)]> = [
    ['invoiceDate', 'Siste faktura', v => moment(v as string).format(`L`)],
    ['invoiceDueDate', 'Forfall', v => v ? moment(v as string).format(`L`) : ''],
    ['paymentMethod', 'Betalingsmåte', v => `${v}`],
    ['invoiceAmount', 'Beløp', v => `${v} NOK`],
    ['invoicePaid', 'Betalt', v => v ? `Ja` : `Nei`],
    ['invoicePaidDate', 'Betalt dato', v => v ? moment(v as string).format(`L`) : ''],
  ];
  const filename = `Faktura-${lastInvoice.invoiceNumber}_avtale-${agreementNumber}_konto-${accountNumber}.pdf`;
  return (
    <Card className={classNames(BackgroundColor.BG_WHITE, Width.LG_W_1_3, Width.SM_W_1_2, className)}>

      <Grid className={Margin.MT_3}>

        {properties.map((p, i, a) => {
          const [name, title, transform] = p;
          const val = lastInvoice[name];
          const transformed = (typeof val === 'boolean' || typeof val === 'number' || typeof val === 'string') ? transform(val) : val;
          return transformed ? (
            <p className={classNames(TextAlign.TEXT_LEFT, i === a.length - 1 ? Margin.MB_3 : '')} key={i}>
              <span className={classNames(FontWeight.FONT_SEMIBOLD, FontSize.TEXT_SM, Padding.PR_3)}>{title}:</span>
              <span className={FontWeight.FONT_LIGHT}>{transformed}</span>
            </p>
          ) : null;
        })}

        <InvoiceButton
          accountNumber={accountNumber}
          agreementNumber={agreementNumber}
          icon={<InvoiceIcon/>}
          invoiceNumber={lastInvoice.invoiceNumber}
          setError={setDownloadError}
          skin={ButtonSkin.PRIMARY}
          spidContext={spidContext}
          text={`Se faktura`}
        />

        <InvoiceButton
          accountNumber={accountNumber}
          agreementNumber={agreementNumber}
          filename={filename}
          icon={<PdfIcon/>}
          invoiceNumber={lastInvoice.invoiceNumber}
          setError={setDownloadError}
          skin={ButtonSkin.SECONDARY}
          spidContext={spidContext}
          text={'Last ned PDF'}
        />
        {downloadError &&
          <div className={classNames(TextColor.TEXT_NEGATIVE, TextAlign.TEXT_CENTER, FontSize.TEXT_SM)}>
            {downloadError}
          </div>
        }

      </Grid>
    </Card>
  );
};

export default withSpidContext<InvoiceBoxProps>(InvoiceBox);
