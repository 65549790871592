import React, {useEffect, useState} from "react";
import {
  AlignItems,
  AlignSelf,
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSize,
  Checkbox,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  Heading,
  HeadingLevel,
  Height,
  JustifyContent,
  Margin,
  MaxHeight,
  Overflow,
  Padding,
  TextColor,
  Width
} from "@snoam/pinata";
import {ApolloError, MutationFunction, useMutation, useQuery} from "@apollo/client";
import {
  GetAllProducts,
  GetAllProducts_availableProducts,
  GetAllProductsVariables
} from "../../__generated__/GetAllProducts";
import {GET_ALL_MESSAGES, GET_ALL_PRODUCTS} from "../../queries";
import {LoadingFragment} from "../LoadingPage";
import {groupProductsByProductName} from "../../components/GroupProducts/GroupProducts";
import {GetAgreementProducts_me_agreementsWithDetails_products} from "../../__generated__/GetAgreementProducts";
import {AddMessage, AddMessageVariables} from "../../__generated__/AddMessage";
import {ADD_MESSAGE, checkNoErrors} from "../../mutations";

const debug = require('debug')('minbedrift:client:Administrator:AddMessage');

const styleClass = {
  root: classNames(
    BackgroundColor.BG_WHITE,
    Padding.P_4,
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_3,
  ),
};

const SendMessage: React.FC = () => {
  const [products, setProducts] = useState<GetAllProducts_availableProducts[] | GetAgreementProducts_me_agreementsWithDetails_products[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [saving, setSaving] = useState<boolean>(false);

  const {data, loading} = useQuery<GetAllProducts, GetAllProductsVariables>(GET_ALL_PRODUCTS, {
    variables: {
      showOtherProducts: true,
    },
    fetchPolicy: "cache-first"
  });
  const addMessageMutation: MutationFunction<AddMessage, AddMessageVariables> = useMutation<AddMessage, AddMessageVariables>(ADD_MESSAGE)[0];

  useEffect(() => {
    if(data && data.availableProducts && data.availableProducts.length > 0) {
      const groupProducts = groupProductsByProductName(data.availableProducts);
      setProducts(groupProducts.grouped);
    }
  }, [data]);

  if (loading) {
    return <LoadingFragment/>;
  }

  const onSelectChange = (value: string) => {
    setErrorMessage("");
    if(selectedProducts.includes(value)) {
      const removed = selectedProducts.filter(p => p !== value)
      setSelectedProducts(removed);
    } else {
      setSelectedProducts([...selectedProducts, value]);
    }
  }

  const onTextChange = (e: any) => {
    const len = e.target.value.replace(/\[(.*?)\]\((.*?)\)/gim, '$1');
    if(len.length > 255) {
      setError(true);
      setErrorMessage("Meldingen er for lang");
    } else {
      setError(false);
      setErrorMessage("");
      setMessage(e.target.value);
    }
  }

  const onSubmit = () => {

    if(selectedProducts.length === 0) {
      setError(true);
      setErrorMessage("Du må velge en eller fler produkter for å lage en melding.");
      return;
    }
    setErrorMessage("")
    setSaving(true);

    addMessageMutation({
      variables: {
        productGroups: selectedProducts,
        message: message
      },
      refetchQueries: [{
        query: GET_ALL_MESSAGES,
        variables: {}
      }],
    }).then((err) => {
      setTimeout(() => {
        setSaving(false);
        setMessage("");
        setSelectedProducts([]);
      }, 1000)

      return err ? checkNoErrors(err) : Promise.reject(`Expected return value, got: ${err}`);
    }).catch((err) => {
      setSaving(false);
      if(err instanceof ApolloError) {
        debug(err.message.replace('GraphQL error: Unexpected error value: ', '').replace(/^"(.*)"$/, '$1'));
      } else {
        debug(err);
      }
    });
  };

  const productNames = products.map(p => {
    const productName = Object.keys(p)[0];
    const productGroup = Object.values(p)[0][0].productGroup;
    return {
      productName,
      productGroup
    }
  });

  return(
    <div className={styleClass.root}>
      <div className={classNames(Display.FLEX, FlexDirection.FLEX_ROW)}>
        <Heading level={HeadingLevel.TWO}>Send melding til bedrifter</Heading>
        <span className={classNames(TextColor.TEXT_NEUTRAL_5, Margin.ML_2, FontSize.TEXT_SM, AlignSelf.SELF_END)}>Maks 255 tegn. Lenker legges inn slik: [Klikk her](https://vg.no)</span>
      </div>
      <div className={classNames(Display.FLEX, FlexDirection.FLEX_ROW, Padding.PT_4)}>
        <div className={classNames(Width.W_2_3)}>
          <textarea
            placeholder={"Melding"}
            value={message}
            onChange={onTextChange}
            className={classNames(Width.W_FULL, BorderWidth.BORDER_1, BorderStyle.BORDER_SOLID, BorderColor.BORDER_NEUTRAL_3, Padding.P_2, Height.H_40)}
          />
          <span className={FontSize.TEXT_XS}>{message.replace(/\[(.*?)\]\((.*?)\)/gim, '$1').length}/255</span>
        </div>
        <div className={classNames(Width.W_1_3, Padding.PL_4)}>
          <div className={classNames(MaxHeight.MAX_H_40, Overflow.OVERFLOW_Y_SCROLL, BorderWidth.BORDER_1, BorderStyle.BORDER_SOLID, BorderColor.BORDER_NEUTRAL_3)}>
            {productNames.map(p => {
              return (
                <Checkbox
                  key={p.productGroup}
                  text={p.productName}
                  checked={selectedProducts.includes(p.productGroup)}
                  onClick={() => onSelectChange(p.productGroup)}
                  className={classNames(Height.H_8)}
                />
              )
            })}
          </div>
        </div>
      </div>
      <div className={classNames(Display.FLEX, JustifyContent.JUSTIFY_END, AlignItems.ITEMS_CENTER)}>
        {error && errorMessage && <span className={classNames(FontSize.TEXT_SM, TextColor.TEXT_NEGATIVE, Padding.PR_4)}>{errorMessage}</span>}
        <Button text={"Send"} onClick={onSubmit} size={ButtonSize.SMALL} loading={saving} disabled={error} />
      </div>
    </div>
  )
}

export default SendMessage;
