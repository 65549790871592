import React, {useMemo, useState} from "react";
import {GetAgreements_me_agreements_edges} from "../../__generated__/GetAgreements";
import {
  AlignItems,
  BackgroundColor, BorderColor, BorderStyle, BorderWidth,
  classNames,
  Display,
  FlexDirection, FontSize, FontWeight, Heading, HeadingLevel,
  Input,
  JustifyContent, Margin, MinHeight,
  Padding, TextColor, Width
} from "@snoam/pinata";
import moment from "moment";

const debug = require('debug')('minbedrift:client:root');
const styleClass = {
  root: classNames(
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_3,
    BackgroundColor.BG_WHITE,
    Padding.P_4,
    MinHeight.MIN_H_MD,
    Display.FLEX,
    JustifyContent.JUSTIFY_BETWEEN,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER
  ),
  score: classNames(
    FontSize.TEXT_HERO,
    TextColor.TEXT_PRIMARY_1,
    FontWeight.FONT_LIGHT,
    'average-activation-degree--text'
  ),
  disclaimer: classNames(
    FontSize.TEXT_SM,
    TextColor.TEXT_NEUTRAL_5
  )
}

export interface IAverageActivationDegreeWidget {
  agreements: GetAgreements_me_agreements_edges[],
  defaultPotential?: number,
}

const AverageActivationDegreeWidget: React.FunctionComponent<IAverageActivationDegreeWidget> = (props) => {
  const {agreements, defaultPotential = 1} = props;
  const [potential, setPotential] = useState<number>(defaultPotential);

  const averageActivationScore: number = useMemo(() => {
    if(agreements.length === 0) {
      return 0
    }
    const fourWeeksAgo = moment().subtract(4, 'weeks');
    const agreementsWithPotential = agreements
      .filter(a => {
        const created = moment(a.created, 'MM/DD/YYYY');
        if (created.isValid() && created.isSameOrBefore(fourWeeksAgo, 'day')) {
          return true;
        } else {
          debug('Excluding %s (%s) - created(%s) < fourWeeksAgo(%s))', a.accountName, a.agreementNumber, created.format(), fourWeeksAgo.format());
          return false;
        }
      })
      .filter(a => a.settings.activationPotential! >= potential);

    const totalActivationDegree = agreementsWithPotential
      .map(a => a.activationDegree)
      .reduce((prev, next) => prev! + next!, 0)

    return Math.round((totalActivationDegree || 0) / agreementsWithPotential.length)

  }, [agreements, potential]);

  return (
    <div className={styleClass.root}>
      <div>
        <Heading level={HeadingLevel.TWO}>Gjennomsnittlig aktiveringsscore*</Heading>
        <span className={classNames()}>
          Vis avtaler med potensial på minimum
          <Input
            aria-label={"minste potensiale"}
            className={classNames(Width.W_14, Display.INLINE_BLOCK, Margin.MR_1, Padding.PB_0)}
            value={potential}
            onChange={(e) => setPotential(Number(e.target.value))}
          />
          abonnement
        </span>
      </div>
      <span className={styleClass.score}>
        {averageActivationScore}%
      </span>
      <p className={styleClass.disclaimer}>
        *Gjennomsnittlig aktiveringsscore beregnes basert på summen av prosentvis aktiveringsscore for alle
        avtaler eldre enn 4 uker som har registrert potensial delt på summen av antall avtaler.
      </p>
    </div>
  )
};

export default AverageActivationDegreeWidget;
