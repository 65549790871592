import React, { ReactNode } from "react";
import Filter from "../../components/Filter/Filter";
import { BorderColor, BorderWidth, Checkbox, classNames, Display, FlexDirection, Margin, Width } from "@snoam/pinata";
import { Between, Gt, Lt } from "../../components/Filter/types";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4,
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

interface Props {}
const NumberOfSubscriptionsFilter: React.FC<Props> = () => {

  const child = (text: string): ({active, state, toggleFilter, filterValue}: any) => JSX.Element => {
    return ({active, toggleFilter}) => (
      <div className={styleClass.inner}>
        <Checkbox
          checked={!!active}
          onClick={toggleFilter}
          text={text}
        />
      </div>
    );
  };

  return (
    <Filter
      heading={'Aktive abonnement'}
      id={'agreementSubscriberCount'}
      wrapper={customWrapper}
    >
      <Lt id={'agreementSubscriberCount_0_to_9'} name={'agreementSubscriberCount'} value={10}>
        {child('Mindre enn 10')}
      </Lt>
      <Between id={'agreementSubscriberCount_10_to_49'}
               name={'agreementSubscriberCount'}
               value={{lowerInclusive: 10, upperExclusive: 50}}>
        {child('10-49')}
      </Between>
      <Between id={'agreementSubscriberCount_50_to_99'}
               name={'agreementSubscriberCount'}
               value={{lowerInclusive: 50, upperExclusive: 100}}>
        {child('50-99')}
      </Between>
      <Between id={'agreementSubscriberCount_100_to_499'}
               name={'agreementSubscriberCount'}
               value={{lowerInclusive: 100, upperExclusive: 500}}>
        {child('100-499')}
      </Between>
      <Between id={'agreementSubscriberCount_500_to_1000'}
               name={'agreementSubscriberCount'}
               value={{lowerInclusive: 500, upperExclusive: 1001}}>
        {child('500-1000')}
      </Between>
      <Gt id={'agreementSubscriberCount_1000_to_infinity'} name={'agreementSubscriberCount'} value={1000}>
        {child('Over 1000')}
      </Gt>
    </Filter>
  );
};

export default NumberOfSubscriptionsFilter;
