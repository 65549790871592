import React, {ReactNode} from "react";
import {
  BorderColor,
  BorderWidth,
  classNames,
  Display,
  FlexDirection,
  Margin,
  RadioButton,
  RadioButtonGroup,
  Width
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Expr} from "../../components/Filter/types/Expr";
import {None} from "../../components/Filter/types/None";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4,
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

export const ProductType = {
  Digital: /^(M0|MPLUS)/,
  Papir: /^(?!(M0|MPLUS))/,
};

export const ProductTypeFilter = () => {

  const productTypes = Object.keys(ProductType);

  return (
    <Filter
      heading={'Type produkt'}
      id={'product_type'}
      wrapper={customWrapper}
      uniqueBy={'customerNumber'}
    >
      <RadioButtonGroup name={'product_type'} value={'Alle'}>

        <None
          id={`product_type_filter`}
          key={`product_type_filter_all`}
          name={'subscriptions.product.productCombination'}
          defaultEnabled={true}
        >
          {
            ({active, filter}) => {
              return (
                <div className={styleClass.inner}>
                  <RadioButton
                    checked={active}
                    value={'Alle'}
                    onClick={() => filter(true)}
                    text={'Alle'}
                  />
                </div>
              )
            }
          }
        </None>

        {
          productTypes.map((productType, i) => {
            return (
              <Expr
                id={`product_type_filter`}
                key={`product_type_filter_${productType}_${i}`}
                name={'subscriptions.product.productCombination'}
                value={ProductType[productType]}
              >
                {
                  ({active, filter}) => {
                    return (
                      <div className={styleClass.inner}>
                        <RadioButton
                          checked={active}
                          value={productType}
                          onClick={() => filter(true)}
                          text={productType}
                        />
                      </div>
                    )
                  }
                }
              </Expr>
            )
          })
        }
      </RadioButtonGroup>
    </Filter>
  )
};

export default ProductTypeFilter;
