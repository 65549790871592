import React from "react";
import {
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Padding,
  TextAlign,
  FontSize,
  Width,
  FlexDirection,
  AlignItems
} from "@snoam/pinata";
import SvgImage from "../../components/SvgImage/SvgImage";
import { ApolloError } from '@apollo/client';

const styleClass = {
  root: classNames(
    TextAlign.TEXT_CENTER,
    Padding.PB_10,
    FlexDirection.FLEX_COL,
    Display.FLEX,
    AlignItems.ITEMS_CENTER
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_2XL
  ),
};

export const EmptyAgreements = ({ error, message }: { error?: ApolloError, message?: string }) => (
  <div className={styleClass.root}>

    <SvgImage href={`${process.env.PUBLIC_URL}/svg/zero_subscribers.svg`} title={'Ingen aktive avtaler'} className={Width.W_FULL}/>

    <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
      Velkommen!
    </Heading>

    <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
      {error && `${error.message}` || message || 'Her er foreløpig ingen avtaler.'}
    </Heading>

  </div>
);
