import * as React from "react";
import {useContext, useEffect, useState} from "react";
import Bottleneck from "bottleneck";
import {
  AlignItems,
  BackgroundColor,
  Button,
  ButtonSkin,
  classNames,
  Display,
  Fill,
  FlexDirection,
  FlexShrink,
  FontFamily,
  FontSize,
  FontWeight,
  Grid,
  GridItem,
  GridSpan,
  Heading,
  HeadingLevel,
  Height,
  Icon,
  IconName,
  JustifyContent,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  Width,
} from "@snoam/pinata";
import EmployeesTable, {TableControls} from "../../components/EmployeesAndSubscriptionsTable/EmployeesTable";
import {useFilterFactory} from "../../context/FilterContext/FilterContext";
import Sort from "../../components/Filter/Sort";
import {Employee, Employees, IEmployee, ISubscription} from "../../models/EmployeeModel";

import NumSubscriptionsFilter from "./NumSubscriptionsFilter";
import FilterEnvelopeContainer from "../../components/Filter/FilterEnvelopeContainer";
import ProductFilter from "./ProductFilter";
import {OnlyActiveSubscribersFilter} from "./OnlyActiveSubscribersFilter";
import {OnlySubscriberAdminFilter} from "./OnlySubscriberAdminFilter";
import {useQuery} from '@apollo/client';
import {
  GetAgreement,
  GetAgreement_me_agreementsWithDetails,
  GetAgreementVariables
} from '../../__generated__/GetAgreement';
import {GET_AGREEMENT, GET_AGREEMENT_NUM_SUBSCRIPTIONS} from '../../queries';
import FilterButton from "../../components/EmployeesAndSubscriptionsTable/FilterButton";
import Filter from "../../components/Filter/Filter";
import {Contains} from "../../components/Filter/types";
import SearchButton from "../../components/EmployeesAndSubscriptionsTable/SearchButton";
import moment from "moment";
import ExcelButton from "../../components/EmployeesAndSubscriptionsTable/ExcelButton";
import {DepartmentContext} from "../../context/DepartmentContext/DepartmentContext";
import EmptySubscriptions from "./EmptySubscriptions";
import ProductTypeFilter from "./ProductTypeFilter";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {accountNameConditions, delayEvent, trackClickEngagment} from "../../utils";
import {MinBedriftRouteComponentProps} from "../../routes/utils";
import {Helmet} from "react-helmet";
import SelectionContextProvider from '../../context/SelectionContext/SelectionContext';
import SelectionFooter from "../../components/Footer/SelectionFooter";
import AgreementActivationPotential from "../../components/AgreementsTable/AgreementActivationPotential";
import {
  GetAgreementNumSubscriptions,
  GetAgreementNumSubscriptionsVariables
} from "../../__generated__/GetAgreementNumSubscriptions";
import {AccountNameConditionOperator} from "../../__generated__/globalTypes";
import {useNavigate, useParams} from "react-router-dom";


const styleClass = {
  btn: classNames(
    Width.W_XS,
    BackgroundColor.BG_PRIMARY_3,
    TextColor.TEXT_WHITE,
    Margin.MY_4,
  ),
  noSubscribers: {
    root: classNames(
      TextAlign.TEXT_CENTER,
      Padding.PB_10,
      FlexDirection.FLEX_COL,
      Display.FLEX,
      AlignItems.ITEMS_CENTER
    ),
    heading: classNames(
      FontWeight.FONT_LIGHT,
      FontSize.TEXT_2XL
    ),
    paragraph: classNames(
      Padding.PY_4,
      TextColor.HOVER_TEXT_NEUTRAL_7,
      FontSize.TEXT_LG,
      FontFamily.FONT_TITLE_2
    ),
    addBtn: {
      iconLeft: classNames(
        Margin.ML_2,
        Margin.MR_4,
        Margin.MY_2,
        Fill.FILL_WHITE,
        FlexShrink.FLEX_SHRINK_0,
        Height.H_6,
        Width.W_6,
      ),
      iconRight: classNames(
        Fill.FILL_WHITE,
        FlexShrink.FLEX_SHRINK_0,
        Margin.M_2,
        Height.H_6,
        Width.W_6,
      )
    },
    iconProduct: classNames(
      Fill.FILL_PRIMARY_1,
      FlexShrink.FLEX_SHRINK_0,
      Margin.M_2,
      Height.H_6,
      Width.W_6,
    )
  },
  container: '',
  heading: (loading: boolean) => classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_2XL,
    Padding.PL_4,
    Display.INLINE_FLEX,
    Padding.MD_PL_0,
    {
      'loading': loading
    }
  ),
  tableHeading: (mobile: boolean) =>  classNames(
    Padding.PX_2,
    Padding.MD_PX_0,
    !mobile ? [Width.W_2_5] : [Width.XS_W_SCREEN]
    ),
  tableControls: classNames(
    Margin.MB_2,
    AlignItems.ITEMS_CENTER,
    FlexShrink.FLEX_SHRINK,
    Padding.PL_0,
  ),
  inviteEmployee: classNames(
    Width.W_FULL,
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER,
  )
};

const getModifiedExcelData = (data: IEmployee[], activeDepartment: string, referanceName1: string, referanceName2: string) => () => {
  const rows = [
    [
      'Navn',
      'Kundenummer',
      'Administrator',
      'Produkt',
      'Produkttype',
      'Startdato',
      'Sluttdato',
      `Til idag ${moment().format('YYYY')}`,
      `Totalt ${moment().format('YYYY')}`,
      `Totalt ${moment().add(-1,"year").format('YYYY')}`,
    ]
  ];

  if(activeDepartment) {
    rows[0].push('Avdeling',);
  }
  if(referanceName1 !== '') {
    rows[0].push(referanceName1,);
  }
  if(referanceName2 !== '') {
    rows[0].push(referanceName2,);
  }


  return data.reduce((acc, user) => {

    user.subscriptions.forEach(subscription => {

        const taxForPeriod = subscription.taxes.map((subscription) => {
          if(subscription.year && subscription.taxForPeriod) {
            return moment().format('YYYY') === subscription.year ? subscription.taxForPeriod : '';
          }
          return "";
        });
        const yearTax = subscription.taxes.map((subscription) => {
          if(subscription.year && subscription.taxForEntireYear) {
            return moment().format('YYYY') === subscription.year ? subscription.taxForEntireYear : '';
          }
          return ""
        });
        const lastYearTax = subscription.taxes.map((subscription) => {
          if(subscription.year && subscription.taxForEntireYear) {
            return moment().subtract(1,"year").format('YYYY') === subscription.year ? subscription.taxForEntireYear : '';
          }
          return ""
        });

        const exportValues = [
          user.name,
          user.customerNumber,
          user.admin ? 'Ja' : 'Nei',
          subscription.name,
          subscription.description,
          subscription.startDate ? moment(subscription.startDate).format('L') : '',
          subscription.endDate ? moment(subscription.endDate).format('L') : '',
          taxForPeriod[0],
          yearTax[0],
          lastYearTax[0],
        ];

        if(activeDepartment) {
          exportValues.push(activeDepartment,);
        }
        if(referanceName1) {
          exportValues.push(subscription.reference1.value || '',);
        }
        if(referanceName2) {
          exportValues.push(subscription.reference2.value || '',);
        }
      acc.push(exportValues)

    });
    return acc;
  }
  , rows)
};

const InviteEmployeeBtn: React.FC<{}> = () => {
  const navigate = useNavigate();
  const {agreementNumber} = useParams<{ agreementNumber: string }>();
  const inviteButtonClick = () => {
    trackClickEngagment('Abonnenter - Inviter ansatte');
    navigate(`/admin/avtaler/${agreementNumber}/inviter`);
  };
  return (
    <div className={styleClass.inviteEmployee}>
      <Button
        text={"Inviter ansatte"}
        ariaLabel={"Inviter ansatte"}
        skin={ButtonSkin.PRIMARY}
        className={styleClass.btn}
        onClick={inviteButtonClick}
        iconLeft={
          <Icon
            name={IconName.PLUS}
          />
        }
      />
    </div>
  )
};

type SubscribersProps = {
  agreement: GetAgreement_me_agreementsWithDetails,
  loading: boolean,
  hasEmployees: boolean,
  agreementNumber: number,
  employees: Employee[]
}
const Subscribers: React.FunctionComponent<SubscribersProps> = ({agreement, loading, hasEmployees, agreementNumber, employees}) => {

  if (!loading && !hasEmployees) {
    return <EmptySubscriptions agreementNumber={agreementNumber}/>
  }

  if (!loading && hasEmployees && employees.length === 0) {
    return (
      <>
        <p className={TextAlign.TEXT_CENTER}>Filteret ga ingen resultater</p>
        <InviteEmployeeBtn/>
      </>
    )
  }

  return (
    <>
      <EmployeesTable agreement={agreement} employees={employees}/>
      <InviteEmployeeBtn/>
    </>
  )
};

const ActualSubscriptions: React.FunctionComponent<MinBedriftRouteComponentProps & { paginate: boolean }> = ({paginate, route}) => {

  const params = useParams<{agreementNumber: string}>();
  const {mobile, tablet, portrait} = useContext(ResponsiveContext);
  const {applyFilter, filterContextState} = useFilterFactory();
  const activeDepartment = useContext(DepartmentContext);
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [agreement, setAgreement] = useState<GetAgreement_me_agreementsWithDetails | undefined>(undefined);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [paginationDone, setPaginationDone] = useState<boolean>(!paginate);
  const [fetchMoreLoadingState, setFetchMoreLoadingState] = useState<string>("");
  const [fetchMoreError, setFetchMoreError] = useState<any>(null);

  // @ts-ignore
  const agreementNumber = [+params.agreementNumber];
  const variables: GetAgreementVariables = Object.assign({}, {
    agreementNumber,
    shouldFetchSubscriptions: true,
    accountNameConditions: paginate ? accountNameConditions()[0] : [],
  },
    activeDepartment ? {department: activeDepartment} : {},
  );

  const {data, error, loading, fetchMore} = useQuery<GetAgreement, GetAgreementVariables>(GET_AGREEMENT, {
    variables,
    fetchPolicy: 'network-only'
  });

  if (error) {
    throw error;
  }
  if (fetchMoreError) {
    throw fetchMoreError;
  }
  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (data && data.hasOwnProperty('me') && paginationDone) {
      const {agreementsWithDetails} = data.me;
      const [agreement] = agreementsWithDetails as GetAgreement_me_agreementsWithDetails[];
      const employees = Employees.fromAgreement(agreement);
      applyFilter<Employee[]>(employees)
        .then(filtered => {
          setEmployees(filtered);
        });

      setInitialLoad(false);
      setAgreement(agreement);
    }

  }, [data, loading, filterContextState, activeDepartment, paginationDone]);
  useEffect(() => {
    if (!loading && paginate) {
      const fetchers: (() => Promise<void>)[] = accountNameConditions()
        .filter((e, i) => i > 0)
        .map((c) => async () => {
          const fetchMoreVariables = Object.assign({}, variables, {
            accountNameConditions: c,
          });
          setFetchMoreLoadingState(`Laster ${fetchMoreVariables.accountNameConditions
            .map((c) => c.character + (c.operator === AccountNameConditionOperator.greaterThan ? ' - ' : ''))
            .join("")
          }`);
          await fetchMore({
            variables: fetchMoreVariables,
            updateQuery: (prev, {fetchMoreResult}) => {
              const nextAgreement = fetchMoreResult?.me?.agreementsWithDetails;
              const nextSubscriptions = nextAgreement?.[0].subscriptions;
              if (nextSubscriptions?.length) {
                return Object.assign({}, prev, {
                  me: Object.assign({}, prev.me, {
                    agreementsWithDetails: ((prev.me || {agreementsWithDetails: []}).agreementsWithDetails || [])
                      .map(a => {
                        const prevSubscriptions = Array.isArray(a.subscriptions) ? a.subscriptions : [];
                        return Object.assign({}, a, {
                          subscriptions: [...prevSubscriptions].concat(nextSubscriptions),
                        });
                      })
                  }),
                });
              }
              return prev;
            },
          });
        });
      const limiter = new Bottleneck({
        maxConcurrent: 3,
      });

      setPaginationDone(false);
      Promise.all(fetchers.map((fetcher) => limiter.schedule(fetcher)))
        .then(() => {
          setFetchMoreLoadingState("");
          setPaginationDone(true);
        })
        .catch((e) => {
          setFetchMoreError(e);
        });
    }
  }, [activeDepartment, loading]);

  const hasEmployees = agreement && agreement.subscriptions.length;
  const depName = agreement && agreement.departments.find(department => department.id === activeDepartment);
  const referanceName1 = agreement && agreement.settings.reference1;
  const referanceName2 = agreement && agreement.settings.reference2;
  const pageHeading = `abonnenter`;

  let filterHeader = 'Filtrer listen';
  if (mobile || (tablet && portrait)) {
    filterHeader = `Filtrer ${pageHeading}`
  }

  const activeSubcriptions = employees ? employees
    .reduce((acc: ISubscription[], cur) => acc.concat(cur.activeSubscriptions), [])
    : [];

  return (
    <>
    <SelectionContextProvider>
      <Helmet>
        <title>{`Min Bedrift ${agreement ? `| ${agreement.accountName} ` : ''} ${route ? `| ${route.label}` : ''}`}</title>
      </Helmet>

      <FilterEnvelopeContainer header={filterHeader}>
        <Sort>
          <Sort.NumericString name={'props.subscriptions.agreementItemStartDate'} checked={true}/>
          <Sort.Ascending name={'name'}/>
          <Sort.Descending name={'name'}/>
        </Sort>

        <ProductTypeFilter/>

        <ProductFilter products={agreement ? agreement.products : []}/>

        <NumSubscriptionsFilter/>

        <OnlyActiveSubscribersFilter/>

        <OnlySubscriberAdminFilter/>



      </FilterEnvelopeContainer>

      {agreement && <AgreementActivationPotential agreement={agreement} className={Margin.LG_MB_12} linkTo={"inviteEmployees"}/>}

      <TableControls>

        <div className={styleClass.tableHeading(mobile)}>
          <Heading level={HeadingLevel.ONE} className={styleClass.heading(initialLoad || loading || !!fetchMoreLoadingState)}>
            {initialLoad || loading || !!fetchMoreLoadingState ? (
              <>
                <Icon name={IconName.LOADING_WHEEL} screenReaderText={'Ikon som viser at informasjon lastes'}/>
                {fetchMoreLoadingState}
              </>
            ) : employees.length > 0
                ? `${employees.length} ${pageHeading} med ${activeSubcriptions.length} aktive abonnement`
                : `Utvalget ga ingen resultater`}
          </Heading>

        </div>

      </TableControls>

      <Grid className={styleClass.tableControls}>
        <GridItem
          span={[GridSpan.XSMALL_12, GridSpan.SMALL_3]}
          className={classNames(Padding.XS_PR_0, Padding.SM_PR_1)}
        >
          <FilterButton disabled={initialLoad}/>
        </GridItem>
        <GridItem
          span={[GridSpan.XSMALL_12, GridSpan.SMALL_6]}
          className={classNames(Padding.XS_PR_0, Padding.SM_PR_1)}
        >
          <Filter
            id={'freeTextSearch'}
            uniqueBy={'customerNumber'}
          >
            <Contains
              id={'freeTextSearch_0'}
              name={[
                'props.subscriptions.subscriptionItems.customerName',
                'props.subscriptions.subscriptionItems.product.productVariation',
              ]}>
              {
                ({filterValue}) => (
                  <SearchButton
                    disabled={initialLoad}
                    onSearch={delayEvent((evt: any) => filterValue(evt.target.value), 450)}
                    placeholder={'Søk i abonnenter (navn og produkt)'}
                  />
                )
              }
            </Contains>
          </Filter>
        </GridItem>
        <GridItem
          span={[GridSpan.XSMALL_12, GridSpan.SMALL_3]}
          className={Padding.PR_0}
        >
          <ExcelButton
            disabled={employees.length === 0 || initialLoad}
            getData={getModifiedExcelData(employees, depName && depName.name ? depName.name : '', referanceName1 && referanceName1.name ? referanceName1.name : '', referanceName2 && referanceName2.name ? referanceName2.name : '')}
            filename={`Min Bedrift ${agreement && agreement.accountName}-Abonnenter.csv`.toLocaleLowerCase().replace(/[^a-zA-Z0-9-_.]/g, '-')}
          />
        </GridItem>
      </Grid>

      <Subscribers
        agreementNumber={agreementNumber[0]}
        loading={loading || !!fetchMoreLoadingState}
        agreement={agreement!}
        employees={employees}
        hasEmployees={!!hasEmployees}
      />

      <SelectionFooter agreementNumber={agreementNumber[0]}/>

      </SelectionContextProvider>
    </>
  );

};

export const Subscriptions: React.FunctionComponent<MinBedriftRouteComponentProps> = (props) => {
  const params = useParams<{ agreementNumber: string }>();
  const activeDepartment = useContext(DepartmentContext);

  // @ts-ignore
  const agreementNumber = [+params.agreementNumber];
  const {data, error, loading} = useQuery<GetAgreementNumSubscriptions, GetAgreementNumSubscriptionsVariables>(GET_AGREEMENT_NUM_SUBSCRIPTIONS, {
    variables: {
      agreementNumber,
      ...(activeDepartment ? {department: activeDepartment} : {}),
    },
  });

  if (loading) {
    return (
      <>
        <Icon name={IconName.LOADING_WHEEL} screenReaderText={'Ikon som viser at informasjon lastes'}/>
      </>
    );
  }
  if (error) {
    throw error;
  }

  if (data && data.hasOwnProperty('me')) {
    const agreementSubscriberCount = data.me.agreementsWithDetails[0].agreementSubscriberCount || 0;
    return <ActualSubscriptions {...props} paginate={agreementSubscriberCount > 1000}/>;
  }

  return null;
};

export default Subscriptions;
