import React, {ReactNode, useContext, useEffect} from "react";
import {EnvelopeContext} from "../../context/EnvelopeContext/EnvelopeContext";
import {useFilterFactory} from "../../context/FilterContext/FilterContext";
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderWidth,
  Card,
  classNames,
  Display, FlexDirection,
  Height,
  Icon, IconColor, IconName, JustifyContent,
  Link,
  Margin,
  MinHeight,
  Padding,
  Position,
  TextColor, TextDecoration,
  Width,
  ZIndex
} from "@snoam/pinata";
import {DesktopHeaderHeight, MobileHeaderHeight} from "../../Const";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";

export interface IUserFilterProps {
  header: string;
  children: ReactNode;
}

const styleClass = {
  root: classNames(
    Position.RELATIVE,
    Display.FLEX,
    AlignItems.ITEMS_STRETCH,
    Padding.PX_3,
    JustifyContent.JUSTIFY_BETWEEN,
    FlexDirection.FLEX_COL,
    MinHeight.MIN_H_SCREEN
  ),
  footer: {
    root: classNames(
      BackgroundColor.BG_POSITIVE,
      ZIndex.Z_MODAL,
      Margin.ML_NEGATIVE_3,
      Margin.MR_NEGATIVE_3,
      Position.STICKY,
      Position.BOTTOM_0,
      Position.LEFT_0,
    ),
    link: classNames(
      Width.W_FULL,
      Display.FLEX,
      FlexDirection.FLEX_ROW,
      AlignItems.ITEMS_CENTER,
      JustifyContent.JUSTIFY_BETWEEN,
      Height.H_14,
      TextDecoration.NO_UNDERLINE,
      TextColor.TEXT_WHITE,
      TextColor.HOVER_TEXT_WHITE
    )
  },
  card: classNames(
    Margin.MB_2,
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    BackgroundColor.BG_WHITE
  ),
  reset: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER,
    Margin.MT_8,
    Margin.MB_8,
  )
};

const FilterEnvelopeContainer = ({header = 'Filtrer listen', children}: IUserFilterProps) => {
  const { mobile, tablet, portrait, desktop, landscape} = useContext(ResponsiveContext);
  const isFullScreen = (mobile || (tablet && portrait));

  const {
    setChildren,
    setHeading,
    setStyle,
    toggle,
  } = useContext(EnvelopeContext);

  const {resetFilter} = useFilterFactory();
  const onResetFilterClick = () => {
    if (isFullScreen) {
      toggle();
      resetFilter();
    } else {
      resetFilter();
    }
  };

  useEffect(() => {

    const wrappedChildren = React.Children.map(
      children || [], child => (
        <Card className={styleClass.card}>
          {child}
        </Card>
      )
    );

    setHeading(header);

    const handleCloseFilterKeyDown = (e: any) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        toggle();
      }
    };
    const handleResetFilterKeyDown = (e: any) => {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        onResetFilterClick();
      }
    };

    setChildren(
      <div className={styleClass.root} style={{minHeight: `calc(100vh - ${isFullScreen ? MobileHeaderHeight : DesktopHeaderHeight})`}}>

        <div>
          {wrappedChildren}

          <Link
            className={styleClass.reset}
            onClick={onResetFilterClick}
            onKeyDown={handleResetFilterKeyDown}
            tabIndex={0}
          >
            Tilbakestill filter
          </Link>
        </div>

        <div className={styleClass.footer.root}>
          <Link
            className={styleClass.footer.link}
            onClick={toggle}
            onKeyDown={handleCloseFilterKeyDown}
            tabIndex={0}
          >
            <Icon name={IconName.FILTER2} color={IconColor.PRIMARY_LIGHT} className={Margin.ML_3}/>
            <span>Lukk filter</span>
            <Icon name={IconName.CHEVRON_RIGHT} color={IconColor.PRIMARY_LIGHT} className={Margin.MR_3}/>
          </Link>
        </div>

      </div>
    );

  }, [children]);

  useEffect(() => {
    if (isFullScreen) {
      setStyle({
        left: 0,
        width: '100%'
      })
    } else {
      setStyle({
        left: '5rem'
      });
    }
  }, [mobile, tablet, portrait, landscape, desktop]);

  return null;
};

export default FilterEnvelopeContainer;
