import React from "react";
import {Navigate, NavigateProps} from "react-router";
import {Params, useParams} from "react-router-dom";

type ReplaceParams = (pathname: string, params: Readonly<Params>) => string;

const replaceParams: ReplaceParams = (pathname, params) => {
  return Object.entries(params).reduce((acc, [k, v]) =>
      acc.replace(`/:${k}/`, `/${v}/`)
    , pathname);
}


const NavigateWithParams: React.FC<NavigateProps> = (props) => {

  const params = useParams();
  const to = typeof props.to === 'string' ? replaceParams(props.to, params) : Object.assign({}, props.to, {
    pathname: props.to.pathname !== undefined ? replaceParams(props.to.pathname, params) : props.to.pathname,
  })

  return (
    <Navigate {...props} to={to}/>
  );
};

export default NavigateWithParams;
