import * as React from "react";
import {useEffect, useState} from "react";
import {GetAgreement, GetAgreementVariables} from "../../__generated__/GetAgreement";
import {useQuery} from "@apollo/client";
import {GET_AGREEMENT_DEPARTMENTS} from "../../queries";
import {PaymentLevel} from "../../__generated__/globalTypes";
import DepartmentSelector from "../../pages/Subscriptions/DepartmentSelector";
import {LoadingFragment} from "../../pages/LoadingPage";
import {SubscriptionSettingsModel} from "../../pages/Subscriptions/models/SubscriptionSettingsModel";
import {useParams} from "react-router-dom";

type DepartmentContextType = string | null;

export const DepartmentContext = React.createContext<DepartmentContextType>(null);

export const DepartmentContextProvider: React.FunctionComponent<{}> = ({children}) => {

  const params = useParams<{ agreementNumber: string }>();
  const persistentSettings = SubscriptionSettingsModel.instance();
  // @ts-ignore
  const agreementNumber = +params.agreementNumber;
  const agreementNumbers = [agreementNumber];
  const [activeDepartmentId, setActiveDepartmentId] = useState<DepartmentContextType>(persistentSettings.getDepartment(agreementNumber));
  const variables: GetAgreementVariables = Object.assign({}, {
    agreementNumber: agreementNumbers,
  });
  const {data: apolloData, loading} = useQuery<GetAgreement, GetAgreementVariables>(GET_AGREEMENT_DEPARTMENTS, {
    variables,
    fetchPolicy: 'cache-first'
  });
  const [agreement] = apolloData && apolloData.hasOwnProperty('me') ? apolloData.me.agreementsWithDetails : [{
    agreementPaymentlevel: null,
    departments: []
  }];
  const {agreementPaymentlevel = null} = agreement;

  useEffect(() => {
    if (agreement.departments.length && !activeDepartmentId) {
      const [department] = agreement.departments;
      setActiveDepartmentId(department.id);
    }
  }, [loading]);

  const renderDepartmentSelector = () => (
    agreementPaymentlevel === PaymentLevel.Department ? <DepartmentSelector
      departments={agreement.departments!}
      setActiveDepartment={(departmentId) => {
        setActiveDepartmentId(departmentId);
        persistentSettings.setDepartment(agreementNumber, departmentId);
      }}
      value={activeDepartmentId || ''}
    /> : null
  );

  const renderChildren = () => {
    if (loading) {
      return <LoadingFragment/>;
    }
    return children;
  };

  return (
    <DepartmentContext.Provider value={activeDepartmentId}>
      {renderDepartmentSelector()}
      {renderChildren()}
    </DepartmentContext.Provider>
  )
};
