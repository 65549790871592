import * as React from "react";
import SvgImage from "../../components/SvgImage/SvgImage";
import {generatePath, useParams} from "react-router-dom";
import { subscribersPageRoute } from "../../routes/routes";
import {
  BackgroundColor,
  Card as PinataCard,
  classNames,
  Container,
  ContainerWidth,
  Display,
  Dropdown,
  FontWeight,
  Heading,
  HeadingLevel,
  IDropdownListItem,
  Margin,
  MaxWidth,
  Padding,
  StyledRouterLink,
  TextAlign,
  FontSize,
  Width,
  FlexDirection,
  AlignItems,
} from "@snoam/pinata";
import { MinBedriftRouteComponentProps } from "../../routes/utils";
import {Helmet} from "react-helmet";
import { useQuery } from "@apollo/client";
import {
  GetAgreement,
  GetAgreement_me_agreementsWithDetails_departments,
  GetAgreementVariables,
} from "../../__generated__/GetAgreement";
import { GET_AGREEMENT } from "../../queries";
import { LoadingFragment } from "../LoadingPage";
import { PaymentLevel } from '../../__generated__/globalTypes';
import InvoiceBox from './InvoiceBox';
import NoInvoices from './NoInvoices';
import {toGeneratePathParams} from "../../utils";

const styleClass = {
  root: classNames(
    TextAlign.TEXT_CENTER,
    Padding.PB_10,
    FlexDirection.FLEX_COL,
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    Padding.XS_PX_3
  ),
  inner: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_3XL,
    Margin.MB_8
  ),

  card: {
    root: classNames(
      BackgroundColor.BG_WHITE,
      Padding.P_8,
      MaxWidth.MAX_W_SM
    )
  },

  noInvoice: {
    root: classNames(
      BackgroundColor.BG_WHITE,
      Padding.P_8,
      MaxWidth.MAX_W_SM
    )
  }
};

const Card: React.FunctionComponent<{ className?: string }> = (props) => (
  <PinataCard
    backgroundColor={BackgroundColor.BG_WHITE}
    className={classNames(MaxWidth.MAX_W_XS, Padding.PX_8, Padding.PY_4, props.className)}
  >
    {props.children}
  </PinataCard>
);

const EmployeeInvoice = ({agreementNumber}: IInvoiceCategoryProps) => {
  return (
    <Card>
      Deres bedriftsavtale er bestående av faktura pr. abonnement.
      Fakturaene finner dere i <StyledRouterLink to={generatePath(subscribersPageRoute.path, toGeneratePathParams({agreementNumber}))}>
      listen over abonnenter.
    </StyledRouterLink>
    </Card>
  )
};

export type IInvoiceCategoryProps = {
  agreementNumber: number;
  accountNumber: number;
  departments: GetAgreement_me_agreementsWithDetails_departments[];
  type?: PaymentLevel;
}

const InvoiceCategory = (props: IInvoiceCategoryProps) => {

  const {accountNumber, agreementNumber, type} = props;

  if (!type) {
    return <NoInvoices/>;
  }

  if (type === PaymentLevel.Employee) {
    return <EmployeeInvoice {...props}/>
  }

  if (type === PaymentLevel.Department) {
    return <InvoiceSelector {...props}/>
  }

  return (
    <InvoiceBox accountNumber={accountNumber} agreementNumber={agreementNumber}/>
  )
};


type InvoiceSelectorProps = IInvoiceCategoryProps & { departments: GetAgreement_me_agreementsWithDetails_departments[] };
type InvoiceSelectorState = {
  departmentAccountNumber: string | null;
  departmentAgreementNumber: string | null;
};
class InvoiceSelector extends React.PureComponent<InvoiceSelectorProps, InvoiceSelectorState> {

  public readonly props: InvoiceSelectorProps;

  constructor(props: InvoiceSelectorProps) {
    super(props);

    this.props = props;
  }

  public state = {
    departmentAccountNumber: null,
    departmentAgreementNumber: null,
  };

  private buildDepartmentList(): IDropdownListItem[] {
    const {departments} = this.props;

    return departments.map(department => ({
      name: department.name || `${department.agreementNumber}`,
      value: `${department.agreementNumber}`,
    }));
  }

  private onChangeDepartment = (departmentAgreementNumber: string | null) => {
    const department = this.props.departments.find((d) => `${d.agreementNumber}` === departmentAgreementNumber);
    this.setState({
      departmentAccountNumber: department ? `${department.accountNumber}` : null,
      departmentAgreementNumber,
    });
  };

  render() {
    const {departmentAccountNumber, departmentAgreementNumber} = this.state;
    const accountNumber = departmentAccountNumber ? parseInt(`${departmentAccountNumber}`, 10) : this.props.accountNumber;
    return (
      <React.Fragment>
        <Dropdown
          className={classNames(Width.W_FULL, /*Width.MD_W_80*/)}
          defaultTriggerText={"Velg avdeling"}
          data={this.buildDepartmentList()}
          onChange={this.onChangeDepartment}
          value={this.state.departmentAgreementNumber || undefined}
        />
        {
          departmentAgreementNumber ? <InvoiceBox className={Margin.MT_4} accountNumber={accountNumber} agreementNumber={+departmentAgreementNumber!}/> : null
        }
      </React.Fragment>
    )
  }
}


export const Invoice: React.FunctionComponent<MinBedriftRouteComponentProps> = ({route}) => {
  const params = useParams<{ agreementNumber: string }>();
  // @ts-ignore
  const agreementNumber: number = +params.agreementNumber;
  const {data, error, loading} = useQuery<GetAgreement, GetAgreementVariables>(GET_AGREEMENT, {
    variables:{ agreementNumber: [agreementNumber]},
  });

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error) {
    throw error;
  }

  const [agreement] = data!.me.agreementsWithDetails;
  const type: PaymentLevel = agreement.agreementPaymentlevel;

  return (
    <>
      <Helmet>
        <title>{`Min Bedrift ${agreement ? `| ${agreement.accountName} ` : ''} ${route ? `| ${route.label}` : ''}`}</title>
      </Helmet>
      <Container width={ContainerWidth.NARROW} className={styleClass.root}>

        <div className={styleClass.inner}>
          <SvgImage href={`${process.env.PUBLIC_URL}/svg/invoice.svg`} className={Width.W_FULL}/>

          <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
            Faktura
          </Heading>

          <InvoiceCategory
            accountNumber={agreement.accountNumber}
            agreementNumber={agreementNumber}
            departments={agreement.departments}
            type={type}
          />

        </div>

      </Container>
    </>
  )
};

export default Invoice;
