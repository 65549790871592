import * as React from 'react';
import {useSpid} from "@snoam/mono-spid";
import {LoadingFragment} from "../../../pages/LoadingPage";

export interface IAuthBoundary {
  teaser: 'minbedrift-admin' | 'minbedrift-ansatt'
}

const AuthBoundary: React.FunctionComponent<IAuthBoundary> = (props) => {
  const {children, teaser} = props;
  const spidContext = useSpid();

  const {state: {userId, isLoaded}} = spidContext;

  if (!isLoaded) {
    return <LoadingFragment/>
  }

  if (!userId) {
    spidContext.actions.login({simplified: false, teaser});
    return null;
  }

  return <>{children}</>;
};
export default AuthBoundary;


export const OrderAuthBoundary: React.FunctionComponent = ({children}) => {
  return <AuthBoundary teaser={'minbedrift-ansatt'}>{children}</AuthBoundary>
};


