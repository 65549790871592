import * as React from 'react';
import {Component, createContext, useContext} from 'react';
import {
  clientName as findClientName,
  ClientShortHandName,
  clientShortHandNameFromHostname,
  mainHostnameFromHostname
} from '@snoam/mono-scc';

const debug = require('debug')('create-pinata-app:client:clientContext');

export interface IClientContextProps {
  clientShortHandName?: ClientShortHandName;
  href: string;
  domain: string;
  isProduction: boolean;
}

export interface IClientContext {
  clientShortHandName: ClientShortHandName;
  hostname: string;
  href: string;
  clientName: string;
  isProduction: boolean;
}

const ClientContext = createContext<IClientContext>(
  {clientName: '', clientShortHandName: ClientShortHandName.SCH, hostname: '', href: ''} as IClientContext);
export const ClientContextConsumer = ClientContext.Consumer;

class ClientContextProvider extends Component<IClientContextProps, {}> {

  public render() {
    const {domain, href, isProduction} = this.props;
    const clientShortHandName: ClientShortHandName = this.props.clientShortHandName || clientShortHandNameFromHostname(domain);
    const clientName = findClientName(clientShortHandName);
    const hostname: string = mainHostnameFromHostname(domain);
    const clientContext = {
      clientName,
      clientShortHandName,
      hostname,
      href,
      isProduction,
    };

    debug('ClientContext(%o): %o', {...clientContext, domain});

    return (
      <ClientContext.Provider value={{...clientContext}}>
        {this.props.children}
      </ClientContext.Provider>
    );
  }
}

export const useClientContext = () => useContext(ClientContext);
export default ClientContextProvider;
