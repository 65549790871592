import React, { ChangeEventHandler, useEffect, useState} from "react";
import {
  AlignItems,
  BorderColor,
  BorderWidth,
  classNames,
  Display,
  Heading,
  HeadingLevel,
  Padding,
  RadioButton,
  RadioButtonGroup,
  TextColor,
  Width,
} from "@snoam/pinata";
import {GetAgreement_me_agreementsWithDetails} from '../../../__generated__/GetAgreement';
import {
  UpdateAgreementSettings,
  UpdateAgreementSettingsVariables
} from '../../../__generated__/UpdateAgreementSettings';
import {MutationFunction, useMutation} from '@apollo/client';
import {UPDATE_AGREEMENT_SETTINGS} from '../../../mutations';
import {removeTypename} from '../../../utils';
import {EmploymentVerificationMethod} from "../../../__generated__/globalTypes";
import VerificationSectionEmail from "./VerificationSectionEmail";
import VerificationSectionOpenidConnect from "./VerificationSectionOpenidConnect";
import EmploymentPeriodicVerificationSettings from "./EmploymentPeriodicVerificationSettings";

const styleClass = {
  settings: {
    verificationMethods: classNames(
      Display.FLEX,
      AlignItems.ITEMS_START,
      Width.W_SM,
    ),
    section: classNames(
      BorderColor.BORDER_NEUTRAL_2,
      BorderWidth.BORDER_B_4,
      Padding.PX_4,
      Padding.MD_PX_10,
      Padding.PT_8,
      Padding.PB_10,
    ),
    sectionTitle: classNames(
      TextColor.TEXT_NEUTRAL_8,
    ),
  }
};

interface SettingsPanelProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}

const VerificationSection = ({ agreement }: SettingsPanelProps) => {

  const [employeeVerificationMethod, setEmployeeVerificationMethod] = useState<EmploymentVerificationMethod>(agreement.settings.employeeVerificationMethod);
  const updateAgreementSettings: MutationFunction<UpdateAgreementSettings, UpdateAgreementSettingsVariables> = useMutation<UpdateAgreementSettings, UpdateAgreementSettingsVariables>(UPDATE_AGREEMENT_SETTINGS)[0];
  const {
    employeePeriodicVerificationSettings,
    openidConnectClientId,
    openidConnectClientSecretMasked,
    openidConnectDiscoveryUrl,
    openidConnectSystemName,
    ...newSettings
  } = removeTypename(agreement.settings);

  const [pending, setPending] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (employeeVerificationMethod !== agreement.settings.employeeVerificationMethod) {
      const variables: UpdateAgreementSettingsVariables = {
        agreementNumber: agreement.agreementNumber,
        agreementId: agreement.id,
        settings: {
          ...newSettings,
          employeeVerificationMethod
        },
      };
      const asyncAction = () => updateAgreementSettings({ variables });
      setError('');
      setPending('Lagrer...');
      asyncAction()
        .then((data) => {
          setPending('');
        })
        .catch((error) => {
          setError('' + error);
          setPending('');
        });
    }
  }, [employeeVerificationMethod]);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setEmployeeVerificationMethod(e.target.value as EmploymentVerificationMethod);
  };

  return (
    <React.Fragment>
      <div className={ styleClass.settings.section }>
        <Heading
          level={ HeadingLevel.TWO }
          className={ styleClass.settings.sectionTitle }
        >
          Hvordan skal ansatte verifisere at de jobber i deres bedrift?
        </Heading>

        <RadioButtonGroup
          onChange={onChange}
          radioHeading={"Verifiseringsmetode"}
          name={"employeeVerificationMethod"}
          value={employeeVerificationMethod}
        >
          <div className={styleClass.settings.verificationMethods}>
            <RadioButton text={"E-post"} value={EmploymentVerificationMethod.EMAIL} />
            <RadioButton text={<>Bedriftens&nbsp;påloggingssystem</>} value={EmploymentVerificationMethod.OPENID_CONNECT} />
          </div>
        </RadioButtonGroup>
        {pending || null}
        {error || null}

        {employeeVerificationMethod === EmploymentVerificationMethod.EMAIL ? <VerificationSectionEmail agreement={agreement} /> : <VerificationSectionOpenidConnect agreement={agreement} />}
        {employeeVerificationMethod === EmploymentVerificationMethod.OPENID_CONNECT ? <EmploymentPeriodicVerificationSettings agreement={agreement}/> : null}

      </div>
    </React.Fragment>
  )
};

export default VerificationSection;
