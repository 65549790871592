import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Cursor,
  Display, FlexDirection,
  Icon, IconName, JustifyContent,
  Overflow,
  Padding,
  Whitespace,
  Width,
} from '@snoam/pinata';
import * as React from 'react';
import {CSSProperties, useState} from 'react';

export interface ITableProps<T> {
  row: T;
  rowRenderer: (item: T, expanded: boolean) => any;
  backgroundHeaderColor: string;
  backgroundColorHeaderExpanded?: BackgroundColor|string;
  backgroundColorBodyExpanded?: BackgroundColor|string;
  canExpand?: (item: T) => boolean;
  expandSymbolRenderer?: (item: T) => any;
  closeSymbolRenderer?: (item: T) => any;
  expandedRenderer?: (item: T) => any;
  style?: CSSProperties;
}

const styleClass = {
  tableRow: {
    root: ({expanded, backgroundColorHeaderExpanded, backgroundHeaderColor}: any)=> classNames(
      expanded ? BorderColor.BORDER_NEUTRAL_4 : BorderColor.BORDER_NEUTRAL_3,
      BorderStyle.BORDER_SOLID,
      BorderWidth.BORDER_1,
      expanded ? backgroundColorHeaderExpanded : backgroundHeaderColor,
      'tableRow',
    ),
    content: classNames(Display.FLEX, FlexDirection.FLEX_COL),
    collapsed: {
      root: (canExpand: boolean)=>classNames(
        canExpand ? Cursor.CURSOR_POINTER : '',
        Display.FLEX,
        AlignItems.ITEMS_CENTER,
        JustifyContent.JUSTIFY_BETWEEN,
        Padding.P_4,
        'rowHeading',
      ),
      body: classNames(
        Display.FLEX,
        JustifyContent.JUSTIFY_START,
        AlignItems.ITEMS_CENTER,
        Width.W_FULL,
        Whitespace.WHITESPACE_NO_WRAP,
        Overflow.OVERFLOW_HIDDEN
      ),
      symbol: classNames(
        Display.FLEX,
        JustifyContent.JUSTIFY_END,
        AlignItems.ITEMS_CENTER
      )
    },
    expanded: (backgroundColorBodyExpanded: BackgroundColor|string) => classNames(
      Padding.PX_4,
      Padding.PB_4,
      backgroundColorBodyExpanded
    )
  },
  rowHeading: {}
};

const TableRow = <T extends {}>(props: ITableProps<T>) => {
  const [expanded, setExpanded] = useState<boolean>(false && true);
  const {
    rowRenderer,
    expandSymbolRenderer,
    expandedRenderer,
    closeSymbolRenderer,
    canExpand: canExpandResolver,
    backgroundColorHeaderExpanded: headerExpanded,
    backgroundColorBodyExpanded: bodyExpanded,
    style,
    backgroundHeaderColor,
    row
  } = props;

  const backgroundColorHeaderExpanded = headerExpanded || BackgroundColor.BG_NEUTRAL_3;
  const backgroundColorBodyExpanded = bodyExpanded || BackgroundColor.BG_NEUTRAL_3;

  const canExpand = typeof canExpandResolver === 'function' ? canExpandResolver(row) : canExpandResolver as any as boolean;

  function toggle(e: any) {

    if (e.isDefaultPrevented && e.target.type === 'checkbox') {
      return
    }

    if (canExpand) {
      setExpanded(!expanded)
    }
    e.stopPropagation()
  }

  function renderExpandSymbol() {
    if (expandSymbolRenderer) {
      return expandSymbolRenderer(row);
    }
    return <Icon name={IconName.PLUS}/>;
  }

  function renderCloseSymbol() {
    if (closeSymbolRenderer) {
      return closeSymbolRenderer(row);
    }
    return <Icon name={IconName.MINUS}/>;
  }

  function handleKeyPressed(event: React.KeyboardEvent<HTMLDivElement>) {
    const target = event.target as HTMLDivElement;
    if(target && !target.classList.contains('tableRow')) {
      return;
    }

    if (event.key === 'Enter') {
      event.stopPropagation();
      toggle(event);
    }
  }

  return (
    <div style={Object.assign({marginTop: '-1px', outline: 'none'}, style)}>

      <div
        className={styleClass.tableRow.root({expanded, backgroundColorHeaderExpanded, backgroundHeaderColor})}
        tabIndex={0}
        role="button"
        onKeyPress={handleKeyPressed}
      >
        <div className={styleClass.tableRow.content}>

          <div
            tabIndex={0}
            role="button"
            className={styleClass.tableRow.collapsed.root(canExpand)}
            onClick={toggle}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              if (e.key === 'Enter' || e.key === ' ') {
                toggle(e);
              }
            }}
          >
            <div
              className={styleClass.tableRow.collapsed.body}>
              {rowRenderer(row, expanded)}
            </div>

            {
              <div className={styleClass.tableRow.collapsed.symbol}>
                <div>{expanded ? renderCloseSymbol() : renderExpandSymbol()}</div>
              </div>
            }
          </div>

          {
            expanded && expandedRenderer &&
            <div className={styleClass.tableRow.expanded(backgroundColorBodyExpanded)}>
              {expandedRenderer(row)}
            </div>
          }
        </div>
      </div>
    </div>)
};

export default TableRow;
