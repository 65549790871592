import * as React from "react";
import {FunctionComponent, ReactElement, ReactNode, ReactPortal, useContext} from "react";
import {
  classNames,
  Display,
  Fill,
  FlexDirection,
  Icon,
  IconName,
  ListStyleType,
  Margin,
  Padding,
  TextColor,
} from "@snoam/pinata";
import {IBreadCrumbMenuItemProps} from "./BreadCrumbMenuItem";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";

const styleClass = {
  root: classNames(
    ListStyleType.LIST_NONE,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    Margin.MR_AUTO,
    Margin.ML_3,
    Margin.LG_M_0
  ),
  svg: classNames(
    Padding.PL_3,
    Padding.PT_1,
    Fill.FILL_NEUTRAL_4,
    TextColor.TEXT_NEUTRAL_5
  ),
  spacer: classNames(
    Padding.PL_3,
    TextColor.TEXT_NEUTRAL_3
  )
};

interface SeparatorProps {
  isLast?: boolean;
  mobile?: boolean;
  tablet?: boolean;
  desktop?: boolean;
}
const Separator: FunctionComponent<SeparatorProps> = ({ isLast, mobile, tablet, desktop }: SeparatorProps) => {
  if (isLast) {
    return null;
  } else if (desktop) {
    return <Icon
      name={IconName.ARROW_FORWARD}
      className={styleClass.svg}
      style={{height: '18px'}}
    />;
  } else if (mobile || tablet) {
    return (
      <span className={styleClass.spacer}>|</span>
    )
  }

  return null;
};

type GetProps = (props: { child: ReactNode, i: number, numChildren: number, mobile: boolean, tablet: boolean, desktop: boolean }) => { showOnMobile: boolean, Seperator: FunctionComponent<SeparatorProps> };
const getProps: GetProps = ({numChildren, child, i, mobile, tablet, desktop}) => {




  if (numChildren - 1 > i) {
    return {
      Seperator: () => <Separator
        mobile={mobile}
        tablet={tablet}
        desktop={desktop}
        isLast={(mobile || tablet) && numChildren - 2 === i}
      />,
      showOnMobile: true
    };
  }
  return {
    Seperator: () => <Separator
      mobile={mobile}
      tablet={tablet}
      desktop={desktop}
      isLast={true}
    />,
    showOnMobile: false
  };
};

export const BreadCrumbMenu: React.FunctionComponent = (props) => {
  const {children} = props;
  const {mobile, tablet, portrait, desktop} = useContext(ResponsiveContext);
  const truthyChildren: Array<ReactElement | ReactPortal> = (children as ReactNode[] || []).filter(Boolean) as any;
  const numChildren = React.Children.count(truthyChildren);
  return <ul className={styleClass.root}>
    {
      React.Children.map(truthyChildren, (child: React.ReactElement<IBreadCrumbMenuItemProps>, i: number) => {
          if (!React.isValidElement(child)) {
            return child;
          }

          const newProps = getProps({numChildren, child, i, mobile, tablet, desktop});

          if (!newProps.showOnMobile && (mobile || (tablet && portrait))) {
            return null;
          }

          return React.cloneElement(
            child,
            {
              ...(child.props),
              ...newProps
            }
          )
        }
      )
    }
  </ul>;
};

export default BreadCrumbMenu;
