import {
  GetAgreement_me_agreementsWithDetails,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaApi
} from "../../../../__generated__/GetAgreement";
import React, {FormEventHandler, useEffect, useState} from "react";
import { Arrow, Button, Icon, IconName, Margin } from "@snoam/pinata";
import {UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_OKTA_API as MUTATION} from "../../../../mutations";
import {
  UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaApi as MutationReturnValue,
  UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaApiVariables as MutationVariables,
} from "../../../../__generated__/UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaApi";
import {MutationFunction, useMutation} from "@apollo/client";
import InputWithValidator from "../../../../components/InputWithValidator/InputWithValidator";
import {styleClass} from "../VerificationSectionOpenidConnect";
import {ApolloError} from "@apollo/client";
import { MessageBox } from "@snoam/mono-messagebox";
import { EmploymentPeriodicVerificationMethod } from "../../../../__generated__/globalTypes";

interface EmploymentPeriodicVerificationSettingsOktaApiProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}

interface SaveStatus {
  error?: string[];
  pending: boolean;
  success: boolean;
}

function isOktaApiSettings(employeePeriodicVerificationSettings: null | GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings): employeePeriodicVerificationSettings is GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaApi {
  return employeePeriodicVerificationSettings ? employeePeriodicVerificationSettings.__typename === "EmploymentPeriodicVerificationSettingsOktaApi" : false;
}

const EmploymentPeriodicVerificationSettingsOktaApi: React.FC<EmploymentPeriodicVerificationSettingsOktaApiProps> = ({ agreement}) => {

  const { id: agreementId, agreementNumber, settings: { employeePeriodicVerificationSettings } } = agreement;

  const [oktaApiToken, setOktaApiToken] = useState<string>(isOktaApiSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaApiToken : "");
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>({ error: undefined, pending: false, success: false });
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const updateAgreementSettings: MutationFunction<MutationReturnValue, MutationVariables> = useMutation<MutationReturnValue, MutationVariables>(MUTATION)[0];

  useEffect(() => {
    setShowSaveButton(hasChanges());
  }, [oktaApiToken, saveStatus.error, saveStatus.pending, saveStatus.success]);

  const saveButtonLabel = saveStatus.pending ? "Lagrer..." : "Lagre informasjon";

  type Changes = {
    oktaApiToken?: string;
    tenant?: string;
  };
  const changes = (): Changes => isOktaApiSettings(employeePeriodicVerificationSettings) ? ({
    ...(oktaApiToken !== employeePeriodicVerificationSettings.oktaApiToken ? { oktaApiToken } : {}),
  }) : ({
    ...(oktaApiToken !== "" ? { oktaApiToken } : {}),
  });
  const hasChanges = (): boolean => !!Object.keys(changes()).length;
  const save = () => {
    setSaveStatus({ error: undefined, pending: true, success: false });
    const variables: MutationVariables = {
      agreementNumber: agreementNumber,
      agreementId: agreementId,
      settings: {
        oktaApiToken,
        type: EmploymentPeriodicVerificationMethod.OKTA_API,
      },
    };
    console.log("variables: ", variables) // eslint-disable-line

    updateAgreementSettings({ variables })
      .then(() => {
        setSaveStatus({ error: undefined, pending: false, success: true });
      })
      .catch((error: ApolloError) => {
        const states: { [k:string]: string[] } = (error.graphQLErrors || [])
          .map((e) => (e as any).state as { [k: string]: string[] })
          .filter(Boolean)
          .reduce((result, s) => Object.assign(result, s), {});

        if (Object.keys(states).length) {
          setSaveStatus({ error: [JSON.stringify(states)], pending: false, success: false });
        } else {
          setSaveStatus({
            error: [error.message],
            pending: false,
            success: false,
          });
        }
      })
      .finally(() => setFormSubmitted(true));
  };

  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (hasChanges()) {
      save();
    }
  };

  return (
    <>
      <div className={ styleClass.settings.openidContainer }>
        <form className={ styleClass.settings.openidInputContainer } onSubmit={ submit }>
          <label className={ styleClass.settings.label}>
            Okta API token
            <InputWithValidator
              type={"password"}
              ariaLabel={ 'Okta API token' }
              value={ oktaApiToken }
              validator={ (e) => !!e && /\S+/.test(e) }
              forceError={!!saveStatus.error}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              placeholder="f.eks. abc-123"
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOktaApiToken(e.target.value) }
              errorMsg={ `Vennligst oppgi gyldig token${ saveStatus.error ? ` (${ saveStatus.error.join(", ") })` : '' }` }
              clearInput={ () => setOktaApiToken(isOktaApiSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaApiToken : "") }
            />
          </label>
          { showSaveButton && <Button ariaLabel={ saveButtonLabel } text={ saveButtonLabel }/> }
          { (!showSaveButton && saveStatus.success) && (
            <div className={styleClass.settings.message}>
              <MessageBox
                message={`Endringene er registrert.`}
                backgroundColor={`#BFE6D7`}
                arrow={Arrow.TOP_CENTER}
                icon={
                  <Icon
                    name={IconName.CHECK}
                    style={{color: 'rgb(1, 154, 2)'}}
                    className={`${Margin.MR_3}`}
                  />
                }
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EmploymentPeriodicVerificationSettingsOktaApi;
