import * as React from 'react';
import {
  BackgroundColor,
  BorderRadius,
  classNames,
  Display,
  Icon,
  IconColor,
  IconName,
  IconSize,
  LineHeight,
  Margin,
  Padding,
  Position,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  FlexDirection,
} from "@snoam/pinata";

interface ISpeechBubbleProps {
  heading: string | JSX.Element;
  body: string | JSX.Element;
  footer: string | JSX.Element;
  headerColor: string;
}

const styleClass = {
  root: classNames(
    Padding.PX_6,
    Padding.MD_PX_0,
    Display.FLEX,
    FlexDirection.FLEX_COL,
  ),
  containerbody: classNames(
    BackgroundColor.BG_WHITE,
    BorderRadius.ROUNDED_B_BRAND,
  ),
  arrow: classNames(
    Margin.MT_AUTO,
    Position.ABSOLUTE,
  ),
  header: (headerColor: string) => classNames(
    headerColor,
    Padding.PY_3,
    Padding.PX_4,
    FontSize.TEXT_BASE,
    TextAlign.TEXT_LEFT,
    TextColor.TEXT_WHITE,
    Width.W_FULL,
    BorderRadius.ROUNDED_T_BRAND
  ),
  body: classNames(
    Padding.P_4,
    TextColor.TEXT_BLACK,
    TextAlign.TEXT_LEFT,
    LineHeight.LEADING_NORMAL
  ),
  footer: classNames(
    Padding.P_4,
    TextColor.TEXT_BLACK,
    TextAlign.TEXT_CENTER,
    Position.RELATIVE
  )

};

const SpeechBubble = ({heading, body, footer, headerColor}: ISpeechBubbleProps) => {
  return (
    <div className={styleClass.root} style={{maxWidth: '450px'}}>
      <div className={styleClass.header(headerColor)}>
        {heading}
      </div>
      <div className={styleClass.containerbody}>
        <div className={styleClass.body}>
          {body}
        </div>
        <div className={styleClass.footer}>
          <div>
            {footer}
          </div>
          <Icon
            name={IconName.PLAY_ARROW}
            size={IconSize.XXLARGE}
            color={IconColor.PRIMARY_LIGHT}
            style={{bottom: '-1.745rem', left: '1rem'}}
            className={styleClass.arrow}
          />
        </div>
      </div>

    </div>
  );
};

export default SpeechBubble;
