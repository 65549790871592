import {PersistentModel} from "../../../models/PersistentModel";
import {IAddress} from "@snoam/mono-address";

type AgreementNumber = string | null;
type ProductGroup = string | null;
export type Ref1 = string | null;
export type Ref2 = string | null;
type Department = string | number | null;
export type Product = {
  id: string;
  siebelProductId: string;
  product: string;
  productGroup: ProductGroup;
  ref1: Ref1;
  ref2: Ref2;
  department: Department;
};
export type Products = Product[];
type Email = string | null;
export type Reference = {
  name: string;
  value: string;
  productId: string;
};

type OrderAddress = Partial<IAddress> | null;

export interface OrderSession {
  products: Product[];
  email: Email;
  address: OrderAddress;
  references: Reference[];
}

interface OrderReference {
  reference1: string;
  reference2: string;
  billingAgreementNumber: string | number | undefined;
}

export class OrderSessionModel extends PersistentModel<OrderSession> {

  constructor(o?: OrderSession, reset = false) {
    // @ts-ignore
    super({
      id: 'orderSessionModel',
      engine: window.sessionStorage
    }, o, reset);
  }

  get agreementNumber(): AgreementNumber {
    return this.get<AgreementNumber>('agreementNumber');
  }

  set agreementNumber(value: AgreementNumber) {
    value && this.set<AgreementNumber>('agreementNumber', value);
  }

  get products(): Array<Partial<Product> & Pick<Product, "id" | "productGroup" | "siebelProductId">> {
    return this.get<Product[]>('products') || [];
  }

  set products(value: Array<Partial<Product> & Pick<Product, "id" | "productGroup" | "siebelProductId">>) {

    if (!value) {
      return;
    }

    this.set<Partial<Product>[]>('products', Array.from(value));
  }

  getProductById(id: string): Partial<Product> | null {
    const products = this.products;
    const productIndex = products.findIndex(p => p.id === id);

    if (productIndex === -1) {
      return null;
    }

    return products[productIndex];
  }

  updateProductById(id: string, data: Partial<Product>) {
    const products = this.products;
    const productIndex = products.findIndex(p => p.id === id);

    if (productIndex === -1) {
      return;
    }

    const product = products[productIndex];

    products[productIndex] = {
      ...product,
      ...data,
    };

    this.set('products', products);
  }

  get email(): Email {
    return this.get<Email>('email');
  }

  set email(value: Email) {
    value && this.set<Email>('email', value);
  }

  get address(): OrderAddress {
    return this.get<OrderAddress>('address');
  }

  set address(value: OrderAddress) {
    value && this.set<OrderAddress>('address', value);
  }

  get references(): Partial<Reference>[] {
    return this.get<Reference[]>('references') || [];
  }

  referencesIndexByProductId(): { [k: string]: Partial<OrderReference> } {
    return this.products.reduce((o, p) => ({
      ...o,
      [p.siebelProductId || '']: {
        reference1: p.ref1 || '',
        reference2: p.ref2 || '',
        billingAgreementNumber: p.department || undefined
      }
    }), {} as { [k: string]: Partial<OrderReference> });
  }

  updateReference(ref: Partial<Reference>) {
    const refs = this.references;
    const refIndex = refs.findIndex(p => p.name === ref.name && p.productId === ref.productId);

    if (refIndex === -1) {
      refs.push(ref);
    } else {
      const originalRef = refIndex[refIndex];
      refs[refIndex] = {
        ...originalRef,
        ...ref,
      };
    }

    this.set('references', refs);
  }

  deleteReference(productId: string | undefined, refName: string) {
    const refs = this.references;
    const refToDeleteIndex = refs.findIndex(r => r.productId === productId && r.name === refName);

    if(refToDeleteIndex === -1) {
      return;
    }

    refs.splice(refToDeleteIndex, 1);

    this.set('references', refs);
  }

  set references(value: Partial<Reference>[]) {
    if (!value) {
      return;
    }

    this.set<Partial<Reference>[]>('references', Array.from(value));
  }
}
