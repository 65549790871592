import React, { ReactNode } from "react";
import {
  BorderColor,
  BorderWidth,
  Checkbox,
  classNames,
  Display,
  FlexDirection,
  Margin,
  Width,
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Eq} from "../../components/Filter/types";
import { EmploymentVerificationMethod } from "../../__generated__/globalTypes";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4,
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

export const EmployeeVerificationMethodFilter: React.FunctionComponent = () => {

  const humanReadableEmploymentVerificationMethods: { [key in EmploymentVerificationMethod]: string } = {
    [EmploymentVerificationMethod.EMAIL]: 'E-post',
    [EmploymentVerificationMethod.OPENID_CONNECT]: 'Bedriftens påloggingssystem',
  };

  return (
    <Filter
      heading={'Verifiseringsmetode'}
      id={'employeeVerificationMethod'}
      wrapper={customWrapper}
    >
      {
        Object.keys(EmploymentVerificationMethod).map((employmentVerificationMethod, i) => (
          <Eq
            id={`employeeVerificationMethodFilter_${employmentVerificationMethod}_${i}`}
            key={`employeeVerificationMethodFilter_${employmentVerificationMethod}_${i}`}
            name={'settings.employeeVerificationMethod'}
            value={EmploymentVerificationMethod[employmentVerificationMethod]}
          >
            {
              ({active, toggleFilter}) => {
                return (
                  <div className={styleClass.inner}>
                    <Checkbox
                      checked={!!active}
                      onClick={toggleFilter}
                      text={humanReadableEmploymentVerificationMethods[employmentVerificationMethod]}
                    />
                  </div>
                )
              }
            }
          </Eq>
        ))
      }
    </Filter>
  )
};

export default EmployeeVerificationMethodFilter;
