import * as React from "react";
import InputWithValidator, { emailValidator } from "../../../../components/InputWithValidator/InputWithValidator";
import {
  Arrow,
  Button,
  ButtonSkin,
  classNames,
  Cursor, FontSize,
  Margin,
  Position,
  Width
} from "@snoam/pinata";
import { FormEventHandler, useEffect, useState } from "react";
import { MessageBox, MessageBoxType } from "@snoam/mono-messagebox";
import { GetAgreement_me_agreementsWithDetails_settings } from "../../../../__generated__/GetAgreement";

interface AddDomainProps {
  addCallback: (domain: string) => void;
  addDomainButtonClassname: string;
  emailButtonClassname: string;
  agreementSettings: GetAgreement_me_agreementsWithDetails_settings;
}

const styleClass = {
  settings: {
    emailInputContainer: classNames(
      Position.RELATIVE,
      Cursor.CURSOR_POINTER,
      Width.W_FULL,
      Margin.MR_2,
      Margin.MB_4
    ),
    emailInput: classNames(
      FontSize.TEXT_BASE,
    ),
  },
};

const domainRegEx = /^([a-zA-Z0-9]([a-zA-Z0-9-]){0,61}[a-zA-Z0-9]\.){1,3}[A-Za-z]{2,63}$/;
export const domainIsValid = (domain: string) => {
  return domainRegEx.test(domain);
};

const AddDomain: React.FunctionComponent<AddDomainProps> = (props) => {

  const { addCallback, addDomainButtonClassname, emailButtonClassname, agreementSettings: settings } = props;
  const [newDomain, setNewDomain] = useState<string>("");
  const [domainMessage, setDomainMessage] = useState<JSX.Element | null>();

  useEffect(() => {
    if (domainIsValid(newDomain)) {
      setDomainMessage(null);
    }
  }, [newDomain]);

  const add: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    if (domainIsValid(newDomain)) {
      addCallback(newDomain);
    } else {
      setDomainMessage(
        <MessageBox
          message={`Ugyldig format.`}
          type={MessageBoxType.WARNING}
          arrow={Arrow.TOP_CENTER}
        />
      );
    }
  };

  return (
    <form onSubmit={add}>
      <div className={styleClass.settings.emailInputContainer}>
        <InputWithValidator
          ariaLabel={'Legg til domene'}
          value={newDomain}
          validator={emailValidator(void(0), domainRegEx)}
          required={settings.emailDomains.length <= 0}
          formSubmitted={settings.emailDomains.length === 0}
          className={styleClass.settings.emailInput}
          placeholder="f.eks. schibsted.no"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewDomain(e.target.value)}
          errorMsg={'Vennligst oppgi gyldig epost-domene'}
          clearInput={() => setNewDomain("")}
        />
      </div>
      <div className={addDomainButtonClassname}>
        <Button
          text={"Lagre e-postdomene"}
          ariaLabel={"Lagre e-postdomene"}
          skin={ButtonSkin.PRIMARY}
          className={emailButtonClassname}
          type={"submit"}
        />
        {domainMessage}
      </div>
    </form>
  );
};

export default AddDomain;
