  import {Button, ButtonSkin, classNames, Icon, IconName, Margin, MaxWidth, Width} from '@snoam/pinata';
import * as React from 'react';

const styleClass = {
  button: classNames(
    Width.W_FULL,
    MaxWidth.MAX_W_SM,
  ),
};

export interface ITerminateSubscriptionButtonProps {
  classNames?: string;
  onClick: () => void;
}

const TerminateSubscriptionButton = (props: ITerminateSubscriptionButtonProps) => {

  return (
    <div className={classNames(styleClass.button, props.classNames)}>
      <Button
      text={"Avslutt abonnement"}
      ariaLabel={"Avslutt abonnement"}
      skin={ButtonSkin.SECONDARY}
      className={classNames(Width.W_FULL, Margin.MY_0)}
      onClick={() => props.onClick()}
      iconLeft={
        <Icon name={IconName.CLOSE}/>
      }
      />
    </div>
  );
};

export default TerminateSubscriptionButton;
