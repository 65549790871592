import * as React from "react";
import {useContext} from "react";
import {
  classNames,
  Display,
  FontFamily,
  FontWeight,
  LineHeight,
  Overflow,
  Padding,
  StyledRouterLink,
  TextColor,
  FontSize,
  Whitespace,
  FlexDirection,
  TextDecoration,
  AlignItems,
  JustifyContent,
  LetterSpacing,
} from "@snoam/pinata";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";

const styleClass = {
  root: classNames(
    Padding.PR_3,
  ),
  inner: classNames(
    Display.INLINE_FLEX,
    FlexDirection.FLEX_ROW,
    TextDecoration.NO_UNDERLINE,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER
  ),
  label: ({isLink, disabled, invertFontColor, mobile}: { isLink: boolean, disabled?: boolean, invertFontColor?: boolean, mobile?: boolean }) => classNames(
    FontFamily.FONT_TITLE_2,
    FontWeight.FONT_SEMIBOLD,
    FontSize.TEXT_SM,
    LineHeight.LEADING_LOOSE,
    LetterSpacing.TRACKING_WIDE,
    TextColor.TEXT_NEUTRAL_7,
    TextColor.HOVER_TEXT_PRIMARY_1,
    {
      [Display.INLINE_BLOCK]: mobile,
      [Overflow.OVERFLOW_HIDDEN]: mobile,
      [Whitespace.TRUNCATE]: mobile,
      [TextColor.TEXT_NEUTRAL_6]: !invertFontColor && (!isLink && !disabled),
      [TextColor.TEXT_NEUTRAL_6]: !invertFontColor && disabled,
      [TextColor.TEXT_WHITE]: invertFontColor,
    }
  )
};

type LabelProps = { isLink: boolean, disabled?: boolean, text: string, invertFontColor?: boolean };
const Label = ({isLink, disabled, text, invertFontColor}: LabelProps) => {
  const {mobile} = useContext(ResponsiveContext);
  return (
    <span className={styleClass.label({isLink, disabled, invertFontColor, mobile})}
          style={Object.assign({}, mobile ? {maxWidth: '6.6rem'} : {})}>
    {text}
  </span>
  );
};



export interface IBreadCrumbMenuItemProps {
  href?: string;
  label: string;
  Seperator?: React.SFC<any> | null;
  showOnMobile?: boolean;
  disabled?: boolean;
  invertFontColor?: boolean
}

const BreadCrumbContent = ({label, href, Seperator, disabled, invertFontColor}: IBreadCrumbMenuItemProps) => {
  if (href) {
    return (
      <StyledRouterLink to={href} className={styleClass.inner}>
        <Label text={label} isLink={true} disabled={disabled} invertFontColor={invertFontColor}/>
        {Seperator && <Seperator/>}
      </StyledRouterLink>
    );
  }
  return (
    <div className={styleClass.inner}>
      <Label isLink={false} text={label} disabled={disabled} invertFontColor={invertFontColor}/>
      {Seperator && <Seperator/>}
    </div>
  );
};

export const BreadCrumbMenuItem = (props: IBreadCrumbMenuItemProps) => {
  return (
    <li className={styleClass.root}>
      <BreadCrumbContent {...props}/>
    </li>
  );
};

export default BreadCrumbMenuItem;
