import React, {ReactNode} from "react";
import {
  BorderColor,
  BorderWidth,
  Checkbox,
  classNames,
  Display,
  FlexDirection,
  Margin,
  Width
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Contains} from "../../components/Filter/types/Contains";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4,
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

export type IAgreementTypeFilterProps  = {
  agreementTypes: string[];
}

export const AgreementTypeFilter: React.FunctionComponent<IAgreementTypeFilterProps> = ({agreementTypes}) => {

  return (
    <Filter
      heading={'Avtaletype'}
      id={'agreementType'}
      wrapper={customWrapper}
      marshal={true}
    >
      {
        agreementTypes.map((agreementTypeName, i) => {
          return (
            <Contains
              id={`agreement_type_filter_${agreementTypeName}_${i}`}
              key={`agreement_type_filter_${agreementTypeName}_${i}`}
              name={'invoiceAccountType'}
              value={agreementTypeName}
            >
              {
                ({active, toggleFilter}) => {
                  return (
                    <div className={styleClass.inner}>
                      <Checkbox
                        checked={!!active}
                        onClick={toggleFilter}
                        text={agreementTypeName}
                      />
                    </div>
                  )
                }
              }
            </Contains>
          )
        })
      }
    </Filter>
  )
};

export default AgreementTypeFilter;
