import * as React from "react";
import {MouseEventHandler, useState} from "react";
import {
  AlignItems,
  BackgroundColor,
  BorderColor, BorderStyle, BorderWidth,
  classNames,
  Display,
  FlexDirection,
  FlexGrow,
  FlexShrink,
  FontSize,
  Height,
  Icon,
  IconColor,
  IconName,
  IconSize,
  JustifyContent,
  Padding,
  TextAlign,
  TextColor,
  TextDecoration,
  Width,
} from "@snoam/pinata";
import {generatePath, Link, useLocation, useParams} from "react-router-dom";
import ListeningRoute from "../ListeningRoute/ListeningRoute";
import {toGeneratePathParams} from "../../utils";


const styleClass = {
  root: ({expanded, active, hover, focus}: {expanded?: boolean, active?: boolean, hover?: boolean, focus: boolean}) => classNames(
    Padding.PY_3,
    Width.W_FULL,
    {
      // Focus
      [BackgroundColor.BG_PRIMARY_DARK]: focus,
      // Active
      [BackgroundColor.BG_NEUTRAL_3]: active,
      // not active
      [BorderStyle.BORDER_SOLID]: !active,
      [BorderWidth.BORDER_B_1]: !active,
      [BorderColor.BORDER_NEUTRAL_4]: !active
    },

  ),
  link: (expanded: boolean) => classNames(
    Display.FLEX,
    TextDecoration.NO_UNDERLINE,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER,
    {
      [FlexDirection.FLEX_COL]: !expanded,
      [FlexDirection.FLEX_ROW]: expanded,
      [Padding.PX_4]: expanded,
    }
  ),
  icon: {
    root: ({active, hover, focus}: {active?: boolean, hover?: boolean, focus: boolean}) => classNames(
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      FlexShrink.FLEX_SHRINK_0,
      FlexGrow.FLEX_GROW_0,
      JustifyContent.JUSTIFY_CENTER,
      FlexDirection.FLEX_COL,
      Padding.P_1,
    )
  },
  label: ({expanded, hover, active, focus}: {expanded?: boolean, hover?: boolean, active: boolean, focus: boolean}) => classNames(
    TextColor.TEXT_BLACK,
    Width.W_16,
    {
      // hover
      [TextColor.TEXT_PRIMARY_1]: hover && !focus,
      // active
      [TextColor.TEXT_BLACK]: active,
      //Expanded
      [Padding.PT_2]: !expanded,
      [Width.W_16]: !expanded,
      [TextAlign.TEXT_CENTER]: !expanded,
      [FontSize.TEXT_XS]: !expanded,
      [Padding.PL_3]: expanded,
      [FontSize.TEXT_BASE]: expanded,
      [Width.W_FULL]: expanded,
      [TextAlign.TEXT_LEFT]: expanded,
      // focus
      [TextColor.TEXT_WHITE]: focus
    }
  ),
  rect: ({active, hover}: {active?: boolean, hover?: boolean}) => classNames(
    Height.H_XS,
    Width.W_PX,
  )
};

export interface IMenuItemProps {
  icon: IconName;
  label: string;
  path: string;
  expanded?: boolean
  onClick?: MouseEventHandler<HTMLLIElement>;
}

export interface IMenuItemState {
  hover: boolean;
  focus: boolean;
}

const hoverIcon = (active: boolean, hover: boolean, focus: boolean): IconColor => {
  if (hover && !focus) {
    return IconColor.PRIMARY_1;
  }
  if (active && !hover) {
    return IconColor.NEUTRAL_6
  }
  if (focus) {
    return IconColor.PRIMARY_LIGHT
  }
  return IconColor.NEUTRAL_5
};

const Item: React.FC<IMenuItemProps> = (props) => {
  const location = useLocation();
  const params = useParams();
  const {icon, label, path, expanded, onClick} = props;
  const [focus, setFocus] = useState(false);
  const [hover, setHover] = useState(false);

  if (!path) {
    return null;
  }

  try {
    const generatedPath = generatePath(path, toGeneratePathParams(params));
    const active = location.pathname === generatedPath;
    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
      <li
        onClick={(evt) => onClick && onClick(evt)}
        className={styleClass.root({active, hover, expanded, focus})}
        onFocus={() => setFocus(true)}
        onBlur={() => setFocus(false)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Link to={generatedPath} className={styleClass.link(!!expanded)}>
          <div className={styleClass.icon.root({active, hover, focus})}>
            <Icon
              key={`${icon}`}
              name={icon}
              size={IconSize.MEDIUM}
              color={hoverIcon(active, hover, focus)}
            />
          </div>
          <div className={styleClass.label({expanded, hover, active, focus})}>{label}</div>
          {expanded &&
              <Icon name={IconName.CHEVRON_RIGHT} size={IconSize.LARGE} color={IconColor.PRIMARY_1}/>
          }
        </Link>
      </li>
    )
  } catch (e) {
    return null;
  }
};
Item.displayName = 'MenuItem_Item';

class MenuItem extends React.PureComponent<IMenuItemProps, IMenuItemState> {

  static displayName = 'MenuItem';

  static defaultProps = {
    expanded: false,
  };

  render() {
    return (
      <ListeningRoute element={(props: any) => <Item {...props} {...this.props} />}/>
    )
  }
}


export default MenuItem;
