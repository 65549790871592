import * as React from "react";
import {classNames, LineHeight, Link, Margin, Padding,} from "@snoam/pinata";
import AdminList from './AdminList';
import {GetAgreement_me_agreementsWithDetails_admins,} from '../../__generated__/GetAgreement';

const styleClass = {
  adminList: {
    helpTextWrapper: classNames(
      Padding.P_8,
      Padding.PT_6,
      LineHeight.LEADING_TIGHT,
    ),
    helpText: classNames(
      Margin.MB_4,
    ),
  },
};

interface AdminListProps {
  mobile: boolean | undefined;
  admins: GetAgreement_me_agreementsWithDetails_admins[];
  agreementNumber: number;
}

const AdminsPanel: React.FunctionComponent<AdminListProps> = ({mobile, admins, agreementNumber}) => (
  <React.Fragment>
    <AdminList
      mobile={mobile}
      admins={admins}
      agreementNumber={agreementNumber}
    />
    <div className={styleClass.adminList.helpTextWrapper}>
      <p className={styleClass.adminList.helpText}>
        Du kan legge til administratorer ved å velge fra <Link href={"abonnement"}>listen over abonnenter.</Link>
      </p>
      <p>Kontakt kundeservice for å legge til administrator uten abonnement.</p>
    </div>
  </React.Fragment>
);

export default AdminsPanel;
