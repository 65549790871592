import * as React from 'react';
import {useQuery} from '@apollo/client';
import {GetPolicy} from '../../__generated__/GetPolicy';
import {GET_POLICY} from '../../queries';
import {LoadingFragment} from '../LoadingPage';
import {
  BackgroundColor,
  classNames,
  FontWeight,
  Heading,
  HeadingLevel,
  Height,
  Margin,
  Padding,
  FontSize
} from '@snoam/pinata';

import parse, {DOMNode, Element, domToReact} from 'html-react-parser';
import {MinBedriftRouteComponentProps} from "../../routes/utils";

const styleClass = {
  root: classNames(
    'break-long-lines',
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_XL,
    BackgroundColor.BG_WHITE,
    Height.H_FULL,
    Padding.P_10,
  ),
  header: classNames(
    Margin.MB_8,
  ),
};

const DataPolicyAndSubscriptionTerms: React.FunctionComponent<MinBedriftRouteComponentProps> = () => {
  const { data, error, loading } = useQuery<GetPolicy>(GET_POLICY);

  if (loading) {
    return <LoadingFragment/>;
  }

  if (error) {
    throw error;
  }

  //@ts-ignore
  const {content, title} = data!.policy;

  function isElement(domNode: DOMNode): domNode is Element {
    return domNode.type === 'tag'
  }

  const parsedHeader = parse(title || '');
  const parsedContent = parse(content, {
    replace: (domNode => {
      if (isElement(domNode) && domNode.name === 'p') {
        return (
          <p className={Margin.MB_4}>
            {domToReact(domNode.children)}
          </p>
        );
      }
      return false;
    })
  });
  return (
    <div className={styleClass.root}>
      {parsedHeader && <Heading level={HeadingLevel.TWO} className={styleClass.header}>
        {parsedHeader}
      </Heading>}
      {parsedContent}
    </div>
  );
};

export default DataPolicyAndSubscriptionTerms;
