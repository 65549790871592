import * as React from 'react';
import {ReactNode} from 'react';
import {
  classNames,
  Display,
  FlexDirection,
  FontWeight,
  Heading,
  HeadingLevel,
  JustifyContent,
  Margin,
  Padding,
  TextAlign,
  Width
} from "@snoam/pinata";
import SvgImage from "../../components/SvgImage/SvgImage";

const styleclass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    JustifyContent.JUSTIFY_CENTER,
    Width.MD_W_1_2,
    Margin.MX_AUTO,
    TextAlign.TEXT_CENTER,
    Padding.P_4,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    Margin.MB_4
  ),
  button: classNames(
    Width.MD_W_1_2,
    Margin.MX_AUTO,
    Margin.MT_6,
  ),
};

export interface IErrorPage {
  heading: string;
  message: ReactNode;
}


export const ErrorPage: React.FunctionComponent<IErrorPage> = ({children, heading, message}) => {

  return (
    <div className={styleclass.root}>
      <SvgImage
        href={`${process.env.PUBLIC_URL}/svg/error.svg`}
        className={Width.W_FULL}
      />
      <Heading level={HeadingLevel.ONE} className={styleclass.heading}>
        {heading}
      </Heading>

      <>{message}</>

      <>{children}</>
    </div>
  )
};

export default ErrorPage;
