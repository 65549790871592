import * as React from "react";
import {classNames, Cursor, Icon, IconColor, IconName, Input, Margin, Position, FontSize, Width,} from "@snoam/pinata";
import {EventKey, onKeyPress} from '../../../../utils';

const styleClass = {
  settings: {
    emailInputContainer: classNames(
      Position.RELATIVE,
      Cursor.CURSOR_POINTER,
      Width.W_FULL,
      Margin.MR_2
    ),
    emailInput: classNames(
      FontSize.TEXT_BASE,
    ),
    emailInputIcon: classNames(
      Position.ABSOLUTE,
    ),
  }
};

interface DomainListProps {
  domains: string[];
  onDomainDelete: (index: number) => void;
}

const DomainList = ({ domains, onDomainDelete }: DomainListProps) => (
  <React.Fragment>
    {domains.map((emailDomain: string, index: number) => (
      <div key={`emaildomain-${index}`} className={styleClass.settings.emailInputContainer}>
        <Input className={styleClass.settings.emailInput} value={emailDomain} disabled aria-label={`Valgt domene: ${emailDomain}`} />
        <Icon
          tabIndex={0}
          name={IconName.CLOSE}
          className={styleClass.settings.emailInputIcon}
          color={IconColor.NEUTRAL_4}
          onClick={() => onDomainDelete(index)}
          onKeyPress={(e) => onKeyPress(e, function () { return onDomainDelete(index); }, EventKey.ENTER)}
          style={{ bottom: '2.3rem', right: '1rem' }}
          screenReaderText={'Fjern domene'}
        />
      </div>
    ))}
  </React.Fragment>
);

export default DomainList;
