import { Arrow, Dropdown, IDropdownProps } from '@snoam/pinata';
import { GetAgreement_me_agreementsWithDetails } from '../../__generated__/GetAgreement';
import { numberOfProducts } from '../../configuration/agreements';
import * as React from 'react';
import { ReactNode, useState } from 'react';
import { MutationFunction, useMutation } from '@apollo/client';
import {UPDATE_AGREEMENT} from '../../mutations';
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';
import {UpdateAgreement, UpdateAgreementVariables} from "../../__generated__/UpdateAgreement";

interface SelectMaxSubscriptionsPerEmployeeProps extends Omit<IDropdownProps, 'defaultTriggerText'> {
  agreement: Pick<GetAgreement_me_agreementsWithDetails, 'agreementNumber' | 'id' | 'maxSubscriptionsPerEmployee' | 'settings'>;
}
interface SelectMaxSubscriptionsPerEmployeeState {
  message: ReactNode;
}
const SelectMaxSubscriptionsPerEmployee = ({ agreement }: SelectMaxSubscriptionsPerEmployeeProps) => {

  const updateAgreement: MutationFunction<UpdateAgreement, UpdateAgreementVariables> = useMutation<UpdateAgreement, UpdateAgreementVariables>(UPDATE_AGREEMENT)[0];
  const maxSubscriptionsPerEmployee = agreement.maxSubscriptionsPerEmployee;
  const activeNumberOfProducts = numberOfProducts().find(n => n.value === `${maxSubscriptionsPerEmployee}`);
  const defaultTriggerText: string = activeNumberOfProducts ? activeNumberOfProducts.name : 'Velg antall';

  const [{
    message,
  }, setState] = useState<SelectMaxSubscriptionsPerEmployeeState>({
    message: undefined,
  });
  const onChangeItem = (value: string) => {
    const updateAgreementVariables: UpdateAgreementVariables = {
      agreementNumber: agreement.agreementNumber,
      agreementId: agreement.id,
      maxSubscriptionsPerEmployee: +value,
      allowNewSubscriptions: 'Y'
    };

    const updateAgreementResponse = () => updateAgreement({variables: updateAgreementVariables});
    updateMessage(
      <MessageBox
        message={`Lagrer...`}
        type={MessageBoxType.WARNING}
        arrow={Arrow.TOP_CENTER}
      />
    );

    updateAgreementResponse().then((data) => {
      updateMessage(
        <MessageBox
          message={`Oppdatert til ${+value === 0 ? 'ubegrenset' : (+value >1 ? value + ' produkter' : value + ' produkt')}`}
          type={MessageBoxType.INFO}
          arrow={Arrow.TOP_CENTER}
        />
      );
    }).catch((error) => {
      updateMessage(
        <MessageBox
          message={error.toString()}
          type={MessageBoxType.ERROR}
          arrow={Arrow.TOP_CENTER}
        />
      )
    })
  }

  const updateMessage = (message: JSX.Element) => {
    setState({ message })
    setTimeout(() => {
      setState({
        message: null
      });
    }, 5000)
  }

  return (
    <>
      <Dropdown
        defaultTriggerText={defaultTriggerText}
        data={numberOfProducts()}
        onChange={onChangeItem}
        hideLabel={true}
      />
      {message}
    </>
  );
};

export default SelectMaxSubscriptionsPerEmployee;
