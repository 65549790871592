import {PersistentModel} from "../../../models/PersistentModel";

interface SubscriptionSettings {
  department: string;
}

let singleton: SubscriptionSettingsModel | null = null;

type PersistedDepartment = { department: string, agreementNumber: string };

export class SubscriptionSettingsModel extends PersistentModel<SubscriptionSettings> {

  static instance(): SubscriptionSettingsModel {
    if (!singleton) {
      singleton = new SubscriptionSettingsModel();
    }
    return singleton;
  }

  constructor(o?: SubscriptionSettings, reset = false) {
    super({
      id: 'subscriptionSettings',
      engine: window.localStorage
    }, o, reset);
  }

  getDepartment(agreementNumber: string | number): string | null {
    let departments = this.get<{ department: string, agreementNumber: string }[]>('department') || [];

    if(!Array.isArray(departments)) {
      departments = [];
    }

    // eslint-disable-next-line eqeqeq
    const currentDepartment = departments.find((dep) => dep.agreementNumber == agreementNumber);
    return currentDepartment ? currentDepartment.department : null;
  }

  setDepartment(agreementNumber: string | number, value: string) {

    let departments = this.get<PersistedDepartment[]>('department') || [];

    if(!Array.isArray(departments)) {
      departments = [];
    }

    // eslint-disable-next-line eqeqeq
    const departmentExists = departments.findIndex((dep) => dep.agreementNumber == agreementNumber);
    if(departmentExists > -1) {
      departments.splice(departmentExists);
    }

    departments.push({
      agreementNumber: agreementNumber.toString(),
      department: value
    });

    this.set<PersistedDepartment[]>('department', departments);
  }


}
