import { IToggleProps, Toggle } from '@snoam/pinata';
import { useState } from 'react';
import { GraphQLError } from 'graphql';
import * as React from 'react';

const ToggleWithPotentialError = (props: IToggleProps) => {
  const [error, setError] = useState<ReadonlyArray<GraphQLError> | Error | null>(null);
  const asyncAction = props.asyncAction ? () => {
    return props.asyncAction!().catch((error) => {
      setError(error);
      return Promise.reject(error);
    })
  } : undefined;

  return (
    <>
      <Toggle
        {...props}
        asyncAction={asyncAction}
      />
      {error ? (Array.isArray(error) ? error : [error]).map(e => <span>Feil: {e.toString()}</span>) : null}
    </>
  );
};

export default ToggleWithPotentialError;
