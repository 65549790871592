import * as React from "react";
import {
  BackgroundColor,
  BorderRadius,
  classNames,
  Display,
  Height,
  Margin,
  TextColor,
  FontSize,
  Width,
  Grid,
  GridItem,
  IconName,
  Icon, JustifyContent, AlignItems, FlexDirection, FlexShrink, Flex, Link, IconSize, IconColor, Padding,
} from "@snoam/pinata";
import {GetSuperAdmins_superAdmins} from '../../__generated__/GetSuperAdmins';
import AdminModel from "../../models/AdminModel";
import EditAdministrationRoleModal from "../../components/EditAdministrationRoleModal/EditAdministrationRoleModal";
import {useContext} from "react";
import {ModalContext} from "@snoam/mono-modal";

export interface IAdministrationRowProps {
  user: GetSuperAdmins_superAdmins;
  isMobile: boolean;
}

const styleClass = {
  administration: {
    grid: classNames(
      Display.FLEX,
      JustifyContent.JUSTIFY_BETWEEN,
      Width.W_FULL,
      AlignItems.ITEMS_CENTER,
    ),
    gridItemFirst: {
      root: classNames(
        Display.FLEX,
        FlexDirection.FLEX_COL,
        FlexDirection.MD_FLEX_ROW,
      ),
      svgSuperAdmin: {
        wrapper: classNames(
          Display.FLEX,
          JustifyContent.JUSTIFY_CENTER,
          AlignItems.ITEMS_CENTER,
          BackgroundColor.BG_PRIMARY_3,
          BorderRadius.ROUNDED_FULL,
          Height.H_8,
          Width.W_8
        ),
        root: classNames(
          Margin.MY_1,
          Margin.MX_1,
          TextColor.TEXT_WHITE,
          FlexShrink.FLEX_SHRINK_0,
          Height.H_4,
        )
      }
    },
  },
};

const AdministrationRow = ({isMobile, user}: IAdministrationRowProps) => {
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const toggleModal = () => openModalId ? closeModal() : openModal(user.id);

  return (
    <Grid
      key={user.id}
      className={styleClass.administration.grid}
    >
      <GridItem
        className={styleClass.administration.gridItemFirst.root}
        style={{flex: 2}}
      >
        <div className={classNames(Display.FLEX, AlignItems.ITEMS_START, AlignItems.MD_ITEMS_CENTER, Flex.FLEX_1)}>
          <div
            className={styleClass.administration.gridItemFirst.svgSuperAdmin.wrapper}>
            <Icon
              name={IconName.BEDRIFT_SCHIBSTEDADMIN}
              className={styleClass.administration.gridItemFirst.svgSuperAdmin.root}
            />
          </div>
          <p className={classNames(Margin.ML_2, FontSize.TEXT_LG)}>
            {(user.name || {formatted: ''}).formatted || `Fant ikke navn (id: ${user.id}, SPiD: ${user.spid})`}
          </p>
        </div>
        <div className={classNames(Display.FLEX, Flex.FLEX_1, AlignItems.ITEMS_CENTER)}>
          <p className={classNames(Margin.MT_NEGATIVE_1, TextColor.TEXT_NEUTRAL_6)}
             style={isMobile ? {marginLeft: '2.5rem'} : {}}>
            {user.email}
          </p>
        </div>
        <div className={classNames(Display.FLEX, Flex.FLEX_1, AlignItems.ITEMS_CENTER)}>
          <Link role={"button"} onClick={toggleModal} className={classNames(Display.FLEX)}>
            <Icon
              size={IconSize.SMALL}
              name={IconName.EDIT_OUTLINE}
              color={IconColor.PRIMARY_1}
            />
            <p className={classNames(Margin.MT_NEGATIVE_1, TextColor.TEXT_NEUTRAL_6, Padding.PL_2)}
               style={isMobile ? {marginLeft: '2.5rem'} : {}}
            >
              {AdminModel.SchibstedAdministrationRoles.find(m => m.id === user.adminType)!.label}
            </p>
          </Link>
        </div>
      </GridItem>
      <EditAdministrationRoleModal
        id={user.id}
        userId={user.spid}
        currentRole={AdminModel.SchibstedAdministrationRoles.find(m => m.id === user.adminType)!}
        roles={AdminModel.SchibstedAdministrationRoles}
      />
    </Grid>
  )
};

export default AdministrationRow;
