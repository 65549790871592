import React, {useContext} from "react";
import {classNames, Display, JustifyContent, Width,} from "@snoam/pinata";
import Column from "../Column/Column";
import Header from "../Header/Header";
import MobileHeader from "../Header/MobileHeader";
import {DesktopHeaderHeight, DesktopSideBarWidth, MobileHeaderHeight} from "../../Const";
import Sidebar from "../Sidebar/Sidebar";
import Main from "../Main/Main";
import {getHeaderHeights} from "../../utils";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";

export interface IDegreeNavigationProps {
  headerHeight?: string;
  hideSidebar: boolean;
  pageClassName?: string;
  wrapperClassName?: string;
  backgroundImage?: string;
}

const styleClass = {
  column: {
    root: classNames(
      Width.W_FULL
    ),
    header: classNames(
      Width.W_FULL
    ),
    main: classNames(
      Display.FLEX,
      JustifyContent.JUSTIFY_START
    )
  },
};

const DegreeNavigation: React.FunctionComponent<IDegreeNavigationProps> = ({children, hideSidebar = false, wrapperClassName}) => {

  const {mobile, tablet, portrait} = useContext(ResponsiveContext);

  const getHeaderHeight = () => {
    if (mobile || (tablet && portrait)) {
      return MobileHeaderHeight;
    }
    return DesktopHeaderHeight;
  };
  const renderHeader = () => {
    if (mobile || tablet && portrait) {
      return <MobileHeader/>
    }
    return <Header noCrumbs={hideSidebar}/>
  };

  const headerHeight = getHeaderHeight();
  const isFullScreenEnvelope = mobile || (tablet && portrait);

  const [mobileHeaderHeight, desktopHeaderHeight] = getHeaderHeights();

  return (

    <Column
      shrinkable={true}
      className={styleClass.column.root}
    >
      <Column.Header
        inline={true}
        className={styleClass.column.header}
      >
        {renderHeader()}
      </Column.Header>

      <div className={styleClass.column.main}>

        <Sidebar
          hideSidebar={hideSidebar}
          headerHeight={headerHeight}
        />

        <Main
          wrapperClassName={wrapperClassName}
          style={{
            width: `calc(100% - ${hideSidebar || isFullScreenEnvelope ? '0px' : DesktopSideBarWidth})`,
            height: `calc(100vh - ${isFullScreenEnvelope ? mobileHeaderHeight : desktopHeaderHeight})`
          }}
        >
          {children}
        </Main>

      </div>

    </Column>
  )
};

export default DegreeNavigation;
