import React, {ChangeEvent, useContext, useEffect, useState} from "react";
import {
  classNames,
  Display,
  FontWeight,
  Grid,
  GridItem,
  Heading,
  HeadingLevel,
  Icon,
  IconName,
  Margin,
  Padding,
  TextAlign,
  FontSize,
  AlignItems,
  FlexShrink,
  JustifyContent,
  GridSpan,
} from "@snoam/pinata";
import AgreementsTable from '../../components/AgreementsTable/AgreementsTable';
import {Navigate, Route, Routes} from "react-router";
import {TableControls} from "../../components/EmployeesAndSubscriptionsTable/EmployeesTable";
import FilterEnvelopeContainer from "../../components/Filter/FilterEnvelopeContainer";
import {useFilterFactory} from "../../context/FilterContext/FilterContext";
import {useQuery} from '@apollo/client';
import Sort from "../../components/Filter/Sort";
import SearchButton from "../../components/EmployeesAndSubscriptionsTable/SearchButton";
import FilterButton from "../../components/EmployeesAndSubscriptionsTable/FilterButton";
import {OnlyAdminFilter} from "./OnlyAdminFilter";
import {
  GetAgreements,
  GetAgreements_me_agreements_edges,
  GetAgreementsVariables
} from "../../__generated__/GetAgreements";
import {GET_AGREEMENTS} from '../../queries';
import {Contains} from "../../components/Filter/types";
import {OnlyActiveAgreementsFilter} from "./OnlyActiveAgreementsFilter";
import {OnlyActiveSubscriptionsFilter} from "./OnlyActiveSubscriptionsFilter";
import Filter from "../../components/Filter/Filter";
import AgreementTypeFilter from "./AgreementTypeFilter";
import {InvoiceAccountType} from "../../__generated__/globalTypes";
import BillingTypeFilter from "./BillingTypeFilter";
import {EmptyAgreements} from "./EmptyAgreements";
import {BillingTypes} from "../../configuration/agreements";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {useRole} from "../../components/Boundary/RoleBoundary";
import {OnlyManuelInvoiceAgreementsFilter} from "./OnlyManuelInvoiceAgreementsFilter";
import EmployeeVerificationMethodFilter from "./EmployeeVerificationMethodFilter";
import EmployeePeriodicVerificationMethodFilter from "./EmployeePeriodicVerificationMethodFilter";
import ActivationDegreeFilter from "./ActivationDegreeFilter";
import NumberOfSubscriptionsFilter from "./NumberOfSubscriptionsFilter";
import {isSuperOrObserver} from "../../utils";

const styleClass = {
  root: classNames(
    Margin.M_8,
  ),
  tableControls: classNames(
    Margin.MB_2,
    AlignItems.ITEMS_CENTER,
    FlexShrink.FLEX_SHRINK_0,
    Padding.PL_0,
  ),
  heading: (loading: boolean) => classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_2XL,
    Display.INLINE_FLEX,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER,
    Margin.ML_3,
    Margin.MD_ML_0,
    Margin.MB_3,
    Margin.MD_MB_0,
    {
      'loading': loading
    }
  ),

};


const Agreements: React.FunctionComponent<{}> = () => {

  const {mobile, tablet, portrait} = useContext(ResponsiveContext);
  const {applyFilter, getParams, filterContextState} = useFilterFactory();
  const [agreements, setAgreements] = useState<GetAgreements_me_agreements_edges[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const {role: userRole} = useRole();
  const queryParams = getParams();
  const {
    data,
    error,
    loading
  } = useQuery<GetAgreements, GetAgreementsVariables>(GET_AGREEMENTS, {
    variables: {
      filter: queryParams
    }
  });

  if (error) {
    throw error;
  }

  useEffect(() => {
    if (data && data.hasOwnProperty('me')) {

      const {
        me: {
          agreements: {
            edges: agreements,
            totalCount: numAgreements
          }
        }
      } = data;

      applyFilter<GetAgreements_me_agreements_edges[]>(agreements)
        .then(agreements => {
          setAgreements(agreements);
        });

      setTotalCount(numAgreements);
      setInitialLoad(false);
    }
  }, [data, loading, filterContextState]);

  if ((userRole && !isSuperOrObserver(userRole)) && !loading && totalCount === 1 && agreements && agreements.length === 1) {
    const redirectTo = `/admin/avtaler/${agreements[0].agreementNumber}/abonnement`;
    return (
      <Routes>
        <Route path={''} element={<Navigate to={redirectTo} />}/>
      </Routes>
    );
  }

  const pageHeading = `Avtaler`;

  let filterHeader = 'Filtrer listen';
  if (mobile || (tablet && portrait)) {
    filterHeader = `Filtrer ${pageHeading.toLowerCase()} (${agreements.length})`;
  }

  const filterInvoiceAccountTypes = (): string[] => {
    return [
      InvoiceAccountType.Bedrift,
      InvoiceAccountType.Gratist,
      InvoiceAccountType.Intern,
      InvoiceAccountType.Konsern
    ];
  };

  return (
    <>
      <FilterEnvelopeContainer header={filterHeader}>
        <Sort>
          <Sort.Ascending name={'accountName'} checked={true}/>
          <Sort.Descending name={'accountName'}/>
        </Sort>

        <OnlyActiveSubscriptionsFilter/>

        <OnlyActiveAgreementsFilter/>

        <AgreementTypeFilter agreementTypes={filterInvoiceAccountTypes()}/>

        <BillingTypeFilter billingTypes={BillingTypes}/>

        <NumberOfSubscriptionsFilter />

        <ActivationDegreeFilter />

        <OnlyAdminFilter/>

        <OnlyManuelInvoiceAgreementsFilter/>

        <EmployeeVerificationMethodFilter/>

        <EmployeePeriodicVerificationMethodFilter/>

      </FilterEnvelopeContainer>

      <TableControls>
        <Heading level={HeadingLevel.ONE} className={styleClass.heading(initialLoad || loading)}>
          <span className={Padding.PR_2}>{pageHeading}</span>
          {initialLoad || loading ? <Icon name={IconName.LOADING_WHEEL} screenReaderText={'Ikon som viser at informasjon lastes'}/> : ` (${agreements.length})`}
        </Heading>
      </TableControls>

      <Grid className={styleClass.tableControls}>
        <GridItem
          span={[GridSpan.XSMALL_12, GridSpan.SMALL_3]}
          className={classNames(Padding.XS_PR_0, Padding.SM_PR_1)}
        >
          <FilterButton disabled={initialLoad || agreements.length === 0}/>
        </GridItem>
        <GridItem span={[GridSpan.XSMALL_12, GridSpan.SMALL_9]} className={Padding.PR_0}>
          <Filter id={'freeTextSearch'}>
            <Contains
              id={'freeTextSearch_0'}
              name={[
              'accountName',
              'agreementNumber',
              'products.productVariation',
              'admins.name.formatted',
              'admins.email',
            ]}>
              {
                ({filterValue}) => (
                  <SearchButton
                    disabled={initialLoad}
                    onSearch={(evt: ChangeEvent<HTMLInputElement>) => filterValue(evt.target.value)}
                    placeholder={'Søk i avtaler (bedriftsnavn, avtalenr, produktnavn og admin)'}
                  />
                )
              }
            </Contains>
          </Filter>
        </GridItem>
      </Grid>

      <AgreementsTable
        data={agreements as GetAgreements_me_agreements_edges[]}
        loading={loading}
      />

      {totalCount === 0 && agreements.length === 0 && !loading ? <EmptyAgreements/> : null}
      {totalCount > 0 && !loading && agreements.length === 0 ?
        <p className={TextAlign.TEXT_CENTER}>Filteret ga ingen resultater</p> : null}
    </>
  );
};

export default Agreements;
