import React from "react";
import {BaseFilter, BaseFilterProps} from "./BaseFilter";
import {FilterType} from "../utils";

type LengthFilterProps = Omit<BaseFilterProps, 'type'> & { variant: "gt" | "lt" | "eq" };

export const Length: React.FunctionComponent<LengthFilterProps> = (props) => {
  const {variant} = props;
  const filterType = variant === "gt" ? FilterType.LENGTH_GT : variant === "lt" ? FilterType.LENGTH_LT : FilterType.LENGTH_EQ;
  return (
    <BaseFilter
      {...props}
      type={filterType}/>
  )
};
