import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  FontWeight,
  LineHeight,
  Padding,
  RadioButton,
  RadioButtonGroup,
  TextColor,
  FontSize,
  FlexDirection
} from "@snoam/pinata";
import * as React from "react";
import {GetAgreementProducts_me_agreementsWithDetails_products} from "../../../__generated__/GetAgreementProducts";
import { Product } from "../models/OrderSessionModel";

const styleClass = {
  radioWrapper: classNames(
    BorderWidth.BORDER_T_1,
    BorderStyle.BORDER_DASHED,
    BorderColor.BORDER_NEUTRAL_4,
    FontSize.TEXT_BASE,
    FontWeight.FONT_SEMIBOLD,
    BackgroundColor.BG_WHITE,
  ),
  radioButton: classNames(
    FlexDirection.FLEX_COL,
    FontWeight.FONT_SEMIBOLD,
    FontSize.TEXT_BASE,
  ),
  infoText: classNames(
    BackgroundColor.BG_NEUTRAL_1,
    Padding.P_4,
    FontWeight.FONT_NORMAL,
    LineHeight.LEADING_NORMAL,
    FontSize.TEXT_BASE,
    TextColor.TEXT_NEUTRAL_6,
  ),
};

interface IProductSelector {
  products: GetAgreementProducts_me_agreementsWithDetails_products[];
  chosenProductVariant: string;
  setProductVariation: (productVariant: Pick<Product, "id" | "productGroup" | "siebelProductId">) => void;
}

export const ProductSelector = ({chosenProductVariant, products, setProductVariation}: IProductSelector) => {
  return (
    <RadioButtonGroup name={'product-selector'} value={chosenProductVariant}>
      {
        products.map((product: GetAgreementProducts_me_agreementsWithDetails_products, i: number) => {
          return (
            <div
              className={styleClass.radioWrapper}
              key={`radio-button-${i}`}
            >
              <RadioButton
                // skin={RadioButton.Skin.PRIMARY_3}
                className={styleClass.radioButton}
                text={product.productVariation}
                onClick={() => setProductVariation(product)}
                value={product.id}
              />

            </div>
          )
        })
      }
    </RadioButtonGroup>
  )
};
