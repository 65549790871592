import * as React from 'react';
import RoleBoundary, {RoleBoundaryState} from "./RoleBoundary";
import {LoadingFragment} from "../../pages/LoadingPage";
import {AdminType} from "../../__generated__/globalTypes";
import {MinBedriftRouteComponentProps} from "../../routes/utils";

const AccessBoundary: React.FunctionComponent<{ role: AdminType | AdminType[] }> = ({children, role, ...otherProps}) => (
  <RoleBoundary
    {...otherProps as MinBedriftRouteComponentProps}
    role={role}
  >
    {
      ({roleBoundaryState}) => {
        switch (roleBoundaryState) {
          case RoleBoundaryState.HAS_ACCESS:
            return children;
          case RoleBoundaryState.LOADING:
            return <LoadingFragment/>;
          default:
            return null;
        }
      }
    }
  </RoleBoundary>
);


export default AccessBoundary;
