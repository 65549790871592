import React, {useContext} from "react";
import {GetAgreements_me_agreements_edges} from "../../__generated__/GetAgreements";
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderWidth,
  classNames,
  Display,
  FlexDirection,
  FlexShrink,
  FontFamily,
  FontWeight,
  Grid,
  GridComposition,
  GridGutter,
  GridItem,
  Height,
  JustifyContent,
  LineHeight,
  Margin,
  MinHeight,
  Padding,
  StyledRouterLink,
  TextTransform,
  Width,
} from "@snoam/pinata";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {useRole} from "../Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";

const styleClass = {
  grid: classNames(
    AlignItems.ITEMS_CENTER,
    BackgroundColor.BG_NEUTRAL_1,
    BorderColor.BORDER_NEUTRAL_3,
    BorderWidth.BORDER_T_1,
    FlexDirection.FLEX_COL,
    FlexDirection.MD_FLEX_ROW,
  ),
  subHeading: classNames(
    TextTransform.UPPERCASE,
    FontWeight.FONT_SEMIBOLD,
    FontFamily.FONT_BODY
  ),
  gridItemOne: classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_BETWEEN,
    JustifyContent.MD_JUSTIFY_START,
    AlignItems.MD_ITEMS_CENTER,
    Padding.P_4,
    BorderColor.BORDER_NEUTRAL_3
  ),
  gridItemTwo: classNames(
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER,
    Width.W_FULL,
    Display.FLEX,
    Padding.PX_4,
    Margin.MX_AUTO,
    Height.H_FULL,
    FlexShrink.FLEX_SHRINK,
  ),
  gridItemThree: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    Padding.MD_PR_4,
    Padding.PX_4,
    JustifyContent.MD_JUSTIFY_END,
    JustifyContent.JUSTIFY_BETWEEN,
    FlexDirection.MD_FLEX_ROW,
    LineHeight.LEADING_HEADLINE3,
  ),
  activationDegreeWrapper: classNames(
    Height.H_4,
    Padding.PR_2,
    Width.W_FULL,
  ),
  activationDegree: classNames(
    BackgroundColor.BG_PRIMARY_1,
    Display.INLINE_BLOCK,
    MinHeight.MIN_H_FULL,
  ),
  activationDegreeFiller: classNames(
    BackgroundColor.BG_NEUTRAL_4,
    Display.INLINE_BLOCK,
    MinHeight.MIN_H_FULL,
  ),
};

export interface Props {
  agreement: Pick<GetAgreements_me_agreements_edges, "activationDegree" | "agreementNumber" | "agreementSubscriberCount" | "settings">;
  className?: string;
  linkTo: 'settings' | 'inviteEmployees'
}

const AgreementActivationPotential: React.FC<Props> = ({agreement, className, linkTo}) => {
  const {mobile} = useContext(ResponsiveContext);
  const activationScoreActivated = agreement.settings.showActivationDegreeForCompanyAdmin || false;
  const {role: userRole} = useRole();
  const link = linkTo === 'settings' ? (
    <StyledRouterLink
      to={`/admin/avtaler/${agreement.agreementNumber}/avtale?tab=settings`}
      style={{outline: 'none'}}
    >
      Innstillinger
    </StyledRouterLink>
  ) : (
    <StyledRouterLink
      to={`/admin/avtaler/${agreement.agreementNumber}/inviter`}
      style={{outline: 'none'}}
    >
      Inviter ansatte
    </StyledRouterLink>
  );

  const activationScore = () => {
    if(agreement.activationDegree === null) {
      return null;
    }


    return (
      <Grid
        composition={mobile ? GridComposition.DEFAULT : GridComposition.ALPHA}
        gutter={[GridGutter.TOP_NONE, GridGutter.LEFT_NONE, GridGutter.RIGHT_NONE]}
        className={classNames(className || "", styleClass.grid)}
      >
        <GridItem>
          <div className={classNames(styleClass.gridItemOne, styleClass.subHeading, Padding.LG_P_8, Padding.PL_8, Padding.PR_8)}>
            AKTIVERINGSGRAD
          </div>
        </GridItem>
        <GridItem>
          <div className={classNames(Display.FLEX, Padding.LG_P_8, Padding.PL_8, Padding.PR_8)}>
            <div className={styleClass.activationDegreeWrapper}>
                      <span className={styleClass.activationDegree} style={{
                        minWidth: `${Math.min(agreement.activationDegree, 100)}%`,
                      }}/>
              <span className={styleClass.activationDegreeFiller} style={{
                minWidth: `${100 - Math.min(agreement.activationDegree, 100)}%`,
              }}/>
            </div>
            <b>{Math.round((agreement.activationDegree + Number.EPSILON) * 100) / 100}% </b>
          </div>
        </GridItem>
        <GridItem>
          <div className={classNames(Display.FLEX, JustifyContent.JUSTIFY_BETWEEN, Padding.LG_P_8, Padding.PL_8, Padding.PR_8)}>
            <span>{agreement.agreementSubscriberCount} av {agreement.settings.activationPotential} har registrert seg</span>
            {link}
          </div>
        </GridItem>
      </Grid>
    )
  }

  if((userRole === AdminType.ADMIN && activationScoreActivated) || userRole === AdminType.SUPERADMIN) {
    return activationScore();
  }
  return null;
}

export default AgreementActivationPotential;
