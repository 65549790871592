import React, {useContext} from "react";
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Container,
  Display,
  Dropdown,
  FlexDirection,
  JustifyContent,
  Margin,
  Padding,
  Width,
} from "@snoam/pinata";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";

interface DepartmentSelectorProps {
  departments: any[] | null
  setActiveDepartment: (id: string) => void;
  value: string;
}

const styleClass = {
  root: classNames(
    BackgroundColor.BG_NEUTRAL_1,
    Width.W_FULL,
    Padding.PY_4
  ),
  inner: (isMobile: boolean) => classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_START,
    Padding.PX_8,
    Width.MD_W_AUTO,
    Width.XS_W_FULL,
    Margin.MX_AUTO,
    AlignItems.ITEMS_CENTER,
    {
      [FlexDirection.FLEX_COL]: isMobile
    }
  ),
  label: (isMobile: boolean) => classNames({
    [Display.HIDDEN]: isMobile
  }),
  dropDown: classNames(
    Display.INLINE_FLEX,
    Width.MD_W_1_2,
    Width.XS_W_FULL,
    Padding.PL_4,
  )
};

export const DepartmentSelector: React.FunctionComponent<DepartmentSelectorProps> = ({departments, setActiveDepartment, value}) => {

  const {mobile, tablet, portrait} = useContext(ResponsiveContext);

  if (!departments || departments.length === 0) {
    return null;
  }

  const data = departments.map(dep => ({
    name: dep.name,
    value: dep.id
  })).sort((a, b) => a.name < b.name ? -1 : 1);

  const isMobile: boolean = (mobile || tablet && portrait);

  return (
    <Container className={styleClass.root}>
      <div className={styleClass.inner(isMobile)}>
        <span className={styleClass.label(isMobile)}>Velg avdeling</span>
        <Dropdown
          onChange={setActiveDepartment}
          data={data}
          value={value}
          className={styleClass.dropDown}
          defaultTriggerText={'Velg avdeling'}
          hideLabel={true}
        />
      </div>
    </Container>
  )
};

export default DepartmentSelector;
