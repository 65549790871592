import React, { ChangeEvent } from "react";
import {
  classNames,
  Input,
  Display,
  Padding,
  IconName,
  Icon,
  Margin,
  Cursor,
  IconColor,
  FlexDirection,
  AlignItems,
} from "@snoam/pinata";

const styleClass = {
  inputContainer: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    Padding.PB_4
  )
};

interface NewReferenceProps {
  index: number;
  reference: string;
  onInputChanged: (event: ChangeEvent<HTMLInputElement>, key: number) => void;
  onListItemRemoved: (key: number) => void;
  selectedReference: string | null;
}

const NewReference = ({ index, reference, onInputChanged, onListItemRemoved, selectedReference }: NewReferenceProps) => (
  <div className={styleClass.inputContainer}>
    <Input
      value={reference}
      onChange={(e) => onInputChanged(e, index)}
      className={Padding.PB_0}
      placeholder={`Skriv inn navn på ${selectedReference}`}
      aria-label={`Skriv inn navn på ${selectedReference}`}
    />
    <Icon
      name={IconName.DELETE}
      className={classNames(Margin.ML_4, Cursor.CURSOR_POINTER)}
      color={IconColor.NEUTRAL_4}
      onClick={() => onListItemRemoved(index)}
      screenReaderText={'Søppelkasse-ikon - klikk for å slette referanse'}
    />
  </div>
);

export default NewReference;
