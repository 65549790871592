import * as React from "react";
import {useContext} from "react";
import {BackgroundContext} from "@snoam/mono-background-loader";
import DegreeNavigation from "../../components/DegreeNavigation/DegreeNavigation";

export const OrderNavigation: React.FunctionComponent<{}> = ({ children }) => {
  const { setBgImage, setStyle } = useContext(BackgroundContext);
  React.useEffect(() => {
    setBgImage(`${process.env.PUBLIC_URL}/img/menu_bg_mobile.png`);
    setStyle({ backgroundSize: "cover" });
  }, [children]);
  return (
    <DegreeNavigation
      key={'order-navigation'}
      hideSidebar={true}
    >
      {children}
    </DegreeNavigation>
  )
};
