import moment, {Moment} from 'moment';
import {
  GetAgreement_me_agreementsWithDetails,
  GetAgreement_me_agreementsWithDetails_subscriptions,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_product,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference1,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference2,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_stopOrders,
  GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_taxes
} from '../__generated__/GetAgreement';
import {PaymentLevel, SubscriptionAgreementStatus} from '../__generated__/globalTypes';

export const isSubscriptionActive = (status: SubscriptionAgreementStatus) => [SubscriptionAgreementStatus.Aktiv, SubscriptionAgreementStatus.Avventende].indexOf(status) > -1;

export interface ISubscription {
  id: string;
  deliveryAddressId: string;
  name: string;
  description: string;
  startDate?: Moment;
  endDate?: Moment;
  reference1: GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference1;
  reference2: GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_reference2;
  assetStatus: string;
  agreementId: string;
  assetNumber: string;
  taxes: GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_taxes[];
  location?: string;
  agreementPaymentlevel: PaymentLevel;
  status: SubscriptionAgreementStatus;
  spid: number | null;
  product: GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_product | null;
  stopOrders: Array<Pick<GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems_stopOrders, 'id' | 'date'>>;
  agreementNumber: number;
}

export interface IEmployee {
  accountName: string;
  name: string;
  customerNumber: string;
  admin: boolean;
  isSelected?: boolean;
  subscriptions: ISubscription[];
  activeSubscriptions: ISubscription[];
}

type EmployeeProps =
  Pick<GetAgreement_me_agreementsWithDetails, 'accountName' | 'agreementPaymentlevel'>
  & {
  admin: boolean;
  subscriptions: Array<GetAgreement_me_agreementsWithDetails_subscriptions_subscriptionItems & {
    agreementNumber: number;
  }>;
  parentAgreementId: string;
  rootAgreementId: string;
};

export class Employee implements IEmployee {

  private readonly props: EmployeeProps;
  private readonly usDateFormat = 'MM-DD-YYYY';

  public customerNumber: string = '';
  public name: string = '';
  public subscriptions: ISubscription[] = [];
  public activeSubscriptions: ISubscription[] = [];
  public accountName: string;
  public isSelected: boolean = false;
  public admin: boolean = false;

  constructor(props: EmployeeProps) {
    this.props = props;

    this.populateSubscriptions();
    this.populateCustomerNumber();
    this.populateName();
    this.accountName = this.props.accountName;
    this.admin = this.props.admin;
  }


  private populateCustomerNumber(): void {
    const [firstSubscription] = this.props.subscriptions;
    this.customerNumber = firstSubscription.customerNumber;
  }

  private populateName(): void {
    const [firstSubscription] = this.props.subscriptions;
    this.name = firstSubscription.customerName;
  }


  private populateSubscriptions(): void {

    const {subscriptions} = this.props;

    this.subscriptions = subscriptions
      .map((item) => {
        return {
          id: item.id,
          spid: item.customerSpid,
          name: item.product ? item.product.productName : '',
          description: item.product ? item.product.productVariation.replace(new RegExp(`^${item.product.productName}`), '') : '',
          startDate: item.agreementItemStartDate ? moment(item.agreementItemStartDate, this.usDateFormat) : undefined,
          endDate: item.agreementItemEndDate ? moment(item.agreementItemEndDate, this.usDateFormat) : undefined,
          reference1: item.reference1,
          reference2: item.reference2,
          assetStatus: item.assetStatus,
          agreementId: item.agreementId,
          assetNumber: item.assetNumber,
          taxes: item.taxes,
          location: this.props.parentAgreementId !== this.props.rootAgreementId ? this.props.parentAgreementId : undefined,
          deliveryAddressId: item.deliveryAddressId,
          product: item.product,
          status: item.status,
          agreementPaymentlevel: this.props.agreementPaymentlevel,
          stopOrders: item.stopOrders || [],
          agreementNumber: +item.agreementNumber,
        };
      })
      .sort((a: any) => {
        return isSubscriptionActive(a.status) ? -1 : 1;
      });

    this.activeSubscriptions = this.subscriptions
      .filter(s => isSubscriptionActive(s.status)) // only active subscriptions => (Aktiv || Avventende)
      .reduce((acc: ISubscription[], cur: ISubscription) => { // remove duplicate agreementIds
        // If multipe entries with the same agreementId
        if (this.subscriptions.filter(s => isSubscriptionActive(s.status) && s.agreementId === cur.agreementId).length > 1)
        {
          // pick only the ones with assetStatus ==== (Aktiv || Midlertidig Stoppet)
          if (cur.assetStatus === ('Aktiv'||'Midlertidig Stoppet')) {
            return acc.concat(cur);
          }
          return acc
        }
        return acc.concat(cur)
      }, []);
  }

}

export class Employees {
  static fromAgreement(agreement: GetAgreement_me_agreementsWithDetails) {

    if (!agreement) {
      return [];
    }
    const spids: string[] = Object.keys(agreement.admins.reduce((acc, a) => ({[a.spid]: true, ...acc}), {}));
    return agreement.subscriptions.map((s: GetAgreement_me_agreementsWithDetails_subscriptions) => {
      const {subscriptionItems, ...rest} = s;
      const isAdmin = subscriptionItems.find(a => {
        const {customerSpid} = a;
        if (!customerSpid) {
          return false;
        }
        return spids.indexOf(customerSpid.toString()) > -1;
      });
      return new Employee({
        accountName: agreement.accountName,
        admin: !!isAdmin,
        agreementPaymentlevel: agreement.agreementPaymentlevel,
        subscriptions: subscriptionItems.map((item) => ({
          ...item,
          agreementNumber: s.agreementNumber,
        })),
        ...rest
      });
    });
  }
}
