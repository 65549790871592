import { IWithSpidContext } from '@snoam/mono-spid';
import { MouseEventHandler, default as React, useState, ReactElement, Dispatch, SetStateAction } from 'react';
import {Button, ButtonSkin, classNames, Margin, Width} from '@snoam/pinata';
import { fetchInvoiceHref } from '../../utils';
import FetchInvoice from './FetchInvoice';

export interface Props extends IWithSpidContext {
  accountNumber: number;
  agreementNumber: number;
  filename?: string;
  icon: JSX.Element;
  invoiceNumber: string;
  setError: Dispatch<SetStateAction<ReactElement | string | null>>;
  skin: ButtonSkin;
  text: string;
}

const InvoiceButton: React.FunctionComponent<Props> = (props) => {
  const [downloading, setDownloading] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const {filename, icon, skin, text} = props;

  const invoiceHref = fetchInvoiceHref(props);
  const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();
    setDownloading(true);
  };

  return (
    <>
      <Button
        iconLeft={icon}
        className={classNames(Margin.MY_0, Width.W_FULL)}
        skin={skin}
        text={text}
        ariaLabel={text}
        href={invoiceHref}
        download={filename}
        onClick={onClick}
        loading={loading}
      />
      {downloading && <FetchInvoice {...props} filename={filename} setDownloading={setDownloading} setLoading={setLoading}/>}
    </>
  )
};

export default InvoiceButton;
