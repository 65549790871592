import * as React from 'react';
import {classNames, FontWeight, Heading, HeadingLevel, Padding, TextAlign} from '@snoam/pinata';

const styleClass = {
  bodyText: Padding.PY_4,
  root: (centerContent: boolean = false) => classNames(
    Padding.P_8,
    {
      [TextAlign.TEXT_CENTER]: centerContent
    }
  ),
};

export interface IBodyProps {
  centerContent?: true;
  className?: string;
  children: React.ReactNode;
  header?: React.ReactNode;
}

const DefaultContent = () => (
  <React.Fragment>
    Lorem ipsum dolor amet fam humblebrag four loko, fixie church-key celiac taiyaki poke echo park ugh blog
    sartorial activated charcoal snackwave. Tacos venmo master cleanse polaroid small batch, pabst you probably
    haven't heard of them. Artisan scenester squid, normcore narwhal hell of affogato af. Ennui selvage
    post-ironic kogi vice listicle scenester asymmetrical pitchfork bushwick before they sold out readymade
    coloring book drinking vinegar edison bulb. Taxidermy migas whatever fanny pack, green juice kogi lumbersexual
    hammock yr quinoa readymade. Kale chips 3 wolf moon fam, cardigan tumeric tumblr franzen banjo adaptogen
    bicycle rights vinyl. Woke dreamcatcher whatever small batch.
  </React.Fragment>
);

const Body = ({centerContent, children, header, className}: IBodyProps) => (
  <div className={classNames(styleClass.root(centerContent), className)}>
    <Heading level={HeadingLevel.ONE} className={FontWeight.FONT_LIGHT}>{header}</Heading>
    <div className={styleClass.bodyText}>
      {children || <DefaultContent/>}
    </div>
  </div>
);

export default Body;
