import {
  AlignItems,
  classNames,
  Display,
  Height,
  Icon,
  IconName,
  Margin,
  Position,
  Width,
} from '@snoam/pinata';
import * as React from 'react';

import Table from '../Table/Table';
import UserIcon from '../EmployeesAndSubscriptionsTable/UserIcon';
import AgreementActivationPotential from './AgreementActivationPotential';
import AgreementHeader from './AgreementHeader';
import AgreementBody from './AgreementBody';
import AgreementRow from './AgreementRow';
import AbbreviationTooltip from '../AbbreviationTooltip/AbbreviationTooltip';
import {PaymentLevel} from "../../__generated__/globalTypes";
import {GetAgreements_me_agreements_edges} from '../../__generated__/GetAgreements';
import {BillingTypes, IBillingType} from "../../configuration/agreements";

export interface IAgreementsTableProps {
  data: GetAgreements_me_agreements_edges[];
  loading: boolean;
}

const renderInvoiceTypeText = (invoiceType: PaymentLevel) => {
  const type: IBillingType | undefined = BillingTypes.find(type => type.id === invoiceType);
  return type ? type.label : ""
};

const renderExpanded = (agreement: GetAgreements_me_agreements_edges) => {

  return (
    <>
      <AgreementHeader
        agreement={agreement}
      />

      <AgreementActivationPotential
        agreement={agreement}
        linkTo={'settings'}
      />

      <AgreementBody
        agreement={agreement}
      />

      <div className={classNames(Margin.MT_4)}>
        {renderInvoiceTypeText(agreement.paymentLevel)}
      </div>
    </>
  );
};

const renderUserIconToolTip = (agreement: GetAgreements_me_agreements_edges) => {
  return (
    <AbbreviationTooltip
      title={agreement.admins.length === 0 ? 'Mangler administrator' : `${agreement.admins.length} administrator${agreement.admins.length === 1 ? '' : 'er'}`}
    >
      <UserIcon admin={agreement.admins.length > 0}/>
    </AbbreviationTooltip>
  )
};


const styleClass = {
  overlay: (loading: boolean) => classNames({
    [Position.ABSOLUTE]: loading,
    [Position.BOTTOM_0]: loading,
    [Position.TOP_0]: loading,
    [Width.W_FULL]: loading,
    [Height.H_FULL]: loading,
  })
};

const AgreementsTable = ({data, loading}: IAgreementsTableProps) => {
  const style = loading && data && data.length ? {backgroundColor: 'rgba(255,255,255,0.5)'} : {};
  return (
    <div className={Position.RELATIVE}>
      <div className={styleClass.overlay(loading)} style={style}/>
      <Table
        rows={data}
        alternatingColor={true}
        canExpand={() => true}
        rowRenderer={(agreement: GetAgreements_me_agreements_edges, expanded: boolean) => {
          return <AgreementRow agreement={agreement} expanded={expanded}/>
        }}
        expandedRenderer={(agreement: GetAgreements_me_agreements_edges) => {
          return renderExpanded(agreement)
        }}
        expandSymbolRenderer={(agreement: GetAgreements_me_agreements_edges) => (
          <div className={classNames(Width.W_16, Display.FLEX, AlignItems.ITEMS_CENTER)}>
            {renderUserIconToolTip(agreement)}
            <Icon name={IconName.PLUS}/>
          </div>
        )}
        closeSymbolRenderer={(agreement: GetAgreements_me_agreements_edges) => (
          <div className={classNames(Width.W_16, Display.FLEX, AlignItems.ITEMS_CENTER)}>
            {renderUserIconToolTip(agreement)}
            <Icon name={IconName.MINUS}/>
          </div>
        )}
      />
    </div>
  )
};


export default AgreementsTable;
