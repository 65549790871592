import * as React from "react";
import {useContext, useState} from "react";
import {
  AlignItems,
  BackgroundColor,
  Card,
  classNames,
  Cursor,
  Display,
  FlexDirection,
  FlexShrink,
  FontSize,
  FontWeight,
  Grid,
  GridComposition,
  GridGutter,
  GridItem,
  GridSpan,
  Icon,
  IconColor,
  IconName,
  IconSize,
  JustifyContent,
  LineHeight,
  Link,
  Margin,
  Padding,
  TextColor,
  TextDecoration,
  Width
} from "@snoam/pinata";
import SelectProductModal from "../SelectProductModal/SelectProductModal";
import {PublicationLogo} from "../Logo/Logo";
import {GetAgreementProducts_me_agreementsWithDetails_products} from "../../__generated__/GetAgreementProducts";
import {GET_AGREEMENT_PRODUCTS_AND_SIEBEL_PRODUCTS} from "../../queries";
import {checkNoErrors, UPDATE_AGREEMENT_PRODUCTS} from "../../mutations";
import {MutationFunction, useMutation} from "@apollo/client";
import {updateAgreementProduct, updateAgreementProductVariables} from "../../__generated__/updateAgreementProduct";
import {ModalContext} from "@snoam/mono-modal";
import {getDiscountPercentage, trackClickEngagment} from '../../utils';
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {getGroupOfProductsSortedByBundleCode, SyntheticAgreementProduct} from '../GroupProducts/GroupProducts';
import {AdminType, NewAgreementProduct} from "../../__generated__/globalTypes";
import {useRole} from "../Boundary/RoleBoundary";

interface SelectedProductBoxProps {
  agreementNumber: number;
  agreementDiscountPercent: number;
  products: GetAgreementProducts_me_agreementsWithDetails_products[];
  modalKey: number;
  isActivated: boolean;
}

const styleClass = {
  root: classNames(
    BackgroundColor.BG_WHITE,
    Margin.MB_1,
    Cursor.CURSOR_POINTER,
    'tableRow',
    'rowHeading',
  ),
  top: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER
  ),
  edit: classNames(
    TextDecoration.NO_UNDERLINE,
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
  ),
  products: {
    root: (active: boolean, mobile?: boolean, tablet?: boolean) => classNames(
      active ? TextColor.TEXT_BLACK : TextColor.TEXT_NEUTRAL_4,
      {
        [Padding.PT_2]: mobile || tablet,
        [Margin.MB_2]: mobile || tablet,
      },
    ),
    wrapper: classNames(
      Display.FLEX,
    ),
    product: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Margin.ML_2
    ),
    icon: classNames(
      Margin.LG_MT_1,
      FlexShrink.FLEX_SHRINK_0,
    ),
    name: classNames(
      FontWeight.FONT_SEMIBOLD,
      FontSize.TEXT_LG,
      LineHeight.LEADING_TIGHT,
    ),
    price: classNames(
      FontSize.TEXT_SM
    )
  }
};

const SelectedProductBox: React.FunctionComponent<SelectedProductBoxProps> = ({modalKey, agreementNumber, products, isActivated, agreementDiscountPercent}) => {

  const {mobile, tablet} = useContext(ResponsiveContext);
  const modalId = `selected-product-modal-${modalKey}`;
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const toggleModal = (changeAccessModalId: string) => openModalId ? closeModal() : openModal(changeAccessModalId);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const updateAgreement: MutationFunction<updateAgreementProduct, updateAgreementProductVariables> = useMutation(UPDATE_AGREEMENT_PRODUCTS)[0];
  const {role: userRole} = useRole();

  const convertToPreselectedArray = (productGroup: GetAgreementProducts_me_agreementsWithDetails_products[]) => {
    const pProducts: string[] = [];
    productGroup.forEach((product) => {
      pProducts.push(product.productCombination);
    });
    return pProducts
  };
  const [preSelectedProducts] = useState<string[]>(convertToPreselectedArray(products));

  const onEditProducts = (selectedProducts: GetAgreementProducts_me_agreementsWithDetails_products[]) => {
    const refetchQueries = [{
      query: GET_AGREEMENT_PRODUCTS_AND_SIEBEL_PRODUCTS,
      variables: {agreementNumber: [agreementNumber]}
    }];

    if (selectedProducts.length > 0) {
      selectedProducts.map(async (product) => {
        setIsLoading(!isLoading);
        const isActivated = product.active;
        delete product.__typename;
        await updateAgreement(
          {
            refetchQueries,
            variables: {
              active: isActivated ? 'Y' : 'N',
              agreementNumber: agreementNumber,
              product: product as NewAgreementProduct
            }
          }).then(checkNoErrors).then(() => {
          setIsLoading(false);
          closeModal();
        });
      });
      trackClickEngagment(`Produkter - Endre abo for ${products[0].productName}`);
    }
  };

  const sortedGroupProducts = getGroupOfProductsSortedByBundleCode(products) as SyntheticAgreementProduct[];

  const sortedAndGroupedByAvailability = sortedGroupProducts.reduce((acc: SyntheticAgreementProduct[][], p) => {
    const [pub, priv] = acc;
    if(p.public) {
      pub.push(p);
    }  else {
      priv.push(p);
    }
    return acc;
  }, [[],[]]);


  const [publicAvailability, privateAvailability] = sortedAndGroupedByAvailability;

  const handleKeyDown = (e: any) => {
    if(userRole !== AdminType.SUPEROBSERVER) {
      if (e.key === 'Enter' || e.key === ' ') {
        e.preventDefault();
        toggleModal(modalId);
      }
    }
  };

  const showProduct = (product: SyntheticAgreementProduct, key: number) => {
    const discountPercent: number = getDiscountPercentage(agreementDiscountPercent, product.discountPercent);
    const discountPrice: number = discountPercent && product.monthlyPrice && (Math.round(product.monthlyPrice * (discountPercent / 100)));
    return (
      <GridItem
        key={key}
        className={styleClass.products.root(product.active, mobile, tablet)}
      >
        <div className={styleClass.products.wrapper}>
          <Icon
            name={product.active ? IconName.CHECK : IconName.PLUS}
            color={product.active ? IconColor.POSITIVE : IconColor.NEUTRAL_4}
            size={IconSize.SMALL}
            className={styleClass.products.icon}
            screenReaderText={product.active ? 'Ikon som viser at man har dette produktet allerede' : 'Ikon som viser at man kan legge til dette produktet'}
          />

          <div className={styleClass.products.product}>
          <span className={styleClass.products.name}>
            {product.productVariation}{privateAvailability.indexOf(product) !== -1 ? <sup>*</sup> : null}
          </span>
            <span className={styleClass.products.price}>
            {discountPercent === 100 ? `Ordinær pris: ${product.monthlyPrice}` : product.monthlyPrice - discountPrice} kr/mnd
          </span>
          </div>
        </div>
      </GridItem>
    )
  };
  return (
    <>

      <Card
        className={styleClass.root}
        tabIndex={0}
        onClick={() => userRole !== AdminType.SUPEROBSERVER && toggleModal(modalId)}
        onKeyDown={handleKeyDown}
      >
        <div className={styleClass.top}>
          <PublicationLogo
            publicationName={products[0].productGroup}
            className={classNames(Display.FLEX, FlexShrink.FLEX_SHRINK_0, Width.W_FULL)}
          />
          {userRole !== AdminType.SUPEROBSERVER &&
            <div>
              <Link
                className={styleClass.edit}
                onClick={() => toggleModal(modalId)}
                tabIndex={0}
                onKeyDown={handleKeyDown}
              >
                Endre

                <Icon
                  name={IconName.CREATE}
                  size={IconSize.BASE}
                  color={IconColor.NEUTRAL_4}
                  className={Margin.ML_2}
                />
              </Link>
            </div>
          }
        </div>

        <Grid
          className={Margin.MT_4}
        >
          <Grid isNested={true} gutter={GridGutter.NONE} composition={GridComposition.CHARLIE} className={Padding.PB_4}>
          {
            publicAvailability.map(showProduct)
          }
          </Grid>

          <Grid isNested={true} composition={GridComposition.CHARLIE}>
          {
            privateAvailability.map(showProduct)
          }
          </Grid>

          <Grid isNested={true} className={Padding.PT_4}>
            {
              privateAvailability.length > 0 ? <GridItem span={GridSpan.ALL_12}>
                <p><sup>*</sup> Endringer på produktet må gjøres ved å kontakte kundeservice.</p>
              </GridItem> : null
            }
          </Grid>

        </Grid>
      </Card>

      <SelectProductModal
        id={modalId}
        onClose={() => toggleModal(modalId)}
        products={sortedGroupProducts}
        publication={products[0].productName}
        isActiveProducts={isActivated}
        onSave={onEditProducts}
        preselectedProducts={preSelectedProducts}
        isLoading={isLoading}
        buttonText={"Lagre"}
        isLoadingText={"Lagrer"}
        agreementDiscountPercent={agreementDiscountPercent}
      />

    </>
  )
};

export default SelectedProductBox;
