import * as React from "react";
import {
  AlignItems,
  BackgroundColor,
  BorderRadius,
  classNames,
  Display,
  FlexDirection,
  FlexGrow,
  FontSize,
  Height,
  Icon,
  IconColor,
  IconName,
  LineHeight,
  Margin,
  MaxWidth,
  Overflow,
  Padding,
  TextAlign, TextColor,
  Width,
  FontWeight,
  MinWidth,
  MinHeight
} from "@snoam/pinata";
import {AdminType} from '../../__generated__/globalTypes';
import {MaybeAdminType} from "../Boundary/RoleBoundary";


const styleClass = {
  root: (transparent: boolean) => classNames(
    MaxWidth.MAX_W_SM,
    Overflow.OVERFLOW_HIDDEN,
    TextAlign.TEXT_CENTER,
    {
      [BackgroundColor.BG_WHITE]: !transparent,
    }
  ),
  wrapper: (className: string) => classNames(
    className ? className : null,
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    Padding.PY_4,
  ),
  icon: {
    root: (top: boolean) => classNames(
      BorderRadius.ROUNDED_FULL,
      Height.H_10,
      Width.W_10,
      MinWidth.MIN_W_10,
      MinHeight.MIN_H_10,
      Overflow.OVERFLOW_HIDDEN,
      Margin.MX_3,
      {
        [BackgroundColor.BG_NEUTRAL_3]: !top,
        [BackgroundColor.BG_PRIMARY_2]: top,
      }
    ),
    svg: classNames(
      Margin.MT_2,
      Margin.MX_AUTO,
    )
  },
  inner: classNames(
    TextAlign.TEXT_CENTER,
    TextAlign.SM_TEXT_LEFT,
    FlexGrow.SM_FLEX_GROW,
  ),
  user: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      TextAlign.TEXT_LEFT
    ),
    name: (textColorNegative: boolean) =>  classNames(
      FontSize.TEXT_BASE,
      textColorNegative ? TextColor.TEXT_WHITE : TextColor.TEXT_NEUTRAL_6,
      LineHeight.LEADING_TIGHT,
    ),
    namebold: classNames(
      FontWeight.FONT_SEMIBOLD,
    ),
    role: (textColorNegative: boolean) => classNames(
      FontSize.TEXT_XS,
      textColorNegative ? TextColor.TEXT_NEUTRAL_3 : TextColor.TEXT_NEUTRAL_6,
      LineHeight.LEADING_TIGHT,
    ),
  }
};

export interface IUserBadgeProps {
  email?: string | null;
  infotext?: string | null;
  name?: string;
  role: MaybeAdminType;
  classNames?: string;
  transparent?: boolean;
  textColorNegative?: boolean;
}

export const UserRoleName = {
  [AdminType.SUPERADMIN]: 'Schibsted administrator',

  [AdminType.ADMIN]: 'Administrator',
  [AdminType.ACCOUNTANT]: 'Lesetilgang',
};


const UserBadge = (props: IUserBadgeProps) => {

  const { email, name, role, infotext, transparent = false, textColorNegative = false } = props;

  const generateIcon = (type: MaybeAdminType) => {
    if(type === AdminType.ADMIN)
      return IconName.BEDRIFT_ADMIN;
    if(type === AdminType.ACCOUNTANT)
      return IconName.BEDRIFT_USER;
    if(type === AdminType.SUPERADMIN)
      return IconName.BEDRIFT_SCHIBSTEDADMIN;
    return IconName.BEDRIFT_USER;
  };

  return (
    <div className={styleClass.root(transparent)}>
      <div className={styleClass.wrapper(props && props.classNames || "")}>

        <div className={styleClass.icon.root(true)}>
          <Icon
            name={generateIcon(role)}
            className={styleClass.icon.svg}
            color={IconColor.PRIMARY_DARK}
            screenReaderText={'Bruker-ikon'}
          />
        </div>

        <div className={styleClass.inner}>
          <div className={styleClass.user.root}>
            <p className={styleClass.user.name(textColorNegative)}>
            <strong className={styleClass.user.namebold}>{name}</strong>{infotext ? ", "+infotext : ""}
            </p>
            <p className={styleClass.user.role(textColorNegative)}>{role ? UserRoleName[role] : email}</p>
          </div>
        </div>
      </div>
    </div>
  );
};


export default UserBadge;
