import * as React from 'react';
import {useContext, useState} from 'react';
import {Modal, ModalContext} from "@snoam/mono-modal";
import {
  AlignContent,
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button, ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel, Icon, IconColor, IconName,
  Margin,
  Padding,
  RadioButton,
  RadioButtonGroup,
  TextAlign,
  TextColor
} from "@snoam/pinata";
import {IAdminRole} from "../../models/AdminModel";
import {MutationFunction, useMutation} from "@apollo/client";
import {UpdateAdminRole, UpdateAdminRoleVariables} from "../../__generated__/UpdateAdminRole";
import {checkNoErrors, UPDATE_ADMIN_ROLE} from "../../mutations";
import {GET_SUPER_ADMINS} from "../../queries";

const styleClass = {
  modal: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      AlignContent.CONTENT_CENTER,
      Padding.PX_1,
      Padding.MD_PX_10
    ),
    heading: classNames(
      FontSize.TEXT_2XL,
      FontWeight.FONT_HAIRLINE,
      TextAlign.TEXT_CENTER,
      Margin.MB_6
    ),
    closeButton: classNames(
      classNames(
        Margin.MT_8,
        Margin.MB_1,
        BackgroundColor.BG_TRANSPARENT,
        BackgroundColor.HOVER_BG_TRANSPARENT,
        TextColor.TEXT_NEUTRAL_6,
        FontWeight.FONT_HAIRLINE
      )
    ),
    addButton: classNames(
      BackgroundColor.BG_PRIMARY_3,
      BackgroundColor.HOVER_BG_PRIMARY_4,
      TextColor.TEXT_WHITE,
      FontWeight.FONT_NORMAL,
      Padding.PL_3
    ),
  },
}

interface EditAdministrationRoleModalProps {
  id: string;
  userId: number,
  currentRole: IAdminRole;
  roles: IAdminRole[]
}

const EditAdministrationRoleModal: React.FunctionComponent<EditAdministrationRoleModalProps> = ({
                                                                                             id,
                                                                                             currentRole,
                                                                                             roles,
                                                                                             userId
                                                                                           }) => {

  const [role, setRole] = useState<IAdminRole>(currentRole);
  const [isSaving, setIsSaving] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const {closeModal} = useContext(ModalContext);

  const updateAdminMutation: MutationFunction<UpdateAdminRole, UpdateAdminRoleVariables> = useMutation<UpdateAdminRole, UpdateAdminRoleVariables>(UPDATE_ADMIN_ROLE)[0];
  const refetchQueries = [{ query: GET_SUPER_ADMINS }]

  const updateRole = () => {
    setIsSaving(true);
    updateAdminMutation({
      refetchQueries,
      variables: {
        agreementNumber: null,
        adminType: role.id,
        spid: userId
      }
    }).then(checkNoErrors).then(() => {
      setIsSaving(false);
      setTimeout(onCloseModal, 750);
      setIsSaved(true);
    })
  }

  const onCloseModal = () => {
    closeModal();
    setIsSaved(false);
  }

  return (
    <Modal
      id={id}
      backdrop={true}
      className={BackgroundColor.BG_NEUTRAL_1}
      onClose={onCloseModal}
    >
      <div className={styleClass.modal.root}>
        <Heading level={HeadingLevel.TWO} className={styleClass.modal.heading}>
          Endre administrator
        </Heading>

        <RadioButtonGroup
          name={'rights'}
          value={role.id}
        >
          {
            roles.map((r, i: number) => {
              return (

                <div key={`${r.id}_${i}`}
                     className={
                       classNames(
                         BorderStyle.BORDER_SOLID,
                         BorderWidth.BORDER_B_1,
                         BorderColor.BORDER_NEUTRAL_3,
                         FontWeight.FONT_BOLD
                       )
                     }>
                  <RadioButton
                    // skin={RadioButtonSkin.PRIMARY_3}
                    className={FlexDirection.FLEX_COL}
                    value={r.id}
                    text={r.label}
                    onClick={() => setRole(r)}
                    checked={r.id === role.id}
                  />
                </div>
              )
            })
          }
        </RadioButtonGroup>

        <Button
          onClick={() => {
            updateRole();
          }}
          loading={isSaving}
          loadingText={"Endrer..."}
          text={isSaved ? 'Oppdatert' : 'Oppdater rolle'}
          aria-label={isSaved ? 'Oppdatert' : 'Oppdater rolle'}
          skin={isSaved ? ButtonSkin.CONFIRM : ButtonSkin.PRIMARY}
          iconRight={isSaved ? <Icon name={IconName.CHECK} color={IconColor.PRIMARY_LIGHT} /> : undefined}
          ariaLabel={'Oppdater rolle'}
          className={styleClass.modal.addButton}
          style={{transition: 'background-color 500ms ease'}}
        />

        <Button
          onClick={onCloseModal}
          text={'Avbryt'}
          ariaLabel={'Avbryt'}
          className={styleClass.modal.closeButton}
        />
      </div>
    </Modal>
  )
}

export default EditAdministrationRoleModal;
