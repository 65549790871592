import * as React from "react";
import {classNames, Display, TextColor, FontSize, AlignItems,} from "@snoam/pinata";

const styleClass = {
  accordionTitleContent: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    TextColor.TEXT_NEUTRAL_8,
    FontSize.TEXT_LG,
  ),
};

interface AccordionPanelTitleProps {
  title: string;
  children: JSX.Element;
}

const AccordionPanelTitle: React.StatelessComponent<AccordionPanelTitleProps> = ({ title, children }) => (
  <div className={styleClass.accordionTitleContent}>
    {children}
    <span>{title}</span>
  </div>
);

export default AccordionPanelTitle;
