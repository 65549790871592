import React, { useContext } from "react";
import {
  classNames,
  Width,
  TextDecoration,
  Margin,
  Display,
  FlexDirection,
  AlignItems,
  Fill,
  FontSize,
  FontWeight,
  TextColor,
  Padding
} from "@snoam/pinata";
import SvgImage from "../SvgImage/SvgImage";
import { footerConfig } from "../../configuration/footer";
import { ResponsiveContext } from "../../context/ResponsiveContext/ResponsiveContext";

const styleClass = {
  iconContainer: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_START,
    Margin.MY_4
  ),
  content: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_START
  ),
  icon: classNames(Fill.FILL_NEUTRAL_6, Margin.MR_3),
  phone: classNames(
    FontSize.TEXT_XL,
    FontWeight.FONT_THIN,
    TextColor.TEXT_WHITE,
    TextDecoration.NO_UNDERLINE
  ),
  deliveredByParagraph: classNames(Margin.MT_AUTO, Padding.PT_10)
};

enum IconType {
  PHONE = "phone",
  ENVELOPE = "envelope"
}

interface IContactContainerProps {
  svgType: string;
}
const ContactContainer: React.FC<IContactContainerProps> = ({
  children,
  svgType
}) => {
  return (
    <div className={styleClass.iconContainer}>
      <SvgImage
        svgStyle={{ width: "25px", height: "25px" }}
        href={`${process.env.PUBLIC_URL}/svg/${svgType}.svg`}
        className={styleClass.icon}
      />
      <div className={styleClass.content}>{children}</div>
    </div>
  );
};

interface IFooterContactProps {}
const FooterContact: React.FC<IFooterContactProps> = () => {
  const { landscape } = useContext(ResponsiveContext);
  return (
    <>
      <SvgImage
        className={classNames(Width.W_48, Margin.MB_6)}
        href={`${process.env.PUBLIC_URL}/svg/publications/schibsted_negativ.svg`}
      />
      <ContactContainer svgType={IconType.PHONE}>
        <a
          href={`tel: ${footerConfig.phoneNumber}`}
          className={styleClass.phone}
        >
          {footerConfig.phoneNumber}
        </a>
      </ContactContainer>

      <ContactContainer svgType={IconType.ENVELOPE}>
        <p>Kontakt oss på mail:</p>
        <a
          className={classNames(TextDecoration.UNDERLINE)}
          href={`mailto: ${footerConfig.businessEmail}`}
        >
          {footerConfig.businessEmail}
        </a>
      </ContactContainer>
      {landscape && (
        <p className={styleClass.deliveredByParagraph}>
          Denne tjenesten er levert av Schibsted Media AS
        </p>
      )}
    </>
  );
};

export default FooterContact;
