import React, {createContext, useContext, useState} from "react";
import { IEmployee } from "../../models/EmployeeModel";

const SelectionContext = createContext({
  context: {
    state: {
      selectedEmployees: null,
      terminationStatusLog: [],
    },
    actions: {
      addOrRemoveFromSelection: (object: IEmployee) => {
      },
      getSelected: (): any => {
      },
      resetSelection: (): any => {
      },
      getTerminationStatusLog: (): any => {
      },
      setTerminationStatusLogMsg: (object: any) => {
      },
    }
  }
});

const SelectionContextProvider: React.FunctionComponent<{ props?: any }> = ({props, children}) => {
  const [selectedEmployees, setSelectedEmployees] = useState<any>([]);
  const [terminationStatusLog, setTerminationStatusLog] = useState<any>([]);

   const addOrRemoveFromSelection = (customerObject: IEmployee) => {
      const elementExistsIndex = selectedEmployees.findIndex((item: IEmployee) => item.customerNumber===customerObject.customerNumber)
      if (elementExistsIndex !== -1) {
          const selectionArray = [...selectedEmployees];
          selectionArray.splice(elementExistsIndex, 1);
          setSelectedEmployees(selectionArray);
      }
      else {
          setSelectedEmployees([...selectedEmployees, customerObject]);
      }
  };

  const getTerminationStatusLog = () => {
    return terminationStatusLog;
  }

  const setTerminationStatusLogMsg = (statusmessage: any) => {
    terminationStatusLog.push(statusmessage)
    const newLog = new Array(0);
    terminationStatusLog.forEach((element: any) => {
      newLog.push(element)
    });
    setTerminationStatusLog(newLog)
  }

  const resetSelection = ():any => {
    selectedEmployees.forEach((item: IEmployee) => item.isSelected=false)
    setSelectedEmployees([]);
    setTerminationStatusLog([]);
  };

  const getSelected = ():any => {
    return selectedEmployees;
  };

  const context = {
    state: {
      selectedEmployees,
      terminationStatusLog,
    },
    actions: {
      addOrRemoveFromSelection: addOrRemoveFromSelection,
      getSelected: getSelected,
      resetSelection: resetSelection,
      setTerminationStatusLogMsg: setTerminationStatusLogMsg,
      getTerminationStatusLog: getTerminationStatusLog,
    },
  };

  return (
    <SelectionContext.Provider value={{context}}>
      {children}
    </SelectionContext.Provider>
  )
};

export default SelectionContextProvider

export const useSelectionContext = () => {
  return useContext(SelectionContext);
};
