import {
  BackgroundColor,
  BorderColor,
  BorderWidth,
  classNames,
  Display,
  FlexDirection,
  Height,
  Overflow,
  Position,
  ZIndex
} from "@snoam/pinata";
import React, {CSSProperties, MouseEventHandler, ReactNode} from "react";
import {EnvelopeHeader} from "./EnvelopeHeader";

interface IEnvelopeContainerProps {
  open: boolean;
  width: string;
  toggle: MouseEventHandler<HTMLButtonElement>;
  heading: string;
  children?: ReactNode;
  style?: CSSProperties;
  isFullScreen: boolean;
}

const styleClass = {
  root: (open: boolean, useAnimation: boolean) => classNames(
    'envelope',
    Height.H_SCREEN,
    Position.ABSOLUTE,
    Position.TOP_0,
    FlexDirection.FLEX_COL,
    {
      [Display.HIDDEN]: !open,
      [Display.FLEX]: open,
      [ZIndex.Z_50]: !useAnimation,
      'envelope--animation': useAnimation,
      'envelope--animation--open': open
    }
  ),
  container: (open: boolean, isFullscreen: boolean) => classNames(
    'envelope__container',
    BackgroundColor.BG_WHITE,
    BorderColor.BORDER_NEUTRAL_2,
    BorderWidth.BORDER_R_1,
    BackgroundColor.BG_NEUTRAL_1,
    Height.H_FULL,
    {
      'envelope__container--open': open,
      [Overflow.OVERFLOW_Y_AUTO]: !isFullscreen,
      [Overflow.OVERFLOW_X_HIDDEN]: !isFullscreen
    }
  )
};

export const EnvelopeContainer: React.FunctionComponent<IEnvelopeContainerProps> = React.memo(({open, toggle, heading, children, style, width, isFullScreen}) => {
  return (
    <div className={styleClass.root(open, !isFullScreen)} style={style}>
      <aside
        className={styleClass.container(open, isFullScreen)}
        style={{width:width, ...style}}
      >
        <EnvelopeHeader
          toggle={toggle}
          heading={heading}
          isFullScreen={isFullScreen}
        />

        {children}
      </aside>
    </div>
  )
});


export default EnvelopeContainer;
