import {

} from '@snoam/pinata';
import * as React from 'react';

interface IAbbreviationTooltipProps {
  title: string;
  className?: string;
}

const AbbreviationTooltip: React.StatelessComponent<IAbbreviationTooltipProps> = ({ title, className, children }) => (
  <abbr
    title={title}
    className={className}
  >
    {children}
  </abbr>
);

export default AbbreviationTooltip;
