import React from "react";
import {BaseFilter, BaseFilterProps} from "./BaseFilter";
import {FilterType} from "../utils";

type BetweenFilterProps = Omit<BaseFilterProps, 'type' | 'value'> & {
  value: {
    lowerInclusive: number;
    upperExclusive: number;
  };
};

export const Between: React.FunctionComponent<BetweenFilterProps> = (props) => <BaseFilter {...props} type={FilterType.BETWEEN}/>;
