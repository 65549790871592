import * as React from 'react';
import {useContext} from 'react';
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  FontWeight,
  Grid,
  GridGutter,
  GridItem,
  GridSpan,
  Icon,
  IconColor,
  IconName,
  JustifyContent,
  Link,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  TextDecoration,
  Width
} from '@snoam/pinata';
import {IAddress} from '@snoam/mono-address/lib/Address';
import AddressModal from './AddressModal';
import {ModalContext} from "@snoam/mono-modal";

const styleClass = {
  container: classNames(
    Padding.PB_10,
    Padding.PX_4,
    Padding.SM_PB_10,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.TEXT_2XL,
    Margin.MB_4,
    TextAlign.TEXT_CENTER,
  ),
  greyBox: classNames(
    Padding.P_4,
    BackgroundColor.BG_NEUTRAL_1
  ),
  whiteBox: classNames(
    Width.W_FULL,
    Display.FLEX,
    Padding.P_4,
    BackgroundColor.BG_WHITE,
    AlignItems.ITEMS_CENTER,
    Margin.MT_4
  ),
};

const AddressRenderer = (props: {address: IAddress, onClick: () => void}) => {
  const {street, streetNumber, nameOnDoor} = props.address;
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      props.onClick();
    }
  };
  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() => props.onClick()}
      onKeyDown={onKeyDown}
      className={classNames(styleClass.whiteBox, Margin.MT_4)}
    >
      <Grid gutter={GridGutter.NONE} className={classNames(Margin.MX_4, TextColor.TEXT_NEUTRAL_6)}>
        <GridItem span={[GridSpan.XSMALL_10, GridSpan.SMALL_10, GridSpan.MEDIUM_10, GridSpan.LARGE_10]}
                  className={classNames(Display.FLEX, FlexDirection.FLEX_COL)}>
          <div className={classNames(FontWeight.FONT_BOLD)}>Leveringsadresse:</div>
          <div>{street.streetName} {streetNumber.streetNo}{streetNumber.entrance}</div>
          <div>{street.city} {streetNumber.postalCode}</div>
          <div>Navn på dør/postkasse: {nameOnDoor || 'Ikke definert'}</div>
        </GridItem>
        <GridItem span={[GridSpan.XSMALL_2, GridSpan.SMALL_2, GridSpan.MEDIUM_2, GridSpan.LARGE_2]}
          className={classNames(Display.FLEX, AlignItems.ITEMS_START, JustifyContent.JUSTIFY_END)}>
          <Icon name={IconName._EDIT} color={IconColor.NEUTRAL_5}/>
          <Link className={classNames(TextDecoration.NO_UNDERLINE, Margin.ML_2)}>Endre</Link>
        </GridItem>
      </Grid>
    </div>
  );
};



const NoAddress = (props: {onClick: () => void}) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      props.onClick();
    }
  };

  return (
    <div
      tabIndex={0}
      role="button"
      onClick={() => props.onClick()}
      onKeyDown={onKeyDown}
      className={styleClass.whiteBox}
    >
      <Icon name={IconName.HOME} className={classNames(Margin.ML_2)} color={IconColor.NEUTRAL_4}/>
      <Link className={classNames(Margin.ML_2, TextDecoration.NO_UNDERLINE)}>Legg til din leveringsadresse</Link>
    </div>
  );
};

const AddressSelector = (props: {address: IAddress, addressSelected: (address: IAddress) => void}) => {
  const { closeModal, openModal, openModalId } = useContext(ModalContext);
  const toggleModalFactory = (adressModalId: string) => () => {
    openModalId ? closeModal() : openModal(adressModalId);
  };
  const toggleModal = toggleModalFactory('address-modal');

  function saveAddress(address: IAddress) {
    const hasStreetNumber = address.streetNumber && address.streetNumber.deliveryPointId;
    const isHouseHold = address.houseHold && address.houseHold.deliveryPointId;
    if (hasStreetNumber || isHouseHold) {
      props.addressSelected(address);
      toggleModal();
    }
  }

  const addressPresent = (props.address && props.address.streetNumber && props.address.streetNumber.deliveryPointId);

  return (
    <div>
      {addressPresent && <AddressRenderer address={props.address} onClick={toggleModal} />}
      {!addressPresent && <NoAddress onClick={toggleModal} />}
      <AddressModal id={'address-modal'} address={props.address} onClose={toggleModal} addressUppdated={saveAddress} />
    </div>
  );
};

export default AddressSelector;
