import React, { ChangeEvent, useEffect } from 'react';
import { useState } from 'react';
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSkin,
  Card,
  classNames,
  Cursor,
  Display,
  Dropdown,
  FontWeight,
  Icon,
  IconColor,
  IconName,
  Margin,
  Padding,
  TextColor,
  FontSize,
  Width,
  Arrow,
  Position,
  LineHeight,
  JustifyContent,
  AlignItems,
  FlexDirection,
} from "@snoam/pinata";
import { referenceType, AgreementReference } from "../../configuration/agreements";
import { GetAgreement_me_agreementsWithDetails_settings_reference1, GetAgreement_me_agreementsWithDetails_settings_reference2, GetAgreement_me_agreementsWithDetails } from '../../__generated__/GetAgreement';
import { ReferenceType } from '../../__generated__/globalTypes';
import { UpdateAgreementSettings, UpdateAgreementSettingsVariables } from '../../__generated__/UpdateAgreementSettings';
import { MutationFunction, useMutation } from '@apollo/client';
import { UPDATE_AGREEMENT_SETTINGS} from '../../mutations';
import { removeTypename, onKeyPress, EventKey } from '../../utils';
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';
import { LoadingFragment } from '../../pages/LoadingPage';
import ActiveReference from './ActiveReference';
import NewReference from './NewReference';

interface IReferenceSelectorProps {
  reference: GetAgreement_me_agreementsWithDetails_settings_reference1 | GetAgreement_me_agreementsWithDetails_settings_reference2 | null;
  number: number;
  agreement: Pick<GetAgreement_me_agreementsWithDetails, 'agreementNumber' | 'id' | 'maxSubscriptionsPerEmployee' | 'settings'>;
}

const styleClass = {
  card: classNames(
    BorderColor.BORDER_NEUTRAL_4,
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_DASHED,
    Width.MD_W_1_2,
    Padding.P_6,
    Margin.MX_1,
    Margin.MD_MY_0,
    Margin.MY_2,
  ),
  header: classNames(
    FontSize.TEXT_XL,
    FontWeight.FONT_LIGHT,
    Margin.MB_4,
    Display.FLEX,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER,
  ),
  headerCloseButton: classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER,
    Cursor.CURSOR_POINTER
  ),
  helpText: classNames(
    FontSize.TEXT_SM,
    Margin.MT_4,
  ),
  listContainer: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Position.RELATIVE
  ),
  listInfoText: classNames(
    Margin.MY_4,
    LineHeight.LEADING_TIGHT,
  ),
  inputContainer: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    Padding.PB_4
  ),
  referenceSelected: {
    card: classNames(
      Width.MD_W_1_2,
      BackgroundColor.BG_WHITE,
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Padding.P_6,
      Margin.MX_1,
      Margin.MD_MY_0,
      Margin.MY_2,
    ),
    header: classNames(
      Display.FLEX,
      FlexDirection.FLEX_ROW,
      JustifyContent.JUSTIFY_BETWEEN
    ),
    edit: classNames(
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      TextColor.TEXT_PRIMARY_3,
      Cursor.CURSOR_POINTER
    ),
    text: classNames(
      FontSize.TEXT_LG,
      Margin.MB_2
    ),
    smallText: classNames(
      FontSize.TEXT_SM
    ),
  },
  messageContainer: classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_CENTER
  )
};

const ReferenceSelector = ({ reference, agreement, number }: IReferenceSelectorProps) => {
  const [selectedReference, setSelectedReference] = useState<AgreementReference>(reference ? reference : {} as AgreementReference);
  const [newReferences, setNewReference] = useState<string[]>(['']);
  const [isReferenceValid, setReferenceValidStatus] = useState<boolean>(false);
  const [activeReferences, setActiveReferences] = useState<string[]>([]);
  const [editMode, setEditMode] = useState<boolean>();
  const [message, setMessage] = useState<JSX.Element | null>();
  const [isLoading, setLoadingState] = useState<boolean>();

  const updateAgreementSettings: MutationFunction<UpdateAgreementSettings, UpdateAgreementSettingsVariables> = useMutation<UpdateAgreementSettings, UpdateAgreementSettingsVariables>(UPDATE_AGREEMENT_SETTINGS)[0];
  const {
    openidConnectClientId,
    openidConnectClientSecretMasked,
    openidConnectDiscoveryUrl,
    openidConnectSystemName,
    employeePeriodicVerificationSettings,
    ...newSettings
  } = removeTypename(agreement.settings);
  const hasReferenceName = reference && reference.name;

  useEffect(() => {
    validateReferences();
    if (reference && (reference.allowedValues !== newReferences) && !editMode) {
      if (reference && reference.allowedValues) {
        setActiveReferences(reference.allowedValues);
      }
    }
  }, [newReferences, editMode]);

  const onSelectReference = (value: string) => {
    const selectedRef = referenceType.find(reference => reference.value === value);
    if (selectedRef) {
      const selectedReference: AgreementReference = {
        name: selectedRef.name,
        type: selectedRef.type,
        allowedValues: newReferences
      };
      setSelectedReference(selectedReference);
    }
  };

  const onInputChanged = (event: ChangeEvent<HTMLInputElement>, key: number) => {
    const value = event.target.value;
    if (newReferences) {
      const references = [...newReferences];
      references[key] = value;
      setNewReference(references);
    }
  };

  const addNewReferenceOnClick = () => {
    if (newReferences) {
      setNewReference([...newReferences, ""]);
    }
  };

  const removeReference = (key: number) => (e: any) => {
    if (newReferences) {
      const tempReferences = [...newReferences];
      tempReferences.splice(key, 1);
      setNewReference(tempReferences);
    }
  };

  const updateMessage = (message: JSX.Element | null) => {
    setMessage(message);
    setTimeout(() => {
      setMessage(null);
    }, 5000)
  };

  const submitReference = () => {
    setLoadingState(true);
    setActiveReferences([...activeReferences, ...newReferences]);
    setNewReference([]);

    const variables: UpdateAgreementSettingsVariables = {
      agreementNumber: agreement.agreementNumber,
      agreementId: agreement.id,
      settings: {
        ...newSettings,
        [number === 0 ? 'reference1' : 'reference2']: {
          id: `${agreement.agreementNumber}-Reference${number + 1}`,
          name: selectedReference.name,
          type: selectedReference.type,
          allowedValues: getReferences()
        }
      },
    };

    updateAgreementSettings({ variables }).then(() => {
      setLoadingState(false);
      updateMessage(
        newReferences.length > 0 ?
          <div className={styleClass.messageContainer}>
            <MessageBox
              message={`${newReferences.map((reference) => reference).join(', ')} lagt til.`}
              type={MessageBoxType.WARNING}
              arrow={Arrow.TOP_CENTER}
            />
          </div>
          : null
      )
    })
      .catch(() => {
        setLoadingState(false);
        updateMessage(
          <div className={styleClass.messageContainer}>
            <MessageBox
              message={'En feil oppstod. Vennligst prøv igjen.'}
              type={MessageBoxType.WARNING}
              arrow={Arrow.TOP_CENTER}
            />
          </div>
        )
      })
  };

  const toggleEditMode = () => {
    setEditMode(!editMode);
    setNewReference([]);
  };

  const validateReferences = () => {

    if (selectedReference.type === ReferenceType.LIST) {
      newReferences.map((item: any) => {
        if (!item) {
          return setReferenceValidStatus(true);
        }
        return setReferenceValidStatus(false);
      });
    }
  };

  const getReferences = () => {
    if (activeReferences.length > 0 && newReferences.length > 0) {
      return [...activeReferences, ...newReferences];
    }
    if (activeReferences.length > 0) {
      return [...activeReferences];
    }
    return [...newReferences];
  };

  const renderReferences = () => {
    return (
      <div className={styleClass.listContainer}>
        {activeReferences.length > 0 &&
          <p className={Margin.MY_4}>
            Referanser som allerede er i bruk er desverre ikke mulig å endre.
            Ta kontakt med kundeservice om du vil endre på aktive referanser i avtalen
          </p>
        }
        {activeReferences && activeReferences.map((reference, key) => {
          return (
            <ActiveReference
              key={key}
              reference={reference}
            />
          )
        })
        }
        {newReferences && newReferences.map((reference, key) => {
          return (
            <NewReference
              key={key}
              index={key}
              reference={reference}
              onInputChanged={(e) => onInputChanged(e, key)}
              onListItemRemoved={removeReference(key)}
              selectedReference={selectedReference.name}
            />
          )
        })
        }
      </div>
    )
  };

  return (
    reference && !editMode ?
      <Card className={styleClass.referenceSelected.card}>
        <div className={styleClass.referenceSelected.header}>
          <p>REFERANSE {number + 1}</p>
          {reference.type === ReferenceType.LIST &&
            <div
              role="button"
              tabIndex={0}
              className={styleClass.referenceSelected.edit}
              onClick={toggleEditMode}
              onKeyPress={(e) => onKeyPress(e, toggleEditMode, EventKey.ENTER)}
            >
              <Icon name={IconName._EDIT} className={Margin.MR_2} color={IconColor.NEUTRAL_4} /> Endre
            </div>
          }
        </div>
        <div>
          {reference.type === ReferenceType.LIST ?
            <>
              <p className={styleClass.referenceSelected.text}>Ansatte velger <b>{reference.name}</b> fra liste.</p>
              <p className={styleClass.referenceSelected.smallText}>Liste: {reference.allowedValues && reference.allowedValues.toString()}</p>
            </>
            :
            <p>Ansatte skriver selv inn <b>{reference.name}</b></p>
          }
        </div>
      </Card>
      :
      <Card className={styleClass.card}>
        <div className={styleClass.header}>
          {hasReferenceName ? <p>Legg til {hasReferenceName.toLowerCase()}</p> : <p>Legg til referanse</p>}
          {hasReferenceName && editMode &&
            <div
              tabIndex={0}
              role="button"
              className={styleClass.headerCloseButton}
              onClick={toggleEditMode}
              onKeyPress={(e) => onKeyPress(e, toggleEditMode, EventKey.ENTER)}
            >
              <Icon name={IconName.CLOSE} className={Margin.MR_2} color={IconColor.NEUTRAL_4} /> Lukk
            </div>
          }
        </div>
        {!reference &&
          <Dropdown
            defaultTriggerText={'Velg type referanse'}
            hideLabel={true}
            data={referenceType}
            onChange={onSelectReference}
          />
        }
        {
          Object.keys(selectedReference).length > 0 ?
            <div className={styleClass.listContainer}>
              <p className={styleClass.listInfoText}>
                {selectedReference.type === ReferenceType.LIST ?
                  <>Opprett en liste de ansatte må velge fra når de registrerer sitt abonnement.</>
                  :
                  <>De ansatte må legge inn dette nummeret selv når de registrerer sitt abonnement.</>
                }
              </p>
              {selectedReference.type === ReferenceType.LIST && renderReferences()}
              {selectedReference.type === ReferenceType.LIST &&
              <Button
                iconLeft={<Icon name={IconName.ADD} />}
                text={'Legg til flere i listen'}
                ariaLabel={'Legg til flere i listen'}
                skin={ButtonSkin.SECONDARY}
                className={Margin.MB_0}
                onClick={addNewReferenceOnClick}
                onKeyPress={(e) => onKeyPress(e, function () { return setNewReference([...newReferences, ""]); }, EventKey.ENTER)}
              />}
              <Button
                text={'Lagre'}
                ariaLabel={'Lagre'}
                skin={ButtonSkin.CONFIRM}
                disabled={isReferenceValid}
                onClick={submitReference}
                onKeyPress={(e) => onKeyPress(e, submitReference, EventKey.ENTER)}
                iconRight={isLoading ?
                  <LoadingFragment
                    label={''}
                    style={{ minHeight: 0, marginLeft: '-1rem' }}
                  />
                  : undefined
                }
              />
              {message}
            </div>
            :
            <p className={styleClass.helpText}>Dette kan være ressursnummer, avdelingsnummer, navn på underavdeling osv.</p>
        }
      </Card>
  )
};

export default ReferenceSelector;
