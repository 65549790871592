import * as React from "react";
import {AdminType} from "../../../__generated__/globalTypes";
import AdminBoundary, {Props} from "./AdminBoundary";
import {RouteProps} from "react-router";

export const RoleBoundary: React.FunctionComponent<Props> = ({children, role, ...otherProps}) => (
  <AdminBoundary
    {...otherProps as RouteProps}
    role={role}
  >
    {children}
  </AdminBoundary>
);

export const SuperAdminOnlyBoundary: React.FunctionComponent<Omit<Props, 'role'>> = ({children, ...otherProps}) => (
  <RoleBoundary
    children={children}
    role={[AdminType.SUPERADMIN]}
    {...otherProps}
  />
);

export const SuperAdminOrAdminBoundary: React.FunctionComponent<Omit<Props, 'role'>> = ({children, ...otherProps}) => (
  <RoleBoundary
    children={children}
    role={[AdminType.SUPERADMIN, AdminType.ADMIN]}
    {...otherProps}
  />
);

export const SuperAdminOrSuperObserverBoundary: React.FunctionComponent<Omit<Props, 'role'>> = ({children, ...otherProps}) => (
  <RoleBoundary
    children={children}
    role={[AdminType.SUPERADMIN, AdminType.SUPEROBSERVER]}
    {...otherProps}
  />
);

export const SuperAdminOrAdminOrAccountantBoundary: React.FunctionComponent<Omit<Props, 'role'>> = ({children, ...otherProps}) => (
  <RoleBoundary
    children={children}
    role={[AdminType.SUPERADMIN, AdminType.SUPEROBSERVER, AdminType.ADMIN, AdminType.ACCOUNTANT]}
    {...otherProps}
  />
);
