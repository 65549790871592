// @ts-ignore
const createCookie = (name: string, value: string, days: number = 0) => {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  } else {
    expires = "";
  }
  document.cookie = encodeURIComponent(name) + "=" + encodeURIComponent(value) + expires + "; path=/";
};

const readCookie = (name: string) => {
  var results = document.cookie.match('(^|;) ?' + encodeURIComponent(name) + '=([^;]*)(;|$)');
  return results ? decodeURIComponent(results[2]) : null;
};

type StorageType = 'local' | 'session';

const StoragePolyFill = (type: StorageType) => {

  const setData = (data: any) => {
    data = JSON.stringify(data);
    if (type === 'session') {
      createCookie(getSessionName(), data);
    } else {
      createCookie('localStorage', data, 365);
    }
  };

  const clearData = () => {
    if (type === 'session') {
      createCookie(getSessionName(), '');
    } else {
      createCookie('localStorage', '', 365);
    }
  };

  const getData = () => {
    var data = readCookie(type === 'session' ? getSessionName() : 'localStorage');
    return data ? JSON.parse(data) : {};
  };

  const getSessionName = () => {
    if (!window.name) {
      window.name = Math.random() + '-' + (new Date().getTime());
    }
    return 'sessionStorage-' + window.name;
  };

  // Initialize if there's already data.
  let data = getData();

  const obj = {
    POLYFILLED: true,
    length: 0,
    clear() {
      data = {};
      this.length = 0;
      clearData();
    },
    getItem(key: string) {
      return Object.prototype.hasOwnProperty.call(data, key) ? data[key] : null;
    },
    key(i: number) {
      var ctr = 0;
      for (var k in data) {
        // eslint-disable-next-line eqeqeq
        if (ctr++ == i) {
          return k;
        }
      }
      return null;
    },
    removeItem(key: string) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        delete data[key];
        this.length--;
        setData(data);
      }
    },
    setItem(key: string, value: string) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        this.length++;
      }
      data[key] = '' + value;
      setData(data);
    }
  };
  return obj;
};

try {
  // Test webstorage existence.
  if (!window.localStorage || !window.sessionStorage) {
    // eslint-disable-next-line no-throw-literal
    throw "web storage not supported";
  }
  // Test webstorage accessibility - Needed for Safari private browsing.
  window.localStorage.setItem('storage_test', '1');
  window.localStorage.removeItem('storage_test');
} catch (e) {

  (function () {
    var localStorage = StoragePolyFill("local");
    var sessionStorage = StoragePolyFill("session");
    try {
      //@ts-ignore
      // noinspection JSConstantReassignment
      window.localStorage = localStorage;
      //@ts-ignore
      // noinspection JSConstantReassignment
      window.sessionStorage = sessionStorage;
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
    try {
      // For Safari private browsing need to also set the proto value.
      window.localStorage.__proto__ = localStorage;
      window.sessionStorage.__proto__ = sessionStorage;
      // eslint-disable-next-line no-empty
    } catch (e) {
    }
  })();
}

