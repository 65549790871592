import {
  BackgroundColor,
  classNames,
  Display,
  FontFamily,
  FontWeight,
  Grid,
  GridComposition,
  GridItem,
  Heading,
  HeadingLevel,
  Margin,
  MaxWidth,
  Overflow,
  TextColor,
  FontSize,
  Whitespace,
  Width,
  AlignItems,
  TextTransform,
  FlexWrap
} from '@snoam/pinata';
import * as React from 'react';
import {useContext} from 'react';
import AbbreviationTooltip from '../AbbreviationTooltip/AbbreviationTooltip';
import {GetAgreements_me_agreements_edges} from '../../__generated__/GetAgreements';
import {hasOnlyOneItem} from '../../utils';
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import { RbacSuperAdminOnly } from '../Boundary';
import moment from "moment";

const styleClass = {
  activeProducts: classNames(
    TextColor.TEXT_NEUTRAL_5,
    Margin.ML_6
  ),
  agreement: {
    name: classNames(
      FontSize.TEXT_LG,
      TextColor.TEXT_NEUTRAL_6,
      FontWeight.FONT_SEMIBOLD,
      Display.INLINE_BLOCK,
      AlignItems.ITEMS_CENTER,
      Overflow.OVERFLOW_HIDDEN,
      MaxWidth.MAX_W_FULL,
      Whitespace.WHITESPACE_NO_WRAP
    ),
    subHeading: classNames(
      TextTransform.UPPERCASE,
      FontSize.TEXT_SM,
      FontWeight.FONT_SEMIBOLD,
      FontFamily.FONT_BODY
    ),
  },
};

interface IAgreementRowProps {
  expanded: boolean;
  agreement: GetAgreements_me_agreements_edges;
}

const AgreementNumberAndProducts = ({agreement}: IAgreementRowProps) => {

  const {mobile, tablet} = useContext(ResponsiveContext);

  const activeProducts = agreement.products.filter(p => p.active);
  return (
    <div className={styleClass.activeProducts}>
      {agreement.agreementNumber}
      <span
        className={Margin.MX_2}>-</span>
        {activeProducts.length} produkt{!hasOnlyOneItem(activeProducts) ? 'er' : ''}

      {(!mobile && !tablet) &&
        <RbacSuperAdminOnly>
          <span className={Margin.MX_2}>-</span>{`${agreement.agreementSubscriberCount} abonnement`}
          {agreement.settings.activationPotential && (
            <> av {agreement.settings.activationPotential}</>
          )}
          {agreement.manualInvoiceFlag &&
            <><span className={Margin.MX_2}>-</span>Manuell Fakturering</>}
        </RbacSuperAdminOnly>
      }
    </div>
  )
};

const renderOrderingStatus = (agreement: GetAgreements_me_agreements_edges) => {
  const size = '.875rem';
  return (
    <AbbreviationTooltip
      title={agreement.settings.enableOrdering ? 'Invitasjon til ansatte er aktiv' : 'Invitasjon til ansatte er skrudd av'}
      className={classNames(Width.W_6)}
    >
      <div
        className={classNames(agreement.settings.enableOrdering ? BackgroundColor.BG_POSITIVE : BackgroundColor.BG_NEUTRAL_4)}
        style={{height: size, width: size, borderRadius: size, marginRight: size}}
      />
    </AbbreviationTooltip>
  )
};

const AgreementRow = ({expanded, agreement}: IAgreementRowProps) => {

  const {mobile} = useContext(ResponsiveContext);

  return (
    <Grid
      composition={mobile ? GridComposition.BRAVO : GridComposition.ECHO}
      className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER, FlexWrap.FLEX_WRAP)}
    >
      <GridItem className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER)}>
        {renderOrderingStatus(agreement)}
        <div>
          <Heading
            level={HeadingLevel.TWO}
            className={styleClass.agreement.name}
            style={{textOverflow: 'ellipsis'}}>
            {agreement.accountName}
          </Heading>
          {agreement.created && <div className={classNames(FontSize.TEXT_SM)}> Opprettet {moment(agreement.created, "MM/DD/YYYY hh:mm:ss").format("DD.MM.YYYY")}</div>}
        </div>
      </GridItem>

      <AgreementNumberAndProducts
        agreement={agreement}
        expanded={expanded}
      />

    </Grid>
  );
};

export default AgreementRow;
