import {
  AlignItems,
  classNames,
  Display,
  Icon,
  IconName,
  JustifyContent
} from "@snoam/pinata";
import * as React from "react";
import {CSSProperties} from "react";

const styleClass = {
  root: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER,
    'loading'
  )
};

export const LoadingFragment = ({label = 'Laster ...', style = {minHeight: '70vh'}}: { label?: string; style?: CSSProperties }) => {
  return (
    <div className={styleClass.root}
         style={style}>
      <Icon name={IconName.LOADING_WHEEL}/> {label}
    </div>
  )
};
