import * as React from "react";
import {
  classNames,
  Container,
  Display,
  Overflow,
  Padding,
  TextColor,
  Width,
  ListStyleType,
  FlexDirection, ContainerWidth
} from "@snoam/pinata";
import { ReactNode } from 'react';

const styleClass = {
  root: classNames(
    Overflow.OVERFLOW_X_AUTO,
    Overflow.OVERFLOW_Y_SCROLL,
    TextColor.TEXT_PRIMARY_LIGHT,
  ),
  menu: classNames(
    ListStyleType.LIST_NONE,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Width.W_FULL,
    Padding.PT_2
  )
};

export interface IMobileMenuProps {
  menuOpen: boolean;
  children: ReactNode;
}

export const MobileMenu = (props: IMobileMenuProps) => {
  const {menuOpen, children} = props;
  return (
    <Container width={ContainerWidth.NARROW} className={styleClass.root} style={{WebkitOverflowScrolling: 'touch'}}>
      {
        menuOpen && <ul className={styleClass.menu}>
          {children}
        </ul>
      }
    </Container>

  );
};

export default MobileMenu;
