import * as React from "react";
import {
  AlignItems,
  BackgroundColor,
  BorderRadius,
  Button,
  ButtonSkin,
  classNames,
  Display,
  Fill,
  FlexShrink,
  FontWeight,
  Height,
  Icon,
  IconName,
  JustifyContent,
  Margin,
  MaxWidth,
  Padding,
  TextColor,
  Width,
} from "@snoam/pinata";

export interface IAdministrationAddButtonProps {
  toggleModal: () => void;
  isMobile: boolean;
}

const styleClass = {
  button: classNames(
    MaxWidth.MAX_W_XS,
    BackgroundColor.BG_PRIMARY_3,
    BackgroundColor.HOVER_BG_PRIMARY_4,
    TextColor.TEXT_WHITE,
    FontWeight.FONT_NORMAL,
    Padding.PL_3
  ),
  svgAdd: (fill: string) => classNames(
    FlexShrink.FLEX_SHRINK_0,
    Margin.M_2,
    Height.H_6,
    Width.W_6,
    fill
  ),
  iconBtn:classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
    BackgroundColor.BG_PRIMARY_3,
    BorderRadius.ROUNDED_FULL,
    Width.W_12, Height.H_12,
  )
};

const AdministrationAddButton = ({ isMobile, toggleModal }: IAdministrationAddButtonProps) => {
  return (
    isMobile ?
      <div
        tabIndex={0}
        role="button"
        onClick={() => toggleModal()}
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter' || e.key === ' ') {
            toggleModal();
          }
        }}
        className={styleClass.iconBtn}>
        <Icon
          key={`${IconName.ADD}`}
          name={IconName.ADD}
          className={styleClass.svgAdd(Fill.FILL_WHITE)}
          screenReaderText={'Legg til administrator'}
        />
      </div>
      :
      <Button
        text={"Legg til Schibsted administrator"}
        ariaLabel={"Legg til Schibsted administrator"}
        skin={ButtonSkin.SECONDARY}
        className={styleClass.button}
        style={{ transition: 'background-color 500ms ease' }}
        iconLeft={
          <Icon
            key={`${IconName.ADD}`}
            name={IconName.ADD}
            className={styleClass.svgAdd(Fill.FILL_WHITE)}
          />
        }
        onClick={() => toggleModal()}
      />
  )
};

export default AdministrationAddButton;
