import {AdminType} from "../__generated__/globalTypes";

export interface IAdminRole {
  id: AdminType,
  label: string;
}

export default class AdminModel {

  static SchibstedAdministrationRoles: IAdminRole[] = [
    {
      id: AdminType.SUPERADMIN,
      label: 'Schibsted administrator',
    },
    {
      id: AdminType.SUPEROBSERVER,
      label: 'Schibsted administrator med kun lesetilgang'
    }

  ];

  static AdministrationRoles: IAdminRole[] = [
    {
      id: AdminType.ADMIN,
      label: 'Administrator',
    },
    {
      id: AdminType.ACCOUNTANT,
      label: 'Kun leserettigheter',
    }
  ]
}
