import {
  BorderColor,
  BorderWidth,
  Card,
  classNames,
  Display,
  FontFamily,
  FontWeight,
  Grid,
  GridComposition,
  GridItem,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  Margin,
  StyledRouterLink,
  FontSize,
  TextTransform,
  AlignItems,
  JustifyContent,
} from '@snoam/pinata';
import * as React from 'react';
import {useContext} from 'react';
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {useRole} from "../Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";

const styleClass = {
  agreement: {
    root: (mobile: boolean | undefined, tablet: boolean | undefined) => classNames(
      BorderColor.BORDER_NEUTRAL_3,
      (tablet || mobile) ? BorderWidth.BORDER_T_1 : BorderWidth.BORDER_R_1
    ),
    subHeading: classNames(
      TextTransform.UPPERCASE,
      FontSize.TEXT_XS,
      FontWeight.FONT_SEMIBOLD,
      FontFamily.FONT_BODY
    ),
    link: classNames(
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      JustifyContent.JUSTIFY_END,
      'editLink'
    )
  },
};

export interface IAgreementsCardProps {
  icon: JSX.Element,
  pathName: string;
  title: string;
}

const AgreementsCard: React.FunctionComponent<IAgreementsCardProps> = ({icon, pathName, title, children}) => {

  const {mobile, tablet} = useContext(ResponsiveContext);
  const {role: userRole} = useRole();

  return (
    <Card className={styleClass.agreement.root(mobile, tablet)}>
      <Grid composition={GridComposition.ALPHA}>
        <GridItem style={{flex: 1}}>
          {icon}
        </GridItem>
        <GridItem style={{flex: 6}}>
          <Grid>
            <GridItem>
              <Heading
                level={HeadingLevel.THREE}
                className={styleClass.agreement.subHeading}>
                {title}
              </Heading>
              <div className={classNames(Display.FLEX, JustifyContent.JUSTIFY_END)}>
                {userRole === AdminType.SUPERADMIN &&
                  <StyledRouterLink
                    to={pathName}
                    className={styleClass.agreement.link}
                    style={{outline: 'none'}}
                  >
                    <span className={classNames(Margin.MR_2)}>Endre</span>
                    <Icon
                      name={IconName._EDIT}
                      color={classNames(IconColor.NEUTRAL_4)}
                    />
                  </StyledRouterLink>
                }
              </div>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
      {children}
    </Card>
  )
};

export default AgreementsCard;
