import React, { useState } from "react";
import {
  AlignItems,
  BorderColor,
  BorderWidth,
  classNames,
  Display,
  Dropdown,
  Heading,
  HeadingLevel,
  Padding,
  TextColor,
  Width
} from "@snoam/pinata";
import { $PropertyType } from "utility-types/dist/utility-types";
import {
  GetAgreement_me_agreementsWithDetails,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsAzureAdGraphApi,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaApi,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaSystemLog,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsPhenixId
} from "../../../../__generated__/GetAgreement";

import { default as EmploymentPeriodicVerificationSettingsAzureAdGraphApi } from "./EmploymentPeriodicVerificationSettingsAzureAdGraphApi";
import { default as EmploymentPeriodicVerificationSettingsPhenixId } from "./EmploymentPeriodicVerificationSettingsPhenixId";
import { default as EmploymentPeriodicVerificationSettingsOktaApi } from "./EmploymentPeriodicVerificationSettingsOktaApi";
import { default as EmploymentPeriodicVerificationSettingsOktaSystemLog } from "./EmploymentPeriodicVerificationSettingsOktaSystemLog";
import EmploymentPeriodicVerificationSettingsNone from "./EmploymentPeriodicVerificationSettingsNone";

export {
  default as EmploymentPeriodicVerificationSettingsPhenixId
} from "./EmploymentPeriodicVerificationSettingsPhenixId";

export {
  default as EmploymentPeriodicVerificationSettingsOktaSystemLog
} from "./EmploymentPeriodicVerificationSettingsOktaSystemLog";

const styleClass = {
  settings: {
    verificationMethods: classNames(
      Display.FLEX,
      AlignItems.ITEMS_START,
      Width.W_SM,
    ),
    section: classNames(
      BorderColor.BORDER_NEUTRAL_2,
      BorderWidth.BORDER_B_4,
      Padding.PX_4,
      Padding.MD_PX_10,
      Padding.PT_8,
      Padding.PB_10,
    ),
    sectionTitle: classNames(
      TextColor.TEXT_NEUTRAL_8,
    ),
  }
};

type EmploymentPeriodicVerificationSettingsTypename = $PropertyType<
  | GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsAzureAdGraphApi
  | GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaApi
  | GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaSystemLog
  | GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsPhenixId,
  "__typename"
> | "";

interface EmploymentPeriodicVerificationSettingsProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}

export const periodicEmployeeVerificationMethods: { name: string, value: string }[] = [{
  name: "Ingen periodisk verifisering",
  value: "",
}, {
  name: "Azure AD Graph API",
  value: "EmploymentPeriodicVerificationSettingsAzureAdGraphApi",
}, {
  name: "Okta API",
  value: "EmploymentPeriodicVerificationSettingsOktaApi",
}, {
  name: "Okta systemlogg",
  value: "EmploymentPeriodicVerificationSettingsOktaSystemLog",
}, {
  name: "PhenixID API",
  value: "EmploymentPeriodicVerificationSettingsPhenixId",
}];

const EmploymentPeriodicVerificationSettings: React.FC<EmploymentPeriodicVerificationSettingsProps> = ({ agreement }) => {

  const { settings: { employeePeriodicVerificationSettings } } = agreement;

  const s = employeePeriodicVerificationSettings || {
    __typename: "",
  };
  const [periodicEmployeeVerificationMethod, setPeriodicEmployeeVerificationMethod] = useState<EmploymentPeriodicVerificationSettingsTypename>(s.__typename || "");

  const onChangePeriodicEmployeeVerificationMethod: (value: string) => void = (value) => {
    const typename = value as EmploymentPeriodicVerificationSettingsTypename;
    setPeriodicEmployeeVerificationMethod(typename);
  };

  return (
    <>
      <div className={styleClass.settings.section}>
        <Heading
          level={HeadingLevel.TWO}
          className={styleClass.settings.sectionTitle}
        >
          Ønsker du periodisk verifisering av de som har abonnement fortsatt jobber i deres bedrift?
        </Heading>
        <Dropdown
          onChange={onChangePeriodicEmployeeVerificationMethod}
          hideLabel={true}
          data={periodicEmployeeVerificationMethods}
          value={periodicEmployeeVerificationMethod || ""}
          />
        {periodicEmployeeVerificationMethod === "" ? <EmploymentPeriodicVerificationSettingsNone agreement={agreement}/> : null}
        {periodicEmployeeVerificationMethod === "EmploymentPeriodicVerificationSettingsAzureAdGraphApi" ? <EmploymentPeriodicVerificationSettingsAzureAdGraphApi agreement={agreement}/> : null}
        {periodicEmployeeVerificationMethod === "EmploymentPeriodicVerificationSettingsOktaApi" ? <EmploymentPeriodicVerificationSettingsOktaApi agreement={agreement}/> : null}
        {periodicEmployeeVerificationMethod === "EmploymentPeriodicVerificationSettingsOktaSystemLog" ? <EmploymentPeriodicVerificationSettingsOktaSystemLog agreement={agreement}/> : null}
        {periodicEmployeeVerificationMethod === "EmploymentPeriodicVerificationSettingsPhenixId" ? <EmploymentPeriodicVerificationSettingsPhenixId agreement={agreement}/> : null}
      </div>
    </>
  );
};

export default EmploymentPeriodicVerificationSettings;
