import * as React from 'react';
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Display,
  FlexDirection,
  FlexShrink,
  Grid,
  GridItem,
  GridSpan,
  Heading,
  HeadingLevel,
  JustifyContent, Margin,
  Padding,
  Width,
} from '@snoam/pinata';
import TableRow from './TableRow';
import {Pagination, PaginationProvider} from "../Pagination/Pagination";


export interface ITableProps<T> {
  rows: T[];
  rowRenderer: (item: T, expanded: boolean) => any;
  alternatingColor?: boolean;
  canExpand?: (item: T) => boolean;
  expandedRenderer?: (item: T) => any;
  expandSymbolRenderer?: (item: T) => any;
  closeSymbolRenderer?: (item: T) => any;
  backgroundColorHeaderExpanded?: string;
  backgroundColorBodyExpanded?: string;
  pageSize?: number;
  enablePagination?: boolean;
}

const styleClass = {
  paginationContainer: {
    root: classNames(
      Padding.PT_2,
      Display.INLINE_FLEX,
      Width.W_FULL,
      AlignItems.ITEMS_CENTER,
      FlexDirection.MD_FLEX_ROW,
      FlexDirection.SM_FLEX_COL,
    ),
    innerContainer: classNames(
      Display.FLEX,
      JustifyContent.SM_JUSTIFY_CENTER,
      JustifyContent.MD_JUSTIFY_START,
      FlexDirection.MD_FLEX_ROW,
      FlexDirection.XS_FLEX_COL,
      AlignItems.ITEMS_CENTER
    ),
    heading: classNames(
      Display.INLINE_BLOCK,
      Margin.MR_3
    )
  }
};

const Table = <T extends object>(props: ITableProps<T>) => {

  const {
    rows,
    pageSize = 25,
    alternatingColor,
    backgroundColorBodyExpanded,
    backgroundColorHeaderExpanded,
    canExpand = () => false,
    closeSymbolRenderer,
    expandedRenderer,
    expandSymbolRenderer,
    rowRenderer,
    enablePagination
  } = props;

  return (
    <PaginationProvider
      pageSize={pageSize}
      rows={rows}
      enablePagination={!!enablePagination}
    >
      {
        ({chunk, canEnablePagination, page, from, to}) => (
          <>
            <div className={FlexShrink.FLEX_SHRINK_0}>
              {
                chunk.map((row, i) => {
                  const backgroundHeaderColor = alternatingColor && i % 2 !== 0 ? BackgroundColor.BG_NEUTRAL_2 : BackgroundColor.BG_WHITE;
                  return (
                  <TableRow
                      key={`index:${i}_page:${page}`}
                      row={row}
                      rowRenderer={rowRenderer}
                      canExpand={canExpand}
                      expandedRenderer={expandedRenderer}
                      backgroundHeaderColor={backgroundHeaderColor}
                      backgroundColorHeaderExpanded={backgroundColorHeaderExpanded}
                      backgroundColorBodyExpanded={backgroundColorBodyExpanded}
                      expandSymbolRenderer={expandSymbolRenderer}
                      closeSymbolRenderer={closeSymbolRenderer}
                    />
                  )
                })
              }
            </div>

            {
              canEnablePagination && <Grid className={styleClass.paginationContainer.root}>
								<GridItem span={[GridSpan.XSMALL_12, GridSpan.MEDIUM_6]}>
									<div className={styleClass.paginationContainer.innerContainer}>
										<Heading
											level={HeadingLevel.FIVE}
											className={styleClass.paginationContainer.heading}>
                      {`Viser ${from}-${to} av ${rows.length}`}
										</Heading>
										<Pagination key={`pagination_${chunk.length}_${rows.length}`}/>
									</div>
								</GridItem>
							</Grid>
            }
          </>
        )
      }
    </PaginationProvider>
  );
};

export default Table;
