import React, { ReactNode } from "react";
import {
  BorderColor,
  BorderWidth,
  Checkbox,
  classNames,
  Display,
  FlexDirection,
  Margin,
  Width
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Between, Eq, Gt} from "../../components/Filter/types";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4,
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

export type IActivationDegreeFilterProps  = {

}

export const ActivationDegreeFilter: React.FunctionComponent<IActivationDegreeFilterProps> = () => {

  const child = (text: string): ({active, state, toggleFilter, filterValue}: any) => JSX.Element => {
    return ({active, toggleFilter}) => (
      <div className={styleClass.inner}>
        <Checkbox
          checked={!!active}
          onClick={toggleFilter}
          text={text}
        />
      </div>
    );
  };

  return (
    <Filter
      heading={'Aktiveringsgrad'}
      id={'activationDegree_not_setup'}
      wrapper={customWrapper}
    >
      <Eq id={'activationDegree_null'} key={'activationDegree_null'} name={'activationDegree'} value={null}>
        {child('Ikke satt')}
      </Eq>
      <Between id={'activationDegree_0_to_10'} name={'activationDegree'}
               value={{ lowerInclusive: 0, upperExclusive: 10 }}>
        {child(`0 - <10%`)}
      </Between>
      <Between id={'activationDegree_10_to_25'} name={'activationDegree'}
               value={{ lowerInclusive: 10, upperExclusive: 25 }}>
        {child(`10 - <25%`)}
      </Between>
      <Between id={'activationDegree_25_to_50'} name={'activationDegree'}
               value={{ lowerInclusive: 25, upperExclusive: 50 }}>
        {child(`25 - <50%`)}
      </Between>
      <Between id={'activationDegree_50_to_75'} name={'activationDegree'}
               value={{ lowerInclusive: 50, upperExclusive: 75 }}>
        {child(`50 - <75%`)}
      </Between>
      <Between id={'activationDegree_75_to_100'} name={'activationDegree'}
               value={{ lowerInclusive: 75, upperExclusive: 100 }}>
        {child(`75 - <100%`)}
      </Between>
      <Eq id={'activationDegree_100'} name={'activationDegree'} value={100}>
        {child(`100%`)}
      </Eq>
      <Gt id={'activationDegree_over_100'} name={'activationDegree'} value={100}>
        {child(`>100%`)}
      </Gt>
    </Filter>
  );
};

export default ActivationDegreeFilter;
