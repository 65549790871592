import { default as React, Dispatch, SetStateAction } from 'react';
import { useQuery } from '@apollo/client';

import { Props as InvoiceButtonProps } from './InvoiceButton';
import { GetInvoice, GetInvoiceVariables } from '../../__generated__/GetInvoice';
import { GET_INVOICE } from '../../queries';

interface Props extends InvoiceButtonProps {
  setDownloading: Dispatch<SetStateAction<boolean>>
  setLoading: Dispatch<SetStateAction<boolean>>
}

const FetchInvoice: React.FunctionComponent<Props> = (props) => {
  const {accountNumber, agreementNumber, filename, invoiceNumber, setError, setDownloading, setLoading} = props;
  const {data, error, loading} = useQuery<GetInvoice, GetInvoiceVariables>(GET_INVOICE, {
    variables: {
      accountNumber,
      agreementNumber,
      invoiceNumber,
    },
  });

  setError(null);
  setLoading(loading);

  if (loading) {
    return null;
  }

  if (error) {
    setDownloading(false);
    setError("Noe gikk galt under lasting av faktura. Prøv gjerne igjen senere.");
    setLoading(false);
    return null;
  }

  if (!data) {
    setError(`Noe gikk galt under lasting av faktura. Prøv gjerne igjen senere.`);
    setLoading(false);
    return null;
  }

  if (!data.me.invoice) {
    setError(`Fant ikke faktura.`);
    setLoading(false);
    return null;
  }

  setError(null);
  setLoading(false);
  const {contentType: type, dataBase64Encoded} = data.me.invoice;
  const byteArray = new Uint8Array(
    atob(dataBase64Encoded)
      .split('')
      .map(char => char.charCodeAt(0))
  );
  const blob = new Blob([byteArray], {type});
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    if (filename) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      window.navigator.msSaveOrOpenBlob(blob);
    }
  } else {
    const blobUrl = URL.createObjectURL(blob);

    if (filename) {
      const a = document.createElement("a");
      a.href = blobUrl;
      a.setAttribute("download", filename);
      a.click();
    } else {
      window.open(blobUrl, '_blank');
    }
  }

  setDownloading(false);
  return null;
};

export default FetchInvoice;
