import React, {useContext, useState} from "react";
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  FontSize,
  FontWeight,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  JustifyContent,
  Margin,
  Padding,
  Table,
  TableCell,
  TableCellType,
  TableGroup,
  TableGroupType,
  TableRow,
  TextColor
} from "@snoam/pinata";
import {MutationFunction, useMutation, useQuery} from "@apollo/client";
import {GetAllMessages} from "../../__generated__/GetAllMessages";
import {GET_ALL_MESSAGES} from "../../queries";
import moment from "moment";
import {DeleteMessage, DeleteMessageVariables} from "../../__generated__/DeleteMessage";
import {DELETE_MESSAGE} from "../../mutations";
import {Modal, ModalContext} from "@snoam/mono-modal";
import SvgImage from "../../components/SvgImage/SvgImage";
import {groupProductsByProductName} from "../../components/GroupProducts/GroupProducts";
import {simpleMarkdown} from "../../utils";
import {useRole} from "../../components/Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";

const styleClass = {
  root: classNames(
    BackgroundColor.BG_WHITE,
    Padding.P_4,
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_3,
  ),
  table: {
    root: classNames(
      Margin.MT_6,
    ),
    headerRow: classNames(
      FontSize.TEXT_SM,
      FontWeight.FONT_NORMAL,
      TextColor.TEXT_NEUTRAL_5,
      Margin.MB_3,
    ),
    headerCell: classNames(
      FontWeight.FONT_NORMAL
    ),
    row: classNames(
      BackgroundColor.HOVER_BG_NEUTRAL_2,
      Margin.MY_3,
      FontSize.TEXT_SM,
      BorderStyle.BORDER_SOLID,
      BorderColor.BORDER_NEUTRAL_2,
      BorderWidth.BORDER_B_1,
    ),
  },

};

interface IDeleteMessageModal {
  id: string,
  onClose: () => void,
  onConfirm: () => void,
  loading: boolean,
}

const DeleteMessageModal = ({id, onClose, onConfirm, loading = false}: IDeleteMessageModal) => {
  const {closeModal} = useContext(ModalContext);

  return(
    <Modal
      key={id}
      id={id}
      onClose={closeModal}
      backdrop={true}
      className={BackgroundColor.BG_NEUTRAL_1}
    >
      <div className={classNames(Display.FLEX, FlexDirection.FLEX_COL, JustifyContent.JUSTIFY_CENTER)}>
        <SvgImage
          svgStyle={{width: '60px', height: 'auto'}}
          href={`${process.env.PUBLIC_URL}/svg/megaphone.svg`}
          className={classNames(Margin.MX_AUTO)}
        />
        <Heading level={HeadingLevel.THREE}>Er du sikker på at du vil slette denne meldingen?</Heading>
        <Button skin={ButtonSkin.CONFIRM} text={"Ja"} onClick={onConfirm} loading={loading} loadingText={"Sletter melding"} />
        <Button skin={ButtonSkin.NEGATIVE} text={"NEI"} onClick={onClose} />
      </div>
    </Modal>
  )
}

const ActiveMessages: React.FC = () => {
  const {openModal, closeModal} = useContext(ModalContext);
  const [loading, setLoading] = useState<boolean>(false)
  const deleteAdminMessage: MutationFunction<DeleteMessage, DeleteMessageVariables> = useMutation<DeleteMessage, DeleteMessageVariables>(DELETE_MESSAGE)[0];
  const {data, loading: messagesLoading, error} = useQuery<GetAllMessages>(GET_ALL_MESSAGES, {});
  const {role: userRole} = useRole();

  const onDeleteMessage = async (id: string) => {
    setLoading(true);
    await deleteAdminMessage({
      refetchQueries: [{
        query: GET_ALL_MESSAGES,
        variables: {}
      }],
      variables: {
        messageId: Number.parseInt(id)
      },
      awaitRefetchQueries: true
    });
    setLoading(false);
  };

  const toggleModal = (id: string) => {
    openModal(`message-${id}`);
  }

  const renderMessages = () => {
    if(messagesLoading || !data || error) {
      return null;
    }

    const groupProducts = groupProductsByProductName(data.availableProducts).grouped;
    const productNames = groupProducts.map(p => {
      const productName = Object.keys(p)[0];
      const productGroup = Object.values(p)[0][0].productGroup;
      return {
        productName,
        productGroup
      }
    });

    if(data && data.adminMessages) {
      return data.adminMessages.map(message => {
        const products = message.productGroups.map(pg => {
          const match = productNames.find(pn => pn.productGroup === pg)
          return match ? match.productName : pg;
        })
        return (
          <TableRow className={styleClass.table.row} key={`message-${message.id}`}>
            <TableCell>{moment(Number.parseInt(message.createdAt)).format('DD.MM.YYYY')}</TableCell>
            <TableCell>{products.join(", ")}</TableCell>
            <TableCell><span dangerouslySetInnerHTML={{__html: simpleMarkdown(message.message)}} /></TableCell>
            <TableCell>
              {userRole === AdminType.SUPERADMIN && <Icon name={IconName.DELETE} color={IconColor.NEUTRAL_5} onClick={() => toggleModal(message.id)} />}
            </TableCell>
            {userRole === AdminType.SUPERADMIN &&  <DeleteMessageModal
              id={`message-${message.id}`}
              onClose={() => closeModal()}
              onConfirm={() => onDeleteMessage(message.id)}
              loading={loading}
            />}
          </TableRow>
        )
      })
    }
    return null;
  }

  return (
    <div className={styleClass.root}>
      <Heading level={HeadingLevel.TWO}>Aktive meldinger</Heading>
      <Table className={styleClass.table.root}>
        <TableGroup type={TableGroupType.HEAD}>
          <TableRow className={styleClass.table.headerRow}>
            <TableCell type={TableCellType.HEAD} className={styleClass.table.headerCell}>Sendt</TableCell>
            <TableCell type={TableCellType.HEAD} className={styleClass.table.headerCell}>Produkter</TableCell>
            <TableCell type={TableCellType.HEAD} className={styleClass.table.headerCell}>Meldinger</TableCell>
            {userRole === AdminType.SUPERADMIN && <TableCell type={TableCellType.HEAD} className={styleClass.table.headerCell}>Slett</TableCell> }
          </TableRow>
          {renderMessages()}
        </TableGroup>
      </Table>
    </div>
  )
}

export default ActiveMessages;
