import React from "react";
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Display,
  FlexDirection,
  Heading,
  HeadingLevel,
  Height,
  MinHeight,
  Padding, Position, TextColor, TextDecoration, Width
} from "@snoam/pinata";
import SvgImage from "../../components/SvgImage/SvgImage";

const styleClass = {
  root: classNames(
    BorderWidth.BORDER_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_3,
    BackgroundColor.BG_WHITE,
    Padding.P_4,
    MinHeight.MIN_H_MD,
    Display.FLEX,
    FlexDirection.FLEX_COL,
  ),
  link: classNames(
    Padding.PY_2,
    TextDecoration.UNDERLINE,
    TextColor.TEXT_PRIMARY_1
  )
}

const links = [
  {
    title: "Daglige aktive abonnenter",
    href: "https://tableau.schibsted.io/#/site/sch-no-publishing/views/DagligaktiveabonnenterpB2BFulltilgang/DagligaktiveabonnenterpB2BFulltilgang?:iid=1",
  },
  {
    title: "Bruk per bedrift",
    href: "https://tableau.schibsted.io/#/site/sch-no-publishing/views/B2BActiveCustomers/Specificinfo?:iid=1"
  }
]

const TableauReports: React.FunctionComponent = () => {
  return (
    <div className={styleClass.root}>
      <Heading level={HeadingLevel.TWO}>
        Rapporter i Tableau
      </Heading>
      <div className={classNames(Padding.PT_4, Display.FLEX, FlexDirection.FLEX_COL)}>
        {links.map(l =>
          <a key={l.title} href={l.href} target={"_blank"} rel="noopener noreferrer" className={styleClass.link}>{l.title}</a>
        )}
      </div>
      <div className={classNames(Position.RELATIVE, Height.H_FULL)}>
      <SvgImage
        href={`${process.env.PUBLIC_URL}/svg/schibsted-love-privacy.svg`}
        svgStyle={{right: '20px', bottom: '20px'}}
        className={classNames(Width.W_AUTO, Position.ABSOLUTE)}
      />
      </div>
    </div>
  )
};

export default TableauReports;
