import React from "react";
import {
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Icon,
  Margin,
  TextColor,
  FontSize,
  Toggle,
  ToggleSize,
  ToggleSkin,
  Width,
  FlexDirection,
  FlexGrow,
  FlexShrink,
  AlignItems,
  JustifyContent,
  IconName,
  IconColor
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Length} from "../../components/Filter/types/Length";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    FlexGrow.FLEX_GROW_0,
    FlexShrink.FLEX_SHRINK_0,
    AlignItems.ITEMS_START,
    JustifyContent.JUSTIFY_BETWEEN
  ),
  descr: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Width.MD_W_FULL,
      FlexGrow.FLEX_GROW
    ),
    icon: classNames(
      Margin.MT_1,
      Margin.MR_2,
    ),
    heading: classNames(
      FontSize.TEXT_SM,
      Margin.MB_1,
      FontWeight.FONT_SEMIBOLD,
    ),
    text: classNames(
      TextColor.TEXT_NEUTRAL_4,
      FontSize.TEXT_SM
    )
  },
  toggle: ''
};
const inputId = `toggle_only_active_subscribers`;
export const OnlyActiveSubscribersFilter = () => (
  <Filter
    id={'OnlyActiveSubscribersFilter'}
    uniqueBy={'customerNumber'}
  >
    <Length
      id={'OnlyActiveSubscribersFilter_0'}
      name={'activeSubscriptions'}
      value={0}
      variant={'gt'}
      defaultEnabled={true}
    >
      {
        ({active, toggleFilter}) => (
          <label className={styleClass.root} htmlFor={inputId}>

            <div className={styleClass.descr.icon}>
              <Icon name={IconName.FEEDBACK} color={IconColor.NEUTRAL_4}/>
            </div>
            <div className={styleClass.descr.root}>
              <Heading level={HeadingLevel.FIVE} className={styleClass.descr.heading}>
                Inkluder inaktive abonnenter
              </Heading>
              <span className={styleClass.descr.text}>
                Listen vil inkludere abonnenter uten aktiv abonnement
              </span>
            </div>

            <div className={styleClass.toggle}>
              <Toggle
                id={inputId}
                size={ToggleSize.LARGE}
                skin={ToggleSkin.PRIMARY}
                checked={!active}
                onClick={toggleFilter}
              />
            </div>
          </label>
        )
      }
    </Length>
  </Filter>
);
