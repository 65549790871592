import React, { FormEventHandler, useEffect, useState } from "react";
import {
  Arrow,
  Button,
  classNames,
  Cursor,
  Display,
  FlexDirection,
  FontSize,
  Icon,
  IconName,
  Margin,
  Padding,
  Position,
  Width,
} from "@snoam/pinata";
import {GetAgreement_me_agreementsWithDetails} from '../../../__generated__/GetAgreement';
import {
  UpdateAgreementSettings,
  UpdateAgreementSettingsVariables
} from '../../../__generated__/UpdateAgreementSettings';
import { ApolloError, MutationFunction, useMutation } from '@apollo/client';
import { UPDATE_AGREEMENT_SETTINGS } from '../../../mutations';
import { removeTypename } from '../../../utils';
import InputWithValidator from '../../../components/InputWithValidator/InputWithValidator';
import { MessageBox } from "@snoam/mono-messagebox";

export const styleClass = {
  settings: {
    openidContainer: classNames(
      Margin.MT_6,
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Width.W_FULL,
    ),
    openidInputContainer: classNames(
      Position.RELATIVE,
      Cursor.CURSOR_POINTER,
      Width.W_FULL,
      Margin.MR_2,
      Margin.MB_4
    ),
    input: classNames(
      FontSize.TEXT_BASE,
    ),
    label: classNames(
      FontSize.TEXT_SM,
    ),
    message: classNames(
      Padding.P_8,
      Width.W_SM,
    ),
  }
};

interface SettingsPanelProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}

export const urlValidator = (openidConnectDiscoveryUrl: string | undefined): boolean => {
  return openidConnectDiscoveryUrl ? /https?:\/\/.+/.test(openidConnectDiscoveryUrl) : false;
};

type ErrorStates = Partial<Record<
  | "openidConnectClientId"
  | "openidConnectDiscoveryUrl"
  | "openidConnectSystemName"
  | "openidConnectClientSecret"
  | "openidConnectUserIdField"
  | "openidConnectScope"
  | "feideApiCheckUserExistenceEndpoint"
  , string[]>>;

interface SaveStatus {
  errors: ErrorStates;
  pending: boolean;
  success: boolean;
}

const VerificationSectionOpenidConnect = ({ agreement }: SettingsPanelProps) => {
  const settings = agreement.settings;
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>({ errors: {}, pending: false, success: false });
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const [openidConnectDiscoveryUrl, setOpenidConnectDiscoveryUrl] = useState<string>(settings.openidConnectDiscoveryUrl || '');
  const [openidConnectUserIdField, setOpenidConnectUserIdField] = useState<string>(settings.openidConnectUserIdField || 'sub');
  const [openidConnectSystemName, setOpenidConnectSystemName] = useState<string>(settings.openidConnectSystemName || '');
  const [openidConnectClientId, setOpenidConnectClientId] = useState<string>(settings.openidConnectClientId || '');
  const [openidConnectClientSecret, setOpenidConnectClientSecret] = useState<string>(settings.openidConnectClientSecretMasked || '');
  const [openidConnectScope, setOpenidConnectScope] = useState<string>(settings.openidConnectScope || 'openid profile');
  const [feideApiCheckUserExistenceEndpoint, setFeideApiCheckUserExistenceEndpoint] = useState<string>(settings.feideApiCheckUserExistenceEndpoint || '');

  const updateAgreementSettings: MutationFunction<UpdateAgreementSettings, UpdateAgreementSettingsVariables> = useMutation<UpdateAgreementSettings, UpdateAgreementSettingsVariables>(UPDATE_AGREEMENT_SETTINGS)[0];
  const { openidConnectClientSecretMasked, employeePeriodicVerificationSettings, ...newSettings } = removeTypename(settings);

  type Changes = {
    openidConnectClientId?: string;
    openidConnectDiscoveryUrl?: string;
    openidConnectSystemName?: string;
    openidConnectClientSecret?: string;
    openidConnectUserIdField?: string;
    openidConnectScope?: string;
    feideApiCheckUserExistenceEndpoint?: string;
  };
  const changes = (): Changes => ({
    ...(openidConnectClientId !== settings.openidConnectClientId ? { openidConnectClientId } : {}),
    ...(openidConnectDiscoveryUrl !== settings.openidConnectDiscoveryUrl ? { openidConnectDiscoveryUrl } : {}),
    ...(openidConnectSystemName !== settings.openidConnectSystemName ? { openidConnectSystemName } : {}),
    ...(openidConnectClientSecret !== settings.openidConnectClientSecretMasked ? { openidConnectClientSecret } : {}),
    ...(openidConnectUserIdField !== settings.openidConnectUserIdField ? { openidConnectUserIdField } : {}),
    ...(openidConnectScope !== settings.openidConnectScope ? { openidConnectScope } : {}),
    ...(feideApiCheckUserExistenceEndpoint !== settings.feideApiCheckUserExistenceEndpoint && (feideApiCheckUserExistenceEndpoint || settings.feideApiCheckUserExistenceEndpoint) ? { feideApiCheckUserExistenceEndpoint } : {}),
  });
  const hasChanges = (): boolean => !!Object.keys(changes()).length;
  const save = () => {
    setSaveStatus({ errors: {}, pending: true, success: false });
    const variables: UpdateAgreementSettingsVariables = {
      agreementNumber: agreement.agreementNumber,
      agreementId: agreement.id,
      settings: {
        ...newSettings,
        ...changes(),
      },
    };

    const asyncAction = () => updateAgreementSettings({ variables });
    asyncAction()
      .then(() => {
        setSaveStatus({ errors: {}, pending: false, success: true });
        if (changes().openidConnectClientSecret) {
          setOpenidConnectClientSecret(settings.openidConnectClientSecretMasked || '');
        }
      })
      .catch((error: ApolloError) => {
        const states: ErrorStates = (error.graphQLErrors || [])
          .map((e) => (e as any).state as { [k: string]: string[] })
          .filter(Boolean)
          .reduce((result, s) => Object.assign(result, s), {});

        if (Object.keys(states).length) {
          setSaveStatus({ errors: states, pending: false, success: false });
        } else {
          setSaveStatus({
            errors: {
              feideApiCheckUserExistenceEndpoint: [error.message],
              openidConnectClientId: [error.message],
              openidConnectDiscoveryUrl: [error.message],
              openidConnectSystemName: [error.message],
              openidConnectClientSecret: [error.message],
              openidConnectUserIdField: [error.message],
              openidConnectScope: [error.message],
            },
            pending: false,
            success: false,
          });
        }
      })
      .finally(() => setFormSubmitted(true));
  };
  useEffect(() => {
    setShowSaveButton(hasChanges());
  }, [
    feideApiCheckUserExistenceEndpoint,
    openidConnectDiscoveryUrl,
    openidConnectSystemName,
    openidConnectClientId,
    openidConnectClientSecret,
    openidConnectUserIdField,
    openidConnectScope,
    settings,
  ]);

  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (hasChanges()) {
      save();
    }
  };
  const saveButtonLabel = saveStatus.pending ? "Lagrer..." : "Lagre informasjon";
  const errors = saveStatus.errors;

  return (
    <React.Fragment>
      <div className={ styleClass.settings.openidContainer }>
        <form className={ styleClass.settings.openidInputContainer } onSubmit={ submit }>
          <label className={ styleClass.settings.label }>
            Navn på bedriftens påloggingssystem
            <InputWithValidator
              ariaLabel={ 'Navn på bedriftens påloggingssystem' }
              value={ openidConnectSystemName }
              validator={ (s) => !!s }
              forceError={!!(errors.openidConnectSystemName || []).length}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              placeholder={ "f.eks. Active Directory" }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOpenidConnectSystemName(e.target.value) }
            />
          </label>
          <label className={ styleClass.settings.label }>
            OpenID Connect Discovery URL. Denne skal typisk settes
            til <i>https://adresse-til-ditt-inloggingssystem/.well-known/openid-configuration</i>
            <InputWithValidator
              ariaLabel={ 'OpenID Connect Discovery URL' }
              value={ openidConnectDiscoveryUrl }
              validator={ urlValidator }
              forceError={!!(errors.openidConnectDiscoveryUrl || []).length}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              placeholder="f.eks. https://schibsted.okta.com/.well-known/openid-configuration"
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOpenidConnectDiscoveryUrl(e.target.value) }
              errorMsg={ `Vennligst oppgi gyldig OpenID Connect Discovery URL${ errors.openidConnectDiscoveryUrl ? ` (${ errors.openidConnectDiscoveryUrl.join(", ") })` : '' }` }
              clearInput={ () => setOpenidConnectDiscoveryUrl(settings.openidConnectDiscoveryUrl || '') }
            />
          </label>
          <label className={ styleClass.settings.label }>
            Client ID
            <InputWithValidator
              ariaLabel={ 'Client ID' }
              value={ openidConnectClientId }
              validator={ (s) => !!s}
              forceError={!!(errors.openidConnectClientId || []).length}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOpenidConnectClientId(e.target.value) }
              errorMsg={ `Vennligst oppgi gyldig OpenID Connect client id${ errors.openidConnectClientId ? ` (${ errors.openidConnectClientId.join(", ") })` : '' }` }
              clearInput={ () => setOpenidConnectClientId(settings.openidConnectClientId || '') }
            />
          </label>
          <label className={ styleClass.settings.label }>
            Client secret (ikke del denne med andre)
            <InputWithValidator
              ariaLabel={ 'Client secret (ikke del denne med andre)' }
              placeholder={ "Client secret (ikke del denne med andre)" }
              value={ openidConnectClientSecret }
              validator={ (s) => !!s }
              forceError={!!(errors.openidConnectClientSecret || []).length}
              required={ true }
              formSubmitted={ true }
              className={ styleClass.settings.input }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOpenidConnectClientSecret(e.target.value) }
              errorMsg={ 'Vennligst oppgi gyldig OpenID Connect client secret' }
              clearInput={ () => setOpenidConnectClientSecret(settings.openidConnectClientSecretMasked || '') }
            />
          </label>
          <label className={ styleClass.settings.label}>
            OpenID Connect felt for bruker ID
            <InputWithValidator
              ariaLabel={ 'OpenID Connect felt for bruker ID' }
              placeholder={ "OpenID Connect felt for bruker ID" }
              value={ openidConnectUserIdField }
              validator={ (s) => !!s }
              forceError={!!(errors.openidConnectUserIdField || []).length}
              required={ true }
              formSubmitted={ true }
              className={ styleClass.settings.input }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOpenidConnectUserIdField(e.target.value) }
              errorMsg={ 'Vennligst oppgi felt for bruker id' }
              clearInput={ () => setOpenidConnectUserIdField(settings.openidConnectUserIdField || 'sub') }
            />
          </label>
          <label className={ styleClass.settings.label}>
            OpenID Scopes
            <InputWithValidator
              ariaLabel={ 'OpenID Connect scopes separert med mellomrom' }
              placeholder={ "OpenID Connect scopes separert med mellomrom" }
              value={ openidConnectScope }
              validator={ (s) => !!s }
              forceError={!!(errors.openidConnectScope || []).length}
              required={ true }
              formSubmitted={ true }
              className={ styleClass.settings.input }
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOpenidConnectScope(e.target.value) }
              errorMsg={ 'Vennligst oppgi felt for bruker id' }
              clearInput={ () => setOpenidConnectScope(settings.openidConnectScope || 'openid profile') }
            />
          </label>
          <label className={ styleClass.settings.label}>
            Feide Check user existence endpoint
            <InputWithValidator
              ariaLabel={ 'Feide Check user existence endpoint URL' }
              value={ feideApiCheckUserExistenceEndpoint }
              validator={ (e) => !e || urlValidator(e) }
              forceError={!!(errors.feideApiCheckUserExistenceEndpoint || []).length}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              placeholder="f.eks. https://checkuser.dataporten-api.no/"
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setFeideApiCheckUserExistenceEndpoint(e.target.value) }
              errorMsg={ `Vennligst oppgi gyldig URL${ errors.feideApiCheckUserExistenceEndpoint ? ` (${ errors.feideApiCheckUserExistenceEndpoint.join(", ") })` : '' }` }
              clearInput={ () => setFeideApiCheckUserExistenceEndpoint(settings.feideApiCheckUserExistenceEndpoint || '') }
            />
          </label>
          { showSaveButton && <Button ariaLabel={ saveButtonLabel } text={ saveButtonLabel }/> }
          { (!showSaveButton && saveStatus.success) && (
            <div className={styleClass.settings.message}>
              <MessageBox
                message={`Endringene er registrert.`}
                backgroundColor={`#BFE6D7`}
                arrow={Arrow.TOP_CENTER}
                icon={
                  <Icon
                    name={IconName.CHECK}
                    style={{color: 'rgb(1, 154, 2)'}}
                    className={`${Margin.MR_3}`}
                  />
                }
              />
            </div>
          )}
          { Object.keys(errors).length ? (
            <div className={styleClass.settings.message}>
              <MessageBox
                message={`Det har skjedd en feil, vennligst se over feltene over eller kontakt teknisk ansvarlig i din organisasjon eller kontakt kundeservice.`}
                backgroundColor={`#F7C8C0`}
                arrow={Arrow.TOP_CENTER}
                icon={
                  <Icon
                    name={IconName.WARNING}
                    style={{color: '#DE2102', minWidth: '24px'}}
                    className={`${Margin.MR_3}`}
                  />
                }
              />
            </div>
          ) : null}
        </form>
      </div>
    </React.Fragment>
  )
};

export default VerificationSectionOpenidConnect;
