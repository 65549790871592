import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './shame.css'
const debug = require('debug')('minbedrift:client:root');
try {
  localStorage.setItem('debug', 'create-pinata-app,create-pinata-app:*,minbedrift,minbedrift:*,mono:*');
} catch (e) {
  debug('Couldn\'t enable debug, seems like localStorage is disabled...', e);
}


ReactDOM.render(
  <App
    domain={window.location.hostname}
    href={window.location.href}
  />,
  document.getElementById('root') as HTMLElement
);


