import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSize,
  classNames,
  FontWeight,
  Heading,
  HeadingLevel,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  Width
} from "@snoam/pinata";
import * as React from "react";
import {useState} from "react";
import {ProductSelector} from "./ProductSelector";
import {GetAgreementProducts_me_agreementsWithDetails_products} from "../../../__generated__/GetAgreementProducts";
import {Modal} from "@snoam/mono-modal";
import {Product} from "../models/OrderSessionModel";

const styleClass = {
  root: classNames(
    BackgroundColor.BG_NEUTRAL_2,
  ),
  wrapper: classNames(
    TextAlign.TEXT_CENTER,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    TextColor.TEXT_NEUTRAL_8,
    Margin.MB_2,
    Margin.MT_4,
  ),
  introText: classNames(
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_NEUTRAL_6,
    Margin.MB_6,
  ),
  button: classNames(
    Padding.PX_10,
    Margin.MT_8,
    Margin.MB_8,
  ),
  closeButton: classNames(
    Width.W_FULL,
    BorderStyle.BORDER_DASHED,
    BorderWidth.BORDER_0,
    BorderWidth.BORDER_T_1,
    BorderColor.BORDER_NEUTRAL_4,
    Padding.PT_4,
    Padding.PB_PX,
    Margin.MT_8,
  ),
};

interface ISelectProductModalProps {
  onClose: () => void;
  setChosenProduct: (products: Pick<Product, "id" | "productGroup" | "siebelProductId">[]) => void;
  chosenProducts: Pick<Product, "id" | "productGroup" | "siebelProductId">[];
  products: GetAgreementProducts_me_agreementsWithDetails_products[];
  productGroup: string;
  id: string;
}

const ProductVariantModal = ({id, onClose, products, productGroup, chosenProducts, setChosenProduct}: ISelectProductModalProps) => {
  const [chosenProductVariant, setChosenProductVariation] = useState<{id: string, siebelProductId: string}>({id: '', siebelProductId: ''});
  const relevantProducts = products.filter((product) => product.productGroup === productGroup).sort((a, b) => a.productCombination < b.productCombination ? -1 : 1);
  const productGroupName: string = relevantProducts[0].productName;

  const onAddProductClick = () => {
    setChosenProduct([
      ...chosenProducts,
      {id: chosenProductVariant.id, productGroup, siebelProductId: chosenProductVariant.siebelProductId}
    ]);

    setChosenProductVariation({ id: '', siebelProductId: '' });

    onClose();
  };

  const onProductVariationChange = (productVariation: Pick<Product, "id" | "productGroup" | "siebelProductId">) => {
    setChosenProductVariation(productVariation);
  };

  return (
    <Modal
      id={id}
      backdrop={true}
      className={classNames(styleClass.root, BackgroundColor.BG_NEUTRAL_1)}
      onClose={onClose}
    >
      <div className={styleClass.wrapper}>
        <Heading
          level={HeadingLevel.TWO}
          className={styleClass.heading}
        >
          Hvordan vil du lese {productGroupName}?
        </Heading>
        <p className={styleClass.introText}>Velg mellom disse produktvariantene:</p>

        <ProductSelector
          products={relevantProducts}
          chosenProductVariant={chosenProductVariant.id}
          setProductVariation={onProductVariationChange}
        />

        <Button
          text={'Legg til produkt'}
          ariaLabel={'Legg til produkt'}
          size={ButtonSize.SMALL}
          className={styleClass.button}
          onClick={onAddProductClick}
          disabled={!chosenProductVariant.id}
        />
          <button
            onClick={onClose}
            className={styleClass.closeButton}
            aria-label={"Avbryt"}
          >
            Avbryt
          </button>
      </div>
    </Modal>
  );
};

export default ProductVariantModal;
