import React from 'react';
import {
  AlignItems,
  BackgroundColor,
  classNames,
  Display,
  FlexDirection,
  JustifyContent,
  Padding,
  TextAlign,
  TextColor,
  Width
} from '@snoam/pinata';
import {IWithSpidContext, withSpidContext} from "@snoam/mono-spid";
import UserBadge from "../UserBadge/UserBadge";
import BreadCrumbMenu from "../BreadCrumb/BreadCrumbMenu";
import BreadCrumbMenuItem from "../BreadCrumb/BreadCrumbMenuItem";
import ListeningRoute, {ListeningRouteOutputProps} from "../ListeningRoute/ListeningRoute";
import {routes, subscribersPageRoute} from "../../routes/routes";
import {generatePath} from "react-router";
import Logo from "../Logo/Logo";
import {AdminType} from '../../__generated__/globalTypes';
import {useQuery} from '@apollo/client';
import {GET_AGREEMENT_ORDER_INITIAL, GET_ROLE} from '../../queries';
import {GetRole, GetRoleVariables} from '../../__generated__/GetRole';
import {
  GetAgreementOrderInitial,
  GetAgreementOrderInitial_agreement,
  GetAgreementOrderInitialVariables
} from "../../__generated__/GetAgreementOrderInitial";
import PathBoundary from "../PathBoundary/PathBoundary";
import LoginAndLogoutButton from "../LoginAndLogoutButton/LoginAndLogoutButton";
import {isOrderFlow, toGeneratePathParams} from "../../utils";
import {useParams} from "react-router-dom";

const styleClass = {
  root: classNames(
    TextAlign.TEXT_CENTER,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_BETWEEN,
    Width.W_FULL,
    BackgroundColor.BG_WHITE
  ),
  column: classNames(
    Display.FLEX,
    BackgroundColor.BG_WHITE,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER,
    Padding.PX_4
  ),
  logoContainer: (isOrderFlow: boolean) => classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    {
      [JustifyContent.JUSTIFY_CENTER] : !isOrderFlow,
      [Padding.PX_6] : isOrderFlow,
      [BackgroundColor.BG_WHITE] : isOrderFlow,
      [BackgroundColor.BG_PRIMARY_1] : !isOrderFlow
    }
  )
};

export interface IHeaderProps extends IWithSpidContext {
  noCrumbs?: boolean;
}

interface ContextAwareBreadCrumbsProps {
  agreementNumber: number | null;
  invertFontColor?: boolean;
  noCrumbs?: boolean;
  role: AdminType | null;
}

export const ContextAwareBreadCrumbs: React.FunctionComponent<ContextAwareBreadCrumbsProps> = ({agreementNumber, noCrumbs = false, invertFontColor = false, role}) => {

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {data, error, loading} = agreementNumber ? useQuery<GetAgreementOrderInitial, GetAgreementOrderInitialVariables>(GET_AGREEMENT_ORDER_INITIAL, {
    variables: {
      agreementNumber: +agreementNumber,
    },
  }) : {data: undefined, error: undefined, loading: undefined};
  const agreement: GetAgreementOrderInitial_agreement | null = error || loading || !data ? null : data.agreement;

  return (
    <ListeningRoute element={(props: ListeningRouteOutputProps) => {
      const {location, params} = props;

      const route = routes.admin.routes.find(r => {
        try {
          return generatePath(r.path, toGeneratePathParams(params)) === location.pathname;
        } catch (e) {
          return false;
        }
      });
      return (
        <BreadCrumbMenu>
          {!noCrumbs && agreementNumber &&
          <BreadCrumbMenuItem
            label={agreement ? agreement.accountName : `${agreementNumber}`}
            href={generatePath(subscribersPageRoute.path, {agreementNumber: `${agreementNumber}`})}
            invertFontColor={invertFontColor}
          />
          }
          {!noCrumbs && route &&
          <BreadCrumbMenuItem label={route.label} disabled={true} invertFontColor={invertFontColor}/>}
        </BreadCrumbMenu>
      )
    }}/>
  );
};

export const ContextAwareUserBadge = (props: IWithSpidContext & { email: string | null, role: AdminType | null, transparent?: boolean, className?: string; }) => {
  const {email, role, spidContext, ...otherProps} = props;

  if (!spidContext.state.userId) {
    return null;
  }

  return <UserBadge
    classNames={classNames(Padding.PX_6, TextColor.TEXT_BLACK)}
    email={email}
    name={spidContext.state.givenName || ''}
    role={role}
    transparent={true}
    {...otherProps}
  />;
};

export const Header: React.FunctionComponent<IHeaderProps> = (props) => {

  const {agreementNumber} = useParams<{agreementNumber?: string}>();
  const {spidContext, noCrumbs} = props;
  const variables = {
    agreementNumber: agreementNumber ? +agreementNumber : undefined,
  };
  const {data, error, loading} = useQuery<GetRole, GetRoleVariables>(GET_ROLE, {variables});
  const {email = null, role = null} = (loading || error) ? {} : data ? data.me : {};
  const orderFlow = isOrderFlow();

  return (
    <header className={styleClass.root}>

      <div
        className={styleClass.logoContainer(orderFlow)}
        style={{width: orderFlow ? '300px' : '80px', height: '80px'}}
      >
        <Logo mobile={false}/>
      </div>

      <div
        className={styleClass.column}
        style={{width: orderFlow ? 'calc(100% - 300px)' : 'calc(100% - 80px)', height: '80px'}}
      >
        <div>
          <PathBoundary
            excludeIfUrl={'/bestill'}
          >
            <ContextAwareBreadCrumbs agreementNumber={agreementNumber ? +agreementNumber : null} noCrumbs={noCrumbs}
                                     role={role}/>
          </PathBoundary>
        </div>

        <div className={classNames(Display.FLEX, FlexDirection.FLEX_ROW)}>
          {<ContextAwareUserBadge email={email} role={role} spidContext={spidContext}/>}
          <LoginAndLogoutButton
            spidContext={spidContext}
            agreementNumber={agreementNumber}
          />
        </div>
      </div>
    </header>
  );
};

export default withSpidContext<IHeaderProps>(Header);
