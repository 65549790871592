import { gql } from '@apollo/client';
import { UpdateAgreementSettings } from './__generated__/UpdateAgreementSettings';
import {ExecutionPatchResult} from "@apollo/client/link/core/types";

export const DELETE_SUBSCRIPTION_CHANGE = gql`
  mutation DeleteSubscriptionChange(
    $agreementNumber: Int!
    $assetNumber: String!
    $id: ID!
  ) {
    deleteSubscriptionChange(
      agreementNumber: $agreementNumber
      subscriptionItem: {
        assetNumber: $assetNumber
        stopOrders: [{
          id: $id
        }]
      }
    ) {
      deletedStopOrders {
        id
      }
    }
  }
`;

export const DELETE_ADMIN = gql`
  mutation DeleteAdmin($id: ID!) {
    deleteAdmin(
      id: $id
    ) {
      admin {
        id
      }
    }
  }
`;

export const ADD_ADMIN = gql`
  mutation AddAdmin($admin: NewAdminSettings!) {
    addAdmin(
      admin: $admin
    ) {
      admin {
        id
        adminType
        spid
        email
        displayName
        name {
          formatted
        }
      }
    }
  }
`;

export const ADD_MESSAGE = gql`
  mutation AddMessage($productGroups: [String!]!, $message: String!) {
    addMessage(
      productGroups: $productGroups
      message: $message
    ) {
      id
      productGroups
      message
      createdAt
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation DeleteMessage($messageId: Int!) {
    deleteAdminMessage(
      messageId: $messageId
    ) {
      deletedMessageId
    }
  }
`;

export const UPDATE_ADMIN_ROLE = gql`
  mutation UpdateAdminRole($agreementNumber: Int, $adminType: AdminType!, $spid: Int!) {
    updateAdminRole(
      agreementNumber: $agreementNumber,
      adminType: $adminType,
      spid: $spid
    ) {
      admin {
        id
        adminType
        spid
        email
        name {
          formatted
        }
      }
    }
  }
`;

export const TERMINATE_SUBSCRIPTIONS_FOR_NON_EMPLOYEES = gql`
  mutation TerminateSubscriptionsForNonEmployees($agreementNumber: Int!, $agreementId: String!) {
    terminateSubscriptionsForNonEmployees(agreementId: $agreementId, agreementNumber: $agreementNumber) {
      __typename
      terminatedSubscriptions {
        agreementNumber
        agreementStatus
        subscriptionItems {
          agreementId
          agreementItemEndDate
          customerName
          customerNumber
          customerSpid
          product {
            active
            companyCode
            discountPercent
            id
            monthlyPrice
            productCombination
            productGroup
            productName
            productVariation
            productVariationActive
            productVariationDescription
            productVariationImageUrl
            public
            siebelProductId
          }
        }
      }
    }
  }
`;
export const UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_CLEAR = gql`
  mutation ClearAgreementSettingsEmploymentPeriodicVerificationSettings($agreementNumber: Int!, $agreementId: String!) {
    clearAgreementEmploymentPeriodicVerificationSettings(agreementId: $agreementId, agreementNumber: $agreementNumber) {
      maxSubscriptionsPerEmployee
      settings {
        emailDomains
        employeeVerificationMethod
        employeePeriodicVerificationSettings {
          __typename
          ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
            type
          }
          ... on EmploymentPeriodicVerificationSettingsPhenixId {
            phenixIdApiUrl
          }
          ... on EmploymentPeriodicVerificationSettingsOktaApi {
            oktaApiToken
          }
          ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
            oktaApiToken
            oktaAppId
            oktaOrgUrl
          }
        }
        enableOrdering
        id
        openidConnectUserIdField
        openidConnectClientId
        openidConnectClientSecretMasked
        openidConnectDiscoveryUrl
        openidConnectSystemName
        openidConnectScope
        reference1 {
          id
          name
          type
          allowedValues
        }
        reference2 {
          id
          name
          type
          allowedValues
        }
      }
    }
  }
`;
export const UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_AZURE_AD_GRAPH_API = gql`
  mutation UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsAzureAdGraphApi($agreementNumber: Int!, $agreementId: String!, $settings: NewEmploymentPeriodicVerificationSettingsAzureAdGraphApiSettings!) {
    updateAgreementEmploymentPeriodicVerificationSettingsAzureAdGraphApi(agreementId: $agreementId, agreementNumber: $agreementNumber, settings: $settings) {
      maxSubscriptionsPerEmployee
      settings {
        emailDomains
        employeeVerificationMethod
        employeePeriodicVerificationSettings {
          __typename
          ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
            type
          }
          __typename
          ... on EmploymentPeriodicVerificationSettingsPhenixId {
            phenixIdApiUrl
          }
          ... on EmploymentPeriodicVerificationSettingsOktaApi {
            oktaApiToken
          }
          ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
            oktaApiToken
            oktaAppId
            oktaOrgUrl
          }
        }
        enableOrdering
        id
        openidConnectUserIdField
        openidConnectClientId
        openidConnectClientSecretMasked
        openidConnectDiscoveryUrl
        openidConnectSystemName
        openidConnectScope
        reference1 {
          id
          name
          type
          allowedValues
        }
        reference2 {
          id
          name
          type
          allowedValues
        }
      }
    }
  }
`;
export const UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_OKTA_SYSTEM_LOG = gql`
  mutation UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaSystemLog($agreementNumber: Int!, $agreementId: String!, $settings: NewEmploymentPeriodicVerificationSettingsOktaSystemLogSettings!) {
    updateAgreementEmploymentPeriodicVerificationSettingsOktaSystemLog(
      agreementNumber: $agreementNumber
      agreementId: $agreementId
      settings: $settings
    ) {
      maxSubscriptionsPerEmployee
      settings {
        emailDomains
        employeeVerificationMethod
        employeePeriodicVerificationSettings {
          __typename
          ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
            type
          }
          ... on EmploymentPeriodicVerificationSettingsPhenixId {
            phenixIdApiUrl
          }
          ... on EmploymentPeriodicVerificationSettingsOktaApi {
            oktaApiToken
          }
          ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
            oktaApiToken
            oktaAppId
            oktaOrgUrl
          }
        }
        enableOrdering
        id
        openidConnectUserIdField
        openidConnectClientId
        openidConnectClientSecretMasked
        openidConnectDiscoveryUrl
        openidConnectSystemName
        openidConnectScope
        reference1 {
          id
          name
          type
          allowedValues
        }
        reference2 {
          id
          name
          type
          allowedValues
        }
      }
    }
  }
`;
export const UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_OKTA_API = gql`
  mutation UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaApi($agreementNumber: Int!, $agreementId: String!, $settings: NewEmploymentPeriodicVerificationSettingsOktaApiSettings!) {
    updateAgreementEmploymentPeriodicVerificationSettingsOktaApi(
      agreementNumber: $agreementNumber
      agreementId: $agreementId
      settings: $settings
    ) {
      maxSubscriptionsPerEmployee
      settings {
        emailDomains
        employeeVerificationMethod
        employeePeriodicVerificationSettings {
          __typename
          ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
            type
          }
          ... on EmploymentPeriodicVerificationSettingsPhenixId {
            phenixIdApiUrl
          }
          ... on EmploymentPeriodicVerificationSettingsOktaApi {
            oktaApiToken
          }
          ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
            oktaApiToken
            oktaAppId
            oktaOrgUrl
          }
        }
        enableOrdering
        id
        openidConnectUserIdField
        openidConnectClientId
        openidConnectClientSecretMasked
        openidConnectDiscoveryUrl
        openidConnectSystemName
        openidConnectScope
        reference1 {
          id
          name
          type
          allowedValues
        }
        reference2 {
          id
          name
          type
          allowedValues
        }
      }
    }
  }
`;
export const UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_PHENIX_ID_API = gql`
  mutation UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsPhenixId($agreementNumber: Int!, $agreementId: String!, $settings: NewEmploymentPeriodicVerificationSettingsPhenixIdApiSettings!) {
    updateAgreementEmploymentPeriodicVerificationSettingsPhenixIdApi(
      agreementNumber: $agreementNumber
      agreementId: $agreementId
      settings: $settings
    ) {
      maxSubscriptionsPerEmployee
      settings {
        emailDomains
        employeeVerificationMethod
        employeePeriodicVerificationSettings {
          __typename
          ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
            type
          }
          ... on EmploymentPeriodicVerificationSettingsPhenixId {
            phenixIdApiUrl
          }
          ... on EmploymentPeriodicVerificationSettingsOktaApi {
            oktaApiToken
          }
          ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
            oktaApiToken
            oktaAppId
            oktaOrgUrl
          }
        }
        enableOrdering
        id
        openidConnectUserIdField
        openidConnectClientId
        openidConnectClientSecretMasked
        openidConnectDiscoveryUrl
        openidConnectSystemName
        openidConnectScope
        reference1 {
          id
          name
          type
          allowedValues
        }
        reference2 {
          id
          name
          type
          allowedValues
        }
      }
    }
  }
`;
export const UPDATE_AGREEMENT_SETTINGS = gql`
  mutation UpdateAgreementSettings($agreementNumber: Int!, $agreementId: String!, $settings: NewAgreementSettings!) {
    updateAgreementSettings(
      agreementNumber: $agreementNumber
      agreementId: $agreementId
      settings: $settings
    ) {
      maxSubscriptionsPerEmployee
      settings {
        showActivationDegreeForCompanyAdmin
        activationPotential
        emailDomains
        employeeVerificationMethod
        employeePeriodicVerificationSettings {
          __typename
          ... on EmploymentPeriodicVerificationSettingsAzureAdGraphApi {
            type
          }
          ... on EmploymentPeriodicVerificationSettingsPhenixId {
            phenixIdApiUrl
          }
          ... on EmploymentPeriodicVerificationSettingsOktaApi {
            oktaApiToken
          }
          ... on EmploymentPeriodicVerificationSettingsOktaSystemLog {
            oktaApiToken
            oktaAppId
            oktaOrgUrl
          }
        }
        enableOrdering
        id
        openidConnectUserIdField
        openidConnectClientId
        openidConnectClientSecretMasked
        openidConnectDiscoveryUrl
        openidConnectSystemName
        openidConnectScope
        reference1 {
          id
          name
          type
          allowedValues
        }
        reference2 {
          id
          name
          type
          allowedValues
        }
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION_ITEM_REFERENCES = gql`
  mutation UpdateReferences($agreementNumber: Int!, $referenceSettings: [NewReferences!]!) {
    updateReferences(
      agreementNumber: $agreementNumber
      subscriptionItems: $referenceSettings
    ) {
      subscriptionItems {
        assetNumber
        id
        reference1 {
          id
          name
          type
          allowedValues
          value
        }
        reference2 {
          id
          name
          type
          allowedValues
          value
        }
      }
    }
  }
`;


export const UPDATE_PRODUCT_SETTINGS = gql`
  mutation updateProductSettings($product: NewProductSettings!) {
    updateProductSettings(
        product: $product
    ){
     product {
      id
      productGroup
      productName
      productCombination
      monthlyPrice
      productVariation
      productVariationDescription
      productVariationImageUrl
      productVariationActive
     }
    }
  }
`;

export const UPDATE_AGREEMENT_PRODUCTS = gql`
  mutation updateAgreementProduct($agreementNumber: Int!, $product: NewAgreementProduct!, $active: String!) {
    updateAgreementProducts(agreementNumber: $agreementNumber, productItem: $product, active: $active) {
      product {
        id
        productName
        discountPercent
        active
        siebelProductId
        public
      }
    }
  }
`;

export const TERMINATE_SUBSCRIPTION = gql`
  mutation TerminateSubscription($agreementNumber: Int!, $assetNumber: String!, $stopEdition: String!, $stopDate: String!) {
    terminateSubscription(
      agreementNumber: $agreementNumber,
      assetNumber: $assetNumber,
      stopEdition: $stopEdition,
      stopDate: $stopDate
    ) {
      __typename
      subscriptionItem {
        __typename
        id
        agreementItemEndDate
        stopOrders {
          __typename
          id
          date
        }
      }
    }
  }
`;

export const UPDATE_AGREEMENT = gql`
  mutation UpdateAgreement($agreementNumber: Int!, $agreementId: String!, $allowNewSubscriptions: String!, $maxSubscriptionsPerEmployee: Int!) {
    updateAgreement(agreementId: $agreementId, agreementNumber:$agreementNumber,  allowNewSubscriptions: $allowNewSubscriptions, maxSubscriptionsPerEmployee: $maxSubscriptionsPerEmployee) {
      agreement {
        id
        maxSubscriptionsPerEmployee
      }
    }
  }
`;


export const checkNoErrors = async <T> ({ data, errors }: ExecutionPatchResult<T>): Promise<T> => {
  if (errors) {
    return Promise.reject(errors);
  }
  if (!data) {
    return Promise.reject(new Error(`Unexpected data returned: ${JSON.stringify(data)}`));
  }
  return data;
};


export const checkNoErrorsUpdateAgreementSettings = async ({ data, errors }: ExecutionPatchResult<UpdateAgreementSettings>): Promise<UpdateAgreementSettings> => {
  const someData = await checkNoErrors({ data, errors });
  if (!someData.updateAgreementSettings) {
    return Promise.reject(new Error(`Unexpected data returned: ${JSON.stringify(someData)}`));
  }
  return someData;
};

export const resolveInMs = (ms: number): Promise<void> => new Promise<void>((resolve) => {
  setTimeout(() => resolve(), ms);
});
