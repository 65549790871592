import * as React from "react";
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Container,
  Display,
  FlexDirection,
  FontSize,
  JustifyContent,
  ListStyleType,
  Margin,
  Padding,
  Width,
} from "@snoam/pinata";
import {useQuery} from "@apollo/client";
import {GetAgreementVariables} from "../../__generated__/GetAgreement";
import SvgImage from "../../components/SvgImage/SvgImage";
import {
  GET_AGREEMENT_PRODUCTS,
  GET_MESSAGES_FOR_PRODUCT_GROUPS
} from "../../queries";
import {
  GetAllMessagesForProductGroups,
  GetAllMessagesForProductGroupsVariables
} from "../../__generated__/GetAllMessagesForProductGroups";
import moment from "moment";
import {GetAgreementProducts, GetAgreementProductsVariables} from '../../__generated__/GetAgreementProducts';
import {useEffect, useState} from "react";
import {simpleMarkdown} from "../../utils";
import {useParams} from "react-router-dom";

const styleClass = {
  root: classNames(
    BackgroundColor.BG_WHITE,
    Width.W_FULL,
    Padding.PY_4,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    JustifyContent.JUSTIFY_CENTER,
    AlignItems.ITEMS_CENTER,
    BorderWidth.BORDER_B_1,
    BorderWidth.BORDER_T_1,
    BorderStyle.BORDER_SOLID,
    BorderColor.BORDER_NEUTRAL_3,
  ),
}

export const MessageContext = React.createContext(null);

export const MessageContextProvider: React.FunctionComponent<{}> = ({children}) => {
  const params = useParams<{ agreementNumber: string }>();
  // @ts-ignore
  const agreementNumbers = [+params.agreementNumber];
  const variables: GetAgreementVariables = Object.assign({}, {
    agreementNumber: agreementNumbers,
  });
  const [productGroups, setProductGroups] = useState<Set<string>>(new Set());

  const {data: agreementProducts, error: agreementError} = useQuery<GetAgreementProducts, GetAgreementProductsVariables>(GET_AGREEMENT_PRODUCTS, {
    variables
  });

  useEffect(() => {
    if(agreementProducts && agreementProducts.me) {
      const productGroupsSet = new Set<string>()
      agreementProducts.me.agreementsWithDetails[0].products.map(p => productGroupsSet.add(p.productGroup));
      setProductGroups(productGroupsSet);
    }
  }, [agreementProducts]);

  const {data, error} = useQuery<GetAllMessagesForProductGroups, GetAllMessagesForProductGroupsVariables>(GET_MESSAGES_FOR_PRODUCT_GROUPS, {
    variables: {
      filter: Array.from(productGroups),
    },
    skip: productGroups.size === 0
  })

  if(error || agreementError) {
    return null;
  }

  const renderMessages = () => {
    if(data && data.adminMessages && data.adminMessages.length > 0) {
      return (
        <Container className={styleClass.root}>
          <div className={classNames(Width.W_2_3, Display.FLEX, FlexDirection.FLEX_ROW, AlignItems.ITEMS_CENTER)}>
          <SvgImage
            svgStyle={{width: '60px', height: 'auto'}}
            href={`${process.env.PUBLIC_URL}/svg/megaphone.svg`}
            className={classNames(Margin.MD_MR_6, Margin.MR_0)}
          />
          <ul className={classNames(ListStyleType.LIST_DISC, Margin.ML_8, FontSize.TEXT_SM)}>
          {data.adminMessages.map((message) => {
            const date = moment(Number.parseInt(message.createdAt)).format("DD.MM.YY");
            return (
              <li key={`message-${message.id}`} className={Padding.PY_1}>
                {date} - <span dangerouslySetInnerHTML={{__html: simpleMarkdown(message.message)}} />
              </li>
            )
          })}
          </ul>
          </div>
        </Container>
      )
    }
    return null;
  }

  return (
    <MessageContext.Provider value={null}>
      {renderMessages()}
      {children}
    </MessageContext.Provider>
  )
}
