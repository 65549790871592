import * as React from 'react';
import { ChangeEventHandler } from 'react';
import {
  Arrow,
  BackgroundColor,
  classNames,
  Display,
  Height,
  Icon,
  IconColor,
  IconName,
  Margin,
  Padding,
  FontSize,
  Width,
  FlexDirection,
  JustifyContent,
  AlignItems
} from "@snoam/pinata";
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';

export enum Verified {
  VALID="VALID",
  INVALID="INVALID",
  LOADING="LOADING",
  NEUTRAL="NEUTRAL",
  ERROR="ERROR",
}

interface IEmailcodeInputProps {
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  verified: Verified;
  verifyError: string;
}

const styleClass = {
  root: classNames(
    BackgroundColor.BG_WHITE,
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_CENTER
  ),
  input: (verified: Verified) =>  classNames(
    Height.H_12,
    verified === Verified.NEUTRAL ? Width.W_64 : Width.W_48,
    Padding.PX_4,
    Padding.PY_2,
    FontSize.TEXT_XL
  )
};

const EmailcodeInput = ({value, verified, verifyError, onChange}: IEmailcodeInputProps) => {
  const errorMessage = verifyError && (
    <div className={Padding.PT_3}>
      <MessageBox type={MessageBoxType.ERROR} arrow={Arrow.TOP_CENTER} message={verifyError}/>
    </div>
  );
  return (
    <>
      <div className={styleClass.root}>
        <input
          type="text"
          placeholder={'Skriv inn koden her'}
          aria-label={'Skriv inn koden her'}
          className={styleClass.input(verified)}
          onChange={onChange}
          value={value}
          disabled={verified === Verified.LOADING}
        />
        {verified === Verified.ERROR && <Icon
          name={IconName.ERROR}
          className={Margin.MX_4}
          color={IconColor.NEGATIVE}
          screenReaderText={'Ikon som viser at feltet er fylt ut feil'}
        />}
        {verified === Verified.VALID && <Icon
          name={IconName.CHECK}
          className={Margin.MX_4}
          color={IconColor.POSITIVE}
          screenReaderText={'Ikon som viser at feltet er fylt ut riktig'}
        />}
        {verified === Verified.LOADING && <Icon
          name={IconName.LOADING_WHEEL}
          className={classNames(Margin.MX_4, 'spin')}
          color={IconColor.POSITIVE}
          screenReaderText={'Ikon som viser at informasjon lastes'}
        />}
      </div>
      {errorMessage}
    </>
  )
};

export default EmailcodeInput;
