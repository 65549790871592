import * as React from "react";
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  BoxShadow,
  Button,
  ButtonSkin,
  classNames,
  Display,
  Flex,
  FlexWrap,
  Float,
  FontSize,
  FontWeight,
  Grid,
  GridGutter,
  GridItem,
  GridSpan,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  IconSize,
  JustifyContent,
  Link,
  Margin,
  MaxWidth,
  MinWidth,
  Padding,
  TextAlign,
  TextColor,
} from "@snoam/pinata";
import { IEmployee, ISubscription } from "../../models/EmployeeModel";
import { useSelectionContext } from '../../context/SelectionContext/SelectionContext';
import UserIcon from "../../components/EmployeesAndSubscriptionsTable/UserIcon";
import { ModalContext } from "@snoam/mono-modal";
import { useEffect, useState } from "react";
import { SingleCalendar } from "@snoam/mono-singlecalendar";
import moment, { Moment } from "moment";
import { ApolloError, MutationFunction, useMutation } from "@apollo/client";
import { TerminateSubscription, TerminateSubscriptionVariables } from "../../__generated__/TerminateSubscription";
import { checkNoErrors, TERMINATE_SUBSCRIPTION } from "../../mutations";
import { ResponsiveContext } from "../../context/ResponsiveContext/ResponsiveContext";

const styleClass = {
  employeesTable: classNames(
    'employeesTable',
    Margin.MY_8,
    BoxShadow.SHADOW,
  ),
  name: classNames(
    FontSize.TEXT_LG,
  ),
  numberOfActiveProducts: classNames(
    FontWeight.FONT_THIN,
    TextColor.TEXT_NEUTRAL_6,
  ),
  verticalAlignRemove: classNames(
    Display.FLEX,
    Flex.FLEX_AUTO,
    AlignItems.ITEMS_CENTER,
    FlexWrap.FLEX_WRAP,
    Padding.PX_2,
    Padding.PT_2,
    Padding.PB_1,
    Padding.LG_PX_5,
    Padding.LG_PT_5,
    Padding.LG_PB_2,
    FontWeight.FONT_THIN,
    TextColor.TEXT_TRANSPARENT,
    JustifyContent.JUSTIFY_END,
    TextColor.HOVER_TEXT_NEUTRAL_6,
  ),
  verticalAlignRight: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    FlexWrap.FLEX_WRAP,
    Padding.LG_PX_5,
    Padding.LG_PT_8,
    Padding.LG_PB_5,
    Padding.PX_2,
    Padding.PT_5,
    Padding.PB_2,
    FontWeight.FONT_THIN,
    JustifyContent.JUSTIFY_END,
  ),
  verticalAlignCenter: classNames(
    Display.FLEX,
    JustifyContent.JUSTIFY_END,
    AlignItems.ITEMS_CENTER,
    Padding.LG_PX_2,
    Padding.LG_PT_2,
    Padding.LG_PB_1,
    Padding.PX_5,
    Padding.PT_5,
    Padding.PB_2,
  ),
  verticalAlign: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    Padding.PX_2,
    Padding.PT_2,
    Padding.PB_1,
    Padding.LG_PX_5,
    Padding.LG_PT_5,
    Padding.LG_PB_2,
  ),
  heading: classNames(
    FontWeight.FONT_BOLD,
    TextAlign.TEXT_LEFT,
    Padding.PB_2,
  ),
  infoboxWarning: classNames(
    'infobox-warning',
    Padding.P_4,
    Padding.LG_PR_64,
    Padding.PR_2,
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    Float.FLOAT_LEFT,
    FontWeight.FONT_THIN
  ),
  infoboxSuccess: classNames(
    Padding.P_4,
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    Float.FLOAT_LEFT,
    Padding.LG_PR_64,
    Padding.PR_2,
    'infobox-success',
    FontWeight.FONT_THIN
  ),
};

enum TerminationState {
  LOADING,
  DELETED,
  ERROR,
}

enum BulkTerminationState {
  STARTED,
  COMPLETED_SUCCESSFUL,
  COMPLETED_WITH_ERRORS,
}

const TerminatingSubscription: React.FunctionComponent<any> = ({ subscription, agreementNumber, stopDate }) => {
  const terminateSubscriptionMutation: MutationFunction<TerminateSubscription, TerminateSubscriptionVariables> = useMutation<TerminateSubscription, TerminateSubscriptionVariables>(TERMINATE_SUBSCRIPTION)[0];
  const [terminationState, setTerminationState] = useState<TerminationState | null>(TerminationState.LOADING);
  const [errMsg, setErrMsg] = useState<string>();
  const assetNumber = subscription.assetNumber;
  const stopEdition = '';
  const selectionContext = useSelectionContext();

  useEffect(() => {
    terminateSubscriptionMutation({
      variables: {
        agreementNumber,
        assetNumber,
        stopEdition,
        stopDate: `${stopDate.format('MM/DD/YYYY')}`,
      }
    }).then(checkNoErrors).then(() => {
      subscription.isDeleted = true;
      setTerminationState(TerminationState.DELETED)
      selectionContext.context.actions.setTerminationStatusLogMsg(TerminationState.DELETED)
    }).catch((err) => {
      if (err instanceof ApolloError) {
        setErrMsg(err.message.replace('GraphQL error: Unexpected error value: ', '')
          .replace(', som vil stoppe abonnementet', '')
          .replace(/^"(.*)"$/, '$1'))
        selectionContext.context.actions.setTerminationStatusLogMsg(TerminationState.ERROR)
        setTerminationState(TerminationState.ERROR)
      }
    })
  }, [])

  return (
    <GridItem key={`${subscription.assetNumber}-${terminationState}`} span={[GridSpan.XSMALL_12, GridSpan.LARGE_10, GridSpan.SMALL_6]} className={classNames(BackgroundColor.BG_WHITE, BorderColor.BORDER_NEUTRAL_2, BorderStyle.BORDER_SOLID, BorderWidth.BORDER_1)}>
      <GridItem >
        <div className={styleClass.verticalAlign}>
          <div className={classNames(FontSize.TEXT_LG, FontWeight.FONT_BOLD)}>{subscription.product.productName + ' (' + subscription.product.productVariation + ')'}</div>
        </div>
      </GridItem>
      <GridItem>
        <div className={styleClass.verticalAlignRight}>
          {errMsg && <div>{errMsg}</div>}
          <div className={terminationState === TerminationState.LOADING ? 'loading' : ''}>
            <Icon
              name={terminationState === TerminationState.ERROR ? IconName.ERROR : terminationState === TerminationState.DELETED ? IconName.CHECK : IconName.LOADING_WHEEL}
              color={terminationState === TerminationState.ERROR ? IconColor.NEGATIVE : terminationState === TerminationState.DELETED ? IconColor.POSITIVE : IconColor.NEUTRAL_5}
              size={IconSize.BASE}
              className={classNames(Margin.ML_3)} />
          </div>
        </div>
      </GridItem>
    </GridItem>);
}



const TerminationHeader: React.FunctionComponent<any> = ({ bulkState, onDateChange, date }) => {
  const { mobile } = React.useContext(ResponsiveContext);

  const isOutSideRange = () => {
    // At earliest tomorrow
    return (date: Moment) => {
      return date.isBefore(moment().add(1, 'days'));
    }
  }

  const bulkCompleted = (bulkState === BulkTerminationState.COMPLETED_WITH_ERRORS || bulkState === BulkTerminationState.COMPLETED_SUCCESSFUL)

  return <div key={`heading_${bulkState}`} className={classNames(Margin.LG_MX_2, Margin.MX_6)}>
    <Heading level={HeadingLevel.ONE} className={styleClass.heading}>
      {bulkCompleted ? 'Ferdig'
        : bulkState === BulkTerminationState.STARTED ? 'Stopper tilgang til produkter...'
          : 'Stopp tilgang til produkter.'}
    </Heading>
    {(bulkState === null) &&
      <div>
        <Heading level={HeadingLevel.THREE} className={classNames(FontWeight.FONT_BOLD, Padding.PT_10)}>
          Når vil du stoppe tilgangen?
        </Heading>
        <div className={classNames(Padding.PB_4, MaxWidth.MAX_W_2XL)}>
          Produkttilgangen vil stoppes på første mulige dato etter valgt stoppdato.
          Det kan bli noe variasjon i stoppdato på produkter med fysisk levering.
        </div>
        <SingleCalendar
          inclusivelyAfterDays={1}
          startDatePlaceholderText="Fra i morgen"
          onDateChange={onDateChange}
          date={date}
          customInputIcon={<Icon
            name={IconName.DATE_RANGE}
            size={IconSize.BASE}
          />}
          isOutsideRange={isOutSideRange()}
          renderCalendarInfo={<span className={classNames(FontWeight.FONT_BOLD)}>Velg dato når endringen skal tre i kraft</span>}
        />
        {!mobile &&
          <Heading level={HeadingLevel.THREE} className={classNames(FontWeight.FONT_BOLD, Padding.PT_10)}>
            Dine utvalgte abonnenter
        </Heading>}
      </div>
    }
    {bulkCompleted &&
      <div key={`subheading_${bulkState}`} className={bulkState === BulkTerminationState.COMPLETED_WITH_ERRORS ? styleClass.infoboxWarning : styleClass.infoboxSuccess}>
        <Icon
          name={bulkState === BulkTerminationState.COMPLETED_WITH_ERRORS ? IconName.ERROR : IconName.CHECK}
          color={bulkState === BulkTerminationState.COMPLETED_WITH_ERRORS ? IconColor.NEGATIVE : IconColor.POSITIVE}
          size={IconSize.BASE}
          className={classNames(Margin.MR_3, MinWidth.MIN_W_4)} />
        {bulkState === BulkTerminationState.COMPLETED_WITH_ERRORS ?
          <div key={`infobox_${bulkState}`} className={classNames(Padding.LG_PR_64, Padding.PR_0)} >
            Vi fikk ikke stoppet tilgangen for alle produkter på grunn av overlappende endringer på produktene under. <Link href='https://kundeportal.aftenposten.no/kundeservice/'
              title='Til kundeservice' aria-label='Til kundeservice'>Kontakt kundeservice</Link> for hjelp eller forsøk igjen om en liten stund
            </div>
          :
          <div>Vi fikk stoppet tilgangen for alle produkter. Produktene vil være tilgjengelige noe tid etter ønsket stoppdato,
              da noen ordre til distributør allerede kan være sendt.</div>
        }
      </div>}
  </div>
}



export const SelectedSubscriptions: React.FunctionComponent<any> = ({ rows, agreementNumber }) => {
  const { closeModal } = React.useContext(ModalContext);
  const [date, setDate] = useState<Moment>();
  const [expanded, setExpanded] = useState(false);
  const [showMobileList, setShowMobileList] = useState(false);

  const [bulkState, setBulkState] = useState<BulkTerminationState | null>(null);
  const selectionContext = useSelectionContext()
  const { mobile } = React.useContext(ResponsiveContext);

  // get all active subscriptions from employees
  const subscriptions = rows.reduce((acc: any, employee: IEmployee) =>
    acc.concat(employee.activeSubscriptions.reduce((ac: any, subscription: ISubscription) =>
      ac.concat(subscription),
      [])),
    [])

  const onDateChange = (date: Moment) => {
    setDate(date);
  };

  useEffect(() => {
    if (selectionContext.context.state.terminationStatusLog.length === subscriptions.length) {
      if (selectionContext.context.state.terminationStatusLog.find((event: TerminationState) => event === TerminationState.ERROR)) {
        setBulkState(BulkTerminationState.COMPLETED_WITH_ERRORS)
      }
      else {
        setBulkState(BulkTerminationState.COMPLETED_SUCCESSFUL)
      }
    }
  }, [selectionContext.context.state.terminationStatusLog])


  const removeClick = (employee: IEmployee) => {
    selectionContext.context.actions.addOrRemoveFromSelection(employee)
  }

  const stopDate = date || moment().add(1, 'days');

  const bulkTerminateSubscriptions = () => {
    // Expand view and show subscriptions / Set bulkState to started
    setExpanded(true);
    setShowMobileList(true)
    setBulkState(BulkTerminationState.STARTED)
  };


  return (
    <>
      <TerminationHeader bulkState={bulkState} onDateChange={onDateChange} date={date} />
      <div className={styleClass.employeesTable}>

        {(mobile && bulkState === null)
          && <GridItem span={[GridSpan.LARGE_10, GridSpan.SMALL_6]} className={classNames(BackgroundColor.BG_WHITE, BorderColor.BORDER_NEUTRAL_2, BorderStyle.BORDER_SOLID, BorderWidth.BORDER_1)}  >
            <div className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER, Padding.PX_2, Padding.PT_4, Padding.PB_2,)}
              role="button"
              onKeyDown={() => setShowMobileList(!showMobileList)}
              tabIndex={0}
              onClick={() => setShowMobileList(!showMobileList)}>
              <Icon
                name={showMobileList ? IconName.EXPAND_LESS : IconName.EXPAND_MORE}
                className={classNames(Margin.MT_1, Margin.MR_2, TextColor.TEXT_NEUTRAL_6,)}
                size={IconSize.MEDIUM} />
              <div className={classNames(FontSize.TEXT_LG, FontWeight.FONT_BOLD)}>Du har valgt {rows.length} person{rows.length > 1 && 'er'}
              </div>
            </div>
          </GridItem>
        }
        {rows.map((employee: IEmployee, index: any) => {
          employee.isSelected = false;
          const backgroundHeaderColor = expanded ? BackgroundColor.BG_NEUTRAL_2 : BackgroundColor.BG_WHITE;

          // SubscriptionList
          const subscriptionList = employee.activeSubscriptions.map((sub: any) => {
            return <TerminatingSubscription subscription={sub} agreementNumber={agreementNumber} stopDate={stopDate} />
          });

          // EmployeeList
          const employeeList = (
            <GridItem span={[GridSpan.LARGE_10, GridSpan.SMALL_6]} className={classNames(backgroundHeaderColor, BorderColor.BORDER_NEUTRAL_2, BorderStyle.BORDER_SOLID, BorderWidth.BORDER_1)}  >
              <GridItem >
                <div className={styleClass.verticalAlign}>
                  <UserIcon admin={employee.admin} />
                  <div className={styleClass.name}>{employee.name}</div>
                </div>
              </GridItem>
              <GridItem>
                <div className={styleClass.verticalAlignCenter}>
                  <Icon
                    name={IconName.BEDRIFT_EDIT_PRODUCTS}
                    className={classNames(Margin.MT_1, Margin.MR_2, TextColor.TEXT_NEUTRAL_6,)}
                    size={IconSize.MEDIUM}
                    href={`${process.env.PUBLIC_URL}/svg/products.svg`} />
                  <div className={styleClass.numberOfActiveProducts}>
                    {`${employee.subscriptions.length} ${employee.subscriptions.length > 1 ? 'Aktive produkter' : 'Aktivt produkt'}`}
                  </div>
                </div>
              </GridItem>
              <GridItem>
                {expanded ?
                  <div className={styleClass.verticalAlignRight}>
                    <Icon
                      name={IconName.MINUS}
                      color={IconColor.NEUTRAL_3}
                      size={IconSize.BASE}
                      className={classNames(Margin.ML_3)} />
                  </div>
                  :
                  <div className={styleClass.verticalAlignRemove}
                    role="button"
                    onKeyDown={() => removeClick(employee)}
                    tabIndex={index}
                    onClick={() => removeClick(employee)}>
                    <div>Fjern fra listen</div>
                    <Icon
                      name={IconName.CANCEL}
                      color={IconColor.NEUTRAL_5}
                      size={IconSize.BASE}
                      className={classNames(Margin.ML_3)} />
                  </div>}
              </GridItem>
            </GridItem>
          )
          // EmployeeListMobile
          const employeeListMobile = (
            showMobileList &&
            <GridItem span={[GridSpan.LARGE_10, GridSpan.SMALL_6]} className={classNames(backgroundHeaderColor, BorderColor.BORDER_NEUTRAL_2, BorderStyle.BORDER_SOLID, BorderWidth.BORDER_1)}  >
              <div className={styleClass.verticalAlign}>
                <UserIcon admin={employee.admin} />
                <div className={styleClass.name}>{employee.name}
                  <div className={styleClass.numberOfActiveProducts}>
                    {`${employee.subscriptions.length} ${employee.subscriptions.length > 1 ? 'Aktive produkter' : 'Aktivt produkt'}`}
                  </div>
                </div>
                {expanded ?
                  <div className={styleClass.verticalAlignRemove}>
                    <Icon
                      name={IconName.MINUS}
                      color={IconColor.NEUTRAL_3}
                      size={IconSize.BASE}
                      className={classNames(Margin.ML_3)} />
                  </div>
                  :
                  <div className={styleClass.verticalAlignRemove}
                    role="button"
                    onKeyDown={() => removeClick(employee)}
                    tabIndex={index}
                    onClick={() => removeClick(employee)}>
                    <Icon
                      name={IconName.CANCEL}
                      color={IconColor.NEUTRAL_5}
                      size={IconSize.BASE}
                      className={classNames(Margin.ML_3)} />
                  </div>}
              </div>
            </GridItem>
          )
          return (<Grid gutter={GridGutter.NONE}>{mobile ? employeeListMobile : employeeList}{expanded ? subscriptionList : ''}</Grid>);
        }
        )}
      </div>

      {!expanded && <div className={classNames(Margin.LG_MT_4, Margin.MT_0, AlignItems.ITEMS_CENTER, JustifyContent.JUSTIFY_CENTER, Display.FLEX, FlexWrap.FLEX_WRAP)}>
        <Button
          text={"Stopp tilgang til produkter"}
          ariaLabel={"Stopp tilgang til produkter"}
          onClick={bulkTerminateSubscriptions}
          className={classNames(Margin.MR_2)}
        />
        <Button
          text={"Avbryt"}
          ariaLabel={"Avbryt"}
          skin={ButtonSkin.SECONDARY}
          onClick={() => { closeModal(); selectionContext.context.actions.resetSelection() }}
        />
      </div>}
      {(bulkState === BulkTerminationState.COMPLETED_WITH_ERRORS || bulkState === BulkTerminationState.COMPLETED_SUCCESSFUL)
        && <div className={classNames(Margin.LG_MT_4, Margin.MT_0, AlignItems.ITEMS_CENTER, JustifyContent.JUSTIFY_CENTER, Display.FLEX, FlexWrap.FLEX_WRAP)}>
          <Button
            text={"Ok, lukk"}
            ariaLabel={"Lukk"}
            skin={ButtonSkin.PRIMARY_1}
            href={`/admin/avtaler/${agreementNumber}/abonnenter`}
            onClick={() => { selectionContext.context.actions.resetSelection(); closeModal(); }}
          />
        </div>}
    </>
  );
};

export default SelectedSubscriptions;
