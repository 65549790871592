import React, {ReactNode, useContext} from "react";
import {classNames, Container, ContainerWidth, Height, Overflow, Padding} from "@snoam/pinata";
import {EnvelopeContext} from "../../context/EnvelopeContext/EnvelopeContext";


const styleClass = {
  root: (className?: string) => classNames(
    className,
    Height.H_FULL,
    Overflow.OVERFLOW_X_HIDDEN,
    Overflow.OVERFLOW_Y_SCROLL,
  ),
  inner: (hideGutter: boolean) => classNames(
    Padding.PX_0,
    {
      [Padding.PT_PX]: !hideGutter,
      [Padding.MD_PX_0]: !hideGutter
    },
  )
};

export interface IPageProps {
  className?: string;
  hideGutter?: boolean;
  hideFooter?: boolean;
  children?: ReactNode;
  width?: ContainerWidth;
}

const OrderPage: React.FunctionComponent<IPageProps> = React.memo(({className, children, hideGutter = false}) => {
  const { offsetAnimationClass, open, toggle} = useContext(EnvelopeContext);

  const onOutsideEnvelopeClick = () => {
    if (open) {
      toggle();
    }
  };



  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={styleClass.root(className)}
      onClick={onOutsideEnvelopeClick}
      onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
          onOutsideEnvelopeClick();
        }
      }}
    >
      <Container
        className={classNames(styleClass.inner(hideGutter), offsetAnimationClass)}
      >
        {children}
      </Container>
    </div>
  )
});


export default OrderPage;
