import * as React from 'react';

import { Card, classNames, LineHeight, Padding } from '@snoam/pinata';

const NoInvoices = () => {
  return (
    <Card className={classNames(Padding.PY_8, LineHeight.LEADING_HEADLINE4)}>
      Det er ikke registrert noen faktura enda.
      Fakturaen vises her når den er lagt inn i systemet.
    </Card>
  )
};

export default NoInvoices;
