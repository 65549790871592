import * as React from "react";
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Card,
  classNames,
  Cursor,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Height,
  LineHeight,
  Overflow,
  Padding,
  Position,
  TextAlign,
  TextColor,
  FontSize,
  ZIndex,
  TextDecoration,
  FlexDirection,
  JustifyContent,
  AlignItems,
  FontFamily,
  BorderRadius,
  BoxShadow, Margin,
} from "@snoam/pinata";

const styleClass = {
  root: (productCanBeClicked: boolean | undefined, className?: string) => classNames(
    className,
    TextDecoration.NO_UNDERLINE,
    BorderRadius.ROUNDED_LG,
    BoxShadow.SHADOW,
    Padding.P_0,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    JustifyContent.JUSTIFY_BETWEEN,
    Height.H_FULL,
    productCanBeClicked ? Cursor.CURSOR_POINTER : null,
    Margin.ML_2,
    Margin.MR_2,
  ),
  content: classNames(
    Padding.PX_4,
    Padding.PY_4,
    FontSize.TEXT_BASE,
    LineHeight.LEADING_TIGHT,
  ),
  heading: classNames(
    TextColor.TEXT_BLACK,
    FontFamily.FONT_TITLE_1,
    FontSize.TEXT_XL,
    FontWeight.FONT_SEMIBOLD,
  ),
  price: {
    root: classNames(
      Padding.PT_6
    ),
    percent: classNames(
      TextDecoration.LINE_THROUGH,
      TextColor.TEXT_NEUTRAL_6,
      Padding.MD_PL_4,
    )
  },
  banner: classNames(
    Position.ABSOLUTE,
    ZIndex.Z_10,
    FontSize.TEXT_XS,
    BackgroundColor.BG_PRIMARY_3,
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_WHITE,
    Padding.PY_2,
    Padding.PX_8,
    'product-box--banner'
  ),
  activeBanner: (active: string | undefined) => classNames(
    Position.ABSOLUTE,
    ZIndex.Z_10,
    FontSize.TEXT_BASE,
    active && active === 'Aktiv' ? BackgroundColor.BG_INFO : BackgroundColor.BG_NEGATIVE,
    TextColor.TEXT_WHITE,
    Padding.PY_2,
    TextAlign.TEXT_CENTER,
    'product-box--active-banner'
  ),
  footer: classNames(
    BorderWidth.BORDER_T_1,
    BorderColor.BORDER_NEUTRAL_3,
    BorderStyle.BORDER_DASHED,
  ),
  footerWrapper: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
  ),
};

type PriceProps = {
  price: number;
  discountPercent: number;
  discountPrice: number | undefined;
}
const Price: React.FunctionComponent<PriceProps> = ({ price, discountPercent, discountPrice }) => {
  return (
    <p className={styleClass.price.root}>
      {discountPercent === 0 || discountPercent < 100 ? 'Fra:' : ''}
      <strong className={Padding.PL_2}>
        {discountPercent === 100 ? `Ordinær pris: ${price}` : discountPrice && discountPrice > 0 ? price - discountPrice : price} kr/mnd
      </strong>
      {discountPercent > 0 && discountPercent < 100 && price && discountPrice &&
        <span className={styleClass.price.percent}> {price} kr/mnd</span>
      }
    </p>
  )
};

type BannerProps = {
  discount: number;
}
const Banner: React.FunctionComponent<BannerProps> = ({ discount }) => {
  return (
    <div className={styleClass.banner}>
      <p className={classNames(Padding.PX_10, FontWeight.FONT_LIGHT)}>INNTIL</p>
      <p className={FontWeight.FONT_BOLD}>{discount}% rabatt</p>
    </div>
  )
};

type ActiveBannerProps = {
  active: string | undefined;
};
const ActiveBanner: React.FunctionComponent<ActiveBannerProps> = ({ active }) => {
  return (
    <div className={styleClass.activeBanner(active)}>
      <p className={classNames(Padding.PX_10, FontWeight.FONT_LIGHT)}>{active}</p>
    </div>
  )
};

interface IProductBoxProps {
  img: string | null;
  heading: string;
  infoText: string | null;
  className?: string;
  footer?: JSX.Element;
  price?: number;
  active?: string;
  percent?: number;
  onClick?: () => void;
  productLabel?: string;
  productCanBeClicked?: boolean;
  tabIndex?: number;
  onKeyDown?: any;
}


export const ProductBox: React.FunctionComponent<IProductBoxProps> = (props) => {
  const {productLabel, productCanBeClicked, className, heading, img, infoText, percent, price, active, onClick, onKeyDown = () => void (0)} = props;
  const discountPercent: number = percent ? percent : 0;
  const discountPrice = discountPercent && price && (Math.round(price * (discountPercent / 100)));

  return (
    <Card
      backgroundColor={BackgroundColor.BG_WHITE}
      className={styleClass.root(productCanBeClicked, className)}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex={props.tabIndex}
    >
      <div>
        <div className={classNames(Position.RELATIVE, Overflow.OVERFLOW_HIDDEN)}
        >
          <img
            className={classNames(BorderRadius.ROUNDED_T_LG)}
            src={img ? img : 'https://snotech-media.s3.amazonaws.com/Bedrift/product_default_2@2x.png'}
            alt={"Illustrativt bilde"} />
          {discountPercent > 0 && active === undefined ? <Banner discount={discountPercent} /> : null}
          {active ? <ActiveBanner active={active} /> : null}
        </div>

        <div className={styleClass.content}>
          <div className={classNames(Padding.PB_2)}>
            <Heading
              level={HeadingLevel.TWO}
              className={styleClass.heading}
              >
              {heading}
            </Heading>
            {productLabel ? <p className={classNames(FontSize.TEXT_SM)}>{productLabel}</p> : null}
          </div>
          {infoText ? <p className={FontSize.TEXT_BASE}>{infoText}</p> : null}
          {price ? <Price discountPercent={discountPercent} discountPrice={discountPrice} price={price} /> : null}
        </div>
      </div>

      {props.footer &&
        <div className={classNames(Padding.PB_3, Padding.PL_10, Padding.PR_10)}>
          <div className={styleClass.footer}>
            {props.footer}
          </div>
        </div>
      }
    </Card>
  )
};

export default ProductBox;
