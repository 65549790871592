import * as React from "react";
import {useContext, useEffect, useState} from "react";
import {
  classNames,
  Cursor,
  Display,
  FontWeight,
  Grid,
  GridComposition,
  GridGutter,
  GridItem,
  Heading,
  HeadingLevel,
  Margin,
  MaxWidth,
  TextColor,
  FontSize, Flex, AlignItems, TextDecoration, JustifyContent,
} from "@snoam/pinata";
import Table from '../../components/Table/Table';
import {useFilterFactory} from "../../context/FilterContext/FilterContext";
import Sort from "../../components/Filter/Sort";
import FilterButton from "../../components/EmployeesAndSubscriptionsTable/FilterButton";
import {TableControls} from "../../components/EmployeesAndSubscriptionsTable/EmployeesTable";
import AdministrationModal from '../../components/AdministrationModal/AdministrationModal';
import FilterEnvelopeContainer from "../../components/Filter/FilterEnvelopeContainer";
import {useQuery} from '@apollo/client';
import {GetSuperAdmins, GetSuperAdmins_superAdmins} from '../../__generated__/GetSuperAdmins';
import {LoadingFragment} from '../LoadingPage';
import AdminModel from '../../models/AdminModel';
import {GET_AGREEMENT, GET_AGREEMENTS, GET_SUPER_ADMINS} from '../../queries';
import AdministrationRow from '../Administration/AdministrationRow';
import AdministrationAddButton from '../Administration/AdministrationAddButton';
import {ModalContext} from "@snoam/mono-modal";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import DeleteAdminLink from "../../components/DeleteAdminButton/DeleteAdminLink";
import {useParams} from "react-router-dom";
import {DocumentNode} from "graphql";
import {GetAgreementVariables} from "../../__generated__/GetAgreement";

const styleClass = {
  heading: classNames(
    FontSize.TEXT_2XL,
    FontWeight.FONT_HAIRLINE,
    Margin.MD_ML_0,
    Margin.ML_4,
    Margin.MB_4,
    Margin.MD_MB_0
  ),
  symbol: {
    root: classNames(
      Display.FLEX,
      Flex.FLEX_1
    ),
    deleteAdminLink: classNames(
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      Margin.LG_MT_NEGATIVE_1,
      TextColor.TEXT_PRIMARY_3,
      TextColor.HOVER_TEXT_PRIMARY_4,
      TextDecoration.NO_UNDERLINE,
      Cursor.CURSOR_POINTER,
      JustifyContent.JUSTIFY_END
    )
  }
};

const Administration: React.FunctionComponent<{}> = () => {
  const params = useParams<{ agreementNumber: string }>();
  const agreementNumber = params.agreementNumber ? +params.agreementNumber : undefined;
  const {mobile, tablet, portrait} = useContext(ResponsiveContext);
  const {applyFilter, filterContextState} = useFilterFactory();
  const [filtered, setFiltered] = useState<GetSuperAdmins_superAdmins[]>([]);
  const {data = {superAdmins: []}, error, loading} = useQuery<GetSuperAdmins>(GET_SUPER_ADMINS);
  const modalId = 'AdministrationModal';
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const toggleModal = () => openModalId ? closeModal() : openModal(modalId);
  const isSpecificAgreement = agreementNumber && typeof agreementNumber === "number" || false;
  const refetchQueries: { query: DocumentNode, variables?: object }[] = [
    { query: GET_SUPER_ADMINS },
    { query: GET_AGREEMENTS },
  ];
  if (isSpecificAgreement) {
    refetchQueries.push({ query: GET_AGREEMENT, variables: {agreementNumber: [agreementNumber]} as GetAgreementVariables });
  }

  useEffect(() => {
    applyFilter(data.superAdmins || [])
      .then(filtered => setFiltered(filtered));
  }, [data, loading, filterContextState]);


  if (loading) {
    return <LoadingFragment/>;
  }

  if (error) {
    throw error;
  }

  const pageHeading = `Schibsted admin (${filtered.length})`;

  let filterHeader = 'Filtrer listen';
  if (mobile || (tablet && portrait)) {
    filterHeader = `Filtrer ${pageHeading.toLowerCase()}`
  }

  return (
    <>
      <FilterEnvelopeContainer header={filterHeader}>
        <Sort>
          <Sort.Ascending name={'name.formatted'} checked={true}/>
          <Sort.Descending name={'name.formatted'}/>
        </Sort>
      </FilterEnvelopeContainer>

      <Heading
        level={HeadingLevel.TWO}
        className={styleClass.heading}>
        {pageHeading}
      </Heading>

      <TableControls>
        <Grid
          composition={GridComposition.ECHO}
          gutter={mobile ? [] : [GridGutter.LEFT_NONE, GridGutter.RIGHT_NONE]}
          className={classNames(Display.FLEX, AlignItems.ITEMS_CENTER, Margin.MB_4, Margin.MD_MB_0)}
        >
          <GridItem className={classNames(JustifyContent.JUSTIFY_START)}>
            <FilterButton className={MaxWidth.MAX_W_XS}/>
          </GridItem>
          <GridItem className={classNames(JustifyContent.JUSTIFY_END)}>
            <AdministrationAddButton
              toggleModal={toggleModal}
              isMobile={!!mobile}
            />
          </GridItem>
        </Grid>
      </TableControls>

      <Table
        rows={filtered}
        alternatingColor={true}
        rowRenderer={user => <AdministrationRow user={user} isMobile={!!mobile}/>}
        canExpand={() => false}
        expandSymbolRenderer={(user) => {
          return <div
            className={styleClass.symbol.root}
          >
            <DeleteAdminLink
              id={user.id}
              refetchQueries={refetchQueries}
              className={styleClass.symbol.deleteAdminLink}
              mobile={!!mobile}
            />
          </div>
        }}
      />

      <AdministrationModal
        id={modalId}
        roles={AdminModel.SchibstedAdministrationRoles}
        roleTitle={'Schibsted administrator'}
        agreementNumber={agreementNumber}
      />
    </>
  )
};

export default Administration;
