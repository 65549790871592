import * as React from "react";
import {toGeneratePathParams, trackPageView} from '../../utils';
import {
  EventType,
  ObjectType,
  getInstance,
  initialize,
} from '@snoam/mono-pulse';
import { generatePath } from 'react-router';
import { routes } from "../routes";
import { MinBedriftRoute } from '../utils';
import {createContext, useEffect} from 'react';
import { ClientShortHandName } from '@snoam/mono-scc';
import {useSpid} from "@snoam/mono-spid/lib/spid";
import {Location, Params, useLocation, useParams} from "react-router-dom";

let timeout: NodeJS.Timeout;

const debouncePulseEvent = (wait: number, func: any) => {
  return function () {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      //@ts-ignore
      func.apply(this, []);
    }, wait);
  }
}

type TrackerProps = any;

const isCurrentPath = ({ params, location }: { params: Params,location: Location }) => (route: MinBedriftRoute<any>): boolean => {
  try {
    return generatePath(route.path, toGeneratePathParams(params)) === location.pathname;
  } catch (e) {
    return false;
  }
}

export interface IPulseTrackerContext {
  state: { message: string[] }
  action: { setTrackMessage: any};
}

export const PulseTrackerContext = createContext<IPulseTrackerContext>({} as IPulseTrackerContext);

export const PulseTrackerProvider = ({ children }: { children?: React.ReactNode }) => {
  const [message, setMessage] = React.useState<string[]>(['']);
  const spid = useSpid();
  const pulse = getInstance();

  const setTrackMessage = (trackMessage: string) => {
    message.pop();
    message.push(trackMessage);
    setMessage(message);
  };

  useEffect(() => {
    initialize(ClientShortHandName.BEDRIFT);
  }, []);
  useEffect(() => {
    if(spid.state.userId) {
      pulse.setUser(spid.state.userId.toString());
    }
  }, [spid.state.userId])
  const context = {
    state: {
      message
    },
    action: {
      setTrackMessage: setTrackMessage,
    }
  };
  return (
    <PulseTrackerContext.Provider value={context}>
      {children}
    </PulseTrackerContext.Provider>
  )
};

export const Tracker: React.FunctionComponent<TrackerProps> = ({ children }) => {
  const params = useParams();
  const location = useLocation();
  const pulseContext = React.useContext(PulseTrackerContext);
  const message = pulseContext.state.message;
  useEffect(() => {
    pulseContext.action.setTrackMessage('');
  }, []);

  const matchingRoute: MinBedriftRoute<any> = Object.entries(routes).map(([_, v]) => v.routes)
    .reduce((a, c) => a.concat(c), [] as MinBedriftRoute<any>[])
    .find(route => isCurrentPath({ params, location })(route))
  || {
      trackingLabel : 'Feil - ',
      label: '404'
    };

  const {trackinglabel = '', label} = matchingRoute;

  const pulseTracker = debouncePulseEvent(1500, () =>
    trackPageView({
      label: trackinglabel + label + message,
      labelPrefix: 'Min Bedrift',
      objectType: ObjectType.CONTENT,
      eventType: EventType.VIEW,
      id: `content:minbedrift:${(trackinglabel + label + message).toLowerCase().replace(/ /g, "")}`
    }));
  React.useLayoutEffect(pulseTracker, []);
  return <>{children}</>
};
