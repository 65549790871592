import React from "react";
import {
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Margin,
  TextColor,
  FontSize,
  Toggle,
  ToggleSize,
  ToggleSkin,
  Width, FlexDirection, FlexGrow, FlexShrink, JustifyContent, AlignItems
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Eq} from "../../components/Filter/types/Eq";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    FlexGrow.FLEX_GROW_0,
    FlexShrink.FLEX_SHRINK_0,
    JustifyContent.JUSTIFY_BETWEEN,
    AlignItems.ITEMS_START,
  ),
  descr: {
    root: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Width.MD_W_FULL
    ),
    heading: classNames(
      FontSize.TEXT_BASE,
      Margin.MB_1,
      FontWeight.FONT_NORMAL
    ),
    text: classNames(
      TextColor.TEXT_NEUTRAL_4,
      FontSize.TEXT_SM
    )
  },
  toggle: ''
};


type IOnlyActiveAgreementsFilterProps = {}
const inputId = `toggle_only_active_agreements`;
export const OnlyActiveAgreementsFilter: React.FunctionComponent<IOnlyActiveAgreementsFilterProps> = () => (
  <Filter id={'OnlyActiveAgreementsFilter'} marshal={true}>
    <Eq
      id={'OnlyActiveAgreementsFilter_0'}
      key={'OnlyActiveAgreementsFilter_0'}
      name={'settings.enableOrdering'}
      value={true}
    >
      {
        ({active, toggleFilter}) => (
          <label className={styleClass.root} htmlFor={inputId}>


            <div className={styleClass.descr.root}>
              <Heading level={HeadingLevel.FIVE} className={styleClass.descr.heading}>
                Vis kun avtaler med aktive invitasjoner
              </Heading>
              <span className={styleClass.descr.text}>
                      Listen vil kun vise avtaler med aktive invitasjoner
              </span>
            </div>

            <div className={styleClass.toggle}>
              <Toggle
                id={inputId}
                size={ToggleSize.LARGE}
                skin={ToggleSkin.PRIMARY}
                checked={!!active}
                onClick={toggleFilter}
              />
            </div>
          </label>
        )
      }
    </Eq>
  </Filter>
);
