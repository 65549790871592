import * as React from "react";
import {ReactNode, useEffect, useState} from "react";
import {
  Button,
  classNames,
  FontFamily,
  FontWeight,
  Grid,
  GridComposition,
  GridGutter,
  Heading,
  HeadingLevel,
  Margin,
  FontSize, TextTransform, ButtonSkin, ButtonSize,
} from "@snoam/pinata";
import {useFilterFactory} from "../../context/FilterContext/FilterContext";
import {reducePath} from "./utils";
import {Observable} from "rxjs";
import {map, toArray} from "rxjs/operators";

const styleClass = {
  heading: classNames(
    TextTransform.UPPERCASE,
    FontFamily.FONT_BODY,
    FontSize.TEXT_SM,
    FontWeight.FONT_NORMAL,
    Margin.MB_4,
    Margin.ML_1,
  ),
  btn: classNames(
    Margin.MY_1,
    Margin.ML_1
  )
};

enum SORT {
  NEWEST = 'newest',
  ASC = 'ascending',
  DESC = 'descending'
}

const sortNumericOrDate = <T extends any>(propName: string) => (ob: Observable<T>): Observable<T[]> => {
  return ob
    .pipe(
      toArray(),
      map(xs =>
        xs.sort((a, b) => {
          let leafA = reducePath(propName, a);
          let leafB = reducePath(propName, b);

          if(Array.isArray(leafA)) {
            [leafA] = leafA.sort((a, b) => b>a ? 1 : -1);
         }
          if(Array.isArray(leafB)) {
            [leafB] = leafB.sort((a, b) => b>a ? 1 : -1);
          }
          return leafA  ? leafA.localeCompare(leafB,undefined, {numeric: true})*-1 : 1
        })
      )
    );
};
const sortAscending = <T extends any>(propName: string) => (ob: Observable<T>): Observable<T[]> => {
  return ob
    .pipe(
      toArray(),
      map(xs =>
        xs.sort((a, b) => {
          return reducePath(propName, a).localeCompare(reducePath(propName, b))
        })
      )
    );
};
const sortDescending = <T extends any>(propName: string) => (ob: Observable<T>): Observable<T[]> => {
  return ob
    .pipe(
      toArray(),
      map(xs =>
        xs.sort((a, b) => {
          return reducePath(propName, b).localeCompare(reducePath(propName, a))
        })
      )
    );
};

interface SortState {
  type?: SORT;
  prop?: string;
}

export const Sort = ({children}: { children: ReactNode }) => {

  const [sort, setSort] = useState<SortState>({type: undefined, prop: undefined});
  const {createFilter} = useFilterFactory();

  useEffect(() => {
    if (sort.type === SORT.NEWEST && sort.prop) {
      createFilter('sort', sortNumericOrDate(sort.prop));
    } else if (sort.type === SORT.ASC && sort.prop) {
      createFilter('sort', sortAscending(sort.prop));
    } else if (sort.type === SORT.DESC && sort.prop) {
      createFilter('sort', sortDescending(sort.prop));
    }
  }, [sort]);

  return (
    <>
      <Heading level={HeadingLevel.FIVE} className={styleClass.heading}>
        Tilpass sorteringen
      </Heading>

      <Grid composition={GridComposition.BRAVO} gutter={GridGutter.NONE}>
        {
          React.Children.map(children, (child) => {
            if (React.isValidElement<{ sort: SortState, setSort: (newState: SortState) => void }>(child)) {
              return React.cloneElement(
                child,
                {
                  ...child.props,
                  sort,
                  setSort,
                }
              );
            }
            return child;
          })
        }
      </Grid>
    </>
  )
};

export const withSort: <P extends { setSort: (newSort: SortState) => SortState, sort: SortState }> (c: React.FunctionComponentFactory<P>) => React.FunctionComponentFactory<Omit<P, 'setSort' | 'sort'>> = c => c as any;

export interface ISortAction {
  label?: string;
  name?: string;
  sort: SortState;
  setSort: (newState: SortState) => SortState;
  checked?: boolean;
}

Sort.NumericString = withSort(({label = 'Nyeste', name, sort, setSort, checked = false}: ISortAction) => {
  useEffect(() => {
    if (checked) {
      setSort({type: SORT.NEWEST, prop: name})
    }
  }, []);
  return (
    <Button
      text={label}
      ariaLabel={label}
      skin={sort.type === SORT.NEWEST ? ButtonSkin.PRIMARY : ButtonSkin.SECONDARY}
      size={ButtonSize.SMALL}
      onClick={() => setSort({type: SORT.NEWEST, prop: name})}
      className={styleClass.btn}/>
  )
});

Sort.Ascending = withSort(({label = 'Navn A-Å', name, sort, setSort, checked = false}: ISortAction) => {
  useEffect(() => {
    if (checked) {
      setSort({type: SORT.ASC, prop: name})
    }
  }, []);
  return (
    <Button
      text={label}
      ariaLabel={label}
      skin={sort.type === SORT.ASC ? ButtonSkin.PRIMARY : ButtonSkin.SECONDARY}
      size={ButtonSize.SMALL}
      onClick={() => setSort({type: SORT.ASC, prop: name})}
      className={styleClass.btn}
    />
  )
});

Sort.Descending = withSort(({label = 'Navn Å-A', name, sort, setSort, checked = false}: ISortAction) => {
  useEffect(() => {
    if (checked) {
      setSort({type: SORT.DESC, prop: name})
    }
  }, []);
  return (
    <Button
      text={label}
      ariaLabel={label}
      skin={sort.type === SORT.DESC ? ButtonSkin.PRIMARY : ButtonSkin.SECONDARY}
      size={ButtonSize.SMALL}
      onClick={() => setSort({type: SORT.DESC, prop: name})}
      className={styleClass.btn}
    />
  )
});

export default Sort;
