import * as React from "react";
import {MouseEventHandler, useState} from "react";
import {
  AlignItems,
  BackgroundColor,
  BorderColor,
  BorderRadius,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSize,
  ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  FlexShrink,
  FontSize,
  FontWeight,
  Grid,
  GridComposition,
  Height,
  JustifyContent,
  Margin,
  Padding,
  StyledRouterLink,
  TextColor,
  ToggleSize,
  Width,
} from "@snoam/pinata";
import {SettingsIcon, TouchAppIcon} from "../../components/Icons/Icons";
import * as clipboard from "clipboard-polyfill"
import {generatePath} from 'react-router';
import {agreementPageRoute, orderInitialPageRoute, productsPageRoute} from '../../routes/routes';
import ToggleAgreementActivated from '../../components/ToggleAgreementActivated/ToggleAgreementActivated';
import {
  GetAgreement,
  GetAgreement_me_agreementsWithDetails,
  GetAgreement_me_agreementsWithDetails_departments,
  GetAgreementVariables
} from '../../__generated__/GetAgreement';
import {useQuery} from '@apollo/client';
import {LoadingFragment} from '../LoadingPage';
import {GET_AGREEMENT, GET_AGREEMENT_DEPARTMENTS} from '../../queries';
import {toGeneratePathParams, trackClickEngagment} from '../../utils';
import {MinBedriftRouteComponentProps} from "../../routes/utils";
import {Helmet} from "react-helmet";
import SvgImage from "../../components/SvgImage/SvgImage";
import {useRole} from "../../components/Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";
import {useParams} from "react-router-dom";

const inviteStyleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
  ),
  heading: classNames(
    Padding.PT_8,
    Padding.PB_4,
    FontWeight.FONT_LIGHT,
    Padding.PL_4,
    Padding.MD_PL_0,
    FontSize.MD_TEXT_2XL,
    FontSize.TEXT_XL,
    FontSize.MD_TEXT_HEADLINE1,
  )
};

const inviteBoxStyleClass = {
  whiteBox: {
    root: classNames(
      BackgroundColor.BG_WHITE,
      Padding.SM_P_8,
      Padding.P_2,
      Display.FLEX,
      FlexDirection.MD_FLEX_ROW,
      FlexDirection.FLEX_COL,
    ),
    container: classNames(
      Display.FLEX,
      FlexDirection.FLEX_ROW,
      BorderStyle.BORDER_DASHED,
      BorderWidth.BORDER_B_1,
      BorderColor.BORDER_NEUTRAL_3,
      Padding.PB_6,
      Padding.PT_4,
      Padding.MD_PT_0
    )
  },
  greyBox: {
    root: classNames(
      BackgroundColor.BG_NEUTRAL_1,
      Padding.PL_6,
      Padding.PR_3,
      Padding.PY_6
    ),
    left: classNames(
      Width.MD_W_1_2,
      Display.FLEX,
      FlexDirection.FLEX_COL
    ),
    right: classNames(
      Width.MD_W_1_2,
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Margin.MT_4,
      Margin.MD_MT_0
    ),
    icon: classNames(
      Display.FLEX,
      FlexDirection.FLEX_ROW,
      Padding.PT_2,
      AlignItems.ITEMS_CENTER
    )
  },
  badge: classNames(
    Width.MD_W_6,
    Height.MD_H_6,
    Width.W_6,
    Height.H_6,
    BackgroundColor.BG_PRIMARY_3,
    BorderRadius.ROUNDED_FULL,
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_CENTER,
    TextColor.TEXT_WHITE,
    FontWeight.FONT_BOLD,
    FontSize.TEXT_SM,
    Margin.MR_4,
    FlexShrink.FLEX_SHRINK_0,
  ),
  isActive: (active: boolean) => classNames(
    {
      [BackgroundColor.BG_NEUTRAL_5]: !active,
    }
  ),
  link: (active: boolean, pad: boolean = true) => classNames(
    FontSize.TEXT_SM,
    FontSize.MD_TEXT_BASE,
    {
      [Padding.PT_6]: pad,
      [TextColor.TEXT_NEUTRAL_5]: !active
    }
  )
};

interface InviteBoxProps {
  agreement: Pick<GetAgreement_me_agreementsWithDetails, 'agreementNumber' | 'id' | 'maxSubscriptionsPerEmployee' | 'settings'>;
  departments: Pick<GetAgreement_me_agreementsWithDetails_departments, 'agreementNumber' | 'name'>[];
}

const InviteBox: React.FunctionComponent<InviteBoxProps> = ({agreement, departments}) => {

  const link = generatePath(orderInitialPageRoute.path, toGeneratePathParams({agreementNumber: agreement.agreementNumber}));
  const linkSettings = generatePath(agreementPageRoute.path, toGeneratePathParams({agreementNumber: agreement.agreementNumber}));
  const linkProducts = generatePath(productsPageRoute.path, toGeneratePathParams({agreementNumber: agreement.agreementNumber}));
  const [copySuccess, setCopySuccess] = useState<boolean[]>([false]);
  const active = agreement.settings.enableOrdering;
  const {role: userRole} = useRole();


  const trackChangeSettingsClick = () => {
    trackClickEngagment('Inviter ansatte - Endre instillinger');
  };

  const trackShowProductsClick = () => {
    trackClickEngagment('Inviter ansatte - Se våre produkter');
  };

  const copyToClipboard: (link: string, i: number) => MouseEventHandler<HTMLButtonElement | HTMLAnchorElement | HTMLDivElement> = (link, i) => () => {
    trackClickEngagment('Inviter ansatte - Copy Link Click');
    clipboard.writeText(window.location.origin + link);
    copySuccess[i] = true;
    setCopySuccess([...copySuccess]);
    setTimeout(() => {
      copySuccess[i] = false;
      setCopySuccess([...copySuccess]);
    }, 1250);
  };

  return (
    <>
      <div className={inviteBoxStyleClass.whiteBox.root}>
        <div className={classNames({
          [Width.MD_W_1_2]: departments.length === 0,
          [Width.MD_W_FULL]: departments.length > 0,
        })}>
          <div className={classNames(inviteBoxStyleClass.whiteBox.container, {
            [Width.MD_W_1_2]: departments.length > 0,
          })}>
            <span className={inviteBoxStyleClass.badge}>1</span>
            <span className={classNames(Width.W_2_3, FontWeight.FONT_BOLD)}>Skru på invitasjon slik at siden blir tilgjengelig for ansatte</span>

            <div className={classNames(Display.FLEX, AlignItems.ITEMS_END, Margin.ML_AUTO)}>
              <ToggleAgreementActivated
                agreement={agreement}
                defaultChecked={active}
                disabled={userRole === AdminType.SUPEROBSERVER}
                size={ToggleSize.LARGE}
              />
            </div>

          </div>

          <div className={classNames(Display.FLEX, FlexDirection.FLEX_ROW, Padding.PT_6, Width.W_FULL)}>
            <span className={classNames(inviteBoxStyleClass.badge, inviteBoxStyleClass.isActive(active))}>2</span>
            <div className={classNames(Display.FLEX, FlexDirection.FLEX_COL, {
              [Width.MD_W_FULL]: departments.length > 0,
            })}>
              <span className={FontWeight.FONT_BOLD}>Del link til invitasjon med dine ansatte på e-post, intranett eller SMS.</span>
              {departments.length === 0 ? null : (<>
                Hovedlink:
              </>)}
              <StyledRouterLink
                to={link}
                className={inviteBoxStyleClass.link(active)}
                style={{wordBreak: 'break-word'}}>
                {window.location.origin + link}
              </StyledRouterLink>
              {active &&
              <Button
                text={copySuccess[0] ? "Kopiert!" : "Kopier link"}
                ariaLabel={copySuccess[0] ? "Kopiert!" : "Kopier link"}
                size={departments.length === 0 ? ButtonSize.SMALL : ButtonSize.MEDIUM}
                skin={copySuccess[0] ? ButtonSkin.CONFIRM : ButtonSkin.PRIMARY}
                className={classNames({
                  [Margin.MT_6]: true,
                  [Width.MD_W_2_3]: departments.length === 0,
                  [Width.MD_W_1_6]: departments.length > 0,
                })}
                iconRight={TouchAppIcon()}
                onClick={copyToClipboard(link, 0)}
                style={{transition: 'background-color 500ms ease'}}
              />
              }

              {departments.length ? (
                <>
                  {departments.map((department) => [department.name, `${link}?avdeling=${department.agreementNumber}`] as string[]).map(([departmentName, departmentLink], i) => (
                    <div className={classNames(Display.FLEX, FlexDirection.FLEX_ROW, {[Margin.MT_6]: i === 0})} key={`departments-${i}`}>
                      {departmentName}:
                      <StyledRouterLink
                        to={departmentLink}
                        className={`${inviteBoxStyleClass.link(active, false)} ${Margin.ML_4}`}
                        style={{wordBreak: 'break-word'}}>
                        {window.location.origin + departmentLink}
                      </StyledRouterLink>
                      {active ? <Button
                        text={copySuccess[1 + i] ? "Kopiert!" : "Kopier link"}
                        ariaLabel={copySuccess[1 + i] ? "Kopiert!" : "Kopier link"}
                        size={ButtonSize.SMALL}
                        skin={copySuccess[1 + i] ? ButtonSkin.CONFIRM : ButtonSkin.PRIMARY}
                        className={classNames(Margin.ML_4, Width.MD_W_1_6, Margin.MT_0)}
                        iconRight={TouchAppIcon()}
                        onClick={copyToClipboard(departmentLink, 1 + i)}
                        style={{transition: 'background-color 500ms ease'}}
                      /> : null}
                    </div>
                  )) }
                </>
              ) : null}
            </div>
          </div>

        </div>
        {departments.length === 0 && (
          <div className={classNames(Width.MD_W_1_2, Display.FLEX, JustifyContent.JUSTIFY_CENTER)}>
            <SvgImage href={`${process.env.PUBLIC_URL}/svg/share.svg`} className={Width.W_FULL}/>
          </div>
        )}
      </div>

      <Grid composition={GridComposition.BRAVO} className={inviteBoxStyleClass.greyBox.root}>
        <div className={inviteBoxStyleClass.greyBox.left}>
          <span>Mine ansatte kan benytte seg av {agreement.maxSubscriptionsPerEmployee ? `maks ${agreement.maxSubscriptionsPerEmployee}` : 'ubegrenset antall'} abonnement hver.</span>
          <div className={inviteBoxStyleClass.greyBox.icon}>
            <SettingsIcon/>
            <StyledRouterLink to={linkSettings} onClick={trackChangeSettingsClick}>Endre
              innstillinger</StyledRouterLink>
          </div>
        </div>
        <div className={inviteBoxStyleClass.greyBox.right}>
          <span>Er du usikker på hvilke produkter ansatte kan velge?</span>
          <StyledRouterLink to={linkProducts} onClick={trackShowProductsClick} className={classNames(Padding.PT_2)}>Se
            våre produkter</StyledRouterLink>
        </div>
      </Grid>
    </>
  )
};


export const Invite: React.FunctionComponent<MinBedriftRouteComponentProps> = ({ route }) => {

  const params = useParams<{ agreementNumber: string }>();
  // @ts-ignore
  const agreementNumber = [+params.agreementNumber];
  const variables = {agreementNumber};
  const {data, error, loading} = useQuery<GetAgreement, GetAgreementVariables>(GET_AGREEMENT, {
    variables,
    fetchPolicy: "cache-first"
  });
  const {data: departmentData, error: departmentError, loading: departmentLoading} = useQuery<GetAgreement, GetAgreementVariables>(GET_AGREEMENT_DEPARTMENTS, {
    variables,
    fetchPolicy: 'cache-first'
  });

  if (loading || departmentLoading) {
    return <LoadingFragment/>;
  }

  if (error || departmentError) {
    throw error;
  }

  const [agreement] = data!.me.agreementsWithDetails;
  const [departmentAgreement] = departmentData!.me.agreementsWithDetails;

  return (
    <>
      <Helmet>
        <title>{`Min Bedrift ${agreement ? `| ${agreement.accountName} ` : ''} ${route ? `| ${route.label}` : ''}`}</title>
      </Helmet>
      <div className={inviteStyleClass.root}>
        <h1 className={inviteStyleClass.heading}>Inviter ansatte til å benytte produktene i avtalen</h1>
        <InviteBox agreement={agreement} departments={departmentAgreement.departments || []}/>
      </div>
    </>
  )
};


export default Invite;
