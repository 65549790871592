import * as React from "react";
import {useContext} from "react";
import {BackgroundContext} from "@snoam/mono-background-loader";
import {DesktopSideBarWidth} from "../../Const";
import DegreeNavigation from "../../components/DegreeNavigation/DegreeNavigation";
import {BackgroundColor} from "@snoam/pinata";

export const GenericNavigation: React.FunctionComponent<{}> = ({ children }) => {
  const { setBgImage, setStyle } = useContext(BackgroundContext);
  React.useEffect(() => {
    setBgImage(`${process.env.PUBLIC_URL}/img/navbar_background_img.png`);
    setStyle({ backgroundSize: DesktopSideBarWidth });
  }, []);
  return (
    <DegreeNavigation
      key={'generic-navigation'}
      hideSidebar={false}
      wrapperClassName={BackgroundColor.BG_NEUTRAL_2}>
      {children}
    </DegreeNavigation>
  )
};
