import * as React from "react";
import {useEffect, useState} from "react";
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  Button,
  ButtonSize,
  ButtonSkin,
  Card,
  Checkbox,
  classNames,
  Cursor,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Margin,
  Padding,
  Position,
  TextAlign,
  TextColor,
  FontSize,
  Width, FlexDirection, TextDecoration
} from "@snoam/pinata";
import {Modal} from "@snoam/mono-modal";
import {GetAgreementProducts_me_agreementsWithDetails_products} from "../../__generated__/GetAgreementProducts";
import { getDiscountPercentage } from '../../utils';
import { SyntheticAgreementProduct } from '../GroupProducts/GroupProducts';

const styleClass = {
  root: classNames(
    TextAlign.TEXT_CENTER,
    TextColor.TEXT_NEUTRAL_6,
    Padding.PB_8,
    Padding.MD_PB_0,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    Margin.MB_4,
  ),
  paragraph: classNames(
    Margin.MB_4,
  ),
  card: {
    root: classNames(
      BackgroundColor.BG_WHITE,
      Display.FLEX,
      FlexDirection.FLEX_ROW,
    ),
    priceLine: classNames(
      FontSize.TEXT_SM,
      Padding.PT_1,
    ),
    desc: classNames(
      TextAlign.TEXT_LEFT,
      Width.W_2_3,
    )
  },
  footer: classNames(
    Margin.MT_8,
    BorderWidth.BORDER_T_1,
    BorderStyle.BORDER_DASHED,
    BorderColor.BORDER_NEUTRAL_6,
    Padding.PT_4
  ),
  message: {
    root: classNames(
      BackgroundColor.BG_PRIMARY_2,
      TextColor.TEXT_BLACK,
      Display.FLEX,
      FlexDirection.FLEX_ROW,
      FontSize.TEXT_SM,
      TextAlign.TEXT_LEFT,
      Position.RELATIVE
    )
  }
};

interface ISelectProductModal {
  id: string;
  onClose: () => void;
  products: SyntheticAgreementProduct[];
  publication: string;
  onSave: (selectedProducts: GetAgreementProducts_me_agreementsWithDetails_products[]) => void;
  preselectedProducts: string[];
  isLoading: boolean;
  buttonText: string;
  isActiveProducts: boolean;
  isLoadingText: string;
  agreementDiscountPercent: number;
}

const SelectProductModal: React.FunctionComponent<ISelectProductModal> = ({id, onClose, products, publication, isLoading, buttonText, isLoadingText, isActiveProducts, onSave, agreementDiscountPercent}) => {
  const [showMessageForProducts, setShowMessageForProducts] = useState<string[]>([]);
  const [returnProducts, setReturnProducts] = useState<SyntheticAgreementProduct[]>([]);
  const [listProducts, setListProducts] = useState<SyntheticAgreementProduct[]>([])

  useEffect(() => {
    setListProducts((products || []).map(p => Object.assign({}, p)));
    if (isActiveProducts) {
      setReturnProducts(products);
    }
  }, [products]);

  const changeProduct = (productCombination: string, isChecked: boolean) => {

    listProducts.forEach((p, key: number) => {
      if (p.productCombination === productCombination) {
        p.active = !isChecked;
        if (p.active) {
          setReturnProducts([...returnProducts, p]);
        }
        if (isActiveProducts) {
          listProducts[key] = p;
          return setReturnProducts(listProducts);
        }
        const productToRemove = returnProducts.find(rp => rp.productCombination === productCombination);
        if (!p.active && productToRemove) {
          setReturnProducts(returnProducts.filter(product => product.productCombination !== productToRemove.productCombination))
        }
      }
    });
    const currentProduct = listProducts.find(lp => lp.productCombination === productCombination);
    if (currentProduct && returnProducts.find(rp => rp.productCombination === currentProduct.productCombination && !currentProduct.active)) {
      setShowMessageForProducts([...showMessageForProducts, productCombination])
    } else {
      showMessageForProducts.splice(showMessageForProducts.indexOf(productCombination), 1);
      setShowMessageForProducts([...showMessageForProducts]);
    }
  };

  const onClickSave = () => {
    const productsThatExistsOnAgreement = returnProducts
      .filter(p => (!p.synthetic || p.active)) // strict comparison on synthetic
      .map(p => {
        delete p.synthetic;
        return p;
      });
    onSave(productsThatExistsOnAgreement);
  };

  const handleCancelKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onClose();
    }
  };

  return (
    <Modal
      backdrop={true}
      id={id}
      onClose={onClose}
      className={classNames(styleClass.root, BackgroundColor.BG_NEUTRAL_1)}
    >
      <Heading level={HeadingLevel.TWO} className={styleClass.heading}>
        Hvilke produker fra {publication} vil du gjøre tilgjengelig?
      </Heading>

      <p className={styleClass.paragraph}>Kryss av under:</p>

      {
        listProducts.map((p, key) => {
          const product = Object.assign({}, p);
          const isChecked = product.active;

          if (!product.public) {
            return null;
          }

          const showMessage = showMessageForProducts.indexOf(product.productCombination) >= 0;
          const discountPercent: number = getDiscountPercentage(agreementDiscountPercent, product.discountPercent);
          const discountPrice = discountPercent && product.monthlyPrice && (Math.round(product.monthlyPrice * (discountPercent / 100)));


          return (
            <div key={key}
                 className={classNames(BorderWidth.BORDER_B_1, BorderStyle.BORDER_DASHED, BorderColor.BORDER_NEUTRAL_3,)}>
              <Card className={styleClass.card.root}>
                <Checkbox
                  index={key}
                  className={Width.W_1_6}
                  onChange={() => changeProduct(product.productCombination, isChecked)}
                  checked={isChecked}
                  aria-label={`Make ${product.productCombination} available for ordering?`}
                />
                <div className={styleClass.card.desc}>
                  <span className={classNames(FontWeight.FONT_BOLD)}>{product.productVariation}</span>
                  <div className={styleClass.card.priceLine}>
                    <span>{discountPercent === 100 ? `Ordinær pris: ${product.monthlyPrice}` : product.monthlyPrice - discountPrice} kr/mnd</span>
                    {discountPercent > 0 && discountPercent < 100 &&
                    <>
                      <span
                        className={classNames(TextDecoration.LINE_THROUGH, TextColor.TEXT_NEUTRAL_5, Padding.PL_2)}>{product.monthlyPrice} kr/mnd</span>
                      <span
                        className={classNames(Padding.PL_2, TextColor.TEXT_PRIMARY_3)}>-{discountPercent}%</span>
                    </>
                    }
                  </div>
                </div>
              </Card>
              {showMessage &&
              <Card className={styleClass.message.root}>
                <Icon
                  name={IconName.PLAY_ARROW}
                  size={IconSize.LARGE}
                  color={IconColor.PRIMARY_2}
                  style={{top: '-1.4rem', left: '5rem', transform: 'rotate(-90deg)'}}
                  className={Position.ABSOLUTE}
                />
                <Icon name={IconName.ANNOUNCEMENT} color={IconColor.PRIMARY_1} size={IconSize.LARGE}/>
                <p className={Margin.ML_4}>
                  Dette produktet vil ikke lenger være tilgjengelig for nye abonnenter.
                  Det slettes ikke fra eksisterende abonnenter.
                </p>
              </Card>
              }
            </div>
          )
        })}

      <Button
        skin={isLoading ? ButtonSkin.PRIMARY_1 : ButtonSkin.PRIMARY}
        size={ButtonSize.MEDIUM}
        text={buttonText}
        ariaLabel={buttonText}
        onClick={onClickSave}
        loading={isLoading}
        loadingText={isLoadingText}
        className={Margin.MX_AUTO}
      />
      <p>Oppgitt pris er i henhold til avtale</p>

      <div className={styleClass.footer}>
        <span
          onClick={onClose}
          role="button"
          className={Cursor.CURSOR_POINTER}
          onKeyDown={handleCancelKeyDown}
          tabIndex={0}
        >Avbryt</span>
      </div>
    </Modal>
  )
};

export default SelectProductModal;
