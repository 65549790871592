import {GetAllProducts_availableProducts} from "../../__generated__/GetAllProducts";
import {GetAgreementProducts_me_agreementsWithDetails_products} from "../../__generated__/GetAgreementProducts";
import { GetAgreementProductsAndSiebelProducts_me_agreementsWithDetails_products, GetAgreementProductsAndSiebelProducts_availableProducts } from '../../__generated__/GetAgreementProductsAndSiebelProducts';
import {GetAllMessages_availableProducts} from "../../__generated__/GetAllMessages";

export interface IGroupProducts {
  grouped: GetAllProducts_availableProducts[] | GetAgreementProducts_me_agreementsWithDetails_products[];
  original: GetAllProducts_availableProducts[] | GetAgreementProducts_me_agreementsWithDetails_products[] | GetAllMessages_availableProducts[];
}

export const groupBy = <T extends object> (k: keyof T, a: T[]): {
  [k: string]: T[];
} => {
  const groups = a.map(e => e[k]).filter((e, i, a) => a.indexOf(e) === i);
  return groups.reduce((o, g) => ({
    ...o,
    [`${g}`]: a.filter(e => e[k] === g),
  }), {});
}

const GroupProducts = (products: GetAllProducts_availableProducts[]): IGroupProducts => {
  const groupProductsWithSameCompanyId = (): any[] => {
      const groupedProducts: any[] = [];
      products.forEach((p) => {
        const exists = groupedProducts.find(np => np[p.productGroup]);
        if(!exists) {
          groupedProducts.push({[p.productGroup]: products.filter((pr: any) => pr.productGroup === p.productGroup)});
        }
      });
      return groupedProducts;
  };
  return {
    grouped: groupProductsWithSameCompanyId(),
    original: products
  };
};

export const groupProductsByProductName = (products: GetAllProducts_availableProducts[] | GetAllMessages_availableProducts[]): IGroupProducts => {
  const groupProductsWithSameCompanyId = (): any[] => {
    const groupedProducts: any[] = [];
    products.forEach((p) => {
      const exists = groupedProducts.find(np => np[p.productName]);
      if(!exists) {
        groupedProducts.push({[p.productName]: products.filter((pr: any) => pr.productName === p.productName)});
      }
    });
    return groupedProducts;
  };
  return {
    grouped: groupProductsWithSameCompanyId(),
    original: products
  };
};

export default GroupProducts;

export const getSingleGroupProduct = (product: any): GetAllProducts_availableProducts | GetAgreementProducts_me_agreementsWithDetails_products => {
  return product[Object.keys(product)[0]][0];
};

export const getGroupOfProducts = (product: any): GetAllProducts_availableProducts[] | GetAgreementProducts_me_agreementsWithDetails_products[] => {
  return product[Object.keys(product)[0]];
};

export const getGroupOfProductsSortedByBundleCode = (product: any): GetAllProducts_availableProducts[] | GetAgreementProducts_me_agreementsWithDetails_products[] => {
  return product.sort((current: any, next: any) => current.productCombination < next.productCombination ? -1 : 1);
};

export const getOnlyActiveProducts = (products: GetAllProducts_availableProducts[][]): GetAllProducts_availableProducts[][] => {
  return products.filter((product) => {
    const singleProduct = getSingleGroupProduct(product);
    return singleProduct.productVariationActive;
  })
};

export const getAvailableProducts = <T extends {id: string, productVariationActive: boolean}> (activeProducts: T[][], stockProducts: T[][]): T[][] => {
  const activatedProducts = activeProducts.reduce((a, e) => a.concat(e), []).map(p => p.id);
  const availableProducts = stockProducts.filter(g => g.find(p => !activatedProducts.includes(p.id)));
  return availableProducts.map(g => g.filter(p => p.productVariationActive));
};

export type SyntheticAgreementProduct =
  GetAgreementProductsAndSiebelProducts_me_agreementsWithDetails_products
  & { synthetic: boolean };
export const mergeActiveAndGenericProducts = (
  groupedActivated: { [x: string]: GetAgreementProductsAndSiebelProducts_me_agreementsWithDetails_products[] },
  groupedAvailable: { [x: string]: GetAgreementProductsAndSiebelProducts_availableProducts[] }
): { [x: string]: SyntheticAgreementProduct[] } => {
  const mergedProducts = {};

  for (let [groupName, listOfActivatedProducts] of Object.entries(groupedActivated)) {
    if (groupedAvailable.hasOwnProperty(groupName)) {
      mergedProducts[groupName] = groupedAvailable[groupName].map((genericProduct: GetAgreementProductsAndSiebelProducts_availableProducts, i: number) => {
        const activatedProduct = listOfActivatedProducts.find((activatedProduct: GetAgreementProductsAndSiebelProducts_me_agreementsWithDetails_products) => activatedProduct.productVariation === genericProduct.productVariation);
        if (activatedProduct) {
          return {
            active: activatedProduct.active,
            ...genericProduct,
            discountPercent: activatedProduct.discountPercent,
            synthetic: false,
          }
        } else {
          return {
            synthetic: true,
            active: false,
            ...genericProduct,
          }
        }
      });
    }
  }
  return mergedProducts;
};

export const mergeProductDiscountFromActiveToAvailable = (
  groupedActivated: { [x: string]: GetAgreementProductsAndSiebelProducts_me_agreementsWithDetails_products[] },
  groupedAvailable: { [x: string]: GetAgreementProductsAndSiebelProducts_availableProducts[] }
): { [x: string]: GetAgreementProductsAndSiebelProducts_availableProducts[] } => {
  return Object.entries(groupedAvailable)
    .reduce((prev, [groupName, value]) => Object.assign(prev, {
      [groupName]: value.map(v => {
        if (groupedActivated.hasOwnProperty(groupName)) {
          const productActivated = groupedActivated[groupName]
            .find(p => p.siebelProductId === v.siebelProductId);

          return productActivated ? Object.assign({}, v, {
            discountPercent: productActivated.discountPercent,
          }) : v;
        } else {
          return v;
        }
      }),
    }), {})
    ;
};
