import React, {ReactNode} from "react";
import {
  BorderColor,
  BorderWidth,
  Checkbox,
  classNames,
  Display, FlexDirection, Margin,
  Width
} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {groupProductsByMerchant} from "../../utils";
import {GetAgreements_me_agreements_edges_products} from "../../__generated__/GetAgreements";
import {Contains} from "../../components/Filter/types/Contains";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);

export const ProductFilter = ({products}: { products: GetAgreements_me_agreements_edges_products[] }) => {

  const groupedProducts = groupProductsByMerchant(products);

  return (
    <Filter
      heading={'Avis'}
      id={'newspaper_client'}
      uniqueBy={'customerNumber'}
      wrapper={customWrapper}
    >
      {
        Object
          .keys(groupedProducts)
          .map((productName, i) => {
            return (
              <Contains
                id={`newspaper_client_${productName}_${i}`}
                key={`newspaper_client_${productName}_${i}`}
                name={'props.subscriptions.subscriptionItems.product.productName'}
                value={productName}
              >
                {
                  ({active, toggleFilter}) => (
                    <div className={styleClass.inner}>
                      <Checkbox
                        checked={!!active}
                        onClick={toggleFilter}
                        text={productName}
                      />
                    </div>
                  )
                }
              </Contains>
            );
          })
      }
    </Filter>
  )
};


export default ProductFilter;
