import * as React from 'react';
import {ReactNode, useContext, useState} from 'react';
import {
  Arrow,
  classNames,
  Cursor,
  Display,
  FlexDirection,
  Icon,
  IconColor,
  IconName,
  JustifyContent,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  TextDecoration,
} from "@snoam/pinata";
import ProductBox from "../ProductBox/ProductBox";
import EditProductModal from "../EditProductModal/EditProductModal";
import {updateProductSettings, updateProductSettingsVariables} from "../../__generated__/updateProductSettings";
import {MutationFunction, useMutation} from "@apollo/client";
import {resolveInMs, UPDATE_PRODUCT_SETTINGS} from "../../mutations";
import {MessageBox, MessageBoxType} from "@snoam/mono-messagebox";
import {GetAllProducts_availableProducts} from "../../__generated__/GetAllProducts";
import {ModalContext} from "@snoam/mono-modal";
import {useRole} from "../Boundary/RoleBoundary";
import {AdminType} from "../../__generated__/globalTypes";

interface IEditProduct {
  product: GetAllProducts_availableProducts[];
  id: string;
}

const styleClass = {
  footer: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    JustifyContent.JUSTIFY_CENTER,
    Padding.P_2,
    Cursor.CURSOR_POINTER
  ),
  footerContainer: classNames(
    Display.FLEX,
    TextAlign.TEXT_LEFT
  ),
  footerLink: classNames(
    Padding.PL_4,
    TextDecoration.NO_UNDERLINE,
    TextColor.TEXT_PRIMARY_3
  )
};

const loadingMessage = (
  <>
    <Icon name={IconName.LOADING_WHEEL} color={IconColor.NEUTRAL_4}
          screenReaderText={'Ikon som viser at innhold lastes'}/>
    <p className={Margin.ML_2}>istrator...</p>
  </>
);

const errorMessage = (message: string) => (
  <MessageBox message={message} type={MessageBoxType.INFO} arrow={Arrow.TOP_CENTER}/>
);

const successMessage = (
  <>
    <Icon name={IconName.CHECK} color={IconColor.INFO}/>
    Endringen er utført
  </>
);

const EditProduct: React.FunctionComponent<IEditProduct> = ({product, id}: IEditProduct) => {

  const [message, setMessage] = useState<ReactNode>(undefined);
  const updateProducts: MutationFunction<updateProductSettings, updateProductSettingsVariables> = useMutation<updateProductSettings, updateProductSettingsVariables>(UPDATE_PRODUCT_SETTINGS)[0];
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const toggleModal = (editProductModalId: string) => () => openModalId ? closeModal() : openModal(editProductModalId);
  const {role: userRole} = useRole();

  const onSave = async (product: GetAllProducts_availableProducts) => {
    const asyncAction = () => updateProducts({variables: {product: product}});
    toggleModal(`edit-product-modal-${id}`);
    setMessage(null);
    setMessage(loadingMessage);
    try {
      await asyncAction();
      setMessage(successMessage);
      resolveInMs(3000).then(() => setMessage(null));
    } catch (error) {
      setMessage(errorMessage(`Noe gikk galt, prøv igjen (${error.toString()}).`));
      resolveInMs(3000).then(() => setMessage(null));
    }
  };

  const singleProduct = product[0];

  const toggleEditProductsModal = toggleModal(`edit-product-modal-${id}`);

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      toggleEditProductsModal();
    }
  };

  return (
    <>
      <ProductBox
        img={singleProduct.productVariationImageUrl}
        heading={singleProduct.productName}
        infoText={singleProduct.productVariationDescription}
        active={singleProduct.productVariationActive ? 'Aktiv' : 'Deaktivert'}
        footer={
          <div
            tabIndex={0}
            role="button"
            className={styleClass.footer}
            onClick={toggleEditProductsModal}
            onKeyDown={onKeyDown}
          >
            {userRole === AdminType.SUPERADMIN &&
              <div className={styleClass.footerContainer}>
                <Icon name={IconName.EDIT_OUTLINE} color={IconColor.NEUTRAL_4}/>
                <span className={styleClass.footerLink}>Rediger produkt</span>
              </div>
            }

            <div>
              {message}
            </div>
          </div>
        }
      />
      {userRole === AdminType.SUPERADMIN &&
        <EditProductModal
          id={`edit-product-modal-${id}`}
          onClose={toggleModal(`edit-product-modal-${id}`)}
          onSave={onSave}
          products={product}
        />}

    </>
  )
};


export default EditProduct;


