import React, {MouseEventHandler, ReactNode} from "react";
import {
  Appearance,
  BackgroundColor,
  Card,
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Height,
  Icon,
  Position,
  FontSize,
  Width,
  ZIndex,
  FlexDirection,
  AlignItems,
  JustifyContent,
  FontFamily,
  IconName,
  IconColor
} from "@snoam/pinata";
import {DesktopHeaderHeight, MobileHeaderHeight} from "../../Const";

const styleClass = {
  root: classNames(
    'envelope__header',
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_BETWEEN,
    Width.W_FULL,
    Height.H_24,
    BackgroundColor.BG_NEUTRAL_1,
    Position.STICKY,
    Position.TOP_0,
    Position.LEFT_0,
    ZIndex.Z_MODAL,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontFamily.FONT_TITLE_2,
    FontSize.TEXT_2XL
  )
};

interface EnvelopeHeaderProps {
  toggle: MouseEventHandler<HTMLButtonElement>;
  heading: ReactNode;
  isFullScreen: boolean;
}
export const EnvelopeHeader: React.FunctionComponent<EnvelopeHeaderProps> = ({toggle, heading, isFullScreen}) => {
  return (
    <Card
      className={styleClass.root}
      style={{height: isFullScreen ? MobileHeaderHeight : DesktopHeaderHeight}}
    >
      <Icon
        name={IconName.FILTER2}
        color={IconColor.NEUTRAL_4}
      />

      <Heading
        level={HeadingLevel.TWO}
        className={styleClass.heading}
      >
        {heading}
      </Heading>

      <button
        className={Appearance.APPEARANCE_NONE}
        onClick={toggle}
        aria-label={"Lukk filtermeny"}
      >
        <Icon
          name={IconName.CLOSE}
          color={IconColor.NEUTRAL_4}
        />
      </button>
    </Card>
  )
};



