import {
  GetAgreement_me_agreementsWithDetails,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings,
  GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaSystemLog,
} from "../../../../__generated__/GetAgreement";
import React, {FormEventHandler, useEffect, useState} from "react";
import { Arrow, Button, Icon, IconName, Margin } from "@snoam/pinata";
import {
  UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaSystemLog as MutationReturnValue,
  UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaSystemLogVariables as MutationVariables
} from "../../../../__generated__/UpdateAgreementSettingsEmploymentPeriodicVerificationSettingsOktaSystemLog";
import { MutationFunction, useMutation } from "@apollo/client";
import { UPDATE_AGREEMENT_SETTINGS_EMPLOYEE_VERIFICATION_SETTINGS_OKTA_SYSTEM_LOG as MUTATION } from "../../../../mutations";
import { ApolloError } from "@apollo/client";
import { styleClass, urlValidator } from "../VerificationSectionOpenidConnect";
import InputWithValidator from "../../../../components/InputWithValidator/InputWithValidator";
import { MessageBox } from "@snoam/mono-messagebox";
import { EmploymentPeriodicVerificationMethod } from "../../../../__generated__/globalTypes";

interface EmploymentPeriodicVerificationSettingsOktaSystemLogProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}


interface SaveStatus {
  error?: string[];
  pending: boolean;
  success: boolean;
}

function isOktaSystemLogSettings(employeePeriodicVerificationSettings: null | GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings): employeePeriodicVerificationSettings is GetAgreement_me_agreementsWithDetails_settings_employeePeriodicVerificationSettings_EmploymentPeriodicVerificationSettingsOktaSystemLog {
  return employeePeriodicVerificationSettings ? employeePeriodicVerificationSettings.__typename === "EmploymentPeriodicVerificationSettingsOktaSystemLog" : false;
}

const EmploymentPeriodicVerificationSettingsOktaSystemLog: React.FC<EmploymentPeriodicVerificationSettingsOktaSystemLogProps> = ({ agreement }) => {

  const { id: agreementId, agreementNumber, settings: { employeePeriodicVerificationSettings } } = agreement;

  const [oktaAppId, setOktaAppId] = useState<string>(isOktaSystemLogSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaAppId : "");
  const [oktaApiToken, setOktaApiToken] = useState<string>(isOktaSystemLogSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaApiToken : "");
  const [oktaOrgUrl, setOktaOrgUrl] = useState<string>(isOktaSystemLogSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaOrgUrl : "");
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [saveStatus, setSaveStatus] = useState<SaveStatus>({ error: undefined, pending: false, success: false });
  const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
  const updateAgreementSettings: MutationFunction<MutationReturnValue, MutationVariables> = useMutation<MutationReturnValue, MutationVariables>(MUTATION)[0];

  type Changes = {
    oktaAppId?: string;
    oktaApiToken?: string;
    oktaOrgUrl?: string;
  };
  const changes = (): Changes => isOktaSystemLogSettings(employeePeriodicVerificationSettings) ? ({
    ...(oktaAppId !== employeePeriodicVerificationSettings.oktaAppId ? { oktaAppId } : {}),
    ...(oktaApiToken !== employeePeriodicVerificationSettings.oktaApiToken ? { oktaApiToken } : {}),
    ...(oktaOrgUrl !== employeePeriodicVerificationSettings.oktaOrgUrl ? { oktaOrgUrl } : {}),
  }) : ({
    ...(oktaAppId !== "" ? { oktaAppId } : {}),
    ...(oktaApiToken !== "" ? { oktaApiToken } : {}),
    ...(oktaOrgUrl !== "" ? { oktaOrgUrl } : {}),
  });
  const hasChanges = (): boolean => !!Object.keys(changes()).length;

  useEffect(() => {
    setShowSaveButton(hasChanges());
  }, [oktaAppId, oktaApiToken, oktaOrgUrl, saveStatus.error, saveStatus.pending, saveStatus.success]);

  const saveButtonLabel = saveStatus.pending ? "Lagrer..." : "Lagre informasjon";

  const save = () => {
    setSaveStatus({ error: undefined, pending: true, success: false });
    const variables: MutationVariables = {
      agreementNumber: agreementNumber,
      agreementId: agreementId,
      settings: {
        oktaAppId,
        oktaApiToken,
        oktaOrgUrl,
        type: EmploymentPeriodicVerificationMethod.OKTA_SYSTEM_LOG,
      },
    };

    updateAgreementSettings({ variables })
      .then(() => {
        setSaveStatus({ error: undefined, pending: false, success: true });
      })
      .catch((error: ApolloError) => {
        const states: { [k:string]: string[] } = (error.graphQLErrors || [])
          .map((e) => (e as any).state as { [k: string]: string[] })
          .filter(Boolean)
          .reduce((result, s) => Object.assign(result, s), {});

        if (Object.keys(states).length) {
          setSaveStatus({ error: [JSON.stringify(states)], pending: false, success: false });
        } else {
          setSaveStatus({
            error: [error.message],
            pending: false,
            success: false,
          });
        }
      })
      .finally(() => setFormSubmitted(true));
  };

  const submit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    if (hasChanges()) {
      save();
    }
  };

  return (
    <>
      <div className={ styleClass.settings.openidContainer }>
        <form className={ styleClass.settings.openidInputContainer } onSubmit={ submit }>
          <label className={ styleClass.settings.label}>
            Okta app id
            <InputWithValidator
              ariaLabel={ 'Okta app id' }
              value={ oktaAppId }
              validator={ (s) => !!s }
              forceError={!!saveStatus.error}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              placeholder="f.eks. 0oa1hwd8to1COfddS0h8"
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOktaAppId(e.target.value) }
              errorMsg={ `Vennligst oppgi gyldig Okta app id${ saveStatus.error ? ` (${ saveStatus.error.join(", ") })` : '' }` }
              clearInput={ () => {
                setOktaAppId(isOktaSystemLogSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaAppId : "")
              } }
            />
          </label>
          <label className={ styleClass.settings.label}>
            Okta API token
            <InputWithValidator
              ariaLabel={ 'Okta API token' }
              value={ oktaApiToken }
              validator={ (s) => !!s }
              forceError={!!saveStatus.error}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              placeholder="f.eks. bb785d15ba57458c90041e805dfeb52a61eff44d48824d5494a4a7cd"
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOktaApiToken(e.target.value) }
              errorMsg={ `Vennligst oppgi gyldig Okta API token${ saveStatus.error ? ` (${ saveStatus.error.join(", ") })` : '' }` }
              clearInput={ () => {
                setOktaApiToken(isOktaSystemLogSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaApiToken : "")
              } }
            />
          </label>
          <label className={ styleClass.settings.label}>
            Okta organization URL
            <InputWithValidator
              ariaLabel={ 'Okta organization URL' }
              value={ oktaOrgUrl }
              validator={ (e) => !e || urlValidator(e) }
              forceError={!!saveStatus.error}
              required={ true }
              formSubmitted={ formSubmitted }
              className={ styleClass.settings.input }
              placeholder="f.eks. https://schibsted.okta.com"
              onChange={ (e: React.ChangeEvent<HTMLInputElement>) => setOktaOrgUrl(e.target.value) }
              errorMsg={ `Vennligst oppgi gyldig URL${ saveStatus.error ? ` (${ saveStatus.error.join(", ") })` : '' }` }
              clearInput={ () => {
                setOktaOrgUrl(isOktaSystemLogSettings(employeePeriodicVerificationSettings) ? employeePeriodicVerificationSettings.oktaOrgUrl : "")
              } }
            />
          </label>
          { showSaveButton && <Button ariaLabel={ saveButtonLabel } text={ saveButtonLabel }/> }
          { (!showSaveButton && saveStatus.success) && (
            <div className={styleClass.settings.message}>
              <MessageBox
                message={`Endringene er registrert.`}
                backgroundColor={`#BFE6D7`}
                arrow={Arrow.TOP_CENTER}
                icon={
                  <Icon
                    name={IconName.CHECK}
                    style={{color: 'rgb(1, 154, 2)'}}
                    className={`${Margin.MR_3}`}
                  />
                }
              />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EmploymentPeriodicVerificationSettingsOktaSystemLog;
