import * as React from "react";
import {classNames, Display, FlexDirection, FlexShrink, Height} from "@snoam/pinata";

const styleClass = {
  root: (shrink: boolean = false) => classNames(
    Height.H_FULL,
    Display.FLEX,
    FlexDirection.FLEX_COL,
    {
      [FlexShrink.FLEX_SHRINK]: shrink,
      [FlexShrink.FLEX_SHRINK_0]: !shrink,
    }
  ),
  header: (shrink: boolean, inline: boolean) => classNames(
    {
      [FlexShrink.FLEX_SHRINK]: shrink,
      [FlexShrink.FLEX_SHRINK_0]: !shrink,
      [Display.INLINE_FLEX]: inline
    }
  ),
  content: classNames(

  )
};

interface ColumnProps extends React.HTMLAttributes<HTMLDivElement> {
  shrinkable?: boolean;
}
export const Column = (props: ColumnProps) => {
  const {children, shrinkable, className, ...rest} = props;
  const cls = classNames(styleClass.root(shrinkable), className);
  return (
    <div className={cls} {...rest}>
      {children}
    </div>
  )
};

interface ColumnHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  inline?: boolean;
  shrinkable?: boolean;
}
Column.Header = (props: ColumnHeaderProps) => {
  const {
    children = null,
    shrinkable = false,
    inline = false,
    className = '',
    ...rest
  } = (props || {});

  const cls = classNames(styleClass.header(shrinkable, inline), className);

  return (
    <div className={cls} {...rest}>
      {children}
    </div>
  )
};

type ColumnContentProps = React.HTMLAttributes<HTMLDivElement>;
Column.Content = (props: ColumnContentProps) => {
  const {children, className, ...rest} = props;
  const cls = classNames(styleClass.content, className);
  return (
    <div className={cls} {...rest}>
      {children}
    </div>
  )
};


export default Column;
