import * as React from 'react';
import {useState} from 'react';
import {
  AlignItems,
  Appearance,
  BackgroundColor,
  BackgroundPosition,
  BackgroundRepeat,
  BackgroundSize,
  classNames,
  Display, FlexDirection,
  Height,
  Icon,
  IconColor,
  IconName,
  IconSize, JustifyContent,
  Margin,
  Overflow,
  Padding,
  Position,
  TextAlign,
  TextColor,
  Width,
  ZIndex
} from '@snoam/pinata';
import {IWithSpidContext, withSpidContext} from "@snoam/mono-spid";
import {ContextAwareBreadCrumbs, ContextAwareUserBadge, IHeaderProps} from "./Header";
import LoginAndLogoutButton from '../LoginAndLogoutButton/LoginAndLogoutButton';
import Logo from "../Logo/Logo";
import MobileMenu from "../Menu/MobileMenu";
import MenuItem from "../MenuItem/MenuItem";
import {isMinBedriftRouteWithIcon, routes} from "../../routes/routes";
import {AdminType} from '../../__generated__/globalTypes';
import {MinBedriftRouteWithIcon, MinBedriftSuperAdminRouteWithIcon} from '../../routes/utils';
import {useQuery} from '@apollo/client';
import {GetRole, GetRoleVariables} from '../../__generated__/GetRole';
import {GET_ROLE} from '../../queries';
import PathBoundary from "../PathBoundary/PathBoundary";
import {useParams} from "react-router-dom";

const styleClass = {
  root: (menuOpen: boolean) => classNames(
    TextAlign.TEXT_CENTER,
    Width.W_FULL,
    ZIndex.Z_40,
    BackgroundColor.BG_WHITE,
    menuOpen ? Position.FIXED : Position.RELATIVE,
    {
      [Overflow.OVERFLOW_Y_SCROLL]: menuOpen,
      [BackgroundRepeat.BG_NO_REPEAT]: menuOpen,
      [BackgroundSize.BG_COVER]: menuOpen,
      [BackgroundPosition.BG_CENTER]: menuOpen,
      [Height.H_SCREEN]: menuOpen,
      [Padding.PR_0]: menuOpen,
      [Padding.PR_3]: !menuOpen
    }
  ),
  row: classNames(
    Display.FLEX,
    FlexDirection.FLEX_ROW,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_BETWEEN
  ),
  btn: classNames(
    Appearance.APPEARANCE_NONE,
    TextColor.TEXT_WHITE,
    Width.W_8,
    Height.H_8
  )
};

export const MobileHeader: React.FunctionComponent<IWithSpidContext> = (props) => {

  const {agreementNumber} = useParams<{agreementNumber?: string}>();
  const [menuOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenuOpen = () => {
    setIsOpen(!menuOpen);
  };

  const renderIcon = () => {

    if (menuOpen) {
      return <Icon
        name={IconName.CLOSE}
        color={IconColor.PRIMARY_DARK}
      />;
    }

    return <Icon
      name={IconName.BEDRIFT_MENU_LOGGEDINUSER}
      size={IconSize.MEDIUM}
      color={IconColor.PRIMARY_DARK}
    />
  };

  const renderLoginOrMenuBtn = (role: AdminType) => {

    const { spidContext } = props;

    if (spidContext.state.userId) {
      return (<button
        onClick={toggleMenuOpen}
        className={styleClass.btn}
        aria-label={menuOpen ? "Lukk meny" : "Åpne meny"}
      >
        {renderIcon()}
      </button>)
    }

    return (
      <LoginAndLogoutButton
        agreementNumber={agreementNumber}
        spidContext={spidContext}
        className={Margin.MY_1}
      />
    )
  };

  const { spidContext } = props;
  const variables = {
    agreementNumber: agreementNumber ? +agreementNumber : undefined,
  };
  const { data, error, loading } = useQuery<GetRole, GetRoleVariables>(GET_ROLE, { variables });
  const { email = null, role = null } = (loading || error) ? {} : data ? data.me : {};
  const superAdminRoutes = (role === AdminType.SUPERADMIN && !agreementNumber) ? routes.super.routes : [];
  const adminRoutes = routes.admin.routes;

  return (
    <header
      className={styleClass.root(menuOpen)}
    >

      <div className={classNames(styleClass.row)}>

        <Logo mobile={true} />

        <ContextAwareBreadCrumbs
          agreementNumber={agreementNumber ? +agreementNumber : null}
          invertFontColor={menuOpen}
          role={null}
        />

        {renderLoginOrMenuBtn(role || AdminType.SUPERADMIN)}

      </div>

      {menuOpen && <div className={styleClass.row}>
        <MobileMenu menuOpen={menuOpen}>
          <PathBoundary excludeIfUrl={'/bestill'}>
            {
              adminRoutes
                .concat(superAdminRoutes as MinBedriftSuperAdminRouteWithIcon[])
                .filter(isMinBedriftRouteWithIcon)
                .map(({ element, path, icon, ...entry }: MinBedriftRouteWithIcon) => {

                  if (!path) {
                    return null;
                  }

                  return (
                    <MenuItem
                      expanded={true}
                      path={path}
                      key={entry.label}
                      icon={icon}
                      {...entry}
                      onClick={toggleMenuOpen}
                    />
                  )
                }
                )
            }
          </PathBoundary>

          <li className={Margin.MX_6}>
            <ContextAwareUserBadge
              email={email}
              role={role}
              spidContext={spidContext}
              transparent={true}
            />
            <LoginAndLogoutButton
              agreementNumber={agreementNumber}
              spidContext={spidContext}
              className={Width.W_FULL}
            />
          </li>
        </MobileMenu>
      </div>}
    </header>
  );
};


export default withSpidContext<IHeaderProps>(MobileHeader);
