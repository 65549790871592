import React, {CSSProperties, ReactNode} from "react";
import {classNames, Display, FlexDirection, Height} from "@snoam/pinata";

import Column from "../Column/Column";

const styleClass = {
  root: classNames(
    FlexDirection.FLEX_COL,
    Display.INLINE_FLEX
  ),
  wrapper: classNames(
    Height.H_FULL,
  )
};

export interface IMainProps  {
  children?: ReactNode;
  wrapperClassName?: string;
  style?: CSSProperties;
}

const Main = ({children, wrapperClassName, style}: IMainProps = {}) => {
  return (
    <Column.Content
      className={styleClass.root}
      style={style}
    >
      <div
        className={classNames(styleClass.wrapper, wrapperClassName)}
        style={{WebkitOverflowScrolling: 'touch'}}
      >
        {children}
      </div>

    </Column.Content>
  )
};

export default Main;
