import * as React from 'react';
import {
  BackgroundColor,
  classNames,
  Display,
  FlexDirection,
  Height,
  Margin,
  MinHeight,
  MinWidth,
  TextColor,
  Width
} from "@snoam/pinata";
import { Modal } from "@snoam/mono-modal";
import SelectedSubscriptions from '../../pages/Subscriptions/SelectedSubscriptions';
import { useSelectionContext } from '../../context/SelectionContext/SelectionContext';
import {useNavigate} from "react-router-dom";


const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextColor.TEXT_NEUTRAL_6,
    Margin.LG_MX_48,
  ),
};
interface ISelectionProps {
  agreementNumber:number,
}

const SelectionModal: React.FC<ISelectionProps> = ({agreementNumber}) => {
  const navigate = useNavigate();
  const selectionContext = useSelectionContext()
  const rows = selectionContext.context.actions.getSelected()

  return (
    <Modal
      id={"SELECTION_MODAL"}
      backdrop={true}
      onClose= {() => {selectionContext.context.actions.resetSelection(); navigate(`/admin/avtaler/${agreementNumber}/abonnenter`);}}
      className={classNames(Margin.M_0,Height.XS_H_SCREEN,Width.XS_W_SCREEN,MinHeight.MIN_H_SCREEN, MinWidth.MIN_W_SCREEN, BackgroundColor.BG_NEUTRAL_1)}
    >
      <div className={styleClass.root}>
          <SelectedSubscriptions rows={rows} agreementNumber={agreementNumber} />
      </div>
    </Modal>
  );
};

export default SelectionModal;
