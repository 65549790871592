import * as React from "react";
import {useEffect, useState} from "react";
import {
  classNames,
  FontFamily,
  FontWeight,
  Grid,
  GridComposition,
  GridGutter,
  Heading,
  HeadingLevel,
  Margin,
  FontSize,
  TextTransform,
} from "@snoam/pinata";
import {useFilterFactory} from "../../context/FilterContext/FilterContext";
import {explodeDotPath, FilterType, getFilterForType, isArray} from "./utils";
import {ReactComponentLike} from 'prop-types';
import {Observable} from "rxjs";
import {distinctUntilKeyChanged, filter, map} from "rxjs/operators";

export type FilterValue = string | number | boolean | undefined;

const styleClass = {
  heading: classNames(
    TextTransform.UPPERCASE,
    FontFamily.FONT_BODY,
    FontSize.TEXT_SM,
    FontWeight.FONT_NORMAL,
    Margin.MB_4,
    Margin.ML_0,
  ),
};

export interface IFilterState {
  id?: string;
  type: FilterType,
  props: {
    name: string | string[];
    value: any
  },
  enabled: boolean | undefined
}

export type FilterMarshalProps = { [x: string]: boolean | string | number | null | FilterMarshalProps };
type FilterMarshal = (props: FilterMarshalProps) => FilterMarshalProps;

export interface IFilterProps {
  id: string;
  heading?: string;
  wrapper?: ReactComponentLike;
  uniqueBy?: string;
  marshal?: boolean | FilterMarshal;
}

type InitialFilterState = {
  type: FilterType,
  name: string | string[],
  value: any,
  id?: string,
  enabled?: boolean | undefined,
  defaultEnabled?: boolean
};

export const createActionState = ({type, name, value, id, enabled}: InitialFilterState): IFilterState => ({
  id,
  type,
  props: {
    name, value
  },
  enabled: enabled
});

const initialState: IFilterState[] = [];

const defaultWrapper: React.FunctionComponent = ({children}) => {
  return <Grid composition={GridComposition.DEFAULT} gutter={GridGutter.NONE}>{children}</Grid>
};

const buildParams = (paths: string | string[], value: FilterValue, acc = {} as FilterMarshalProps) => {
  let pathAcc = acc;

  for (let i = 0; i < paths.length; i++) {
    const isLeaf = i === paths.length - 1;
    const fragment = paths[i];
    if (pathAcc.hasOwnProperty(fragment) && isLeaf) {

      const accList = [];

      if (Array.isArray(pathAcc[fragment])) {
        accList.push(...(pathAcc[fragment] as any), value);
      } else {
        accList.push(pathAcc[fragment], value)
      }

      pathAcc = Object.assign(pathAcc, {
        [fragment]: accList
      });

    } else if (isLeaf) {
      pathAcc = Object.assign(pathAcc, {
        [fragment]: [value]
      });
    } else {
      pathAcc[fragment] = {};
    }
    pathAcc = pathAcc[fragment] as any;
  }
  return acc;
};

export const FilterFactoryContext = React.createContext<any>({createFilterAction: () => void (0)});
const Filter: React.FunctionComponent<IFilterProps> = ({id, heading = '', marshal = false, children, wrapper = defaultWrapper, uniqueBy = 'id'}) => {

  const [filters, setFilters] = useState<IFilterState[]>([]);
  const {
    createFilter,
    createParams,
    addResetCallback,
    removeFilter,
    removeParam,
  } = useFilterFactory();

  useEffect(() => {
    addResetCallback(() => {
      setFilters(initialState);
      if (marshal) {
        removeParam(id);
      }
    });
  }, []);

  //unique filter key that is generated based on the state of each local filter registered
  const filterState = filters.reduce((acc, o) => {
    return `${acc}_${o.id}_${o.props.value}`;
  }, '');

  const createAction = (state: IFilterState) => {

    //find locally registered filter based on state
    const filterExists = filters.find(o => {
      return o.id === state.id;
    });

    //remove globally registered filter if it is registered
    if (filterExists && filterExists.id) {
      removeFilter(filterExists.id);
    }

    //remove locally registered filter
    const updatedFilters = [
      ...filters.filter(o => o.id !== state.id),
    ];

    if (!state.enabled) { //if filter state is not enabled - just update state
      setFilters(updatedFilters);
    } else { //else add locally registered filter
      updatedFilters.push(state);
      setFilters(updatedFilters);
    }
  };

  useEffect(() => {
    if (marshal) {
      createParams(id, () => {

        if (filters.length === 0 || !marshal) {
          return {};
        }

        return filters.reduce((rootAcc, filter: IFilterState) => {
          let paths = filter.props.name;
          const value = filter.props.value;

          if (!Array.isArray(paths)) {
            paths = [paths];
          }

          const explodedPaths = paths.map(path => explodeDotPath(path));
          return explodedPaths.reduce((pathAcc, explodedPath) => {
            const builtParams = buildParams(explodedPath, value, pathAcc);
            return typeof marshal === 'function' ? marshal(builtParams) : builtParams;
          }, rootAcc);

        }, {} as FilterMarshalProps);
      });
    } else {

      createFilter(id, (observable: Observable<any>) => {
        const fns = filters.reduce((acc: any[], filterState: IFilterState) => {
          const {
            props: {
              name,
              value
            },
            type
          } = filterState;

          const filterFn = getFilterForType(type);
          const propNames = !isArray(name) ? [name] : name;
          const fn = filterFn(propNames, value);
          acc.push(fn);
          return acc;
        }, []);

        return observable
          .pipe(
            map(x => {
              if (fns.length === 0 || fns.some(fn => fn(x))) {
                return x;
              }
              return null;
            }),
            filter(x => !!x),
            distinctUntilKeyChanged(uniqueBy),
          );
      });


    }
  }, [filterState]);

  const WrapperCmp = wrapper;

  return (
    <>
      {
        heading && (
          <Heading level={HeadingLevel.FIVE} className={styleClass.heading}>
            {heading}
          </Heading>
        )
      }

      <WrapperCmp>
        <FilterFactoryContext.Provider value={{createFilterAction: createAction}}>
          {children}
        </FilterFactoryContext.Provider>
      </WrapperCmp>
    </>
  )
};

export default Filter;
