import {FilterMarshalProps} from "./Filter";
import {Observable} from "rxjs";

export type ParamsFnReturnType = FilterMarshalProps;
export type ParamsFn = () => ParamsFnReturnType;
export type FilterFnReturnType<T> = Observable<T[]>;
export type FilterFn<T> = (ob: Observable<T[]>) => FilterFnReturnType<T>;
export type FilterMatchPredicate<T> = (value: T) => boolean;
export const isString = (v: any) => typeof v === 'string';
export const isNumber = (v: any) => typeof v === 'number' && !isNaN(v);
export const isArray = (v: any) => Array.isArray(v);
export const isBoolean = (v: any) => typeof v === 'boolean';
export const type = (v: any) => Object.prototype.toString.call(v);
export const ensureArray = <T>(v: any): T => isArray(v) ? v : [v];

enum ObjectType {
  Object = '[object Object]',
  Array = '[object Array]',
  Number = '[object Number]',
  String = '[object String]',
  Function = '[object Function]',
  Null = '[object Null]',
  RegExp = '[object RegExp]',
}

/**
 * Reduces a dot path that exists on an object to its value
 * @param path String - the dot path to reduce to
 * @param obj Object - the object containing the dot path
 */
export const reducePath = (path: string, obj: any): any => {
  const branches = path.split('.');
  let leaf = obj;

  do {
    const currentBranch = branches.shift();

    if (!currentBranch) {
      break;
    }

    const objectType = type(leaf);

    switch (objectType) {

      case ObjectType.Object:
        if (leaf.hasOwnProperty(currentBranch)) {
          leaf = leaf[currentBranch];
        }
        break;

      case ObjectType.Array:

        leaf = leaf.reduce((acc: any[], x: any) => {
          const res = reducePath(branches.concat([currentBranch]).join('.'), x);
          acc.push(res);
          return acc;
        }, []);

        break;

      default:
        break;
    }

  } while (branches.length !== 0);

  return leaf;
};

export const explodeDotPath = (dotPath: string) => dotPath.split('.');

/**
 * @param x Object - The Object to examine
 * @param paths String[] - The list of dot paths to apply on x
 * @param predicate Function - Filter function
 */
export const contains = <T, V>(x: T, paths: string | string[], predicate: FilterMatchPredicate<V>) => {
  return ensureArray<string[]>(paths)
    .reduce((acc, path) => {
      const res = reducePath(path, x);
      acc.push(res);
      return acc;
    }, [] as any[])
    .filter(x => x !== undefined)
    .some(predicate)
};

type FilterTypeFn = (propNames: string[], value: any) => (x: any) => boolean;
/**
 * Void filter functions
 */
export const filterNone: FilterTypeFn = (__: string[], _: any) => x => !!x;

/**
 * Boolean (weak comparison) filter functions
 */
export const filterBool: FilterTypeFn = (propNames: string[], value: boolean) => x => {
  return contains(x, propNames, (matchValue: any) => {

    if (isArray(matchValue)) {
      // eslint-disable-next-line eqeqeq
      return matchValue.some((v: any) => v == value)
    }

    // eslint-disable-next-line eqeqeq
    return value == matchValue; //loose comparison
  })
};


/**
 * Length filter functions
 */
export const filterLengthGt: FilterTypeFn = (propNames: string[], value: number) => x => {
  return contains(x, propNames, (matchValue: Array<any> | string) => {
    if (!isArray(matchValue) && !isString(matchValue)) {
      return false;
    }
    return matchValue.length > value;
  });
};

export const filterLengthLt: FilterTypeFn = (propNames: string[], value: number) => x => {
  return contains(x, propNames, (matchValue: Array<any> | string) => {
    if (!isArray(matchValue) && !isString(matchValue)) {
      return false;
    }
    return matchValue.length < value;
  });
};

export const filterLengthEq: FilterTypeFn = (propNames: string[], value: number) => x => {
  return contains(x, propNames, (matchValue: Array<any> | string) => {
    if (!isArray(matchValue) && !isString(matchValue)) {
      return false;
    }
    return matchValue.length === value;
  });
};

/**
 * Contains filter functions
 */
export const filterContains: FilterTypeFn = (propNames: string[], value: string | number) => x => {
  return contains(x, propNames, (matchValue: any) => {
    if (isString(matchValue) || isNumber(matchValue)) {
      return matchValue.toString().toLowerCase().includes(value.toString().toLowerCase())
    } else if (isArray(matchValue)) {
      for (let i = 0; i < (matchValue as any[]).length; i++) {
        const listValue = matchValue[i];
        if (!isString(listValue)) {
          continue;
        }
        if (listValue.toLowerCase().includes(value.toString().toLowerCase())) {
          return true;
        }
      }
    }
    return false;
  })
};

/**
 * "Greater than" filter functions
 */
export const filterGt: FilterTypeFn = (propNames: string[], value: number) => x => {
  return contains(x, propNames, (matchValue: any) => {

    if (isArray(matchValue)) {
      return matchValue.some((v: number) => {
        return isNumber(v) ? v > value : false
      });
    } else if (!isNumber(matchValue)) {
      return false;
    }
    return matchValue > value;
  });
};

/**
 * "Greater than" or "equal" filter functions
 */
export const filterGtEq: FilterTypeFn = (propNames: string[], value: number) => x => {
  return contains(x, propNames, (matchValue: any) => {

    if (isArray(matchValue)) {
      return matchValue.some((v: number) => {
        return isNumber(v) ? v >= value : false
      });
    } else if (!isNumber(matchValue)) {
      return false;
    }
    return matchValue >= value;
  });
};

/**
 * "Less than" filter functions
 */
export const filterLt: FilterTypeFn = (propNames: string[], value: number) => x => {
  return contains(x, propNames, (matchValue: any) => {

    if (isArray(matchValue)) {
      return matchValue.some((v: number) => {
        return isNumber(v) ? v < value : false
      });
    }else if (!isNumber(matchValue)) {
      return false;
    }
    return matchValue < value;
  });
};

/**
 * "Less than" or "equal" filter functions
 */
export const filterLtEq: FilterTypeFn = (propNames: string[], value: number) => x => {
  return contains(x, propNames, (matchValue: any) => {
    if (isArray(matchValue)) {
      return matchValue.some((v: number) => {
        return isNumber(v) ? v <= value : false
      });
    } else if (!isNumber(matchValue)) {
      return false;
    }
    return matchValue <= value;
  });
};

/**
 * "Equal" filter functions
 */
export const filterEq: FilterTypeFn = (propNames: string[], value: number | string) => x => {
  return contains(x, propNames, (matchValue: any) => {
    if (isArray(matchValue)) {
      return matchValue.some((v: number) => {
        // eslint-disable-next-line eqeqeq
        return isNumber(v) || isString(v) || isBoolean(v) ? v == value : ( //loose comparison
          v === null ? v === value : false
        )
      });
    } else if (isNumber(matchValue) || isString(matchValue) || isBoolean(matchValue)) {
      // eslint-disable-next-line eqeqeq
      return matchValue == value; //loose comparison
    } else if (matchValue === null) {
      return matchValue === value;
    }
    return false;
  });
};

/**
 * "Expression" filter functions
 */
export const filterExpr: FilterTypeFn = (propNames: string[], expression: RegExp) => x => {
  return contains(x, propNames, (matchValue: string | string[]) => {
    if (isArray(matchValue)) {
      return (matchValue as string[]).some((v: string) => v !== null && expression.test(v));
    } else if (isString(matchValue)) {
      return expression.test(matchValue as string);
    }
    return false;
  });
};

type BetweenValue = { lowerInclusive: number, upperExclusive: number };
export const filterBetween: FilterTypeFn = (propNames: string[], {
  lowerInclusive,
  upperExclusive
}: BetweenValue) => x => {
  return contains(x, propNames, (matchValue: number | number[]) => {
    return (Array.isArray(matchValue) ? matchValue : [matchValue]).some((val) => {
      return val !== undefined && val !== null && val >= lowerInclusive && val < upperExclusive;
    })
  });
};

export const filterNotBlank: FilterTypeFn = (propNames, value: null) => filterExpr(propNames, /\S/);

export enum FilterType {
  NONE,
  BOOL,
  LENGTH_GT,
  LENGTH_EQ,
  LENGTH_LT,
  GT,
  GTEQ,
  LT,
  LTEQ,
  EQ,
  EXPR,
  CONTAINS,
  BETWEEN,
  NOT_BLANK,
}

export const getFilterForType: any = (type: FilterType) => {
  switch (type) {
    case FilterType.NONE:
      return filterNone;
    case FilterType.BOOL:
      return filterBool;
    case FilterType.EQ:
      return filterEq;
    case FilterType.GT:
      return filterGt;
    case FilterType.GTEQ:
      return filterGtEq;
    case FilterType.LENGTH_GT:
      return filterLengthGt;
    case FilterType.LENGTH_LT:
      return filterLengthLt;
    case FilterType.LENGTH_EQ:
      return filterLengthEq;
    case FilterType.LT:
      return filterLt;
    case FilterType.LTEQ:
      return filterLtEq;
    case FilterType.CONTAINS:
      return filterContains;
    case FilterType.EXPR:
      return filterExpr;
    case FilterType.BETWEEN:
      return filterBetween;
    case FilterType.NOT_BLANK:
      return filterNotBlank;
  }
};
