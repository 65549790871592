import React, {createContext, ReactNode, useContext, useReducer} from "react";
import {ParamsFn} from '../../components/Filter/utils';
import {Observable, of, OperatorFunction} from "rxjs";
import {reduce} from "rxjs/operators";


export enum FilterActionType {
  CREATE_FILTER = 'createFilter',
  REMOVE_FILTER = 'remove',
  RESET = 'reset',
  ADD_RESET_CALLBACK = 'addFilterResetHandler',
  CREATE_PARAM = 'addParam',
  REMOVE_PARAM = 'removeParam',
  FILTER_LOADING = 'loading'
}

type _FilterAction<T extends FilterActionType, P = {}> = {
  type: T;
} & P;
type FilterAction =
  | _FilterAction<FilterActionType.ADD_RESET_CALLBACK, { payload: { fn: () => void } }>
  | _FilterAction<FilterActionType.CREATE_FILTER, { payload: { name: string, fn: OperatorFunction<any, any> } }>
  | _FilterAction<FilterActionType.CREATE_PARAM, { payload: { name: string; fn: ParamsFn } }>
  | _FilterAction<FilterActionType.REMOVE_PARAM, { payload: { name: string; } }>
  | _FilterAction<FilterActionType.FILTER_LOADING, { payload: { loading: boolean; } }>
  | _FilterAction<FilterActionType.RESET>
  | _FilterAction<FilterActionType.REMOVE_FILTER, { payload: string }>
  ;

interface IFilterContextState {
  filters: { [k: string]: OperatorFunction<any, any> };
  resetHandlers: Array<() => void>;
  params: { [k: string]: ParamsFn };
  loading: boolean
}

const initialState: IFilterContextState = {
  filters: {},
  resetHandlers: [],
  params: {},
  loading: false
};

const reducer = (state: IFilterContextState, action: FilterAction): IFilterContextState => {
  switch (action.type) {
    case FilterActionType.FILTER_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      };
    case FilterActionType.RESET:
      const {resetHandlers} = state;
      resetHandlers.forEach(cb => cb());
      return {
        params: {},
        filters: {},
        resetHandlers,
        loading: false,
      };
    case FilterActionType.ADD_RESET_CALLBACK:
      return {
        ...state,
        resetHandlers: [
          ...state.resetHandlers,
          action.payload.fn
        ]
      };
    case FilterActionType.CREATE_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.name]: action.payload.fn
        }
      };
    case FilterActionType.CREATE_PARAM:
      return {
        ...state,
        params: Object.assign(
          state.params,
          {[action.payload.name]: action.payload.fn}
        )
      };
    case FilterActionType.REMOVE_FILTER:
      delete state.filters[action.payload];
      return {
        ...state,
      };
    case FilterActionType.REMOVE_PARAM:
      delete state.params[action.payload.name];
      return {
        ...state,
        params: state.params
      };
  }
  return state;
};

const FilterContext = createContext<any>([
    initialState,
    (value: any): void => void (0)
  ]
);

export const FilterProvider = ({children}: { children?: ReactNode }) => {
  const contextValue = useReducer<(prevState: IFilterContextState, action: FilterAction) => IFilterContextState>(reducer, initialState);
  return (
    <FilterContext.Provider value={contextValue}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterFactory = () => {

  const [state, dispatch] = useContext(FilterContext);
  const {filters, params} = state;

  return {
    createFilter: <T extends {}>(name: string, fn: OperatorFunction<T, T>) => {
      dispatch({type: FilterActionType.CREATE_FILTER, payload: {name, fn}})
    },
    applyFilter: <T extends Array<any>>(items: T): Promise<T> => {
      const filterNames = Object.keys(filters);
      const root = of(...items);
      const chainedRoot = filterNames.reduce((ob: Observable<any>, opName: string, i: number) => {
        return ob.pipe(filters[opName]) as Observable<T>;
      }, root);
      return chainedRoot
        .pipe(
          reduce((acc: any, x) => {
            if (Array.isArray(x)) {
              return acc.concat(x);
            }
            return [...acc, x];
          }, [])
        )
        .toPromise();
    },
    removeFilter: (name: string) => {
      dispatch({type: FilterActionType.REMOVE_FILTER, payload: name})
    },
    addResetCallback: (fn: () => void) => {
      dispatch({type: FilterActionType.ADD_RESET_CALLBACK, payload: {fn}})
    },
    resetFilter: () => {
      dispatch({type: FilterActionType.RESET})
    },
    getParams: () => {
      return Object
        .keys(params)
        .reduce((acc, fnName) => {
          return Object.assign(acc, params[fnName]());
        }, {});
    },
    createParams: (name: string, fn: ParamsFn) => {
      dispatch({type: FilterActionType.CREATE_PARAM, payload: {name, fn}})
    },
    removeParam: (name: string) => {
      dispatch({type: FilterActionType.REMOVE_PARAM, payload: name})
    },
    filterContextState: state,
  }
};

export default FilterProvider;
