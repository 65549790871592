import React, {useContext} from "react";
import {
  BackgroundColor,
  BorderColor,
  BorderStyle,
  BorderWidth,
  classNames,
  Cursor,
  Display,
  Grid,
  GridComposition,
  GridGutter,
  Margin,
  Padding,
  TextAlign,
  TextColor,
  FontSize,
  Width,
  JustifyContent,
  AlignItems,
  TextTransform,
  TextDecoration,
  Link,
} from "@snoam/pinata";
import AdminIcon from './AdminIcon';
import {GetAgreement_me_agreementsWithDetails_admins} from '../../__generated__/GetAgreement';
import {ModalContext} from '@snoam/mono-modal';
import {AdminType} from '../../__generated__/globalTypes';
import ChangeAccessModal from '../../components/ChangeAccessModal/ChangeAccessModal';
import AdminModel from '../../models/AdminModel';
import DeleteAdminLink from '../../components/DeleteAdminButton/DeleteAdminLink';
import {GET_SUPER_ADMINS, GET_AGREEMENT, GET_AGREEMENTS} from '../../queries';

const styleClass = {
  adminList: {
    wrapper: classNames(
      Display.FLEX,
      JustifyContent.JUSTIFY_BETWEEN,
      AlignItems.ITEMS_CENTER,
      BorderColor.BORDER_NEUTRAL_4,
      BorderWidth.BORDER_B_1,
      BorderStyle.BORDER_DASHED,
      Padding.P_4,
    ),
    content: {
      root: classNames(
        Display.FLEX,
        AlignItems.ITEMS_CENTER,
      ),
      adminName: classNames(
        FontSize.TEXT_LG,
        TextColor.TEXT_NEUTRAL_8,
        Padding.PL_PX,
        TextTransform.CAPITALIZE,
      ),
      changeAccess: classNames(
        Margin.ML_4,
        Width.W_1_2,
        TextColor.TEXT_PRIMARY_3,
        Cursor.CURSOR_POINTER,
        TextDecoration.NO_UNDERLINE,
      ),
      deleteAdminWrapper: classNames(
        Display.FLEX,
        JustifyContent.JUSTIFY_END,
      ),
      deleteAdmin: classNames(
        Margin.ML_2,
        Margin.MR_2,
        Display.INLINE_BLOCK,
        AlignItems.ITEMS_CENTER,
        Margin.MT_NEGATIVE_1,
        TextColor.TEXT_PRIMARY_3,
        TextColor.HOVER_TEXT_PRIMARY_4,
        TextDecoration.NO_UNDERLINE,
        Cursor.CURSOR_POINTER,
        JustifyContent.JUSTIFY_END
      ),
      adminType: classNames(
        Width.W_1_2,
        TextAlign.TEXT_RIGHT,
      ),
    },
    wrapperMobile: classNames(
      Padding.P_3,
      Padding.PT_4,
      BorderColor.BORDER_NEUTRAL_4,
      BorderWidth.BORDER_B_1,
      BorderStyle.BORDER_DASHED,
    ),
    contentMobile: {
      root: classNames(
        Display.FLEX,
        AlignItems.ITEMS_CENTER,
      ),
      adminNameWrapper: classNames(
        Display.FLEX,
      ),
      adminName: classNames(
        FontSize.TEXT_LG,
        TextColor.TEXT_NEUTRAL_8,
        Padding.PL_PX,
        Margin.MR_8,
        TextTransform.CAPITALIZE,
      ),
      changeAccess: classNames(
        TextDecoration.NO_UNDERLINE,
        TextColor.TEXT_PRIMARY_3,
      ),
      deleteAdmin: classNames(
        Margin.ML_1,
        Margin.MR_2,
        TextDecoration.NO_UNDERLINE,
      ),
    },
  },
};

enum AdminTypeNames {
  ADMIN = 'Administrator',
  ACCOUNTANT = 'Lesetilgang',
}

interface AdminListProps {
  mobile: boolean | undefined;
  admins: GetAgreement_me_agreementsWithDetails_admins[];
  agreementNumber: number;
}

const AdminList: React.FunctionComponent<AdminListProps> = ({mobile, admins, agreementNumber = []}) => {
  const modalId = 'ChangeAccessModal';
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const toggleModal = (changeAccessModalId: string) => openModalId ? closeModal() : openModal(changeAccessModalId);

  const refetchQueries = [
    {query: GET_SUPER_ADMINS},
    {query: GET_AGREEMENTS},
    {
      query: GET_AGREEMENT,
      variables: {
        agreementNumber: [agreementNumber]
      }
    }
  ];

  const renderModal = (id: string, adminEmail: string | null, adminType: AdminType) => {
    return (
      <ChangeAccessModal
        id={id}
        adminEmail={adminEmail}
        agreementNumber={Number(agreementNumber)}
        roles={AdminModel.AdministrationRoles}
        currentRole={adminType}
      />
    )
  };

  if (admins.length === 0) {
    return null;
  }

  if (mobile) {
    return <>
      {
        admins.map((admin: GetAgreement_me_agreementsWithDetails_admins, i) => {
          const toggleAdminModal = () => toggleModal(`${modalId}_admin-${i}`);
          return (
            <React.Fragment key={`admins-${i}`}>
              {renderModal(`${modalId}_admin-${i}`, admin.email, admin.adminType)}
              <div className={styleClass.adminList.wrapperMobile}>
                <div
                  className={classNames(styleClass.adminList.contentMobile.root, Padding.PB_3, JustifyContent.JUSTIFY_BETWEEN)}>
                <span className={styleClass.adminList.contentMobile.adminNameWrapper}>
                  {AdminIcon(BackgroundColor.BG_POSITIVE)}
                  {admin.name &&
									<span className={styleClass.adminList.contentMobile.adminName}>{admin.name!.formatted}</span>
                  }
                </span>
                  <span>{AdminTypeNames[admin.adminType]}</span>
                </div>

                <div
                  className={classNames(styleClass.adminList.contentMobile.root, JustifyContent.JUSTIFY_BETWEEN, Margin.ML_8, Padding.PL_2)}>
                <span
                  tabIndex={0}
                  role="button"
                  className={styleClass.adminList.contentMobile.changeAccess}
                  onClick={toggleAdminModal}
                  onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      toggleAdminModal();
                    }
                  }}
                >
                  Endre tilgang
                </span>

                  <DeleteAdminLink
                    id={admin.id}
                    className={styleClass.adminList.contentMobile.deleteAdmin}
                    mobile={true}
                    refetchQueries={refetchQueries}
                  />
                </div>
              </div>
            </React.Fragment>
          )
        })
      }
    </>;
  } else {
    return <>
      {
        admins.map((admin: GetAgreement_me_agreementsWithDetails_admins, i) => {
          const handleKeyDown = (e: any) => {
            if (e.key === 'Enter' || e.key === ' ') {
              toggleModal(`${modalId}_admin-${i}`)
            }
          };

          return (
            <React.Fragment key={`admins-${i}`}>
              {renderModal(`${modalId}_admin-${i}`, admin.email, admin.adminType)}
              <Grid key={`admins-${i}`} composition={GridComposition.ALPHA} gutter={GridGutter.BOTTOM_NONE}
                    className={styleClass.adminList.wrapper}>
								<span className={styleClass.adminList.content.root}>
                  {AdminIcon(BackgroundColor.BG_POSITIVE)}
                  {admin.name &&
									<span className={styleClass.adminList.content.adminName}>{admin.name!.formatted}</span>
                  }
                </span>
                <span className={styleClass.adminList.content.root}>
                  <span className={styleClass.adminList.content.adminType}>{AdminTypeNames[admin.adminType]}</span>
                  <span>
                    <Link
                      className={styleClass.adminList.content.changeAccess}
                      onClick={() => toggleModal(`${modalId}_admin-${i}`)}
                      role="button"
                      tabIndex={0}
                      onKeyDown={handleKeyDown}
                    >
                      Endre tilgang
                    </Link>
                  </span>
                </span>

                <div className={styleClass.adminList.content.deleteAdminWrapper}>
                  <DeleteAdminLink
                    id={admin.id}
                    className={styleClass.adminList.content.deleteAdmin}
                    mobile={false}
                    refetchQueries={refetchQueries}
                  />
                </div>
              </Grid>
            </React.Fragment>
          )
        })
      }
    </>
  }
};

export default AdminList;
