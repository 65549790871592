import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import SvgImage from "../../components/SvgImage/SvgImage";
import {
  classNames,
  Display,
  FontWeight,
  Heading,
  HeadingLevel,
  Icon,
  IconColor,
  IconName,
  IconSize,
  Margin,
  Padding,
  TextAlign,
  FontSize,
  Width,
  FlexDirection,
  AlignItems
} from "@snoam/pinata";
import EmailcodeInput, {Verified} from "../../components/EmailcodeInput/EmailcodeInput";
import OrderButton from "../../components/OrderButton/OrderButton";
import qs from 'qs';
import {checkInvitationCode, MySpidContext, sendOrder} from '../../utils';
import TermsAndConditions from "../../components/TermsAndConditions";
import {useNavigate, useParams} from "react-router-dom";

interface InvitationCodePageProps {
  spidContext: MySpidContext;
}

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextAlign.TEXT_CENTER,
    AlignItems.ITEMS_CENTER,
    Padding.PX_4,
    Padding.PB_10,
    Margin.MB_8,
  ),
  heading: classNames(
    FontWeight.FONT_LIGHT,
    FontSize.MD_TEXT_2XL,
    FontSize.TEXT_LG,
    Margin.MB_6,
  ),
  inputContainer: classNames(
    Margin.MY_6,
  ),
  text: classNames(
    FontSize.MD_TEXT_LG,
    FontSize.TEXT_SM,
    FontWeight.FONT_LIGHT,
  ),
  orderText: classNames(
    FontSize.MD_TEXT_2XL,
    FontWeight.FONT_LIGHT,
    Margin.MT_4,
    FontSize.TEXT_BASE,
    Margin.MD_MB_8,
    Margin.MB_4
  ),
  orderBox: classNames(
    Margin.MD_MT_8
  ),
};

const InvitationCodePage: React.FunctionComponent<InvitationCodePageProps> = () => {

  const navigate = useNavigate();
  // @ts-ignore
  const agreementNumber = +useParams<{ agreementNumber?: string }>().agreementNumber;

  const queryCode: string = (qs.parse(window.location.search.replace(/^\?/, '')).code || '') as unknown as string;
  const [emailInput, setEmailInput] = useState<string>(queryCode);
  const [verifyError, setVerifyError] = useState<string>('');
  const [verified, setVerified] = useState<Verified>(Verified.NEUTRAL);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<string>('');
  const [processOrder, setProcessOrder] = useState<boolean>(false);

  const invalidCodeMessage = 'Kode skal inneholde 8 tegn.';

  const checkCode = useCallback((code: string): Promise<string> => {
    setVerified(Verified.LOADING);
    setVerifyError('');

    return checkInvitationCode({
      agreementNumber: agreementNumber,
      code,
    }).then(() => {
      setVerified(Verified.VALID);
    }).catch((err) => {
      const message = err.errorMessage || 'Kunne ikke validere kode. Prøv igjen senere.';
      setVerified(Verified.ERROR);
      setVerifyError(message);
      return message;
    });
  }, [agreementNumber]);
  useEffect(() => {
    if (queryCode && queryCode.length === 8) {
      checkCode(queryCode);
    } else if (queryCode) {
      setVerified(Verified.ERROR);
      setVerifyError(invalidCodeMessage)
    }
  }, [checkCode, queryCode]);

  const completeOrder = () => {
    setProcessOrder(true);
    setVerified(Verified.LOADING);
    sendOrder({
      agreementNumber: agreementNumber,
      code: emailInput,
    }).then((res) => {
      const urlParams = qs.stringify({
        givenName: res.user.givenName || '',
        productGroups: res.products.map(p => `${p.companyCode}_${p.productName.replace(/\W/g, '_')}`),
      });
      setParams(urlParams);
      navigate(`/bestill/${agreementNumber}/flyt/kvittering?${urlParams}`);
    }).catch((err) => {
      const message = err.errorMessage || 'Kunne ikke validere kode. Prøv igjen senere.';
      setVerified(Verified.ERROR);
      setProcessOrder(false);
      setVerifyError(message);

      return message;
    });
  };

  const canProceed = verified === Verified.VALID;
  const onEmailInputChange = (e: { target: { value: string } }) => {
    const value: string = e.target.value;
    if (value !== "" && value.length === 8) {
      checkCode(value);
    } else if (value.length > 8) {
      setVerified(Verified.ERROR);
      setVerifyError(invalidCodeMessage)
    } else {
      setVerified(Verified.NEUTRAL);
    }
    setEmailInput(value);
  };


  return (
    <div className={styleClass.root}>
      {
        !processOrder ?
        <>
          <SvgImage
            href={`${process.env.PUBLIC_URL}/svg/email-sendt.svg`}
            svgStyle={{width: '100%', height: '200px'}}
            className={classNames(Width.W_FULL, Display.FLEX, Margin.MX_AUTO, Margin.MT_NEGATIVE_6)}
          />
          <Heading level={HeadingLevel.THREE} className={styleClass.heading}>Vi har sendt deg en verifiseringskode på din
            jobb-epost</Heading>
          <p className={styleClass.text}>Klikk på lenken i eposten eller skriv inn koden under:</p>
          <div className={styleClass.inputContainer}>
            <EmailcodeInput
              value={emailInput}
              onChange={onEmailInputChange}
              verified={verified}
              verifyError={verifyError}
            />
          </div>
          <p className={styleClass.text}>Det kan ta et par minutter før du ser eposten i din innboks.</p>
          <TermsAndConditions/>
        </>

      :
      <div className={styleClass.orderBox}>
        <SvgImage
          href={`${process.env.PUBLIC_URL}/svg/email-sendt.svg`}
          svgStyle={{width: '100%', height: '200px'}}
          className={classNames(Width.W_FULL, Display.FLEX, Margin.MX_AUTO, Margin.MT_NEGATIVE_6)}
        />
        <p className={styleClass.orderText}>Fullfører bestilling...</p>
        <div className={'loading'}>
          <Icon
            name={IconName.LOADING_WHEEL}
            size={IconSize.XXLARGE}
            color={IconColor.NEUTRAL_4}
          />
        </div>

      </div>
      }

      <OrderButton
        canProceed={canProceed}
        text={'Fullfør bestilling'}
        onProceedClick={completeOrder}
      />
    </div>
  );
};

export default InvitationCodePage;
