import React from "react";
import {BaseFilter, BaseFilterProps} from "./BaseFilter";
import {FilterType} from "../utils";

type NoneFilterProps = Omit<BaseFilterProps, 'type'>;

export const None: React.FunctionComponent<NoneFilterProps> = (props) => (
  <BaseFilter
    {...props}
    type={FilterType.NONE}/>
);
