import React, {CSSProperties, ReactNode, useContext, useReducer} from "react";
import EnvelopeContainer from "./EnvelopeContainer";
import isEqual from "react-fast-compare";
import {classNames, Overflow} from "@snoam/pinata";
import {DesktopHeaderHeight, MobileHeaderHeight} from "../../Const";
import {ResponsiveContext} from "../ResponsiveContext/ResponsiveContext";


interface IEnvelopeContext {
  showEnvelope(): void;

  hideEnvelope(): void;

  toggle(): void;

  getOffset: () => CSSProperties | any,

  setChildren(children: ReactNode): void

  setHeading(heading: string): void

  setStyle(props: CSSProperties): void

  envelopeChildren: React.ReactElement[] | [];
  open: boolean;
  offsetAnimationClass: string;
  style: CSSProperties,
  heading: string;
}

export const EnvelopeContext = React.createContext<IEnvelopeContext>({
  showEnvelope: () => void (0),
  hideEnvelope: () => void (0),
  toggle: () => void (0),
  setChildren: (children) => void (0),
  setStyle: (props: CSSProperties) => void (0),
  setHeading: (heading) => void (0),
  getOffset: () => {
  },
  offsetAnimationClass: '',
  envelopeChildren: [],
  open: false,
  heading: '',
  style: {},
});

const getStyle = (isFullscreen: boolean, open: boolean): CSSProperties => {
  return {
    height: `calc(100vh - ${isFullscreen ? MobileHeaderHeight : DesktopHeaderHeight})`,
    WebkitOverflowScrolling: 'touch',
    pointerEvents: open && isFullscreen ? 'none' : 'all'
  };
};

const getEnvelopeOffset = (isFullscreen: boolean, open: boolean, width: string) => () => {
  if (!isFullscreen && (open && window && window.innerWidth < 2255)) {
    return {transform: `translateX(${width})`};
  }
  return {}
};

enum EnvelopeActionType {
  TOGGLE,
  SHOW,
  HIDE,
  HEADER,
  CHILDREN,
  STYLE
}

type EnvelopeAction = {
  type: EnvelopeActionType.TOGGLE | EnvelopeActionType.SHOW | EnvelopeActionType.HIDE;
} | {
  type: EnvelopeActionType.STYLE | EnvelopeActionType.CHILDREN | EnvelopeActionType.HEADER;
  payload: ReactNode;
}

const styleClass = {
  context: (overflow: boolean) => classNames(
    'envelope-context',
    Overflow.OVERFLOW_X_HIDDEN,
  )
};

const reducer = (state: any, action: EnvelopeAction) => {
  switch (action.type) {
    case EnvelopeActionType.TOGGLE:
      return {
        ...state,
        open: !state.open
      };

    case EnvelopeActionType.SHOW:
      return {
        ...state,
        open: true
      };

    case EnvelopeActionType.HIDE:
      return {
        ...state,
        open: false
      };

    case EnvelopeActionType.STYLE:
      return {
        ...state,
        style: action.payload
      };
    case EnvelopeActionType.CHILDREN:
      return {
        ...state,
        children: action.payload
      };

    case EnvelopeActionType.HEADER:
      return {
        ...state,
        heading: action.payload
      };
  }

  return state;
};
export const EnvelopeProvider: React.FunctionComponent<{ width: string }> = ({width, children}) => {

  const {mobile, tablet, portrait} = useContext(ResponsiveContext);
  const [state, dispatch] = useReducer(reducer, {
    open: false,
    heading: 'Envelope heading',
    children: null,
    style: {}
  });
  const {open, heading, children: envelopeChildren, style} = state;

  const populateEnvelope = (newChildren: ReactNode) => {
    if (isEqual(newChildren, envelopeChildren)) {
      return;
    }
    dispatch({type: EnvelopeActionType.CHILDREN, payload: newChildren});
  };
  const populateEnvelopeHeading = (newHeading: string) => {
    if (isEqual(newHeading, heading)) {
      return;
    }
    dispatch({type: EnvelopeActionType.HEADER, payload: newHeading});
  };
  const showEnvelope = () => dispatch({type: EnvelopeActionType.SHOW});
  const hideEnvelope = () => dispatch({type: EnvelopeActionType.HIDE});
  const toggle = () => dispatch({type: EnvelopeActionType.TOGGLE});
  const setStyle = (payload: ReactNode) => dispatch({type: EnvelopeActionType.STYLE, payload});
  const isFullScreen = (mobile || (tablet && portrait)) as boolean;

  const providerState = {
    offsetAnimationClass: 'envelope-offset-transiton',
    style,
    heading,
    open,
    envelopeChildren,
    showEnvelope,
    hideEnvelope,
    toggle,
    setStyle,
    setChildren: populateEnvelope,
    setHeading: populateEnvelopeHeading,
    getOffset: getEnvelopeOffset(isFullScreen, open, width)
  };

  return (
    <EnvelopeContext.Provider value={providerState}>
      <EnvelopeContainer
        open={open}
        toggle={toggle}
        heading={heading}
        isFullScreen={isFullScreen}
        width={width}
        style={style}
      >
        {envelopeChildren}
      </EnvelopeContainer>
      <div
        className={styleClass.context(!isFullScreen)}
        style={getStyle(isFullScreen, open)}>
        {children}
      </div>
    </EnvelopeContext.Provider>
  );

};
