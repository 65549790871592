import * as React from "react";
import {Location, Params, Routes, Route, useLocation, useParams} from "react-router";
import {RouteProps} from "react-router/dist/lib/components";

export interface ListeningRouteOutputProps {
  location: Location<any>,
  params: Readonly<Params>,
}

function ListeningRoute<T extends RouteProps>(props: T): React.ReactElement<T & {
  element: React.ComponentType<any>,
} & ListeningRouteOutputProps> {
  const Element = props.element as React.ComponentType<any>;
  const location: Location<any> = useLocation();
  const params: Readonly<Params> = useParams();
  return (
    <Routes>
      <Route path={"*"} {...props as any} element={<Element location={location} params={params} {...props } />}/>
    </Routes>
  );
}

export default ListeningRoute;
