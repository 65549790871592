import React from "react";
import {Route, PathRouteProps} from "react-router";
import {Helmet} from "react-helmet";
import {IconName} from "@snoam/pinata";
import {MySpidContext} from "../utils";
import NavigateWithParams from "../components/NavigateWithParams";

export type MinBedriftRoute<P = {}> = PathRouteProps & P & {
  label: string;
  trackingLabel?: string,
  path: string;
  redirectFrom?: string[],
  component: React.ComponentType<MinBedriftRouteComponentProps>;
  wrappers: React.ComponentType<any>[];
}

export type MinBedriftRouteWithIcon<P = {}> = MinBedriftRoute<{ icon: IconName } & P>;
export type MinBedriftSuperAdminRouteWithIcon<P = {}> = MinBedriftRouteWithIcon<{ appliesToAllRoles: boolean } & P>;

export type MinBedriftRouteParent = {
  wrappers: React.ComponentType[],
  routes: MinBedriftRoute[] | MinBedriftRouteWithIcon[] | MinBedriftSuperAdminRouteWithIcon[]
}

export type MinBedriftRoutes = {
  super: MinBedriftRouteParent,
  superObserver: MinBedriftRouteParent,
  admin: MinBedriftRouteParent,
  order: MinBedriftRouteParent,
  generic: MinBedriftRouteParent,
  login: MinBedriftRouteParent,
};

export interface MinBedriftRouteComponentProps {
  route: MinBedriftRoute;
  spidContext: MySpidContext;
}

const wrapReduce = (wrappers: React.ComponentType<any>[], Base: React.ComponentType<any>, props = {}, route: MinBedriftRoute) => {
  return wrappers.reduce((child, Wrapper) => {
    return <Wrapper {...props} route={route}>{child}</Wrapper>
  }, <Base {...props} route={route}/>);
};

export const createRedirect = <P extends {}>(route: MinBedriftRoute<P>) => {
  const {path, redirectFrom = []} = route;
  return redirectFrom.reduce((acc: JSX.Element[], r: string, i: number) => {
    acc.push(
      <Route
        key={`${route.label}_${i}`}
        path={r}
        element={<NavigateWithParams to={path} />}
      />
    );
    return acc;
  }, []);
};

export const createRoute = <P extends {}>(route: MinBedriftRoute<P>, parentWrappers: React.ComponentType[] = [({children}) => <>{children}</>]) => {
  const {wrappers, label, component, ...routeParams} = route;

  const RenderRoute: React.FC<any> = (props) => {
    if (wrappers && wrappers.length > 0) {
      const SingleRouteWrapped = wrapReduce(wrappers, component, route, props);
      return (
        <>
          <Helmet>
            <title>Min Bedrift | {label}</title>
          </Helmet>
          {
            wrapReduce(parentWrappers, (props) => React.cloneElement(SingleRouteWrapped, {...SingleRouteWrapped.props, ...props}), props, route)}
        </>
      );
    } else {
      return (
        <>
          <Helmet>
            <title>Min Bedrift | {label}</title>
          </Helmet>
          {wrapReduce(parentWrappers, component, route, props)}
        </>
      );
    }
  };

  return (
    <Route
      key={label}
      element={<RenderRoute {...routeParams}/>}
      {...routeParams}
    />
  )
};
