import * as React from 'react';
import {
  BorderRadius,
  Overflow,
  Display,
  classNames,
  IconName,
  Icon,
  TextColor, AlignItems, JustifyContent, FlexShrink
} from '@snoam/pinata';

const styleClass = {
  icon: {
    root: (bgColor: string) => classNames(
      bgColor,
      BorderRadius.ROUNDED_FULL,
      Overflow.OVERFLOW_HIDDEN,
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      JustifyContent.JUSTIFY_CENTER,
      FlexShrink.FLEX_SHRINK_0,
    ),
    svg: classNames(
      TextColor.TEXT_WHITE,
      Display.FLEX,
      AlignItems.ITEMS_CENTER,
      JustifyContent.JUSTIFY_CENTER,
    )
  },
};

const AdminIcon = (bgColor: string) => (
  <div className={styleClass.icon.root(bgColor)}
    style={{
      height: '20px',
      width: '20px',
      marginLeft: '2px',
      marginRight: '1rem'
    }}>
    <Icon
      style={{ width: '14px', height: '14px' }}
      name={IconName.BEDRIFT_ADMIN}
      className={styleClass.icon.svg}
    />
  </div>
);

export default AdminIcon;
