import * as React from 'react';
import {useState} from 'react';
import {
  Button,
  ButtonSkin,
  classNames,
  Container,
  ContainerWidth,
  FontWeight,
  Heading,
  HeadingLevel,
  Margin,
  Modal,
  Padding,
  RadioButton,
  RadioButtonGroup,
  TextColor, FontSize,
  Width,
  BackgroundColor
} from '@snoam/pinata';

export enum ReferenceUpdateValue {
  OnlyThis = "ONLY_THIS",
  All = "ALL"
}

interface ReferencesUpdateModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (value: ReferenceUpdateValue) => void;
}

const styleClass = {
  heading: classNames(
    FontWeight.FONT_THIN,
    TextColor.TEXT_NEUTRAL_6,
    Margin.MB_6,
    FontSize.TEXT_2XL
  ),
};

const ReferencesUpdateModal: React.FunctionComponent<ReferencesUpdateModalProps> = ({ open, onClose, onSave }) => {

  const [value, setValue] = useState(ReferenceUpdateValue.OnlyThis);

  function saveOnEnterKeyPressed(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      event.stopPropagation();
      onSave(value);
    }
  }

  function closeOnEnterKeyPressed(event: React.KeyboardEvent<HTMLDivElement>) {
    if (event.key === 'Enter') {
      event.stopPropagation();
      onClose();
    }
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div onKeyPress={saveOnEnterKeyPressed}>
        <Modal
          onClose={onClose}
          backdrop={true}
          open={open}
          className={BackgroundColor.BG_NEUTRAL_1}
        >
            <Container width={ContainerWidth.DEFAULT} className={Padding.MD_PX_10}>
              <Heading level={HeadingLevel.ONE} className={styleClass.heading}>Den ansatte har flere abonnement</Heading>
                <RadioButtonGroup name={'product'} value={value.toLocaleString()}>
                    <RadioButton text="Oppdater kun for dette abonnementet" className={FontWeight.FONT_BOLD} value={ReferenceUpdateValue.OnlyThis} onClick={() => setValue(ReferenceUpdateValue.OnlyThis)}/>
                    <RadioButton text="Oppdater for alle abonnementer" value={ReferenceUpdateValue.All} onClick={() => setValue(ReferenceUpdateValue.All)}/>
                </RadioButtonGroup>
                <Button
                  text="Lagre referanser"
                  ariaLabel="Lagre referanser"
                  onClick={() => onSave(value)}
                  className={classNames(Width.W_FULL, Margin.MT_6)}
                />
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div onKeyPress={(event: React.KeyboardEvent<HTMLDivElement>) => {
                  if (event.key === 'Enter') {
                    event.stopPropagation();
                    onClose ();
                  }
                }}>
                  <Button
                    text="Avbryt"
                    ariaLabel="Avbryt"
                    onClick={onClose}
                    skin={ButtonSkin.SECONDARY}
                    className={Width.W_FULL}
                    onKeyPress={closeOnEnterKeyPressed}
                  />
                </div>
            </Container>
        </Modal>
      </div>
  );
};

export default ReferencesUpdateModal;
