import {
  BackgroundColor,
  BorderColor,
  BorderWidth,
  classNames,
  FlexDirection,
  Grid,
  GridComposition,
  GridGutter,
} from '@snoam/pinata';
import * as React from 'react';
import {useContext} from 'react';
import AgreementCardProducts from './AgreementCardProducts';
import AgreementCardSettings from './AgreementCardSettings';
import AgreementCardAdmins from './AgreementCardAdmins';
import {GetAgreements_me_agreements_edges,} from '../../__generated__/GetAgreements';
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import {useRole} from "../Boundary/RoleBoundary";

const styleClass = {
  agreement: {
    expandedBody: {
      grid: (tablet: boolean | undefined) => classNames(
        BackgroundColor.BG_WHITE,
        BorderColor.BORDER_NEUTRAL_3,
        tablet
          ? [BorderWidth.BORDER_B_1, FlexDirection.FLEX_COL]
          : [BorderWidth.BORDER_T_1, FlexDirection.FLEX_ROW],
      ),
    },
  },
};

interface IAgreementBodyProps {
  agreement: Pick<GetAgreements_me_agreements_edges, 'admins' | 'products' | 'settings' | 'agreementNumber' | 'agreementSubscriberCount' | 'maxSubscriptionsPerEmployee'>;
}

const AgreementBody: React.FunctionComponent<IAgreementBodyProps> = ({agreement}) => {
  const {tablet} = useContext(ResponsiveContext);
  const {role: userRole} = useRole();
  return (
    <Grid
      className={styleClass.agreement.expandedBody.grid(tablet)}
      composition={tablet ? GridComposition.DEFAULT : GridComposition.ALPHA}
      gutter={[GridGutter.NONE]}
    >
      <AgreementCardProducts {...agreement} userRole={userRole} />
      <AgreementCardSettings agreement={agreement}/>
      <AgreementCardAdmins
        admins={agreement.admins}
        agreement={agreement}
      />
    </Grid>
  )
};

export default AgreementBody;
