import * as React from "react";
import {numberOfProducts} from "../../configuration/agreements"
import {
  BorderColor,
  BorderWidth,
  classNames,
  Display,
  FlexDirection,
  Heading,
  HeadingLevel,
  Margin,
  Padding,
  TextColor,
  Width,
} from "@snoam/pinata";
import ReferenceSelector from "../../components/ReferenceSelector/ReferenceSelector";
import {GetAgreement_me_agreementsWithDetails,} from '../../__generated__/GetAgreement';
import SelectMaxSubscriptionsPerEmployee
  from '../../components/SelectMaxSubscriptionsPerEmployee/SelectMaxSubscriptionsPerEmployee';
import VerificationSection from './VerificationSection';
import ActivationDegreeSection from "./ActivationDegreeSection";

export const styleClass = {
  settings: {
    section: classNames(
      BorderColor.BORDER_NEUTRAL_2,
      BorderWidth.BORDER_B_4,
      Padding.PX_4,
      Padding.MD_PX_10,
      Padding.PT_8,
      Padding.PB_10,
    ),
    sectionTitle: classNames(
      TextColor.TEXT_NEUTRAL_8,
    ),
    productsDropdown: classNames(
      Width.MD_W_48,
      Margin.MT_4
    ),
    referenceText: classNames(
      Margin.MY_6,
    ),
    referenceWrapper: classNames(
      Display.FLEX,
      FlexDirection.MD_FLEX_ROW,
      FlexDirection.FLEX_COL
    )
  }
};

interface SettingsPanelProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}

const SettingsPanel = ({ agreement }: SettingsPanelProps) => (
  <React.Fragment>
    <ActivationDegreeSection agreement={agreement} />
    <div className={styleClass.settings.section}>
      <Heading level={HeadingLevel.TWO} className={styleClass.settings.sectionTitle}>
        Hvor mange produkter kan hver ansatt velge?
      </Heading>
      <div className={styleClass.settings.productsDropdown}>
        <SelectMaxSubscriptionsPerEmployee
          agreement={agreement}
          data={numberOfProducts()}
          onChange={() => {
            "d"
          }}
        />
      </div>
    </div>
    <VerificationSection agreement={agreement} />
    <div className={styleClass.settings.section}>
      <Heading level={HeadingLevel.TWO} className={styleClass.settings.sectionTitle}>
        Hvordan skal abonnenter kunne søkes opp?
          </Heading>

      <p className={styleClass.settings.referenceText}>
        Ved å legge til referanser vil det bli enklere å filtrere og søke opp ansatte.
      </p>
      <div className={styleClass.settings.referenceWrapper}>
        {agreement.settings.reference1 ?
          [agreement.settings.reference1, agreement.settings.reference2].map((reference, key) => {
            return <ReferenceSelector
              key={`reference-${key + 1}`}
              number={key}
              reference={reference}
              agreement={agreement}
            />
          })
          :
          <ReferenceSelector
            key={'reference-1'}
            number={0}
            reference={agreement.settings.reference1}
            agreement={agreement}
          />
        }
      </div>
    </div>
  </React.Fragment>
);

export default SettingsPanel;
