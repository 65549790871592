import "../../src/webstorage-polyfill";

interface PersistentModelConfig {
  id: string;
  engine?: Storage;
}

export class PersistentModel<M extends {}> {

  private id: string;
  private engine: Storage;

  constructor(config: PersistentModelConfig, data?: M, reset = false) {

    const {id, engine = window.sessionStorage} = config;

    this.id = id;
    this.engine = engine;

    if(!data) {
      // @ts-ignore
      data = {};
    }

    //if we already have a session model with instance id x then continue
    if (!reset && engine.getItem(this.id)) {
      return;
    } else {
      const model = JSON.stringify(data);
      engine.setItem(this.id, model);
    }
  }

  protected getModel() {
    const marshalled = this.engine.getItem(this.id);

    if (!marshalled) {
      return null;
    }

    return JSON.parse(marshalled);
  }

  public set<T>(key: string, value: T) {

    const model = this.getModel();

    if (!model) {
      return;
    }

    model[key] = value;

    this.engine.setItem(this.id, JSON.stringify(model))
  }

  public get<T>(key: string): T | null {
    const model = this.getModel();

    if (!model) {
      return null;
    }

    return model[key] as T;
  }

  public clear(): void {
    return this.engine.removeItem(this.id);
  }

  public remove(key: string): void {
    const model = this.getModel();
    if (!model) {
      return;
    }
    delete model[key];
    return this.engine.setItem(this.id, JSON.stringify(model));
  }
}
