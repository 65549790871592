import React, { ReactNode } from "react";
import {BorderColor, BorderWidth, Checkbox, classNames, Display, FlexDirection, Margin, Width} from "@snoam/pinata";
import Filter from "../../components/Filter/Filter";
import {Contains} from "../../components/Filter/types/Contains";
import {PaymentLevel} from "../../__generated__/globalTypes";

const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    Margin.ML_NEGATIVE_4,
    Margin.MR_NEGATIVE_4,
    Margin.MB_NEGATIVE_4,
  ),
  inner: classNames(
    BorderWidth.BORDER_B_1,
    BorderColor.BORDER_NEUTRAL_2,
    Width.W_FULL,
  )
};

const customWrapper = ({children}: { children: ReactNode }) => (<div className={styleClass.root}>{children}</div>);


export type BillingType = {
  id: PaymentLevel;
  label: string;
}

export type BillingTypeFilterProps = {
  billingTypes: BillingType[]
}

export const BillingTypeFilter: React.FunctionComponent<BillingTypeFilterProps> = ({billingTypes}) => {

  return (
    <Filter
      heading={'Faktureringsmetode'}
      id={'billingType'}
      wrapper={customWrapper}
      marshal={true}
    >
      {
        billingTypes.map((billingTypes, i) => {
          return (
            <Contains
              id={`billing_type_filter_${billingTypes.id}_${i}`}
              key={`billing_type_filter_${billingTypes.id}_${i}`}
              name={'paymentLevel'}
              value={billingTypes.id}
            >
              {
                ({active, toggleFilter}) => {
                  return (
                    <div className={styleClass.inner}>
                      <Checkbox
                        checked={!!active}
                        onClick={toggleFilter}
                        text={billingTypes.label}
                      />
                    </div>
                  )
                }
              }
            </Contains>
          )
        })
      }
    </Filter>
  )
};

export default BillingTypeFilter;
