import * as React from 'react';
import {useContext} from 'react';
import {
  BackgroundColor,
  classNames,
  Display,
  FontWeight,
  Margin,
  TextAlign,
  TextColor,
  FontSize,
  FlexDirection, AlignItems, JustifyContent,
} from "@snoam/pinata";
import {useSpid} from "@snoam/mono-spid";
import SpeechBubble from "../../../components/SpeechBubble/SpeechBubble";
import {PulseTrackerContext} from '../../../routes/compositions/Tracker';
import {Navigate} from "react-router";
import SelectProductsPage from "../SelectProducts/SelectProductsPage";
import {useParams, useSearchParams} from "react-router-dom";
import {toSearchString} from "../../../utils";
import {MinBedriftRouteComponentProps} from "../../../routes/utils";


const styleClass = {
  root: classNames(
    Display.FLEX,
    FlexDirection.FLEX_COL,
    TextColor.TEXT_WHITE,
    TextAlign.TEXT_CENTER,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_END,
    Margin.MT_8,
  ),
  heading: classNames(
    FontWeight.FONT_THIN,
    FontSize.TEXT_3XL,
    Margin.MB_4
  ),
  textContainer: classNames(
    Margin.MB_8
  ),
  footer: classNames(
    Display.FLEX,
    FlexDirection.MD_FLEX_ROW,
    FlexDirection.FLEX_COL,
    AlignItems.ITEMS_CENTER
  ),
};

export const AgreementNotActive: React.FunctionComponent<{}> = () => (
  <>
    <p className={TextColor.TEXT_NEGATIVE}>
      Ops! Denne invitasjonen er visst deaktivert.
    </p>
    <p>
      Ta kontakt med administrator som opprinnelig<br/>
      sendte deg invitasjonen.
    </p>
  </>
);

export const MissingAgreementNumber = () => {
  return <SpeechBubble
    headerColor={BackgroundColor.BG_NEGATIVE}
    heading={`Ops! Denne lenken er ikke gyldig`}
    body={'Ta kontakt med administrator som opprinnelig sendte deg invitasjonen.'}
    footer={''}
  />
};

const OrderInitial: React.FunctionComponent<MinBedriftRouteComponentProps> = (props) => {
  const spidContext = useSpid();
  const pulseContext = useContext(PulseTrackerContext);
  const {agreementNumber} = useParams<{ agreementNumber?: string }>();
  const [searchParams] = useSearchParams();
  const searchString = toSearchString(searchParams);

  if (spidContext.state.userId) {
    pulseContext.action.setTrackMessage(" - Logged in");
  }

  if (!agreementNumber) {
    return <div className={styleClass.root}>
      <MissingAgreementNumber/>
    </div>
  }

  if (!spidContext.state.userId || !spidContext.state.sig) {
    return <SelectProductsPage {...props}/>;
  }

  return <Navigate to={`/bestill/${agreementNumber}/flyt/produkter${searchString}`} />;
};

export default OrderInitial;
