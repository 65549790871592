import {
  Button,
  ButtonSkin,
  classNames,
  Display,
  Margin,
  Padding,
  PointerEvents,
  TextDecoration,
  Width
} from '@snoam/pinata';
import * as React from 'react';
import {useState} from 'react';
import SvgImage from '../SvgImage/SvgImage';
import {CSVLink} from 'react-csv';
import {Data} from 'react-csv/components/CommonPropTypes';
import { trackClickEngagment } from '../../utils';

interface IExcelButton {
  className?: string;
  getData: () => Data;
  filename: string;
  disabled?: boolean;
}

const styleClass = {
  button: classNames(
    Display.HIDDEN,
    Display.MD_FLEX,
    Padding.PX_2,
    Padding.SM_PX_0,
  ),
  csvLink: (disabled: boolean) => classNames(
    Width.W_FULL,
    TextDecoration.NO_UNDERLINE,
    {
      [PointerEvents.POINTER_EVENTS_NONE]: disabled
    }
  )
};

const ExcelButton = ({className, getData, disabled = false, filename}: IExcelButton) => {

  const [data, setData] = useState<any[]>([]);

  const downloadButtonClick = () => {
    trackClickEngagment('Abonnenter - Last ned');
    setData(getData());
  }

  return (
    <div className={classNames(styleClass.button, className)}>
      <CSVLink
        className={styleClass.csvLink(disabled)}
        data={data}
        target={'_self'}
        filename={filename}
        aria-disabled={disabled}
      >
        <Button
          text={"Last ned"}
          ariaLabel={"Last ned"}
          aria-disabled={disabled}
          disabled={disabled}
          skin={ButtonSkin.SECONDARY}
          className={classNames(Width.W_FULL, Margin.MY_0)}
          onClick={downloadButtonClick}
          iconRight={
            <SvgImage
              className={classNames(Margin.MR_4)}
              svgStyle={{width: '24px', height: '24px'}}
              href={`${process.env.PUBLIC_URL}/svg/excel.svg`}
            />
          }
        />
      </CSVLink>
    </div>
  )
};

export default ExcelButton;
