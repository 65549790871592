import {
  AlignItems,
  classNames,
  Cursor,
  Display,
  Grid,
  GridComposition,
  Icon,
  IconColor,
  IconName,
  IconSize,
  JustifyContent,
  Link,
  Margin,
  Padding,
  TextColor,
} from "@snoam/pinata";
import * as React from "react";
import {CSSProperties, useContext, useEffect, useState} from "react";
import ProductBox from "../../../components/ProductBox/ProductBox";
import {
  GetAgreementOrderInitial_agreement,
  GetAgreementOrderInitial_agreement_products
} from "../../../__generated__/GetAgreementOrderInitial";
import {Product} from "../models/OrderSessionModel";
import {useQuery} from "@apollo/client";
import {GET_SUBSCRIBER_PRODUCTS} from "../../../queries";
import {
  GetSubscriberProducts,
  GetSubscriberProducts_me_subscriptions,
  GetSubscriberProductsVariables
} from "../../../__generated__/GetSubscriberProducts";
import {LoadingFragment} from "../../LoadingPage";
import {Publication, publications, sortMerchants} from "../../../utils";
import {isSubscriptionActive} from "../../../models/EmployeeModel";
import {groupBy} from '../../../components/GroupProducts/GroupProducts';
import {GetAgreementProductsAndSiebelProducts_availableProducts} from '../../../__generated__/GetAgreementProductsAndSiebelProducts';
import ProductVariantModal from "./ProductVariantModal";
import {ModalContext} from "@snoam/mono-modal";
import {useClientContext} from '../../../context/ClientContextProvider';
import {useSpid, SpidContext} from "@snoam/mono-spid";

const styleClass = {
  productBoxGrid: classNames(
    JustifyContent.JUSTIFY_CENTER,
  ),
  productBox: (clickable: boolean) => classNames(
    Margin.MB_6,
    { [Cursor.CURSOR_POINTER]: clickable }
  ),
  footer: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
    JustifyContent.JUSTIFY_BETWEEN,
    Padding.PY_3,
  ),
  footerWrapper: classNames(
    Display.FLEX,
    AlignItems.ITEMS_CENTER,
  ),
  footerIcon: classNames(
    Margin.MR_2,
  ),
  deleteLink: classNames(
    Margin.MR_2,
    Cursor.CURSOR_POINTER,
    TextColor.TEXT_PRIMARY_3,
    TextColor.HOVER_TEXT_PRIMARY_1,
  ),
  administrateLink: classNames(
    Margin.MR_2,
    TextColor.TEXT_PRIMARY_3,
    TextColor.HOVER_TEXT_PRIMARY_1,
  ),
};

enum ProductBoxFooterVariations {
  Default,
  ProductAdded,
  MaxProductsChosen,
  AlreadySubscribed,
  ProductGroup,
  Loading,
  NeedLogin,
}

const findProductGroup = (productList: GetAgreementOrderInitial_agreement_products[], currentProduct: GetAgreementProductsAndSiebelProducts_availableProducts) => {
  const productListGroup = productList.find((product: GetAgreementOrderInitial_agreement_products) => {
    return product.id === currentProduct.id;
  });

  return productListGroup!.productGroup;
};

interface Footer {
  href?: string;
  iconColor?: IconColor;
  iconName: IconName;
  iconSize?: IconSize;
  onClick?: () => void;
  style?: CSSProperties;
  text: string;
}

type ProductBoxFooterProps = {variant: ProductBoxFooterVariations, productName: string, productVariation: string, productGroup: string, onDeleteClick: () => void, spidContext: SpidContext};
const ProductBoxFooter: React.FC<ProductBoxFooterProps> = ({variant, productName, productVariation, productGroup, onDeleteClick, spidContext}) => {

  const { isProduction } = useClientContext();
  const publication: Publication | undefined = publications(isProduction).find((publication) => publication.productGroup === productGroup);
  const footers: { [key in ProductBoxFooterVariations]: Footer } = {
    [ProductBoxFooterVariations.NeedLogin]: {
      text: 'Logg inn for å velge produkt',
      onClick: () => spidContext.actions.login({ simplified: false }),
      iconName: IconName.INFO_OUTLINE,
    },
    [ProductBoxFooterVariations.ProductAdded]: {
      text: `Lagt til ${productName}`,
      iconName: IconName.CHECK,
      iconSize: IconSize.SMALL,
      iconColor: IconColor.PRIMARY_1,
    },
    [ProductBoxFooterVariations.MaxProductsChosen]: {
      text: 'Maks antall valgt',
      iconName: IconName.INFO_OUTLINE,
    },
    [ProductBoxFooterVariations.AlreadySubscribed]: {
      text: 'Allerede abonnent',
      iconName: IconName.CHECK,
      iconSize: IconSize.SMALL,
      href: publication && publication.myPage,
    },
    [ProductBoxFooterVariations.ProductGroup]: {
      text: 'Velg produktvariant',
      iconName: IconName.PLUS,
      iconSize: IconSize.SMALL,
      iconColor: IconColor.NEUTRAL_4,
      style: {},
    },
    [ProductBoxFooterVariations.Loading]: {
      text: ``,
      iconName: IconName.DOT,
    },
    [ProductBoxFooterVariations.Default]: {
      text: `Legg til ${productVariation}`,
      iconName: IconName.PLUS,
      iconSize: IconSize.SMALL,
      iconColor: IconColor.NEUTRAL_4,
      style: {},
    },
  };
  const footer = footers[variant];

  const onDeleteKeyDown = (__event: any) => {
    if (__event.key === "Enter" || __event.key === " ") {
      __event.preventDefault();
      onDeleteClick();
    }
  };

  return (
    <div className={styleClass.footer} style={footer.style}>
      {variant !== ProductBoxFooterVariations.NeedLogin &&
        <div className={styleClass.footerWrapper}>
          <Icon name={footer.iconName} color={footer.iconColor} size={footer.iconSize} className={styleClass.footerIcon}/>
          <span>{footer.text}</span>
        </div>
      }
      {variant === ProductBoxFooterVariations.Loading && <LoadingFragment label={'Sjekker ..'} style={{}}/>}

      {variant === ProductBoxFooterVariations.ProductAdded &&
        <span
          tabIndex={0}
          role="button"
          onClick={onDeleteClick}
          onKeyDown={onDeleteKeyDown}
          className={styleClass.deleteLink}
        >
          Slett
        </span>
      }
      {variant === ProductBoxFooterVariations.AlreadySubscribed && footer.href &&
        <Link
          className={styleClass.administrateLink}
          href={footer.href}
          target={'_blank'}
        >
          Administrer
      </Link>
      }
      {variant === ProductBoxFooterVariations.NeedLogin &&
      <div
        tabIndex={0}
        role="button"
        onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter' || e.key === ' ') {
            footer.onClick!!();
          }
        }}
        className={classNames(styleClass.footerWrapper, Cursor.CURSOR_POINTER)}
        onClick={footer.onClick}
      >
        <Icon name={footer.iconName} color={footer.iconColor} className={styleClass.footerIcon}/>
        {footer.text}
      </div>
      }
    </div>
  )
};

interface IProductBoxList {
  chosenProducts: Pick<Product, "id" | "productGroup" | "siebelProductId">[];
  setChosenProduct: (products: Pick<Product, "id" | "productGroup" | "siebelProductId">[]) => void;
  agreement: GetAgreementOrderInitial_agreement;
  activeProducts: GetAgreementOrderInitial_agreement_products[];
  inactiveProducts: GetAgreementOrderInitial_agreement_products[];
}

export const ProductBoxList: React.FunctionComponent<IProductBoxList> = ({ activeProducts, inactiveProducts, chosenProducts, setChosenProduct, agreement }) => {
  const [clickedProductGroup, setClickedProductGroup] = useState<string | null>(null);
  const {closeModal, openModal, openModalId} = useContext(ModalContext);
  const spidContext = useSpid();
  const createToggleModal = (changeAccessModalId: string) => () => {
    openModalId ? closeModal() : openModal(changeAccessModalId)
  };
  const toggleModal = createToggleModal('choose-product-modal');
  const { agreementNumber } = agreement;
  let renderedGroup: string[] = [];
  const chosenProductGroups: string[] = [];
  const [subscribedProducts, setSubscribedProducts] = useState<Omit<GetSubscriberProducts_me_subscriptions, '__typename'>>({
    id: '',
    activeProducts: [],
    activeCount: 0
  });
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const {data, error, loading} = spidContext.state.userId ? useQuery<GetSubscriberProducts, GetSubscriberProductsVariables>(GET_SUBSCRIBER_PRODUCTS, {
    variables: {
      agreementNumber
    }
  }) : {data: {me: {subscriptions: {id: "", activeCount: 0, activeProducts: []}}}, error: false, loading: false};

  useEffect(() => {
    // eslint-disable-next-line no-prototype-builtins
    if (data && data.hasOwnProperty('me')) {
      const { me } = data;
      setSubscribedProducts(me.subscriptions);
    }
  }, [data, loading]);

  if (error) {
    throw error;
  }

  const { activeProducts: subProducts, activeCount } = subscribedProducts;
  const inactiveProductsSubscribed = inactiveProducts.reduce((acc, ip) => {
    if (subProducts.find((p) => p.companyCode === ip.companyCode && p.productCombination === ip.productCombination)) {
      acc.push(ip);
    }
    return acc;
  }, [] as GetAgreementOrderInitial_agreement_products[]);

  const mergedInactiveButSubscribedAndActiveProducts = [...activeProducts, ...inactiveProductsSubscribed];
  const groupedProducts = groupBy('productGroup', mergedInactiveButSubscribedAndActiveProducts);
  const sortedProductGroups = sortMerchants(Object.keys(groupedProducts)).map(k => groupedProducts[k]) as GetAgreementProductsAndSiebelProducts_availableProducts[][];

  sortedProductGroups.forEach((sortedProduct: GetAgreementProductsAndSiebelProducts_availableProducts[]) => {
    sortedProduct.forEach((product) => {
      chosenProducts.forEach((chosenProduct) => {
        if (chosenProduct.id === product.id) {
          chosenProductGroups.push(product.productGroup);
        }
      });
    });
  });

  const renderProducts = () => {
    return sortedProductGroups.map((sortedProduct: GetAgreementProductsAndSiebelProducts_availableProducts[], i: number) => {

      const { id, siebelProductId, productName, productVariationImageUrl, productVariation, productGroup, productVariationDescription } = sortedProduct[0];

      const maxSubscriptions = agreement.maxSubscriptionsPerEmployee || Infinity;
      const isGroupedProduct = sortedProduct.length > 1;
      const duplicateProductGroup = renderedGroup.indexOf(productGroup) !== -1;
      const productIsChosen = isGroupedProduct ? chosenProductGroups.indexOf(productGroup) !== -1 : chosenProducts.find(p => p.id === id);
      const productCanBeChosen = (chosenProducts.concat(subscribedProducts.activeProducts.map((p) => ({...p, siebelProductId: p.productId}))).length < maxSubscriptions && !productIsChosen);

      const productIsSubscribed = (isGroupedProduct: boolean, sortedProduct: GetAgreementProductsAndSiebelProducts_availableProducts) => {
        if (!isGroupedProduct) {
          return subProducts!.find(p => {
            return (p && (
                p.companyCode === sortedProduct.companyCode
                &&
                p.productCombination === sortedProduct.productCombination
                &&
                isSubscriptionActive(p.status)
              )
            );
          });
        }
        return subProducts!.find(p => (p && p.productGroup === sortedProduct.productGroup && isSubscriptionActive(p.status) && renderedGroup.indexOf(sortedProduct.productGroup) > -1)) !== undefined;
      };
      if (duplicateProductGroup) {
        return null;
      }

      renderedGroup.push(productGroup);

      const getProductFooterVariant = (): ProductBoxFooterVariations => {
        const isMax = activeCount >= maxSubscriptions || chosenProducts.concat(subscribedProducts.activeProducts.map((p) => ({ ...p, siebelProductId: p.productId}))).length >= maxSubscriptions;
        if (!spidContext.state.userId) {
          return ProductBoxFooterVariations.NeedLogin;
        } else if (loading) {
          return ProductBoxFooterVariations.Loading;
        } else if (productIsSubscribed(isGroupedProduct, sortedProduct[0])) {
          return ProductBoxFooterVariations.AlreadySubscribed;
        } else if (isGroupedProduct && !productIsChosen && !isMax) {
          return ProductBoxFooterVariations.ProductGroup;
        } else if (productIsChosen) {
          return ProductBoxFooterVariations.ProductAdded
        } else if (isMax && !productIsChosen) {
          return ProductBoxFooterVariations.MaxProductsChosen;
        }
        return ProductBoxFooterVariations.Default
      };

      const onProductBoxClick = () => {
        if (loading || !productCanBeChosen || productIsSubscribed(isGroupedProduct, sortedProduct[0])) {
          return;
        }
        if (sortedProduct.length > 1) {
          setClickedProductGroup(productGroup);
          toggleModal();
        } else {
          setChosenProduct([
            ...chosenProducts,
            { id, productGroup, siebelProductId }
          ]);
        }
      };

      const onProductBoxKeyDown = (__event: any) => {
        if (__event.key === "Enter" || __event.key === " ") {
          __event.preventDefault();
          onProductBoxClick();
        }
      };

      const onDeleteClick = () => {
        if (productIsChosen) {
          const clickedChosenProductGroup = findProductGroup(activeProducts, sortedProduct[0]);
          const productsInProductGroup: string[] = [];

          activeProducts.forEach((product: GetAgreementOrderInitial_agreement_products) => {
            if (product.productGroup === clickedChosenProductGroup) {
              productsInProductGroup.push(product.id);
            }
          });

          let chosenProductsWithoutClickedProduct: Pick<Product, "id" | "productGroup" | "siebelProductId">[] = chosenProducts;

          productsInProductGroup.forEach((productId) => {
            chosenProductsWithoutClickedProduct = chosenProductsWithoutClickedProduct.filter((product) => product.id !== productId);
          });

          setChosenProduct([
            ...chosenProductsWithoutClickedProduct,
          ]);
        }
      };

      const productLabel = !isGroupedProduct || productIsSubscribed(isGroupedProduct, sortedProduct[0]) ? productVariation : '';
      const productCanBeClicked = getProductFooterVariant() === ProductBoxFooterVariations.Default || getProductFooterVariant() === ProductBoxFooterVariations.ProductGroup;

      return (
        <div
          onClick={onProductBoxClick}
          onKeyDown={onProductBoxKeyDown}
          className={styleClass.productBox(productCanBeClicked)}
          key={`product-${i}`}
          tabIndex={productCanBeClicked ? 0 : undefined}
          role={productCanBeClicked ? "button" : undefined}
        >
          <ProductBox
            img={productVariationImageUrl || ''}
            heading={productName}
            infoText={productVariationDescription || ''}
            productLabel={productLabel}
            footer={(
              <ProductBoxFooter
                variant={getProductFooterVariant()}
                productName={productName}
                productVariation={productVariation}
                productGroup={productGroup}
                onDeleteClick={onDeleteClick}
                spidContext={spidContext}
              />
            )}
          />
        </div>
      )
    })
  };

  return (
    <>
      <Grid
        composition={GridComposition.ALPHA}
        className={styleClass.productBoxGrid}
        style={{marginBottom: '150px'}}
      >
        {renderProducts()}
      </Grid>

      {
        clickedProductGroup !== null ?
          <ProductVariantModal
            id={'choose-product-modal'}
            onClose={closeModal}
            productGroup={clickedProductGroup}
            chosenProducts={chosenProducts}
            setChosenProduct={setChosenProduct}
            products={activeProducts}
          /> : null
      }
    </>
  )
};
