import {
  BackgroundColor,
  BorderRadius,
  classNames,
  Height,
  Icon,
  IconName,
  Margin,
  Padding,
  TextColor
} from '@snoam/pinata';
import * as React from 'react';

export interface IUserIconProps {
  admin?: boolean;
  selected?: boolean;
}
const UserIcon = (props: IUserIconProps) => {
  let backgroundColor = props.selected ? BackgroundColor.BG_NEUTRAL_4 : BackgroundColor.BG_NEUTRAL_3;
  if (props.admin) {
    backgroundColor = BackgroundColor.BG_POSITIVE;
  }

  const styleClass = {
    icon: classNames(
      Padding.P_1,
      TextColor.TEXT_WHITE,
      BorderRadius.ROUNDED_FULL,
      Height.H_4,
      Margin.MR_4,
      Margin.MT_1,
      backgroundColor,
    ),
  };

  return (
    <Icon
      style={{width: '26px', height: '26px'}}
      name={props.admin ? IconName.BEDRIFT_ADMIN : IconName.BEDRIFT_USER}
      className={styleClass.icon}/>
  );
};

export default UserIcon;
