import {Button, ButtonSkin, classNames, Icon, IconColor, IconName, Margin, Padding, Width} from '@snoam/pinata';
import * as React from 'react';
import {CSSProperties, useContext} from 'react';
import {EnvelopeContext} from "../../context/EnvelopeContext/EnvelopeContext";

const styleClass = {
  root: classNames(
    Width.W_FULL,
    Padding.PX_2,
    Padding.MD_PX_0,
  ),
  btn: classNames(
    Width.W_FULL,
    Margin.MY_0,
  )
};

type IFilterButtonProps = {
  className?: string;
  style?: CSSProperties;
  disabled?: boolean;
};

const FilterButton: React.FunctionComponent<IFilterButtonProps> = ({style, className, disabled}) => {
  const {toggle, open: envelopeOpen} = useContext(EnvelopeContext);
  const onToggle = () => {
    if(!envelopeOpen && !disabled) {
      toggle();
    }
  };

  const onKeyDown = (e: any) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      onToggle();
    }
  };

  return (
    <div className={className} style={style}>
      <Button
        composed={true}
        disabled={disabled}
        text={envelopeOpen ? "Skjul filter" : "Filtrer"}
        ariaLabel={envelopeOpen ? "Skjul filter" : "Filtrer"}
        skin={ButtonSkin.SECONDARY}
        className={styleClass.btn}
        onClick={onToggle}
        onKeyDown={onKeyDown}
        iconLeft={
          <Icon
            name={IconName.FILTER2}
            color={envelopeOpen ? IconColor.PRIMARY_1 : IconColor.NEUTRAL_4}
          />
        }
      />
    </div>
  );
};

export default FilterButton;
