import * as React from "react";
import { classNames, FontSize, Link, Margin } from "@snoam/pinata";
import { useContext } from "react";
import { ModalContext } from "@snoam/mono-modal";

import TermsAndConditionModal from './TermsAndConditionModal'

const styleClass = {
  termsText: classNames(
    FontSize.TEXT_SM,
    Margin.MT_8,
  ),
};

const TermsAndConditions: React.FunctionComponent = () => {
  const { closeModal, openModal, openModalId } = useContext(ModalContext);
  const toggleModal = (modalId: string) => () => openModalId ? closeModal() : openModal(modalId);
  return (
    <>
      <p className={styleClass.termsText}>
        Ved å fullføre bestillingen godkjenner du også våre <Link role={"button"} tabIndex={0} onClick={toggleModal('terms-n-condition-modal')}>abonnementsvilkår</Link>.<br/>
        Informasjon om Schibsteds behandling av dine personopplysninger finnes <Link target={"_blank"} href={"https://info.privacy.schibsted.com/no/schibsted-norge-personvernerklaering/"}>her</Link>.
      </p>
      <TermsAndConditionModal
        id={'terms-n-condition-modal'}
      />
    </>
  );
};

export default TermsAndConditions;

