import React, {CSSProperties, ReactNode, useContext} from "react";
import {classNames, Container, ContainerWidth, Height, Overflow, Padding} from "@snoam/pinata";
import Footer from "../Footer/Footer";
import {EnvelopeContext} from "../../context/EnvelopeContext/EnvelopeContext";
import {ResponsiveContext} from "../../context/ResponsiveContext/ResponsiveContext";
import { BackgroundContext } from '@snoam/mono-background-loader/lib/BackgroundContext';


const styleClass = {
  root: (className?: string) => classNames(
    className,
    Height.H_FULL,
    Overflow.OVERFLOW_X_HIDDEN,
    Overflow.OVERFLOW_Y_SCROLL,
  ),
  inner: (hideGutter: boolean) => classNames(
    Padding.PX_0,
    {
      [Padding.PY_8]: !hideGutter,
      [Padding.MD_PX_8]: !hideGutter
    },
  )
};

export interface IPageProps {
  className?: string;
  hideGutter?: boolean;
  hideFooter?: boolean;
  children?: ReactNode;
  width?: ContainerWidth;
}

const Page: React.FunctionComponent<IPageProps> = React.memo(({className, children, hideGutter = false, hideFooter = false, width = ContainerWidth.WIDER}) => {
  const {getOffset, offsetAnimationClass, open, toggle} = useContext(EnvelopeContext);
  const {mobile, tablet, portrait} = useContext(ResponsiveContext);
  const {setBgImage, setStyle} = useContext(BackgroundContext);
  React.useEffect(()=>{
    setBgImage(`${process.env.PUBLIC_URL}/img/menu_bg_mobile.png`);
    setStyle({backgroundSize: "cover"});
  }, []);
  const envelopeOffset = getOffset();
  const footerHeight = (mobile || tablet && portrait) ? '0px' : '425px';
  const containerStyle: CSSProperties = {
    minHeight: `calc(100vh - ${footerHeight})`,
    ...envelopeOffset
  };

  const onOutsideEnvelopeClick = () => {
    if (open) {
      toggle();
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
    <div className={styleClass.root(className)} onClick={onOutsideEnvelopeClick}>
      <Container
        width={width}
        className={classNames(styleClass.inner(hideGutter), offsetAnimationClass)}
        style={containerStyle}
      >
        {children}
      </Container>
      {!hideFooter && <Footer/>}
    </div>
  )
});


export default Page;
