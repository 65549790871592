import React, { useState } from "react";
import {
  AlignItems,
  Arrow,
  BorderColor,
  BorderWidth,
  Button,
  ButtonSkin,
  classNames,
  Display,
  FlexDirection,
  Icon,
  IconColor,
  IconName,
  Margin,
  Padding,
  Whitespace,
  Width,
} from "@snoam/pinata";
import { GetAgreement_me_agreementsWithDetails, } from '../../../../__generated__/GetAgreement';
import {
  UpdateAgreementSettings,
  UpdateAgreementSettingsVariables
} from '../../../../__generated__/UpdateAgreementSettings';
import { FetchResult, MutationFunction, useMutation } from '@apollo/client';
import { UPDATE_AGREEMENT_SETTINGS } from '../../../../mutations';
import { EventKey, onKeyPress, removeTypename } from '../../../../utils';
import { MessageBox, MessageBoxType } from '@snoam/mono-messagebox';
import DomainList from './DomainList';
import AddDomain from "./AddDomain";

const styleClass = {
  settings: {
    section: classNames(
      BorderColor.BORDER_NEUTRAL_2,
      BorderWidth.BORDER_B_4,
      Padding.PX_4,
      Padding.MD_PX_10,
      Padding.PT_8,
      Padding.PB_10,
    ),
    sectionParagraph: classNames(
      Margin.MT_4
    ),
    emailContainer: classNames(
      Margin.MT_6,
      Display.FLEX,
      FlexDirection.FLEX_COL,
      Width.MD_W_1_3,
    ),
    emailButton: classNames(
      Margin.MY_0,
      Margin.MB_2,
      Whitespace.WHITESPACE_NO_WRAP,
      Width.W_FULL,
    ),
    addDomainButton: classNames(
      Display.FLEX,
      FlexDirection.FLEX_COL,
      AlignItems.ITEMS_CENTER,
      Width.W_FULL,
      Width.MD_W_AUTO
    )
  }
};

interface SettingsPanelProps {
  agreement: GetAgreement_me_agreementsWithDetails;
}

const VerificationSectionEmail = ({ agreement }: SettingsPanelProps) => {
  const [adding, setAdding] = useState<boolean>(false);
  const [domainMessage, setDomainMessage] = useState<JSX.Element | null>();
  const updateAgreementSettings: MutationFunction<UpdateAgreementSettings, UpdateAgreementSettingsVariables> = useMutation<UpdateAgreementSettings, UpdateAgreementSettingsVariables>(UPDATE_AGREEMENT_SETTINGS)[0];
  const {
    openidConnectClientId,
    openidConnectClientSecretMasked,
    openidConnectDiscoveryUrl,
    openidConnectSystemName,
    employeePeriodicVerificationSettings,
    ...newSettings
  } = removeTypename(agreement.settings);

  const save = (domains: string[]): Promise<FetchResult<UpdateAgreementSettings>> => {
    const variables: UpdateAgreementSettingsVariables = {
      agreementNumber: agreement.agreementNumber,
      agreementId: agreement.id,
      settings: {
        ...newSettings,
        emailDomains: domains,
      },
    };
    return updateAgreementSettings({ variables });
  };

  const addDomain = (newDomain: string) => {
    if (agreement.settings.emailDomains.includes(newDomain)) {
      updateDomainMessage(
        <MessageBox
          message={ `${ newDomain } er allerede registrert.` }
          type={ MessageBoxType.WARNING }
          arrow={ Arrow.TOP_CENTER }/>
      )
    } else {
      save([...agreement.settings.emailDomains, newDomain])
        .then(() => {
          setAdding(false);
          updateDomainMessage(
            <MessageBox
              message={ `${ newDomain } lagt til.` }
              type={ MessageBoxType.WARNING }
              arrow={ Arrow.TOP_CENTER }/>
          );
        })
        .catch((e) => {
          updateDomainMessage(
            <MessageBox
              message={ `Feil ved lagring: ${ e }` }
              type={ MessageBoxType.WARNING }
              arrow={ Arrow.TOP_CENTER }/>
          );
        });
    }
  };

  const updateDomainMessage = (message: JSX.Element | null) => {
    setDomainMessage(message);
    setTimeout(() => {
      setDomainMessage(null);
    }, 5000);
  };

  const deleteDomain = (index: number) => {
    const domains = [...agreement.settings.emailDomains];
    const removed = domains.splice(index, 1);
    save([...domains])
      .then(() => {
        updateDomainMessage(
          <MessageBox
            message={ `${ removed } slettet.` }
            type={ MessageBoxType.SUCCESS }
            arrow={ Arrow.TOP_CENTER }/>
        );
        // setNewDomain("");
      })
      .catch((e) => {
        updateDomainMessage(
          <MessageBox
            message={ `Feil ved sletting: ${ e }` }
            type={ MessageBoxType.WARNING }
            arrow={ Arrow.TOP_CENTER }/>
        );
      });
  };

  return (
    <React.Fragment>
      <p className={ styleClass.settings.sectionParagraph }>
        For å sikre at det kun er ansatte i bedriften som kan registrere seg gjøres en verifisering av de
        definerte domenene. Legg til de domenene dere benytter i bedriften her:
      </p>

      <div className={ styleClass.settings.emailContainer }>
        <DomainList
          domains={ agreement.settings.emailDomains }
          onDomainDelete={ deleteDomain }
        />
        { adding ? (
          <AddDomain
            addCallback={ addDomain }
            addDomainButtonClassname={ styleClass.settings.addDomainButton }
            emailButtonClassname={ styleClass.settings.emailButton }
            agreementSettings={ agreement.settings }
          />
        ) : (
          <div className={ styleClass.settings.addDomainButton }>
            <Button
              text={ "Legg til e-postdomene" }
              ariaLabel={ "Legg til e-postdomene" }
              iconLeft={
                <Icon
                  name={ IconName.PLUS }
                  color={ IconColor.NEUTRAL_6 }
                />
              }
              skin={ ButtonSkin.SECONDARY }
              className={ styleClass.settings.emailButton }
              onClick={ () => setAdding(true) }
              onKeyPress={ (e) => onKeyPress(e, () => setAdding(true), EventKey.ENTER) }
            />
            { domainMessage }
          </div>
        ) }
      </div>
    </React.Fragment>
  )
};

export default VerificationSectionEmail;
