import React, {ErrorInfo} from "react";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
const debug = require('debug')('minbedrift:client:errorBoundary');

interface IErrorBoundary {}

export default class ErrorBoundary extends React.Component<IErrorBoundary, { hasError: boolean; error: Error | null; }> {

  constructor(props: IErrorBoundary) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true, error};
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    // You can also log the error to an error reporting service
    debug(error, info);
  }

  render() {
    const {hasError, error} = this.state;
    const {children} = this.props;

    if (hasError) {
      return (
        <ErrorPage
          heading={'Det oppstod en feil!'}
          message={error ? error.toString() : error }
        />
      );
    }
    return <>{children}</>;
  }
}
